import React, { Component } from 'react';
import update from "react-addons-update";

import autoBind from 'react-autobind';
import './ChatScreen.css'
import BaseScreenHeader from '../BaseScreenHeader';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import EditText from '../../components/common/EditText';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Button from '../../components/common/Button';

import avatar2 from '../../images/avatar2.png';
import withRouter from '../../utils/withRouter';

class ChatScreen extends BaseScreenHeader {
  constructor(props) {
    super(props)
    autoBind(this);
    this.endRef = null;
  }

  onInitParameters(props) {
    this.state.chat_id = props.params.chatId;
    this.state.comment = "";

    this.queryChat();
  }

  onCleanupParameters() {
  }

  queryChat() {
    var action = this.session.createChat(this.state.chat_id);

    action.OnQueryRequested.register(this.onChatQueryRequested);
    action.OnQueryFinished.register(this.onChatQueryFinished);
    action.OnQueryError.register(this.onChatQueryError);

    action.OnMessagesChanged.register(this.onMessagesChanged);

    action.Query();

    return action;
  }

  onChatQueryRequested(sender, params) {
    this.beginAsyncOperation();
  }

  onChatQueryFinished(sender, params) {
    this.endAsyncOperation();

    let topic = sender.getTopic();
    let is_locked = sender.getIsLocked();

    this.setState((oldState) => { return update(oldState, { action: { $set: sender }, topic: { $set: topic }, is_locked: { $set: is_locked } }) });
  }

  onChatQueryError(sender, error) {
    this.endAsyncOperation();
    this.showError(error);
  }

  onMessagesChanged(sender, msg)
  {
    let messages = null;

    if ( msg )
    {
      messages = msg.map((x) => {
        let retval = {};

        retval.external = x.getExternal();
        retval.author_name = x.getAuthorName();
        retval.message = x.getMessage();
        retval.timestamp =  x.getTimestamp() ? x.getTimestamp().format('YYYY-MM-DD HH:mm:ss') : null;
        retval.avatar = avatar2;

        return retval;
      })
    }

    this.setState((oldState) => { return update(oldState, { messages: { $set: messages }}) });
    if ( null != this.endRef )
    {
      this.endRef.scrollIntoView({ behavior: 'smooth' });
    }
  }

  setCommentText(text)
  {
    this.setState((oldState) => { return update(oldState, { comment: { $set: text }}) });
  }

  commitComment()
  {
    let action = this.state.action.CreateMessage();

    action.setMessage(this.state.comment);

    action.OnCommitRequested.register(this.onCreateMessageCommitRequested);
    action.OnCommitFinished.register(this.onCreateMessageCommitFinished);
    action.OnCommitError.register(this.onCreateMessageCommitError);

    action.Commit();
  }

  
  onCreateMessageCommitRequested(sender, params) {
    this.beginAsyncOperation();
  }

  onCreateMessageCommitFinished(sender, params) {
    this.endAsyncOperation();
    this.setState((oldState) => { return update(oldState, { comment: { $set: "" } }) });
  }

  onCreateMessageCommitError(sender, error) {
    this.endAsyncOperation();
    this.showError(error);
  }


  renderMessage(message)
  {
    return (
      <div key={message.external} className="chat-message" >
        <div className="w3-col m2 w3-center-child">
          <img className="w3-circle chat-avatar" src={message.avatar} />
        </div>
        <div className="w3-col m10 w3-container">
          <h4>{message.author_name} <span className="w3-opacity w3-medium">{message.timestamp}</span></h4>
          <p>{message.message}</p>
        </div>
      </div>
    )
  }

  renderReplyForm()
  {
    return ( <div className="w3-container">
                <h4>Odpowiedź:</h4>
                <textarea className="w3-bar chat-comment" value={this.state.comment} onChange={(event) => { this.setCommentText(event.target.value) }} disabled={this.state.asyncOperation}></textarea>
                <div className="w3-quarter w3-right w3-margin-top">
                    <Button onClicked={() => { this.commitComment()}} disabled={this.state.comment == null || this.state.comment.length < 1 || this.state.asyncOperation} text="Wyślij" iconLeft={this.state.asyncOperation ? faSync : null} />
                </div>
            </div>)
  }

  
  renderScreen(child)
  {
      // if ( null == this.state.chat )
      // {
      //     return super.renderScreen(
      //         <div>Trwa wczytywanie danych</div>
      //     );
      // }
      let messages = null;
      if ( this.state.messages )
      {
        messages = this.state.messages.map((x) => this.renderMessage(x));
      }

      return super.renderScreen(
        <header className="w3-container w3-anchor">
          <div className="w3-container chat-container">
              <h4><b>{this.state.topic}</b></h4>
              {messages}
              <div ref={(e) => { this.endRef = e }}/>
              {!this.state.is_locked && this.renderReplyForm()}
          </div>
        </header>
      )
  }

};

export default withRouter(ChatScreen);
import ICurrentResults, { IClassResults, IPlayerResult } from "../../../sdk/data/livescreen/ICurrentResults";

export class PlayerResult extends IPlayerResult
{
    constructor()
    {
        super();

        this.driver_number = null;
        this.driver_name = null;
        this.driver_car = null;
        this.best_et = null;
        this.best_speed = null;
    }

    getDriverNumber() {
        return this.driver_number;
    }
    getDriverName() {
        return this.driver_name;
    }
    getDriverCar() {
        return this.driver_car;
    }
    getBestEt() {
        return this.best_et;
    }
    getBestSpeed() {
        return this.best_speed;
    }
}

export class ClassResults extends IClassResults
{
    constructor()
    {
        super();
        
        this.class_name = null;
        this.timestamp = null;
        this.results = null;
    }

    getClassName() {
        return this.class_name;
    }

    getTimestamp() {
        return this.timestamp;
    }

    getResults() {
        return this.results;
    }
}

export class CurrentResults extends ICurrentResults
{
    constructor()
    {
        super();
        this.results = null;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    hasResults() {
        return this.results != null;
    }

    getResults() {
        return this.results;
    }
}
import React, { Component, useEffect, useRef, useState, useCallback } from "react";
import { Link, Navigate } from "react-router-dom";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import "./AttendanceAdminScreen.css";
import { DrawBottomRight } from "../LayoutHeader";
import BaseScreenHeader from "../BaseScreenHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStopwatch, faFlagCheckered, faCog, faDownload, faCheckSquare, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { base64StringToBlob } from "blob-util";
import Utils from "../../utils/Utils";
import TextField from "@mui/material/TextField";
import { IconButton, Stack, Tooltip } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Checkbox from "@mui/material/Checkbox";

import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Button from "@mui/material/Button";
import { AttendanceAcceptPopup } from "./AttendanceAcceptPopup";
import { AttendanceRejectPopup } from "./AttendanceRejectPopup";

import { SimplePopup } from "../../components/common/SimplePopup";
import Box from "@mui/material/Box";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

export const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AttendanceAdminScreen = ({ session }) => {
  const urlParams = useParams();
  const navigate = useNavigate();

  const [eventId, setEventId] = useState(null);
  const [leagues, setLeagues] = useState(null);

  const [event, setEvent] = useState(null);
  const [users, setUsers] = useState(null);

  const [isAsyncOperation, setAsyncOperation] = useState(0);
  const [commitErrors, setCommitErrors] = useState(null);
  const [commitSuccess, setCommitSuccess] = useState(null);
  const [refreshData, setRefreshData] = useState(0);

  const [acceptPopup, setAcceptPopup] = useState(null);
  const [rejectPopup, setRejectPopup] = useState(null);

  const [emailMode, setEmailMode] = useState(false);
  const [dataEmailTitle, setDataEmailTitle] = useState(undefined);
  const [dataEmailTemplate, setDataEmailTemplate] = useState(undefined);
  const [dataEmailReplyTo, setDataEmailReplyTo] = useState(undefined);
  const [dataEmailRecipient, setDataEmailRecipient] = useState([]);

  useEffect(() => {
    setEventId(urlParams.eventId);
  }, [urlParams]);

  useEffect(() => {
    const leagueChangedCallback = (sender, leagues) => {
      setLeagues(leagues);
    };
    session.onLeaguesChanged.register(leagueChangedCallback);

    setLeagues(session.getLeagues());
    return () => {
      session.onPersonalizeChanged.unregister(leagueChangedCallback);
    };
  }, []);

  useEffect(() => {
    if (null == eventId) {
      return;
    }
    if (null == leagues) {
      return;
    }
    for (const l of leagues) {
      if (null != l.getEvents()) {
        for (const e of l.getEvents()) {
          if (eventId != e.getExternal()) {
            continue;
          }
          let event = {};
          event.external = e.getExternal();
          event.shortname = e.getShortname();
          event.description = e.getDescription();
          event.location = e.getLocation();
          event.thumbnail = e.getThumbnail();
          event.timestamp_start = e.getTimestampStart();

          event.league_id = l.getExternal();
          event.league_shortname = l.getShortname();

          event.ob = e;
          setEvent(event);
          return;
        }
      }
    }
    return;
  }, [leagues, eventId]);

  useEffect(() => {
    if (null == eventId) {
      return;
    }

    const commitRequested = (sender, params) => {
      setAsyncOperation((prev) => prev + 1);
    };

    const commitFinished = (sender, params) => {
      setAsyncOperation((prev) => prev - 1);

      let users = sender.getRegistered();
      setUsers(users);
    };

    const commitError = (sender, error) => {
      setAsyncOperation((prev) => prev - 1);
      setCommitErrors(error.message);
    };

    let action = session.createAttendanceList(eventId);

    action.OnCommitRequested.register(commitRequested);
    action.OnCommitFinished.register(commitFinished);
    action.OnCommitError.register(commitError);

    action.Commit();
  }, [eventId, refreshData]);

  const download = () => {
    let action = session.createAttendanceList(eventId);

    const downloadRequested = (sender, params) => {
      setAsyncOperation((prev) => prev + 1);
    };
    const downloadFinished = (sender, params) => {
      setAsyncOperation((prev) => prev - 1);

      var data = sender.getData();
      var blob = base64StringToBlob(data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      var url = window.URL.createObjectURL(blob);

      var anchorElem = document.createElement("a");
      anchorElem.style = "display: none";
      anchorElem.href = url;
      anchorElem.download = "obecnosc.xls";

      document.body.appendChild(anchorElem);
      anchorElem.click();
      document.body.removeChild(anchorElem);

      // On Edge, revokeObjectURL should be called only after
      // a.click() has completed, atleast on EdgeHTML 15.15048
      setTimeout(function () {
        window.URL.revokeObjectURL(url);
      }, 1000);
    };
    const downloadError = (sender, error) => {
      setAsyncOperation((prev) => prev - 1);
      setCommitErrors(error.message);
    };

    action.OnDownloadRequested.register(downloadRequested);
    action.OnDownloadFinished.register(downloadFinished);
    action.OnDownloadError.register(downloadError);

    action.Download();
  };

  const doAcceptAttendance = (external, method, amount, date) => {
    const commitRequested = (sender, params) => {
      setAsyncOperation((prev) => prev + 1);
      setAcceptPopup(null);
    };

    const commitFinished = (sender, params) => {
      setAsyncOperation((prev) => prev - 1);
      setRefreshData((prev) => prev + 1);
    };

    const commitError = (sender, error) => {
      setAsyncOperation((prev) => prev - 1);
      setCommitErrors(error.message);
    };

    let action = session.createAttendanceAccept(external);

    action.setPaymentMethod(method);
    action.setPaymentAmount(amount);
    action.setPaymentDate(date);

    action.OnCommitRequested.register(commitRequested);
    action.OnCommitFinished.register(commitFinished);
    action.OnCommitError.register(commitError);

    action.Commit();
  };

  const doRejectAttendance = (external) => {
    const commitRequested = (sender, params) => {
      setAsyncOperation((prev) => prev + 1);
      setRejectPopup(null);
    };

    const commitFinished = (sender, params) => {
      setAsyncOperation((prev) => prev - 1);
      setRefreshData((prev) => prev + 1);
    };

    const commitError = (sender, error) => {
      setAsyncOperation((prev) => prev - 1);
      setCommitErrors(error.message);
    };

    let action = session.createAttendanceReject(external);

    action.OnCommitRequested.register(commitRequested);
    action.OnCommitFinished.register(commitFinished);
    action.OnCommitError.register(commitError);

    action.Commit();
  };

  const doEmailTest = () => {
    const testRequested = (sender, params) => {
      setAsyncOperation((prev) => prev + 1);
    };

    const testFinished = (sender, params) => {
      setAsyncOperation((prev) => prev - 1);
      setCommitSuccess("Testowy email zostal wyslany");
    };

    const testError = (sender, error) => {
      setAsyncOperation((prev) => prev - 1);
      setCommitErrors(error.message);
    };

    let action = session.createAttendanceEmail();

    action.setEmailTitle(dataEmailTitle);
    action.setEmailTemplate(dataEmailTemplate);
    action.setEmailReplyTo(dataEmailReplyTo);

    action.OnTestRequested.register(testRequested);
    action.OnTestFinished.register(testFinished);
    action.OnTestError.register(testError);

    action.Test();
  };

  const doSendEmail = () => {
    const commitRequested = (sender, params) => {
      setAsyncOperation((prev) => prev + 1);
    };

    const commitFinished = (sender, params) => {
      setAsyncOperation((prev) => prev - 1);
      setCommitSuccess("Emaile zostaly wyslane");
    };

    const commitError = (sender, error) => {
      setAsyncOperation((prev) => prev - 1);
      setCommitErrors(error.message);
    };

    let action = session.createAttendanceEmail();

    action.setTokens(dataEmailRecipient);
    action.setEmailTitle(dataEmailTitle);
    action.setEmailTemplate(dataEmailTemplate);
    action.setEmailReplyTo(dataEmailReplyTo);

    action.OnCommitRequested.register(commitRequested);
    action.OnCommitFinished.register(commitFinished);
    action.OnCommitError.register(commitError);

    action.Commit();
  };

  const renderScreen = () => {
    if (null == users || null == event) {
      return (
        <header className="w3-container w3-anchor">
          <h5>
            <b>Trwa wczytywanie danych</b>
          </h5>
        </header>
      );
    }
    if (users.length < 1) {
      return (
        <header className="w3-container w3-anchor">
          <h5>
            <b>Brak zapisanych uczesników na wydarzenie</b>
          </h5>
        </header>
      );
    }

    let _users = users.map((x, idx) => {
      return (
        <tr key={x.external}>
          {emailMode && (
            <td data-title="Lp">
              <Checkbox
                checked={dataEmailRecipient.find((ob) => ob == x.external)}
                onChange={(event) => {
                  if (event.target.checked) {
                    let newData = dataEmailRecipient.slice();
                    newData.push(x.external);
                    setDataEmailRecipient(newData);
                  } else {
                    let newData = dataEmailRecipient.filter((ob) => ob != x.external);
                    setDataEmailRecipient(newData);
                  }
                }}
              />
            </td>
          )}
          {!emailMode && <td data-title="Lp">{idx + 1}</td>}
          <td data-title="Zawodnik">{x.getName()}</td>
          <td data-title="Email">{x.getEmail()}</td>
          <td data-title="Pojazd">{x.getDescription()}</td>
          <td data-title="Numer startowy">{x.getAssignedNumber() ? x.getAssignedNumber() : "BRAK"}</td>
          <td data-title="Dodatkowe osoby">{x.getCrewCount()}</td>
          <td data-title="Status">{Utils.map_values({ PENDING: "Zgłoszone", ACCEPTED: "Potwierdzone", REJECTED: "Odrzucone", CANCELLED: "Anulowane" }, x.getStatus())}</td>
          <td data-title="Data zgłoszenia">{x.getTimestampCreation() ? x.getTimestampCreation().format("YYYY-MM-DD HH:mm:ss") : null}</td>
          <td data-title="Płatność">{Utils.map_values({ TRANSFER: "Przelew", ONLINE: "Online", ONSITE: "W dniu imprezy" }, x.getPaymentType())}</td>
          <td data-title="Data potwierdzenia">{x.getTimestampAcceptance() ? x.getTimestampAcceptance().format("YYYY-MM-DD HH:mm:ss") : null}</td>
          <td data-title="Potwierdzone przez">{x.getAcceptedBy()}</td>

          <td data-title="Akcje" className="w3-inline">
            {"PENDING" == x.getStatus() && <FontAwesomeIcon className="w3-clickable" icon={faCheckSquare} onClick={(e) => setAcceptPopup(x)} />}
            {"PENDING" == x.getStatus() && <FontAwesomeIcon className="w3-clickable" icon={faTimesCircle} onClick={(e) => setRejectPopup(x)} />}
          </td>
        </tr>
      );
    });

    return (
      <header className="w3-container w3-anchor">
        <h5>
          Zapisani na wydarzenie{" "}
          <b>
            {event.league_shortname} - {event.shortname}
          </b>
        </h5>
        {event.timestamp_start && (
          <h5>
            <b>
              {event.location}: {event.timestamp_start.format("YYYY-MM-DD HH:mm:ss")}
            </b>
          </h5>
        )}
        {emailMode && renderEmailForm()}
        <div className="w3-responsive-table">
          <table className="w3-table-all">
            <thead>
              <tr>
                {emailMode && <th>Wyślij mail</th>}
                {!emailMode && <th>Lp</th>}
                <th>Zawodnik</th>
                <th>Email</th>
                <th>Pojazd</th>
                <th>Numer startowy</th>
                <th>Dodatkowe osoby</th>
                <th>Status</th>
                <th>Data zgloszenia</th>
                <th>Płatność</th>
                <th>Data potwierdzenia</th>
                <th>Potwierdzone przez</th>
                <th>Akcje</th>
              </tr>
            </thead>
            <tbody>{_users}</tbody>
          </table>
        </div>
        <div className="w3-container w3-float-topright w3-clickable">
          <h5 onClick={(e) => download()}>
            <FontAwesomeIcon icon={faDownload} /> Pobierz
          </h5>
        </div>
      </header>
    );
  };

  const renderEmailForm = () => {
    return (
      <div className="w3-container register-form">
        <form
          className="w3-form"
          autoComplete={true}
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
        >
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Tytul email"
              variant="standard"
              type={"text"}
              value={dataEmailTitle}
              onChange={(ev) => {
                setDataEmailTitle(ev.target.value);
              }}
              id="data-email-title"
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Adres na ktory bedzie domyslna odpowiedz na email"
              variant="standard"
              type={"text"}
              value={dataEmailReplyTo}
              onChange={(ev) => {
                setDataEmailReplyTo(ev.target.value);
              }}
              id="data-email-reply-to"
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <Stack direction="row" useFlexGap flexWrap="wrap" alignItems="center" justifyContent="start" spacing={3}>
              <p>Tresc email:</p>
              <Tooltip title="Wyslij testowo do siebie">
                <IconButton
                  onClick={() => {
                    doEmailTest();
                  }}
                >
                  <SendIcon />
                </IconButton>
              </Tooltip>
            </Stack>

            <TextareaAutosize
              variant="standard"
              placeholder="Szablon e-mail"
              minRows={2}
              value={dataEmailTemplate}
              onChange={(ev) => {
                setDataEmailTemplate(ev.target.value);
              }}
              id="data-registration-email-template"
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "25%" } }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                doSendEmail();
              }}
              disabled={isAsyncOperation || !dataEmailTitle || !dataEmailTemplate}
            >
              Wyslij email do zaznaczonych
            </Button>
          </Box>
        </form>
      </div>
    );
  };

  const renderSpeedMenu = () => {
    let actions = [];
    if (!emailMode) {
      actions.push(
        <SpeedDialAction
          key="show-email"
          icon={<SendIcon />}
          tooltipTitle={"Wyslij email"}
          tooltipOpen
          onClick={() => {
            setDataEmailRecipient(users.map((x) => x.external));
            setEmailMode(true);
          }}
        />
      );
    } else {
      actions.push(
        <SpeedDialAction
          key="hide-email"
          icon={<SendIcon />}
          tooltipTitle={"Schowaj email"}
          tooltipOpen
          onClick={() => {
            setDataEmailRecipient([]);
            setDataEmailReplyTo(null);
            setDataEmailTitle(null);
            setDataEmailTemplate(null);
            setEmailMode(false);
          }}
        />
      );
    }

    return (
      <DrawBottomRight>
        <SpeedDial ariaLabel="SpeedDial playground example" hidden={false} icon={<SpeedDialIcon />} direction={"up"} sx={{ position: "absolute", bottom: 0, right: 0 }}>
          {actions}
        </SpeedDial>
      </DrawBottomRight>
    );
  };

  return (
    <Box>
      {renderSpeedMenu()}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={commitErrors != null || commitSuccess != null}
        autoHideDuration={6000}
        onClose={() => {
          setCommitErrors(null);
          setCommitSuccess(null);
        }}
        sx={{ minWidth: "50%" }}
      >
        <Alert severity={null != commitErrors ? "error" : "success"} sx={{ width: "100%" }}>
          {commitErrors ? commitErrors : commitSuccess}
        </Alert>
      </Snackbar>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isAsyncOperation > 0}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {null != acceptPopup && (
        <AttendanceAcceptPopup
          processing={isAsyncOperation > 0}
          session={session}
          name={acceptPopup.getName()}
          description={acceptPopup.getDescription()}
          assigned_number={acceptPopup.getAssignedNumber()}
          token={acceptPopup.getExternal()}
          onClose={(e) => setAcceptPopup(null)}
          onAccept={(external, method, amount, date) => {
            doAcceptAttendance(external, method, amount, date);
          }}
        />
      )}

      {null != rejectPopup && (
        <AttendanceRejectPopup
          processing={isAsyncOperation > 0}
          session={session}
          name={rejectPopup.getName()}
          description={rejectPopup.getDescription()}
          assigned_number={rejectPopup.getAssignedNumber()}
          token={rejectPopup.getExternal()}
          onClose={(e) => setRejectPopup(null)}
          onReject={(external) => {
            doRejectAttendance(external);
          }}
        />
      )}

      {renderScreen()}
    </Box>
  );
};

import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom'
import update from "react-addons-update";

import autoBind from 'react-autobind';
import './EventAddScreen.css'
import BaseScreenHeader from '../../BaseScreenHeader';
import InputField from '../../../components/common/InputField';
import { faSync, faSmile, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import PhotoBox from '../../../components/common/PhotoBox';
import Button from '../../../components/common/Button';
import Utils from '../../../utils/Utils';
import HelpIcon from '../../../components/common/HelpIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withRouter from '../../../utils/withRouter';

class EventAddScreen extends BaseScreenHeader {
    static defaultProps = {
        session: null
    }
    constructor(props) {
        super(props)
        autoBind(this);
    }

    validatePermission()
    {
        return this.props.session.getPermissions().hasPermissionGlobal('can_edit_league') || this.props.session.getPermissions().hasPermissionContext('can_edit_league', this.props.params.leagueId);
    }

    onInitParameters(bundle)
    {
      this.state.league = this.createLeague(this.props.params.leagueId);
      return true;
    }
  
    bindDelegates()
    {
        this.session.onLeaguesChanged.register(this.onLeaguesChanged);
        super.bindDelegates();
    }
    unbindDelegates()
    {
        this.session.onLeaguesChanged.unregister(this.onLeaguesChanged);
        super.unbindDelegates();
    }
    onLeaguesChanged(sender, data)
    {
        var league = this.createLeague(this.props.params.leagueId);
  
        this.setState((oldState) => { return update(oldState, { league: { $set: league }})});
    }

    
    createLeague(id)
    {
        let data = this.session.getLeagues();
        if ( null == data )
        {
            return null;
        }
        for ( const l of data )
        {
            if ( l.getExternal() != id )
            {
                continue;
            }
            let league = {};
            league.external = l.getExternal();
            league.shortname = l.getShortname();
            league.description = l.getDescription();
            league.thumbnail = l.getThumbnail();
            league.is_finished = l.getIsFinished();
            league.ob = l;
              return league;
        }
        return null;
    }

    onSubmit()
    {
        let errors = [];
  
        let shortname = this.getFormData('shortname');
        if ( !shortname )
        {
            errors.push('Nazwa klasy nie moze byc pusta');
        }
        let description = this.getFormData('description');
        if ( !description )
        {
            errors.push('Opis nie może byc pusty');
        }      
        let location = this.getFormData('location');
        let gps = this.getFormData('gps');
        let timestamp_start = this.getFormData('timestamp_start');
        let timestamp_end = this.getFormData('timestamp_end');
        
        if ( errors.length > 0 )
        {
            this.showMessage(errors);
            return;
        }
  
        var action = this.state.league.ob.createEventCreate();

        action.setShortname(shortname);
        action.setDescription(description);
        action.setLocation(location);
        action.setGps(gps);
        action.setTimestampStart(timestamp_start);
        action.setTimestampEnd(timestamp_end);
  
        action.OnCommitRequested.register(this.onEventCreateCommitRequested);
        action.OnCommitFinished.register(this.onEventCreateCommitFinished);
        action.OnCommitError.register(this.onEventCreateCommitError);
  
        action.Commit();
  
    }

    onEventCreateCommitRequested(sender, params) {
        this.beginAsyncOperation();
    }

    onEventCreateCommitFinished(sender, params) {
        this.endAsyncOperation();
        this.showAndRedirect('Wydarzenie zostalo dodane', '/manage/league/' + this.state.league.external);
    }

    onEventCreateCommitError(sender, err) {
        this.endAsyncOperation();

        this.showError(err);
    }

    renderScreen(child) {
        let league = this.state.league;

        if ( null == league )
        {
            return super.renderScreen(
                <div>Trwa wczytywanie danych</div>
            );
        }

        return super.renderScreen(
            <header className="w3-container">
                <h5>Nowa wydarzenie dla ligi <b>{league.shortname}</b></h5>
                <div className="w3-container">
                    <h6>Nazwa wydarzenia</h6>
                    <InputField id="event-shortname" onCommit={(value) => this.storeFormData('shortname', value) } />
                    <h6>Opis wydarzenia</h6>
                    <InputField id="event-description" onCommit={(value) => this.storeFormData('description', value) } />
                    <h6>Lokalizacja wydarzenia</h6>
                    <InputField id="event-location" onCommit={(value) => this.storeFormData('location', value) } />
                    <h6>Pinezka GPS</h6>
                    <InputField id="event-gps" onCommit={(value) => this.storeFormData('gps', value) } validate={Utils.validate_gps} />
                    <h6>Data i godzina rozpoczecia</h6>
                    <InputField id="event-timestamp-start" onCommit={(value) => this.storeFormData('timestamp_start', value) } validate={Utils.validate_datetime} />
                    <h6>Data i godzina zakonczenia</h6>
                    <InputField id="event-timestamp-end" onCommit={(value) => this.storeFormData('timestamp_end', value)  } validate={Utils.validate_datetime}/>

                </div>

                <div className="w3-quarter w3-right w3-margin">
                    <Button onClicked={() => { this.onSubmit()}} disabled={this.state.asyncOperation || null == this.getFormData('shortname') || null == this.getFormData('description')} text="Dodaj" iconLeft={this.state.asyncOperation ? faSync : null} />
                </div>                
            </header>);

    }
}
export default withRouter(EventAddScreen);
import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import './AttendanceRejectPopup.css'

import moment from 'moment';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const AttendanceRejectPopup = ({ session, token, name, description, processing, assigned_number, onClose = () => {}, onReject = (external) => {}}) => 
{

  return ( 
    <Dialog open={true} fullScreen={false} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth={true} maxWidth="md">
      <DialogContent>
        <div className="AttendanceRejectPopup">
            <table>
                <tbody>
                  <tr><td className="PlayerNumberPopupCaption">Uzytkownik</td><td className="PlayerNumberPopupValue">{name}</td></tr>
                  <tr><td className="PlayerNumberPopupCaption">Auto</td><td className="PlayerNumberPopupValue">{description}</td></tr>
                  {assigned_number && 
                      <tr><td className="PlayerNumberPopupCaption">Numer startowy</td><td className="PlayerNumberPopupValue">{assigned_number}</td></tr>
                  }
                </tbody>
            </table>
          </div>
          </DialogContent>
        <DialogActions>
                <Button onClick={() => onClose()}>Anuluj</Button>
                <Button onClick={() => onReject(token)} autoFocus>Odrzuć</Button>
        </DialogActions>
      </Dialog>);
}

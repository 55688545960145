import React, { Component, useEffect, useRef, useState } from 'react';
import update from "react-addons-update";
import { Link } from 'react-router-dom'

import autoBind from 'react-autobind';
import './BaseScreenHeader.css'
import Header from 'components/Header';
import PopupAlert from 'components/common/PopupAlert';
import Database from 'Database';
import Footer from '../components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import scs_logo from '../images/logo/scslogo.png';
import avatar6 from '../images/avatar6.png';
import avatar2 from '../images/avatar2.png';
import { faCog, faUser, faEnvelope, faSignInAlt, faSignOutAlt, faUsers, faCar, faBookDead, faBookReader, faFilter } from '@fortawesome/free-solid-svg-icons'
import Loader from '../components/common/Loader';
import { SimplePopup } from '../components/common/SimplePopup';
import { Portal } from '@mui/base/Portal';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

export const DrawBottomRight = ({children}) => {
    return <Portal container={document.getElementById('w3-bottom-right')}>{children}</Portal>
}

export const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export const LayoutHeader = ({session, children}) =>
{    
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [canSeeApplications, setCanSeeApplications] = useState(false);
    const [menuDisplay, setMenuDisplay] = useState('block');

    const [logoutDialog, setLogoutDialog] = useState(false);
    
    useEffect(() => { 
        const personalizedCallback = (sender, value) => {
            setLoggedIn(value) ;
        }
        session.onPersonalizeChanged.register(personalizedCallback);

        setLoggedIn(session.getPersonalized()) ;
        setCanSeeApplications(session.getPermissions().hasPermissionGlobal('menu_applications_vehicle'));
        return () => {
            session.onPersonalizeChanged.unregister(personalizedCallback);
        }
    }, []);

    useEffect(() => {
        function handleResize() {
            if ( window.innerWidth < 700 )
            {
                setMenuDisplay('none');
            }
            else
            {
                setMenuDisplay('block');
            }
        }
        
        window.addEventListener('resize', handleResize)
    })

    const toggleSideMenu = () => {
        if ( 'block' == menuDisplay )
        {
            setMenuDisplay('none');
            return;
        }
        setMenuDisplay('block');
    }

    const closeSideMenu = () => {
        setMenuDisplay('none');
    }

    const doLogout = () => {
        Database.instance().Reset();
        setLogoutDialog(false);
    }

  const renderUserData = () =>
  {
    let user = session.getUser();
    if ( !isLoggedIn || null == user)
    {
      var redirectUrl = "/account/login";
      var url = window.location.pathname;
      if ( url.length > 1 )
      {
        redirectUrl = redirectUrl + "?redirect=" + encodeURIComponent(url);
      }
      return (                  
        <div className="w3-container w3-row w3-anchor">
          <div className="w3-col s4">
            <img src={avatar6} className="w3-circle w3-margin-right" style={{width: 46 + 'px'}}/>
          </div>
          <div className="w3-col s8 w3-bar">
            <span>Witaj, <strong>nieznajomy</strong></span><br/>
            <Link data-tooltip="Zaloguj się do serwisu" to={redirectUrl}  className="w3-bar-item w3-button"><FontAwesomeIcon icon={faSignInAlt} /></Link>
          </div>
        </div>);
    }

    

    return (
      <div className="w3-container w3-row">
        <div className="w3-col s4">
          <img src={avatar2} className="w3-circle w3-margin-right" style={{width: 46 + 'px'}}/>
        </div>
        <div className="w3-col s8 w3-bar">
          <span>Witaj, <strong>{user.getNick()}</strong></span><br/>
          <Link data-tooltip="Przejdź do wiadomości" to="/" className="w3-bar-item w3-button"><FontAwesomeIcon icon={faEnvelope} /></Link>
          <Link data-tooltip="Przejdź do ustawien profilu" to="/account/profile" className="w3-bar-item w3-button"><FontAwesomeIcon icon={faUser} /></Link>
          <Link data-tooltip="Wyloguj się" to="#" onClick={(e) => setLogoutDialog(true) } className="w3-bar-item w3-button"><FontAwesomeIcon icon={faSignOutAlt} /></Link>
        </div>
      </div>      
    );
  }

  const renderUserFilters = () =>
  {
     let filters = session.getFilters();
     if ( null == filters )
     {
       return null;
     }
     if ( filters.length < 1 )
     {
       return null;
     }

     let data = filters.map((x) => {
       return <Link key={x.url} to={x.url} className="w3-bar-item w3-button w3-padding"><FontAwesomeIcon icon={faFilter} /> {x.shortname}</Link>
     });
     return data;
  }

    return ( 
        <div className="BaseScreen">
            <div className="BaseScreenHeader">
                <SimplePopup open={logoutDialog} fullScreen={false} title='Czy na pewno chcesz sie wylogować?'  accept='TAK' reject='NIE' onAccepted={doLogout} onRejected={() => { setLogoutDialog(false)}} />
            {/* <!-- Top container --> */}
            <div className="w3-bar w3-top w3-grey w3-large" style={{zIndex:4}}>
              <button className="w3-bar-item w3-button w3-hide-large w3-hover-none w3-hover-text-light-grey" onClick={toggleSideMenu}><i className="fa fa-bars"></i>  Menu</button>
              <span className="w3-bar-item w3-right">
                <a href="/"><img src={scs_logo} className="scs-logo" /></a>
              </span>
              <div className="screen-menu-loader" />
            </div>
            {/* <!-- Sidebar/menu --> */}
            <nav className="w3-sidebar w3-collapse w3-white w3-animate-left w3-anchor" style={{zIndex:3, width:300 + 'px', display: menuDisplay}} id="mySidebar" >
              <br/>
              {renderUserData()}
              <hr/>
              <div className="w3-bar-block">
                <a href="#" className="w3-bar-item w3-button w3-padding-16 w3-hide-large w3-dark-grey w3-hover-black" onClick={() => this.closeSideMenu()} title="close menu"><i className="fa fa-remove fa-fw"></i>  Close Menu</a>
                <Link to="/events" className="w3-bar-item w3-button w3-padding"><FontAwesomeIcon icon={faUsers} />  Wydarzenia</Link>
                {isLoggedIn && <Link to="/garage" className="w3-bar-item w3-button w3-padding"><FontAwesomeIcon icon={faCar} />  Twój garaż</Link> }
                {isLoggedIn && <Link to="/applications/mine" className="w3-bar-item w3-button w3-padding"><FontAwesomeIcon icon={faBookDead} />  Twoje zgłoszenia</Link> }
                {canSeeApplications && <Link to="/applications/view" className="w3-bar-item w3-button w3-padding"><FontAwesomeIcon icon={faBookReader} />  Zgłoszenia zawodników</Link> }
                {renderUserFilters()}
              </div>
            </nav>


            {/* <!-- Overlay effect when opening sidebar on small screens --> */}
            <div className="w3-overlay w3-hide-large w3-animate-opacity" onClick={closeSideMenu} style={{cursor:'pointer', display: menuDisplay}} title="close side menu" id="myOverlay"></div>

            {/* <!-- !PAGE CONTENT! --> */}
            <div className="w3-main" style={{marginLeft:300 + 'px', marginTop:43 + 'px'}}>
              {children}
            </div>

            <div className="w3-main-bottom-right" id="w3-bottom-right"/>
          </div>
        </div>);
};

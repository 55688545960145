import IUpdatePlayer from "../../../../sdk/data/manage/player/IUpdatePlayer";

export class UpdatePlayer extends IUpdatePlayer
{
    constructor()
    {
        super();
        
        this.external = null;
        this.player_name = null;
        this.player_car = null;
        this.car_power = null;
        this.car_mass = null;
        this.team_name = null;
    }

    getExternal()
    {
        return this.external;
    }
    getPlayerName()
    {
        return this.player_name;
    }
    setPlayerName(value)
    {
        this.player_name = value;
    }

    getPlayerCar()
    {
        return this.player_car;
    }
    setPlayerCar(value)
    {
        this.player_car = value;
    }

    getCarPower()
    {
        return this.car_power;
    }
    setCarPower(value)
    {
        this.car_power = value;
    }

    getCarMass()
    {
        return this.car_mass;
    }
    setCarMass(value)
    {
        this.car_mass = value;
    }
    getTeamName()
    {
        return this.team_name;
    }
    setTeamName(value)
    {
        this.team_name = value;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
        return true;
    }
}
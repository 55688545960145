import Event from "../../property/Event";

export default class ICreateChat
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    getTopic()
    {
        throw new TypeError("Method not implemented");
    }

    setTopic(value)
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }
}
import { Session } from './internal/Session'
import { Public as Environment }  from './environment/Public';
// import { Development as Environment }  from './environment/Development';
// import { Test as Environment }  from './environment/Test';

export default class Lisev
{
    static /*Session*/ createSession(security)
    {
        var env = new Environment();
        return new Session(env, security);
    }
}
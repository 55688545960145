import Log from '../internal/logger/Log';

export default class Engine
{
    constructor()
    {

    }

    static createError(/*JSONObject*/ error)
    {
        var code = error.opt('code', 'ERROR');
        var message = error.opt('message', 'Wystąpił nieznany błąd');
        return new Error(code, message);
    }

    static createThread(func)
    {
        var loadedScript = "var context = {};\nonmessage = " + func.toString();
        Log.i('THREAD', 'Script: ' + loadedScript);

        var blob = new Blob([loadedScript]);
        // Obtain a blob URL reference to our worker 'file'.
        var blobURL = window.URL.createObjectURL(blob);
        
        // create a Worker
        var worker = new Worker(blobURL);
        return worker;
    }
}

export class Error
{
    constructor(code, message)
    {
        this.code = code;
        this.message = message;
    }

    getCode()
    {
        return this.code;
    }

    getMessage()
    {
        return this.message;
    }
}
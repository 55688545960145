import IServiceRequest from "../../../connect/IServiceRequest";

export default class EventExampleEmailCommit extends IServiceRequest
{
    constructor()
    {
        super('www/event/example_email');
    }
    
    setExternal(value)
    {
        this.appendRequest("external", value);
    }
    
    setTemplateAttendanceEmail(value)
    {
        this.appendRequest("template_attendance_email", value);
    }

    setCrewCount(value)
    {
        this.appendRequest("crew_count", value);
    }
}

import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import './AttendanceAcceptPopup.css'
import BaseScreenHeader from '../BaseScreenHeader';
import Utils from '../../utils/Utils';
import Combobox from '../../components/common/Combobox';
import InputField from '../../components/common/InputField';

import { faSync, faThList } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const AttendanceAcceptPopup = ({session, token, name, description, assigned_number, default_amount, processing, onClose = () => {}, onAccept = (external, method, amount, date) => {} }) => {

  const [ paymentMethod, setPaymentMethod ] = useState(null);
  const [ paymentAmount, setPaymentAmount ] = useState(null);
  const [ paymentDate, setPaymentDate ] = useState(null);

  useEffect( () => {
    setPaymentMethod('TRANSFER');
    setPaymentAmount(default_amount);
    setPaymentDate(moment().format('Y-MM-DD'))
  }, []);

  const renderPopup = () => {
    let payment_types = [ 
        { label: 'Przelew', value: 'TRANSFER', key: 'TRANSFER' },
        { label: 'W dniu imprezy', value: 'ONSITE', key: 'ONSITE' }];

    return ( <div className="AttendanceAcceptPopup">
                <table>
                    <tr><td className="PlayerNumberPopupCaption">Uzytkownik</td><td className="PlayerNumberPopupValue">{name}</td></tr>
                    <tr><td className="PlayerNumberPopupCaption">Auto</td><td className="PlayerNumberPopupValue">{description}</td></tr>
                    {assigned_number && 
                        <tr><td className="PlayerNumberPopupCaption">Numer startowy</td><td className="PlayerNumberPopupValue">{assigned_number}</td></tr>
                    }
                    <tr><td className="">Sposób płatności</td><td><Combobox multiple={false} value={paymentMethod} children={payment_types} onChange={(value) => { setPaymentMethod(value)}} /></td></tr>
                    {paymentMethod == "TRANSFER" &&
                        <tr><td className="">Kwota</td><td><InputField text={paymentAmount} validate={(value) => {  return null; }  } onCommit={(value) => { setPaymentAmount(value) } } /></td></tr>
                    }
                    {paymentMethod == "TRANSFER" &&
                        <tr><td className="">Data płatności</td><td><InputField text={paymentDate} validate={(value) => {  return null; }  } onCommit={(value) => { setPaymentDate(value)} } /></td></tr>
                    }
                </table>
               
              </div> );
  }

  return (
      <Dialog open={true} fullScreen={false} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth={true} maxWidth="md">
        <DialogContent>
            {renderPopup()}
        </DialogContent>
        <DialogActions>
                <Button onClick={() => onClose()}>Anuluj</Button>
                <Button onClick={() => onAccept(token, paymentMethod, paymentAmount, paymentDate)} autoFocus>Zaakceptuj</Button>
        </DialogActions>
      </Dialog>
  )
}

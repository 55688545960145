import IServiceRequest from '../../connect/IServiceRequest'

export default class SessionChangePasswordCommit extends IServiceRequest
{
    constructor()
    {
        super('www/session/password');
    }

    setOldPassword(value)
    {
        this.appendRequest('old_password', value);
    }

    setNewPassword(value)
    {
        this.appendRequest('new_password', value);
    }
}
import Session from '../Session'

import IServiceResult from '../connect/IServiceResult';
import Engine, { Error } from '../Engine';
import ApiUtils from '../api/ApiUtils';
import ChatGet from '../api/chat/ChatGet';
import { ChatMessage } from '../data/chat/ChatMessage';
import ChatAdd from '../api/chat/ChatAdd';
import ChatCreate from '../api/chat/ChatCreate';

export default class ChatMonitor {
    constructor(/*Session*/ session) {
        this.mSession = session;
        session.onChatCreated.register(this.onChatCreated.bind(this));

        session.onApplicationsMineCreated.register(this.onApplicationsMineCreated.bind(this));
        session.onApplicationsListCreated.register(this.onApplicationsListCreated.bind(this));
        
    }

    onApplicationsMineCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitFinished.register(this.onApplicationsMineCommitFinished.bind(this));
    }

    onApplicationsMineCommitFinished(/*VehicleList*/ sender, /*Void*/ params) {
        if ( null == sender.vehicles )
        {
            return;
        }

        sender.vehicles.map((x) => {
            x.onChatCreated.register(this.onCreateChatCreated.bind(this));
        })
    }

    
    onApplicationsListCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitFinished.register(this.onApplicationsListCommitFinished.bind(this));
    }

    onApplicationsListCommitFinished(/*VehicleList*/ sender, /*Void*/ params) {
        if ( null == sender.vehicles )
        {
            return;
        }

        sender.vehicles.map((x) => {
            x.onChatCreated.register(this.onCreateChatCreated.bind(this));
        })
    }

    onCreateChatCreated(/*Object*/ sender, /*{CreateChat}*/ action)
    {
        action.OnCommitRequested.register(this.onCreateChatCommitRequested.bind(this));
    }


    onCreateChatCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new ChatCreate();

        request.setTopic(action.topic);

        request.setReferenceId(action.reference_id);
        request.setReferenceType(action.reference_type);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {

                action.external = reply.opt('external', null);

                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("CHAT", result, request);
    }


    onChatCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnQueryRequested.register(this.onChatQueryRequested.bind(this));

        action.onCreateMessageCreated.register(this.onChatCreateMessageRequested.bind(this));
    }


    onChatQueryRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new ChatGet();

        request.setToken(action.token);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {

                action.external = reply.opt('external', null);
                action.topic = reply.opt('topic', null);
                action.is_locked = reply.opt('is_locked', null);
                action.first_message_timestamp = ApiUtils.deserializeDateTime(reply.opt('first_message_timestamp', null));
                action.last_message_timestamp = ApiUtils.deserializeDateTime(reply.opt('last_message_timestamp', null));
                
                let messages = reply.opt('messages', null);
                if ( null != messages )
                {
                    let _messages = [];

                    let esize = messages.length();
                    for ( var j = 0; j < esize; ++j )
                    {
                        let mm = messages.get(j);

                        let msg = new ChatMessage();
                        msg.external = mm.opt('external', null);
                        msg.message = mm.opt('message', null);
                        msg.author_name = mm.opt('author_name', null);
                        msg.timestamp = ApiUtils.deserializeDateTime(mm.opt('timestamp', null));

                        _messages.push(msg);
                    }

                    action.messages = _messages;
                }

                action.OnQueryFinished.callback(sender, null);
                action.OnMessagesChanged.callback(sender, action.messages);
            },
            function (/*Object*/ error) {
                action.OnQueryError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("CHAT", result, request);
    }

    onChatCreateMessageRequested(sender, action) {
        action.OnCommitRequested.register(this.onCreateMessageCommitRequested.bind(this));
    }

    onCreateMessageCommitRequested(sender, params) {
        var action = sender;
        var request = new ChatAdd();

        request.setChat(action.chat);
        request.setMessage(action.message);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.external = reply.opt('external', null);
                action.message = reply.opt('message', null);
                action.author_name = reply.opt('author_name', null);
                action.timestamp = ApiUtils.deserializeDateTime(reply.opt('timestamp', null));

                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("CHAT", result, request);
    }
}
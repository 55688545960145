import React, { Component } from "react";
import update from "react-addons-update";

import autoBind from "react-autobind";
import "./LiveScreen.css";
import BaseScreen from "../BaseScreen";
import do_wynikow_3v1 from "images/do_wynikow_3v1.png";

import prestage_left from "images/prestage_left.png";
import prestage_right from "images/prestage_right.png";

import art_color from "images/2024/baltic/art_color.png";
import ecumaster from "images/2024/baltic/ecumaster.png";
import fmic from "images/2024/baltic/fmic.png";
import gulf from "images/2024/baltic/gulf.png";
import logo from "images/2024/baltic/logo.png";
import protosleeve from "images/2024/baltic/protosleeve.png";
import rtc from "images/2024/baltic/rtc.png";
import scsclub from "images/2024/baltic/scsclub.png";
import spdesign from "images/2024/baltic/spdesign.png";

import prestage_left_driver from "images/prestage_left_driver.png";
import prestage_left_team from "images/prestage_left_team.png";
import prestage_left_class from "images/prestage_left_class.png";
import prestage_left_mass from "images/prestage_left_mass.png";
import prestage_left_power from "images/prestage_left_power.png";
import prestage_left_best_event from "images/prestage_left_best_event.png";
import prestage_left_best_season from "images/prestage_left_best_season.png";

import prestage_right_driver from "images/prestage_right_driver.png";
import prestage_right_team from "images/prestage_right_team.png";
import prestage_right_class from "images/prestage_right_class.png";
import prestage_right_mass from "images/prestage_right_mass.png";
import prestage_right_power from "images/prestage_right_power.png";
import prestage_right_best_event from "images/prestage_right_best_event.png";
import prestage_right_best_season from "images/prestage_right_best_season.png";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import ResultTable from "../../components/widgets/ResultTable";
import withRouter from "../../utils/withRouter";

const logoList = [art_color, ecumaster, fmic, gulf, protosleeve, rtc, scsclub, spdesign];

let result_switch_timeout = 10000;

class LiveScreen extends BaseScreen {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  bindDelegates() {
    super.bindDelegates();
    document.addEventListener("keydown", this.keyWasPressed);
  }

  unbindDelegates() {
    document.removeEventListener("keydown", this.keyWasPressed);
    super.unbindDelegates();
  }

  onInitParameters(props) {
    this.state.results = false;
    this.state.leftDriver = null;
    this.state.leftDriverName = null;
    this.state.leftDriverCar = null;
    this.state.leftEt = null;
    this.state.leftSpeed = null;
    this.state.leftRt = null;
    this.state.leftPicture = null;

    this.state.rightDriver = null;
    this.state.rightDriverName = null;
    this.state.rightDriverCar = null;
    this.state.rightEt = null;
    this.state.rightSpeed = null;
    this.state.rightRt = null;
    this.state.rightPicture = null;

    this.state.viewForm = true;

    this.state.logoIndex = 0;

    this.state.showSponsors = false;
    this.state.showResults = false;
    this.state.showPrevView = false;
    this.state.showNextRace = false;
    this.state.showResultSummary = false;

    this.state.nextLeftAvailable = false;
    this.state.nextLeftPlayer = null;
    this.state.nextLeftDriverName = null;
    this.state.nextLeftTeamName = null;
    this.state.nextLeftPlayerCar = null;
    this.state.nextLeftCarPower = null;
    this.state.nextLeftCarMass = null;
    this.state.nextLeftPlayerClass = null;
    this.state.nextLeftSeasonBestTime1320ft = null;
    this.state.nextLeftSeasonBestSpeed1320ft = null;
    this.state.nextLeftEventBestTime1320ft = null;
    this.state.nextLeftEventBestSpeed1320ft = null;
    this.state.nextLeftPictureUrl = null;

    this.state.nextRightAvailable = null;
    this.state.nextRightPlayer = null;
    this.state.nextRightDriverName = null;
    this.state.nextRightTeamName = null;
    this.state.nextRightPlayerCar = null;
    this.state.nextRightCarPower = null;
    this.state.nextRightCarMass = null;
    this.state.nextRightPlayerClass = null;
    this.state.nextRightSeasonBestTime1320ft = null;
    this.state.nextRightSeasonBestSpeed1320ft = null;
    this.state.nextRightEventBestTime1320ft = null;
    this.state.nextRightEventBestSpeed1320ft = null;
    this.state.nextRightPictureUrl = null;

    this.state.summaryResults = null;
    this.state.summaryTimeoutObject = null;

    // this.latestResult();
    return true;
  }

  onCleanupParameters() {
    if (null != this.state.latestResultTimer) {
      clearInterval(this.state.latestResultTimer);
      this.state.latestResultTimer = null;
    }
    if (null != this.state.logoChangeTimer) {
      clearInterval(this.state.logoChangeTimer);
      this.state.logoChangeTimer = null;
    }
  }

  keyWasPressed(event) {
    if (this.state.viewForm) {
      return;
    }

    if (!this.state.showResultSummary) {
      return;
    }

    if ("r" == event.key) {
      this.doCurrentResults();
    }
  }

  latestResult() {
    var action = this.session.createLatestResult();

    action.OnCommitRequested.register(this.onLatestResultCommitRequested);
    action.OnCommitFinished.register(this.onLatestResultCommitFinished);
    action.OnCommitError.register(this.onLatesResultCommitError);

    action.Commit();

    return action;
  }

  nextRace() {
    var action = this.session.createNextRace();

    action.OnCommitRequested.register(this.onNextRaceCommitRequested);
    action.OnCommitFinished.register(this.onNextRaceCommitFinished);
    action.OnCommitError.register(this.onNextRaceCommitError);

    action.Commit();

    return action;
  }

  doCurrentResults() {
    if (null != this.state.summaryResults) {
      if (null != this.state.summaryTimeoutObject) {
        clearTimeout(this.state.summaryTimeoutObject);
      }
      this.setState((oldState) => {
        return update(oldState, { summaryResults: { $set: null }, summaryTimeoutObject: { $set: null } });
      });
      return;
    }
    var action = this.session.createCurrentResults();

    action.OnCommitRequested.register(this.onCurrentResultsCommitRequested);
    action.OnCommitFinished.register(this.onCurrentResultsCommitFinished);
    action.OnCommitError.register(this.onCurrentResultsCommitError);

    action.Commit();

    return action;
  }

  onLatestResultCommitRequested(sender, params) {
    this.setState((oldState) => {
      return update(oldState, { fetching: { $set: true } });
    });
  }

  onNextRaceCommitRequested(sender, params) {
    this.setState((oldState) => {
      return update(oldState, { fetching: { $set: true } });
    });
  }

  onCurrentResultsCommitRequested(sender, params) {
    this.setState((oldState) => {
      return update(oldState, { fetching: { $set: true } });
    });
  }

  onLatestResultCommitFinished(sender, params) {
    var leftDriver = null;
    var leftDriverName = null;
    var leftDriverCar = null;
    var leftEt = null;
    var leftSpeed = null;
    var leftRt = null;
    var leftPicture = null;

    var rightDriver = null;
    var rightDriverName = null;
    var rightDriverCar = null;
    var rightEt = null;
    var rightSpeed = null;
    var rightRt = null;
    var rightPicture = null;

    let left = sender.getLeft();
    if (left) {
      leftDriver = left.getPlayer();
      leftDriverName = left.getPlayerName();
      leftDriverCar = left.getPlayerCar();
      leftEt = left.getTime1320ft() + "s"; // + ;
      leftSpeed = parseFloat(left.getSpeed1320ft()).toFixed(1);
      leftRt = left.getReactionTime() + "s";
      leftPicture = left.getPictureUrl();
    }
    let right = sender.getRight();
    if (right) {
      rightDriver = right.getPlayer();
      rightDriverName = right.getPlayerName();
      rightDriverCar = right.getPlayerCar();
      rightEt = right.getTime1320ft() + "s";
      rightSpeed = parseFloat(right.getSpeed1320ft()).toFixed(1);
      rightRt = right.getReactionTime() + "s";
      rightPicture = right.getPictureUrl();
    }

    let results = sender.hasResults();

    if (results) {
      if (null != this.state.summaryTimeoutObject) {
        clearTimeout(this.state.summaryTimeoutObject);
        this.setState((oldState) => {
          return update(oldState, { summaryResults: { $set: null }, summaryTimeoutObject: { $set: null } });
        });
      }
    }

    this.setState((oldState) => {
      return update(oldState, {
        fetching: { $set: false },
        results: { $set: results },
        leftEt: { $set: leftEt },
        leftSpeed: { $set: leftSpeed },
        leftRt: { $set: leftRt },
        leftDriver: { $set: leftDriver },
        leftDriverName: { $set: leftDriverName },
        leftDriverCar: { $set: leftDriverCar },
        leftPicture: { $set: leftPicture },
        rightEt: { $set: rightEt },
        rightSpeed: { $set: rightSpeed },
        rightRt: { $set: rightRt },
        rightDriver: { $set: rightDriver },
        rightDriverName: { $set: rightDriverName },
        rightDriverCar: { $set: rightDriverCar },
        rightPicture: { $set: rightPicture },
      });
    });
  }

  onNextRaceCommitFinished(sender, params) {
    var nextLeftAvailable = false;
    var nextLeftPlayer = null;
    var nextLeftDriverName = null;
    var nextLeftTeamName = null;
    var nextLeftPlayerCar = null;
    var nextLeftCarPower = null;
    var nextLeftCarMass = null;
    var nextLeftPlayerClass = null;
    var nextLeftSeasonBestTime1320ft = null;
    var nextLeftSeasonBestSpeed1320ft = null;
    var nextLeftEventBestTime1320ft = null;
    var nextLeftEventBestSpeed1320ft = null;
    var nextLeftPictureUrl = null;

    var nextRightAvailable = false;
    var nextRightPlayer = null;
    var nextRightDriverName = null;
    var nextRightTeamName = null;
    var nextRightPlayerCar = null;
    var nextRightCarPower = null;
    var nextRightCarMass = null;
    var nextRightPlayerClass = null;
    var nextRightSeasonBestTime1320ft = null;
    var nextRightSeasonBestSpeed1320ft = null;
    var nextRightEventBestTime1320ft = null;
    var nextRightEventBestSpeed1320ft = null;
    var nextRightPictureUrl = null;

    let left = sender.getLeft();
    if (left) {
      nextLeftAvailable = true;
      nextLeftPlayer = left.getPlayer();
      nextLeftDriverName = left.getDriverName();
      nextLeftTeamName = left.getTeamName();
      nextLeftPlayerCar = left.getPlayerCar();
      nextLeftCarPower = left.getCarPower();
      nextLeftCarMass = left.getCarMass();
      nextLeftPlayerClass = left.getPlayerClass();
      nextLeftSeasonBestTime1320ft = left.getSeasonBestTime1320ft();
      nextLeftSeasonBestSpeed1320ft = left.getSeasonBestSpeed1320ft();
      nextLeftEventBestTime1320ft = left.getEventBestTime1320ft();
      nextLeftEventBestSpeed1320ft = left.getEventBestSpeed1320ft();
      nextLeftPictureUrl = left.getPictureUrl();
    }
    let right = sender.getRight();
    if (right) {
      nextRightAvailable = true;
      nextRightPlayer = right.getPlayer();
      nextRightDriverName = right.getDriverName();
      nextRightTeamName = right.getTeamName();
      nextRightPlayerCar = right.getPlayerCar();
      nextRightCarPower = right.getCarPower();
      nextRightCarMass = right.getCarMass();
      nextRightPlayerClass = right.getPlayerClass();
      nextRightSeasonBestTime1320ft = right.getSeasonBestTime1320ft();
      nextRightSeasonBestSpeed1320ft = right.getSeasonBestSpeed1320ft();
      nextRightEventBestTime1320ft = right.getEventBestTime1320ft();
      nextRightEventBestSpeed1320ft = right.getEventBestSpeed1320ft();
      nextRightPictureUrl = right.getPictureUrl();
    }

    this.setState((oldState) => {
      return update(oldState, {
        fetching: { $set: false },
        nextLeftAvailable: { $set: nextLeftAvailable },
        nextLeftPlayer: { $set: nextLeftPlayer },
        nextLeftDriverName: { $set: nextLeftDriverName },
        nextLeftTeamName: { $set: nextLeftTeamName },
        nextLeftPlayerCar: { $set: nextLeftPlayerCar },
        nextLeftCarPower: { $set: nextLeftCarPower },
        nextLeftCarMass: { $set: nextLeftCarMass },
        nextLeftPlayerClass: { $set: nextLeftPlayerClass },
        nextLeftSeasonBestTime1320ft: { $set: nextLeftSeasonBestTime1320ft },
        nextLeftSeasonBestSpeed1320ft: { $set: nextLeftSeasonBestSpeed1320ft },
        nextLeftEventBestTime1320ft: { $set: nextLeftEventBestTime1320ft },
        nextLeftEventBestSpeed1320ft: { $set: nextLeftEventBestSpeed1320ft },
        nextLeftPictureUrl: { $set: nextLeftPictureUrl },

        nextRightAvailable: { $set: nextRightAvailable },
        nextRightPlayer: { $set: nextRightPlayer },
        nextRightDriverName: { $set: nextRightDriverName },
        nextRightTeamName: { $set: nextRightTeamName },
        nextRightPlayerCar: { $set: nextRightPlayerCar },
        nextRightCarPower: { $set: nextRightCarPower },
        nextRightCarMass: { $set: nextRightCarMass },
        nextRightPlayerClass: { $set: nextRightPlayerClass },
        nextRightSeasonBestTime1320ft: { $set: nextRightSeasonBestTime1320ft },
        nextRightSeasonBestSpeed1320ft: { $set: nextRightSeasonBestSpeed1320ft },
        nextRightEventBestTime1320ft: { $set: nextRightEventBestTime1320ft },
        nextRightEventBestSpeed1320ft: { $set: nextRightEventBestSpeed1320ft },
        nextRightPictureUrl: { $set: nextRightPictureUrl },
      });
    });
  }

  onCurrentResultsCommitFinished(sender, params) {
    let current_result_data = null;

    if (null != sender.getResults()) {
      current_result_data = [];
      sender.getResults().forEach((x) => {
        let clazz = {};
        clazz.className = x.getClassName();
        clazz.timestamp = x.getTimestamp() ? x.getTimestamp().format("HH:mm:ss") : null;
        if (null != x.getResults()) {
          let players = [];
          x.getResults().forEach((y) => {
            let player = {};

            player.driverNumber = y.getDriverNumber();
            player.driverName = y.getDriverName();
            player.driverCar = y.getDriverCar();
            player.bestEt = y.getBestEt();
            player.bestSpeed = y.getBestSpeed();

            players.push(player);
          });
          clazz.players = players;

          current_result_data.push(clazz);
        }
      });
    }
    let timeoutObject = setTimeout(this.rotateSummaryResult, result_switch_timeout);

    this.setState((oldState) => {
      return update(oldState, {
        fetching: { $set: false },
        summaryResults: { $set: current_result_data },
        summaryTimeoutObject: { $set: timeoutObject },
      });
    });
  }

  rotateSummaryResult() {
    let current_result_data = this.state.summaryResults;

    if (null != current_result_data) {
      if (null != current_result_data) {
        current_result_data.shift();
      }

      if (current_result_data.length < 1) {
        current_result_data = null;
      }

      var timeoutObject = null;
      if (null != current_result_data) {
        timeoutObject = setTimeout(this.rotateSummaryResult, result_switch_timeout);
      }

      this.setState((oldState) => {
        return update(oldState, {
          summaryResults: { $set: current_result_data },
          summaryTimeoutObject: { $set: timeoutObject },
        });
      });
    }
  }

  onLatesResultCommitError(sender, params) {
    this.setState((oldState) => {
      return update(oldState, { fetching: { $set: false } });
    });
  }

  onNextRaceCommitError(sender, params) {
    this.setState((oldState) => {
      return update(oldState, { fetching: { $set: false } });
    });
  }

  onCurrentResultsCommitError(sender, params) {
    this.setState((oldState) => {
      return update(oldState, { fetching: { $set: false } });
    });
  }

  changeSponsorLogo() {
    let val = this.state.logoIndex + 1;

    if (val >= logoList.length) {
      val = 0;
    }

    this.setState((oldState) => {
      return update(oldState, { logoIndex: { $set: val } });
    });
  }

  onSponsonsChanged() {
    var current = this.state.showSponsors;
    current = !current;
    this.setState((oldState) => {
      return update(oldState, { showSponsors: { $set: current } });
    });
  }

  onResultsChanged() {
    var current = this.state.showResults;
    current = !current;
    this.setState((oldState) => {
      return update(oldState, { showResults: { $set: current } });
    });
  }

  onNextRaceChanged() {
    var current = this.state.showNextRace;
    current = !current;
    this.setState((oldState) => {
      return update(oldState, { showNextRace: { $set: current } });
    });
  }

  onShowResultSummaryChanged() {
    var current = this.state.showResultSummary;
    current = !current;

    this.setState((oldState) => {
      return update(oldState, { showResultSummary: { $set: current } });
    });
  }

  changeView() {
    var logoChangeTimer = null;
    var latestResultTimer = null;
    var nextRaceTimer = null;
    if (this.state.showSponsors) {
      logoChangeTimer = setInterval(() => {
        this.changeSponsorLogo();
      }, 10000);
    }
    if (this.state.showResults) {
      latestResultTimer = setInterval(() => {
        this.latestResult();
      }, 1000);
    }
    if (this.state.showNextRace) {
      nextRaceTimer = setInterval(() => {
        this.nextRace();
      }, 1000);
    }

    this.setState((oldState) => {
      return update(oldState, { viewForm: { $set: false }, logoChangeTimer: { $set: logoChangeTimer }, latestResultTimer: { $set: latestResultTimer }, nextRaceTimer: { $set: nextRaceTimer } });
    });
  }

  showPrevView() {
    this.setState((oldState) => {
      return update(oldState, { showPrevView: { $set: true } });
    });
    setInterval(() => {
      this.setState((oldState) => {
        return update(oldState, { showPrevView: { $set: false } });
      });
    }, 20000);
  }

  buildCarDescription(car, power, weight) {
    weight = null;
    if (car && power && weight) {
      return car + " ( " + power + "KM @ " + weight + "kg )";
    }
    if (car && power) {
      return car + " ( " + power + "KM )";
    }
    if (car && weight) {
      return car + " ( " + weight + "kg )";
    }

    return car;
  }

  buildRaceResult(et, speed) {
    if (et && speed) {
      return et + "s @ " + speed + "km/h";
    }
    if (et) {
      return et;
    }
    if (speed) {
      return speed;
    }
    return null;
  }

  render() {
    if (this.state.showPrevView) {
      return (
        <div className="LiveScreen">
          <div className="LiveOverlay">
            <img className="LiveBackground" src={do_wynikow_3v1} alt="" />
            <div className="LeftEt LiveText">4234</div>
            <div className="LeftSpeed LiveText">2342</div>
            <div className="LeftRt LiveText">23442</div>
            <div className="LeftDriver LiveText">323213</div>
            <div className="LeftDriverName LiveTextSmall">Jan</div>
            <div className="LeftDriverCar LiveTextSmall">Mustang</div>
            <img className="LiveLeftPicture" src="http://api.scsclub.pl/pictures/89f60760-aa08-4f7d-a997-45fc8de1b79c.jpg" alt="" />
            <div className="RightEt LiveText">2131</div>
            <div className="RightSpeed LiveText">150</div>
            <div className="RightRt LiveText">131231</div>
            <div className="RightDriver LiveText">13131</div>
            <div className="RightDriverName LiveTextSmall">Kamil</div>
            <div className="RightDriverCar LiveTextSmall">Mustang</div>
            <img className="LiveRightPicture" src="http://api.scsclub.pl/pictures/89f60760-aa08-4f7d-a997-45fc8de1b79c.jpg" alt="" />
          </div>
        </div>
      );
    }

    if (this.state.viewForm) {
      return (
        <div className="LiveScreen">
          <div>
            <button
              className="showPrevViewButton"
              onClick={() => {
                this.showPrevView();
              }}
            >
              Preview wynikow
            </button>
          </div>

          <div className="viewOptions">
            Co pokazywac:
            <div>
              {this.state.showSponsors && (
                <div
                  className="Option-Selected"
                  onClick={() => {
                    this.onSponsonsChanged();
                  }}
                >
                  Loga sponsorow
                </div>
              )}
              {!this.state.showSponsors && (
                <div
                  className="Option-Unselected"
                  onClick={() => {
                    this.onSponsonsChanged();
                  }}
                >
                  Loga sponsorow
                </div>
              )}
            </div>
            <div>
              {this.state.showResults && (
                <div
                  className="Option-Selected"
                  onClick={() => {
                    this.onResultsChanged();
                  }}
                >
                  Wynik wyscigu
                </div>
              )}
              {!this.state.showResults && (
                <div
                  className="Option-Unselected"
                  onClick={() => {
                    this.onResultsChanged();
                  }}
                >
                  Wynik wyscigu
                </div>
              )}
            </div>
            <div>
              {this.state.showNextRace && (
                <div
                  className="Option-Selected"
                  onClick={() => {
                    this.onNextRaceChanged();
                  }}
                >
                  Zapowiedz wyscigu
                </div>
              )}
              {!this.state.showNextRace && (
                <div
                  className="Option-Unselected"
                  onClick={() => {
                    this.onNextRaceChanged();
                  }}
                >
                  Zapowiedz wyscigu
                </div>
              )}
            </div>
            <div>
              {this.state.showResultSummary && (
                <div
                  className="Option-Selected"
                  onClick={() => {
                    this.onNextRaceChanged();
                  }}
                >
                  Wyniki klas
                </div>
              )}
              {!this.state.showResultSummary && (
                <div
                  className="Option-Unselected"
                  onClick={() => {
                    this.onShowResultSummaryChanged();
                  }}
                >
                  Wyniki klas
                </div>
              )}
            </div>
            <div>
              <button
                className="saveButton"
                onClick={() => {
                  this.changeView();
                }}
              >
                Uruchom
              </button>
            </div>
          </div>
        </div>
      );
    }

    let currentLogo = logoList[this.state.logoIndex];

    var summaryClassName = null;
    var summaryTimestamp = null;
    var summaryResults = null;

    if (null != this.state.summaryResults) {
      if (this.state.summaryResults.length > 0) {
        let first = this.state.summaryResults[0];
        summaryResults = first.players.map((x) => {
          return (
            <div key={x.driverNumber} className="LiveResultsRow">
              <img className="LiveResultsRowBackground" src={prestage_right_driver} alt="" />
              <div className="LiveResultsRowDriverNumber">{x.driverNumber}</div>
              <div className="LiveResultsRowDriverName">{x.driverName}</div>
              <div className="LiveResultsRowDriverCar">{x.driverCar}</div>
              <img className="LiveResultsRowBackgroundEt" src={prestage_left_best_event} alt="" />
              <div className="LiveResultsRowBestEt">{x.bestEt} s</div>
              <div className="LiveResultsRowBestSpeed">{x.bestSpeed} kmh</div>
            </div>
          );
        });
        summaryClassName = first.className;
        summaryTimestamp = first.timestamp;
      }
    }

    return (
      <div className="LiveScreen">
        {this.state.showSponsors && (
          <div className="LogoContainer">
            <TransitionGroup>
              <CSSTransition key={"logo-" + this.state.logoIndex} timeout={2500} classNames="slide">
                <img
                  className="SponsorLogo"
                  src={currentLogo}
                  alt=""
                  onClick={() => {
                    this.changeSponsorLogo();
                  }}
                />
              </CSSTransition>
            </TransitionGroup>
          </div>
        )}

        {this.state.results && this.state.showResults && (
          <div className="LiveOverlay">
            <img className="LiveBackground" src={do_wynikow_3v1} alt="" />
            {this.state.leftEt && <div className="LeftEt LiveText">{this.state.leftEt}</div>}
            {this.state.leftSpeed && <div className="LeftSpeed LiveText">{this.state.leftSpeed}</div>}
            {this.state.leftRt && <div className="LeftRt LiveText">{this.state.leftRt}</div>}
            {this.state.leftDriver && <div className="LeftDriver LiveText">{this.state.leftDriver}</div>}
            {this.state.leftDriverName && <div className="LeftDriverName LiveTextSmall">{this.state.leftDriverName}</div>}
            {this.state.leftDriverCar && <div className="LeftDriverCar LiveTextSmall">{this.state.leftDriverCar}</div>}
            {this.state.leftPicture && <img className="LiveLeftPicture" src={this.state.leftPicture} alt="" />}
            {this.state.rightEt && <div className="RightEt LiveText">{this.state.rightEt}</div>}
            {this.state.rightSpeed && <div className="RightSpeed LiveText">{this.state.rightSpeed}</div>}
            {this.state.rightRt && <div className="RightRt LiveText">{this.state.rightRt}</div>}
            {this.state.rightDriver && <div className="RightDriver LiveText">{this.state.rightDriver}</div>}
            {this.state.rightDriverName && <div className="RightDriverName LiveTextSmall">{this.state.rightDriverName}</div>}
            {this.state.rightDriverCar && <div className="RightDriverCar LiveTextSmall">{this.state.rightDriverCar}</div>}
            {this.state.rightPicture && <img className="LiveRightPicture" src={this.state.rightPicture} alt="" />}
          </div>
        )}

        {!this.state.results && this.state.showNextRace && (this.state.nextLeftAvailable || this.state.nextRightAvailable) && (
          <div className="LivePrestage">
            {this.state.nextLeftPictureUrl && <img className="PrestageLeftPicture" src={this.state.nextLeftPictureUrl} alt="" />}
            {this.state.nextLeftAvailable && (
              <div className="PrestageLeft">
                {this.state.nextLeftPlayer && (
                  <div className="PrestageLeftDriver">
                    <img className="PrestageItemBackground" src={prestage_left_driver} alt="" />
                    <div className="PrestageLeftDriverNumber">{this.state.nextLeftPlayer}</div>
                    <div className="PrestageLeftDriverName">{this.state.nextLeftDriverName}</div>
                    <div className="PrestageLeftDriverCar">{this.state.nextLeftPlayerCar}</div>
                  </div>
                )}

                {this.state.nextLeftTeamName && (
                  <div className="PrestageLeftTeamContainer">
                    <img className="PrestageItemBackground" src={prestage_left_team} alt="" />
                    <div className="PrestageLeftTeam">{this.state.nextLeftTeamName}</div>
                  </div>
                )}

                {this.state.nextLeftPlayerClass && (
                  <div className="PrestageLeftClassContainer">
                    <img className="PrestageItemBackground" src={prestage_left_class} alt="" />
                    <div className="PrestageLeftClass">{this.state.nextLeftPlayerClass}</div>
                  </div>
                )}

                {this.state.nextLeftCarMass && (
                  <div className="PrestageLeftMassContainer">
                    <img className="PrestageItemBackground" src={prestage_left_mass} alt="" />
                    <div className="PrestageLeftMass">{this.state.nextLeftCarMass + " Kg"}</div>
                  </div>
                )}

                {this.state.nextLeftCarPower && (
                  <div className="PrestageLeftPowerContainer">
                    <img className="PrestageItemBackground" src={prestage_left_power} alt="" />
                    <div className="PrestageLeftPower">{this.state.nextLeftCarPower + " KM"}</div>
                  </div>
                )}
                {this.state.nextLeftEventBestTime1320ft && (
                  <div className="PrestageLeftEventBestContainer">
                    <img className="PrestageItemBackground" src={prestage_left_best_event} alt="" />
                    <div className="PrestageLeftEventBestEt">{this.state.nextLeftEventBestTime1320ft + " s"}</div>
                    {this.state.nextLeftEventBestSpeed1320ft && <div className="PrestageLeftEventBestSpeed">{this.state.nextLeftEventBestSpeed1320ft + " kmh"}</div>}
                  </div>
                )}

                {this.state.nextLeftSeasonBestTime1320ft && (
                  <div className="PrestageLeftSeasonBestContainer">
                    <img className="PrestageItemBackground" src={prestage_left_best_season} alt="" />
                    <div className="PrestageLeftSeasonBestEt">{this.state.nextLeftSeasonBestTime1320ft + " s"}</div>
                    {this.state.nextLeftSeasonBestSpeed1320ft && <div className="PrestageLeftSeasonBestSpeed">{this.state.nextLeftSeasonBestSpeed1320ft + " kmh"}</div>}
                  </div>
                )}
              </div>
            )}

            {this.state.nextRightPictureUrl && <img className="PrestageRightPicture" src={this.state.nextRightPictureUrl} alt="" />}
            {this.state.nextRightAvailable && (
              <div className="PrestageRight">
                {this.state.nextRightPlayer && (
                  <div className="PrestageRightDriver">
                    <img className="PrestageItemBackground" src={prestage_right_driver} alt="" />
                    <div className="PrestageRightDriverNumber">{this.state.nextRightPlayer}</div>
                    <div className="PrestageRightDriverName">{this.state.nextRightDriverName}</div>
                    <div className="PrestageRightDriverCar">{this.state.nextRightPlayerCar}</div>
                  </div>
                )}

                {this.state.nextRightTeamName && (
                  <div className="PrestageRightTeamContainer">
                    <img className="PrestageItemBackground" src={prestage_right_team} alt="" />
                    <div className="PrestageRightTeam">{this.state.nextRightTeamName}</div>
                  </div>
                )}

                {this.state.nextRightPlayerClass && (
                  <div className="PrestageRightClassContainer">
                    <img className="PrestageItemBackground" src={prestage_right_class} alt="" />
                    <div className="PrestageRightClass">{this.state.nextRightPlayerClass}</div>
                  </div>
                )}

                {this.state.nextRightCarMass && (
                  <div className="PrestageRightMassContainer">
                    <img className="PrestageItemBackground" src={prestage_right_mass} alt="" />
                    <div className="PrestageRightMass">{this.state.nextRightCarMass + " Kg"}</div>
                  </div>
                )}

                {this.state.nextRightCarPower && (
                  <div className="PrestageRightPowerContainer">
                    <img className="PrestageItemBackground" src={prestage_right_power} alt="" />
                    <div className="PrestageRightPower">{this.state.nextRightCarPower + " KM"}</div>
                  </div>
                )}
                {this.state.nextRightEventBestTime1320ft && (
                  <div className="PrestageRightEventBestContainer">
                    <img className="PrestageItemBackground" src={prestage_right_best_event} alt="" />
                    <div className="PrestageRightEventBestEt">{this.state.nextRightEventBestTime1320ft + " s"}</div>
                    {this.state.nextRightEventBestSpeed1320ft && <div className="PrestageRightEventBestSpeed">{this.state.nextRightEventBestSpeed1320ft + " kmh"}</div>}
                  </div>
                )}

                {this.state.nextRightSeasonBestTime1320ft && (
                  <div className="PrestageRightSeasonBestContainer">
                    <img className="PrestageItemBackground" src={prestage_right_best_season} alt="" />
                    <div className="PrestageRightSeasonBestEt">{this.state.nextRightSeasonBestTime1320ft + " s"}</div>
                    {this.state.nextRightSeasonBestSpeed1320ft && <div className="PrestageRightSeasonBestSpeed">{this.state.nextRightSeasonBestSpeed1320ft + " kmh"}</div>}
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {!this.state.results && this.state.showResultSummary && summaryClassName != null && (
          <div className="LiveResultWrapper">
            <TransitionGroup>
              <CSSTransition key={"class-" + summaryClassName} timeout={1800} classNames="fade">
                <div className="LiveResultSummary">
                  <div className="LiveResultsHeader">
                    <img className="LiveResultsHeaderBackground" src={prestage_right_class} alt="" />
                    <div className="LiveResultsHeaderClassName">{summaryClassName}</div>
                    <div className="LiveResultsHeaderTimestamp">{summaryTimestamp}</div>
                  </div>
                  <div className="LiveResultsData">
                    <div className="LiveResultsScroll">{summaryResults}</div>
                  </div>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(LiveScreen);

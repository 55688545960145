import React from 'react';
import { useNavigate, useNavigation, useParams, useSearchParams } from 'react-router-dom';
 
const withRouter = WrappedComponent => props => {
  const params = useParams();
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
 
  return (
    <WrappedComponent
      {...props}
      params={params}
      navigation={navigation}
      search={searchParams}
    />
  );
};
 
export default withRouter;
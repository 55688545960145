import IUser from "../../sdk/data/IUser";

export class User extends IUser
{
    constructor()
    {
        super();

        this.name = null;
        this.nick = null;
        this.email = null;
        this.avatar_url = null;
    }

    getName()
    {
        return this.name;
    }

    getNick()
    {
        return this.nick;
    }

    getEmail()
    {
        return this.email;
    }

    getAvatarUrl()
    {
        return this.avatar_url;
    }


    serialize(stream)
    {
        stream.put('name', this.name);
        stream.put('nick', this.nick);
        stream.put('email', this.email);
        stream.put('avatar_url', this.avatar_url);

        return true;
    }

    deserialize(stream)
    {
        this.name = stream.opt('name', null);
        this.nick = stream.opt('nick', null);
        this.email = stream.opt('email', null);
        this.avatar_url = stream.opt('avatar_url', null);

        return true;
    }
}
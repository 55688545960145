import Event from '../property/Event';

export default class ILeagueClass
{
    getExternal()
    {
        throw new TypeError("Method not implemented");
    }
    getPriority()
    {
        throw new TypeError("Method not implemented");
    }
    getShortname()
    {
        throw new TypeError("Method not implemented");
    }
    getDescription()
    {
        throw new TypeError("Method not implemented");
    }

    getPlayerMask()
    {
        throw new TypeError("Method not implemented");
    }    
    getPlayerStart()
    {
        throw new TypeError("Method not implemented");
    }    
    createUpdateClass()
    {
        throw new TypeError("Method not implemented");
    }
    createRemoveClass()
    {
        throw new TypeError("Method not implemented");
    }
}
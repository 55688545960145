import IServiceRequest from '../../connect/IServiceRequest'

export default class VehicleUpdateCommit extends IServiceRequest
{
    constructor()
    {
        super('www/vehicle/update');
    }

    setToken(token)
    {
        this.appendRequest("token", token);
    }

    setCarShortname(value)
    {
        this.appendRequest("car_shortname", value);
    }
    setCarDescription(value)
    {
        this.appendRequest("car_description", value);
    }

    setCarPower(value)
    {
        this.appendRequest("car_power", value);
    }    
    setCarMass(value)
    {
        this.appendRequest("car_mass", value);
    }    
    setCarDriver(value)
    {
        this.appendRequest("car_driver", value);
    }
    setCarTeam(value)
    {
        this.appendRequest("car_team", value);
    }
}
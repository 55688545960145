import React, { Component } from 'react';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import update from "react-addons-update";
import autoBind from 'react-autobind';
import './HelpIcon.css';

class HelpIcon extends Component {
  static defaultProps = {
    hover: true,
    onClick: null,
    above: true,
    left: true,
    component: null
  }
  constructor(props)
  {
    super(props)
    autoBind(this);
    this.state = {};
    this.state = this.processProps(props);
  }

  componentWillReceiveProps(nextProps) 
  {
    this.setState(this.processProps(nextProps));
  }

  processProps(nextProps)
  {
    let def = nextProps.onClick;
    if ( null == def )
    {
      def = (e) => { this.toggle(); };
    }
    let state = { text: nextProps.text, force: this.state.force || false, show: this.state.show || false, onClick: def, component: nextProps.component };

    return state;
  }

  toggle()
  {
    
    this.setState( (oldState) => { 
      let value = !oldState.show || oldState.force;
      return update(oldState, { show: { $set: !value }, force: { $set: !value }}); });
  }

  onClose()
  {
    this.setState( (oldState) => { return update(oldState, { force: { $set: false }, show: { $set: false }}); });
  }

  hoverOn()
  {
    if ( !this.props.hover )
    {
      return;
    }
    this.setState( (oldState) => { return update(oldState, { show: { $set: true }}); });
  }

  hoverOff()
  {
    if ( !this.props.hover )
    {
      return;
    }
    this.setState( (oldState) => { return update(oldState, { show: { $set: false }}); });
  }

  render() {
    let position = "above";
    if ( !this.props.above )
    {
      position = "below";
    }
    if ( this.props.left )
    {
      position = position + "-left";
    }
    else
    {
      position = position + "-right";
    }
    let show = this.state.force || this.state.show;

    let content = this.state.component || <div class="body" dangerouslySetInnerHTML={{__html: this.state.text}}/>;
    return (
      <div className="HelpIcon"  onMouseEnter={(e) => { this.hoverOn()}} onMouseLeave={(e)=> {this.hoverOff()}} >
        <div onClick={(e) => { this.state.onClick(e)}}>
          {this.props.children}
        </div>

        <TransitionGroup
          className={"HelpIcon-Position-" + position}>
          {show && 
            <CSSTransition
              key={this}
              timeout={300}
              classNames='show'>
                <div key={this} className={"HelpIcon-Tooltip"}>
                  <ul id="HI-Tooltip">
                      <li>
                        <div className={position}>
                          <div className="content">
                            {content}
                            <div className="close" onClick={(e) => { this.onClose() }} >x</div>
                          </div>
                        </div>
                      </li>
                  </ul>
                </div>
            </CSSTransition>

          }
          </TransitionGroup>
      </div>
    );
  }
}

export default HelpIcon;
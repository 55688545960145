import IServiceRequest from "../../../connect/IServiceRequest";

export default class EventUpdateCommit extends IServiceRequest
{
    constructor()
    {
        super('www/event/update');
    }
    
    setExternal(value)
    {
        this.appendRequest("external", value);
    }

    setShortname(value)
    {
        this.appendRequest("shortname", value);
    }

    setDescription(value)
    {
        this.appendRequest("description", value);
    }    

    setLocation(value)
    {
        this.appendRequest("location", value);
    }    

    setGps(value)
    {
        this.appendRequest("gps", value);
    }   
    
    setTimestampStart(value)
    {
        this.appendRequest("timestamp_start", value);
    } 

    setTimestampEnd(value)
    {
        this.appendRequest("timestamp_end", value);
    } 

    setRegistrationFeeOnsite(value)
    {
        this.appendRequest("registration_fee_onsite", value);
    }

    setRegistrationFeeTransfer(value)
    {
        this.appendRequest("registration_fee_transfer", value);
    }

    setRegistrationFeeOnline(value)
    {
        this.appendRequest("registration_fee_online", value);
    }

    setRegistrationFeeCrew(value)
    {
        this.appendRequest("registration_fee_crew", value);
    }

    setTimestampAttendanceStart(value)
    {
        this.appendRequest("timestamp_attendance_start", value);
    }

    setTimestampAttendanceTransferEnd(value)
    {
        this.appendRequest("timestamp_attendance_transfer_end", value);
    }

    setTimestampAttendanceEnd(value)
    {
        this.appendRequest("timestamp_attendance_end", value);
    }

    setTemplateAttendanceEmail(value)
    {
        this.appendRequest("template_attendance_email", value);
    }
    
    setRaceFormula(value)
    {
        this.appendRequest("race_formula", value);
    }
}

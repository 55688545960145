import Event from '../../property/Event';

export default class IApplicationVehicle
{
    getExternal()
    {
        throw new TypeError("Method not implemented");
    }

    getUserName()
    {
        throw new TypeError("Method not implemented");
    }
    getUserNick()
    {
        throw new TypeError("Method not implemented");
    }
    getUserExternal()
    {
        throw new TypeError("Method not implemented");
    }
    
    getLeague()
    {
        throw new TypeError("Method not implemented");
    }

    getClass()
    {
        throw new TypeError("Method not implemented");
    }

    getStatus()
    {
        throw new TypeError("Method not implemented");
    }

    getTimestampCreation()
    {
        throw new TypeError("Method not implemented");
    }

    getTimestampReview()
    {
        throw new TypeError("Method not implemented");
    }

    getAssignedNumber()
    {
        throw new TypeError("Method not implemented");
    }

    setAssignedNumber(value)
    {
        throw new TypeError("Method not implemented");
    }

    getCarShortname()
    {
        throw new TypeError("Method not implemented");
    }
    getCarPower()
    {
        throw new TypeError("Method not implemented");
    }

    getDriverName()
    {
        throw new TypeError("Method not implemented");
    }

    getComment()
    {
        throw new TypeError("Method not implemented");
    }

    OnCancelRequested = new Event();
    OnCancelFinished = new Event();
    OnCancelError = new Event();

    Cancel()
    {
        throw new TypeError("Method not implemented");
    }

    OnAcceptRequested = new Event();
    OnAcceptFinished = new Event();
    OnAcceptError = new Event();

    Accept()
    {
        throw new TypeError("Method not implemented");
    }

    OnRejectRequested = new Event();
    OnRejectFinished = new Event();
    OnRejectError = new Event();

    Reject()
    {
        throw new TypeError("Method not implemented");
    }

    createCreateChat()
    {
        throw new TypeError("Method not implemented");
    }
}
import Event from '../property/Event';

export default class ISessionRecover
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    setEmail(value)
    {
        throw new TypeError("Method not implemented");
    }

    getEmail()
    {
        throw new TypeError("Method not implemented");
    }

    setSecurity(security)
    {
        throw new TypeError("Method not implemented");
    }

    getSecurity()
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }
}
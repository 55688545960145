import React, { Component } from 'react';
import autoBind from 'react-autobind';
import './MovieBox.css';
import { faVideo, faPlusCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoaderBar from './LoaderBar';
import youtube from '../../images/youtube.jpg';

class MovieBox extends Component {
  static defaultProps = {
      session: null,
      type: null,
      reference: null
  }

  constructor(props) {
    super(props)
    autoBind(this);
    this.state = this.processProps(props);
  }

  componentWillReceiveProps(props)
  {
    this.setState(this.processProps(props));
  }

  processProps(props)
  {
    return { loading: false };
  }

  fetchData()
  {
    var action = this.props.session.createRaceDetails()
    action.setRaceId(this.props.reference);

    action.OnCommitRequested.register(this.onRaceDetailsCommitRequested);
    action.OnCommitFinished.register(this.onRaceDetailsCommitFinished);
    action.OnCommitError.register(this.onRaceDetailsCommitError);
    action.Commit();

    return action;
  }

  renderMovies()
  {
    let data = [
        { url: "YiaApudc4yI", uploader: 'Mike', token: 'xxxx'},
        { url: "WwxhQWU-3DM", uploader: 'Mike', token: 'xxxx'},
        { url: "WwxhQWU-3DM", uploader: 'Mike', token: 'xxxx'},
    ];
    data = null;
    if ( this.state.loading )
    {
        return (
            <div className="w3-container w3-gray w3-text-white w3-margin w3-blur yt-loading">
                <h4><LoaderBar /></h4>
            </div>
        );
    }

    if ( null == data || data.length < 1)
    {
        return (<div className="w3-container w3-gray w3-text-white w3-margin yt-loading">
            <h4>Brak filmów</h4>
        </div>);
    }
    let retval = data.map((x) => {
        return (
            <div className="w3-container w3-teal w3-text-white w3-margin">
                <div className="w3-right">
                    <iframe className="w3-border-blue movie-box-square" src={'https://www.youtube.com/embed/' + x.url} />
                </div>
                <div className="w3-clear"></div>
                <h4>{x.uploader}</h4>
            </div>
        );
    });
    return retval;
  }

  render() 
  {

    let movies = this.renderMovies();

    return (
        <div className="w3-container">
            <div className="w3-container">
                <h5 className="w3-left">Filmy</h5>
                <h5 className="w3-right">
                    <FontAwesomeIcon icon={faPlusCircle} />
                </h5>
            </div>
            
            <div className="movie-box-container">
                {movies}
            </div>
        </div>);
  }
}

export default MovieBox;
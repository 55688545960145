import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import './Dashboard.css'
import main_logo from '../../images/main_logo.jpg'
import moment from 'moment';
import AttendanceWidget from '../../components/widgets/AttendanceWidget';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export const Dashboard = ({session}) => {
  const [ upcomingEvents, setUpcomingEvents] = useState(null);
  const [ lastEvents, setLastEvents] = useState(null);
  const [ leagues, setLeagues ] = useState(null);

  useEffect( () => { 
      const leagueChangedCallback = (sender, leagues) => {
          setLeagues(leagues) ;
      }
      session.onLeaguesChanged.register(leagueChangedCallback);

      setLeagues(session.getLeagues()) ;
      return () => {
          session.onPersonalizeChanged.unregister(leagueChangedCallback);
      }
  }, [])

  useEffect(() => {
      if ( null == leagues )
      {
          return;
      }
      
      let window_from = moment().add(-14, 'days');
      let window_to = moment().add(1, 'months');
      let now = moment();

      let upcoming_events = [];
      let last_events = [];

      for ( const l of leagues )
      {
          if ( null != l.getEvents() )
          {
              for ( const e of l.getEvents() )
              {
                if ( null == e.getTimestampStart() )
              {
                continue;
              }
              if ( null == e.getTimestampEnd() )
              {
                continue;
              }
              if ( !e.getIsRegistrationOpen() )
              {
                if ( window_from.isAfter(e.getTimestampEnd()) )
                {
                  continue;
                }
                if ( window_to.isBefore(e.getTimestampStart()) )
                {
                  continue;
                }
              }

              let event = {};

              event.league = l.getExternal();
              event.league_shortname = l.getShortname();
              event.external = e.getExternal();
              event.shortname = e.getShortname();
              event.location = e.getLocation();
              event.timestamp_start = e.getTimestampStart();
              event.timestamp_end = e.getTimestampEnd();
              event.is_registration_open = e.getIsRegistrationOpen();
              event.is_locked = e.getIsLocked();

              if ( !event.is_locked )
              {
                upcoming_events.push(event);
              }
              else
              {
                last_events.push(event);
              }
            }
          }
      }
      setUpcomingEvents(upcoming_events.length > 0 ? upcoming_events : null );
      setLastEvents(last_events.length > 0 ? last_events : null);
  }, [leagues])

  return ( 
    <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
      {upcomingEvents && 
        <Box>
          <h5>Nadchodzące wydarzenia</h5>
          <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
        {upcomingEvents.map((x) => {
          return (
            <Card sx={{ maxWidth: 445, flexGrow: 1 }}>
              <CardMedia
                sx={{ height: 140 }}
                title={x.shortname} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {x.shortname}
                </Typography>
                <Typography variant="body2" color="text.secondary">{x.league_shortname + " - " + x.shortname}</Typography>
                <Typography variant="body2" color="text.secondary">Start: {x.timestamp_start.format('YYYY-MM-DD HH:mm')}</Typography>
              </CardContent>
              <CardActions>
                <Button size="big"><AttendanceWidget session={session} token={x.external} type='EVENT' manage={false} small={true} /></Button>
                <Button size="big"><Link  to={'/results/' + x.external + "/best"}>Wyniki</Link></Button>
              </CardActions>
            </Card>)
          })}
          </Stack>
      </Box>}
      {lastEvents && 
        <Box>
          <h5>Ostatnie wydarzenia</h5>
          <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
          {lastEvents.map((x) => {
            return (
              <Card sx={{ maxWidth: 445, flexGrow: 1 }}>
                <CardMedia
                  sx={{ height: 140 }}
                  title={x.shortname} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {x.shortname}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">{x.league_shortname + " - " + x.shortname}</Typography>
                  <Typography variant="body2" color="text.secondary">{x.timestamp_start.format('YYYY-MM-DD HH:mm')} - {x.timestamp_end.format('YYYY-MM-DD HH:mm')}</Typography>
                </CardContent>
                <CardActions>
                  <Button size="big"><Link  to={'/results/' + x.external + "/best"}>Wyniki</Link></Button>
                </CardActions>
              </Card>)
            })}
            </Stack>
      </Box>}
      <Box sx={{ padding: '12px'}} >
          <img className="mainimage" src={main_logo} />
          <h5>Zapraszamy do współpracy firmy, które chcą zwiększyć swoją rozpoznawalność, być częścią najlepszego cyklu wyścigów drag racingowych w Polsce oraz pomóc rozwijać tę dziedzinę motorsportu</h5>
          <h5>Bardzo atrakcyjne warunki współpracy, działania z doświadczonym zespołem, zaangażowanie i profesjonalizm oraz wiele innych to powody dla których wejść we współpracę z Scsclub</h5>
          <h5>Zapraszamy do kontaktu pod numerami telefonu:</h5>
          <h4>Tomasz: <a href="tel:+48502335093">+48 502 335 093</a></h4>
          <h4>Paulina: <a href="tel:+48730114420">+48 730 114 420</a></h4>
      </Box>
    </Box>
  )
}
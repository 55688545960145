import Event from '../property/Event';

export default class ISessionLogin
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    setEmail(login)
    {
        throw new TypeError("Method not implemented");
    }

    setType(type)
    {
        throw new TypeError("Method not implemented");
    }

    Commit(/*String*/ security)
    {
        throw new TypeError("Method not implemented");
    }
}
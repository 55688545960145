import Session from '../Session'

import IServiceResult from '../connect/IServiceResult';
import Engine, { Error } from '../Engine';
import VehicleListCommit from '../api/vehicle/VehicleListCommit';
import { Vehicle } from '../data/vehicle/Vehicle';
import VehicleImageLeaderboardListCommit from '../api/vehicle/VehicleImageLeaderboardCommit';
import VehicleImageLeaderboardCommit from '../api/vehicle/VehicleImageLeaderboardCommit';
import VehicleImageLivecamCommit from '../api/vehicle/VehicleImageLivecamCommit';
import VehicleImageProfileCommit from '../api/vehicle/VehicleImageProfileCommit';
import VehicleImageTimeslipCommit from '../api/vehicle/VehicleImageTimeslipCommit';
import VehicleApplicationCommit from '../api/vehicle/VehicleApplicationCommit';
import VehicleGet from '../api/vehicle/VehicleGet';
import VehicleUpdateCommit from '../api/vehicle/VehicleUpdateCommit';
import { requirePropFactory } from '@mui/material';

export default class VehiceMonitor {
    constructor(/*Session*/ session) {
        this.mSession = session;
        session.onVehicleCreated.register(this.onVehicleCreated.bind(this));
        session.onVehicleListCreated.register(this.onVehicleListCreated.bind(this));
        session.onVehicleApplicationCreated.register(this.onVehicleApplicationCreated.bind(this));
    }

    onVehicleCreated(/*Object*/ sender, /*{VehicleList}*/ action) {
        action.OnQueryRequested.register(this.onVehicleQueryRequested.bind(this));
    }

    onVehicleQueryRequested(/*Vehicle*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new VehicleGet();
        var monitor = this;

        request.setExternal(action.external);

        var result = new IServiceResult(
            function (/*Object*/ reply) {

                action.car_external = reply.opt('car_external', null);
                action.car_shortname = reply.opt('car_shortname', null);
                action.car_description = reply.opt('car_description', null);
                action.car_driver = reply.opt('car_driver', null);
                action.car_power = reply.opt('car_power', null);
                action.car_mass = reply.opt('car_mass', null);
                action.car_team = reply.opt('car_team', null);

                action.picture_leaderboard = reply.opt('picture_leaderboard', null);
                action.picture_timeslip = reply.opt('picture_timeslip', null);
                action.picture_livecam = reply.opt('picture_livecam', null);
                action.picture_profile = reply.opt('picture_profile', null);

                action.onVehicleImageLeaderboardCreated.register(monitor.onVehicleImageLeaderboardCreated.bind(monitor));
                action.onVehicleImageLivecamCreated.register(monitor.onVehicleImageLivecamCreated.bind(monitor));
                action.onVehicleImageProfileCreated.register(monitor.onVehicleImageProfileCreated.bind(monitor));
                action.onVehicleImageTimeslipCreated.register(monitor.onVehicleImageTimeslipCreated.bind(monitor));
                action.onVehicleUpdateCreated.register(monitor.onVehicleUpdateCreated.bind(monitor));
                action.OnQueryFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnQueryError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("VEHICLE", result, request);
    }

    onVehicleListCreated(/*Object*/ sender, /*{VehicleList}*/ action) {
        action.OnCommitRequested.register(this.onVehicleListCommitRequested.bind(this));
    }

    onVehicleListCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new VehicleListCommit();
        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                let vehicles = [];
                let data = reply.get('vehicles');
                if ( data )
                {
                    let size = data.length();
                    for (var i = 0; i < size; ++i) {
                        let ob = data.get(i);

                        let vehicle = new Vehicle();
                        vehicle.assigned_number = ob.opt('assigned_number', null);

                        vehicle.league_id = ob.opt('league_id', null);
                        vehicle.league_shortname = ob.opt('league_shortname', null);

                        vehicle.league_class_id = ob.opt('league_class_id', null);
                        vehicle.league_class_shortname = ob.opt('league_class_shortname', null);

                        vehicle.car_external = ob.opt('car_external', null);
                        vehicle.car_shortname = ob.opt('car_shortname', null);
                        vehicle.car_description = ob.opt('car_description', null);
                        vehicle.car_driver = ob.opt('car_driver', null);
                        vehicle.car_power = ob.opt('car_power', null);
                        vehicle.car_mass = ob.opt('car_mass', null);
                        vehicle.car_team = ob.opt('car_team', null);

                        vehicle.picture_leaderboard = ob.opt('picture_leaderboard', null);
                        vehicle.picture_timeslip = ob.opt('picture_timeslip', null);
                        vehicle.picture_livecam = ob.opt('picture_livecam', null);
                        vehicle.picture_profile = ob.opt('picture_profile', null);

                        vehicle.onVehicleImageLeaderboardCreated.register(monitor.onVehicleImageLeaderboardCreated.bind(monitor));
                        vehicle.onVehicleImageLivecamCreated.register(monitor.onVehicleImageLivecamCreated.bind(monitor));
                        vehicle.onVehicleImageProfileCreated.register(monitor.onVehicleImageProfileCreated.bind(monitor));
                        vehicle.onVehicleImageTimeslipCreated.register(monitor.onVehicleImageTimeslipCreated.bind(monitor));
                        vehicle.onVehicleUpdateCreated.register(monitor.onVehicleUpdateCreated.bind(monitor));

                        vehicles.push(vehicle);

                    }
                }

                action.vehicles = vehicles;
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("VEHICLE", result, request);
    }
    onVehicleImageLeaderboardCreated(/*Object*/ sender, /*{VehicleList}*/ action) {
        action.OnCommitRequested.register(this.onVehicleImageLeaderboardCommitRequested.bind(this));
    }

    
    onVehicleImageLeaderboardCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new VehicleImageLeaderboardCommit();
        var monitor = this;
        request.setToken(action.token);
        request.setData(action.data);

        var result = new IServiceResult(
                function (/*Object*/ reply) {
                    action.picture_leaderboard = reply.opt('picture_leaderboard', null);
                    action.OnCommitFinished.callback(sender, null);
                },
                function (/*Object*/ error) {
                    action.OnCommitError.callback(sender, Engine.createError(error));
                }
            );
    
        this.mSession.SessionRequest("VEHICLE", result, request);
    }
    
    onVehicleImageLivecamCreated(/*Object*/ sender, /*{VehicleList}*/ action) {
        action.OnCommitRequested.register(this.onVehicleImageLivecamCommitRequested.bind(this));
    }

    
    onVehicleImageLivecamCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new VehicleImageLivecamCommit();
        var monitor = this;
        request.setToken(action.token);
        request.setData(action.data);

        var result = new IServiceResult(
                function (/*Object*/ reply) {
                    action.picture_livecam = reply.opt('picture_livecam', null);
                    action.OnCommitFinished.callback(sender, null);
                },
                function (/*Object*/ error) {
                    action.OnCommitError.callback(sender, Engine.createError(error));
                }
            );
    
        this.mSession.SessionRequest("VEHICLE", result, request);
    }

    onVehicleImageProfileCreated(/*Object*/ sender, /*{VehicleList}*/ action) {
        action.OnCommitRequested.register(this.onVehicleImageProfileCommitRequested.bind(this));
    }

    
    onVehicleImageProfileCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new VehicleImageProfileCommit();
        var monitor = this;
        request.setToken(action.token);
        request.setData(action.data);

        var result = new IServiceResult(
                function (/*Object*/ reply) {
                    action.picture_profile = reply.opt('picture_profile', null);
                    action.OnCommitFinished.callback(sender, null);
                },
                function (/*Object*/ error) {
                    action.OnCommitError.callback(sender, Engine.createError(error));
                }
            );
    
        this.mSession.SessionRequest("VEHICLE", result, request);
    }

    onVehicleImageTimeslipCreated(/*Object*/ sender, /*{VehicleList}*/ action) {
        action.OnCommitRequested.register(this.onVehicleImageTimeslipCommitRequested.bind(this));
    }

    
    onVehicleImageTimeslipCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new VehicleImageTimeslipCommit();
        var monitor = this;
        request.setToken(action.token);
        request.setData(action.data);

        var result = new IServiceResult(
                function (/*Object*/ reply) {
                    action.picture_timeslip = reply.opt('picture_timeslip', null);
                    action.OnCommitFinished.callback(sender, null);
                },
                function (/*Object*/ error) {
                    action.OnCommitError.callback(sender, Engine.createError(error));
                }
            );
    
        this.mSession.SessionRequest("VEHICLE", result, request);
    }

    
    onVehicleUpdateCreated(/*Object*/ sender, /*{VehicleList}*/ action) {
        action.OnCommitRequested.register(this.onVehicleUpdateCommitRequested.bind(this));
    }
    
    onVehicleUpdateCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new VehicleUpdateCommit();
        var monitor = this;
        request.setToken(action.token);
        
        request.setCarShortname(action.car_shortname);
        request.setCarDescription(action.car_description);
        request.setCarPower(action.car_power);
        request.setCarMass(action.car_mass);
        request.setCarDriver(action.car_driver);
        request.setCarTeam(action.car_team);

        var result = new IServiceResult(
                function (/*Object*/ reply) {
                    action.OnCommitFinished.callback(sender, null);
                },
                function (/*Object*/ error) {
                    action.OnCommitError.callback(sender, Engine.createError(error));
                }
            );
    
        this.mSession.SessionRequest("VEHICLE", result, request);
    }

    onVehicleApplicationCreated(/*Object*/ sender, /*{VehicleApplication}*/ action) {
        action.OnCommitRequested.register(this.onVehicleApplicationCommitRequested.bind(this));
    }

    onVehicleApplicationCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new VehicleApplicationCommit();
        var monitor = this;

        request.setLeague(action.league_id);
        request.setLeagueClass(action.league_class_id);

        if ( null != action.assigned_number )
        {
            request.setAssignedNumber(action.assigned_number);
        }
        else
        {
            request.setCarShortname(action.car_shortname);
            request.setCarPower(action.car_power);
            request.setDriverName(action.driver_name);
        }

        if ( action.league_event_id )
        {
            request.setLeagueEvent(action.league_event_id);
            request.setCrewCount(action.crew_count);
        }

        request.setComment(action.comment);

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("VEHICLE", result, request);
    }
}
import Log from '../internal/logger/Log';
import ApiUtils from './api/ApiUtils';


export default class Device
{
    constructor()
    {
        this.fingerprint = null;
        this.application_id = null;
        this.initialize();
    }

    initialize()
    {
        let value = window.localStorage.getItem('device');
        if ( null == value )
        {
            value = ApiUtils.getUuid();
            window.localStorage.setItem('device', value);
        }
        this.fingerprint = value;
    }

    serialize(stream)
    {
        stream.put("fingerprint", this.fingerprint);
        if ( this.application_id )
        {
            stream.put("app_id", this.application_id);
        }
        return true;
    }

    deserialize(stream)
    {
        this.application_id = stream.opt('app_id', null);
    }

}
import Session from '../Session'

import IServiceResult from '../connect/IServiceResult';
import Engine, { Error } from '../Engine';
import LeagueCreateCommit from '../api/manage/league/LeagueCreateCommit';
import ApiUtils from '../api/ApiUtils';
import LeagueUpdateCommit from '../api/manage/league/LeagueUpdateCommit';
import ClassCreateCommit from '../api/manage/class/ClassCreateCommit';
import ClassRemoveCommit from '../api/manage/class/ClassRemoveCommit';
import ClassUpdateCommit from '../api/manage/class/ClassUpdateCommit';

export default class ClassMonitor {
    constructor(/*Session*/ session) {
        this.mSession = session;
        session.onLeaguesChanged.register(this.onLeaguesChanged.bind(this));
    }
    
    onLeaguesChanged(/*Object*/ sender, leagues)
    {
        if ( !leagues )
        {
            return;
        }
        for ( const league of leagues )
        {
            league.onCreateClassCreated.register(this.onCreateClassCreated.bind(this));

            for ( const cls of league.classes )
            {
                cls.onUpdateClassCreated.register(this.onUpdateClassCreated.bind(this));
                cls.onRemoveClassCreated.register(this.onRemoveClassCreated.bind(this));
            }
        }
    }

    onCreateClassCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitRequested.register(this.onCreateClassCommitRequested.bind(this));
    }


    onCreateClassCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new ClassCreateCommit();

        request.setLeague(action.league);
        request.setShortname(action.shortname);
        request.setDescription(action.description);
        request.setPriority(action.priority);
        request.setPlayerStart(action.player_start);
        request.setPlayerMask(action.player_mask);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.external = reply.opt('external', null);
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("MANAGE", result, request);
    }

    
    onRemoveClassCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitRequested.register(this.onRemoveClassCommitRequested.bind(this));
    }


    onRemoveClassCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new ClassRemoveCommit();

        request.setExternal(action.external);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("MANAGE", result, request);
    }

      
    onUpdateClassCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitRequested.register(this.onUpdateClassCommitRequested.bind(this));
    }


    onUpdateClassCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new ClassUpdateCommit();

        request.setExternal(action.external);

        request.setShortname(action.shortname);
        request.setDescription(action.description);
        request.setPriority(action.priority);
        request.setPlayerStart(action.player_start);
        request.setPlayerMask(action.player_mask);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("MANAGE", result, request);
    }
}
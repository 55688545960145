import IServiceRequest from "../../connect/IServiceRequest";

export default class AttendanceQuery extends IServiceRequest
{
    constructor()
    {
        super('www/attendance/query');
    }
    
    setToken(value)
    {
        this.appendRequest("token", value);
    }

    setType(value)
    {
        this.appendRequest("type", value);
    }    
}

import Pete from './pete/Lisev';

import JSONObject from './pete/sdk/JSONObject';
import Log from './pete/internal/logger/Log';
import Event from './pete/sdk/property/Event';
import SoftaxSecurityProvider from './SoftaxSecurityProvider';

class Database 
{
    static _instance = null;

    static initialize()
    {
        this._instance = new Database();
    }

    static instance()
    {
        return this._instance;
    }

    session = null;
    constructor()
    {
        this.session = Pete.createSession(new SoftaxSecurityProvider());
        this.loadCache(this.session);
        this.session.init();

        this.session.onPersonalizeChanged.register(this.onPersonalizedChanged.bind(this));
        this.session.onPermissionsChanged.register(this.onPermissionsChanged.bind(this));
    }

    onResetRequested = new Event();

    Reset()
    {
      this.session.Reset();
      this.saveCache(this.session);
    }

    onPersonalizedChanged(/*Object*/ sender, /*Boolean*/ value)
    {
      this.saveCache(this.session);
    }

    onPermissionsChanged(/*Object*/ sender, /*Boolean*/ value)
    {
      this.saveCache(this.session);
    }
    getSession()
    {
        return this.session;
    }

    loadCache(session)
    {
      if (typeof(Storage) === "undefined") {
        return;
      }
      var data = window.localStorage.getItem('personalization');
      if ( null == data )
      {
        Log.i('DB', 'Local storage is empty');
        return;
      }
  
      var stream = new JSONObject();
      stream.deserialize(JSON.parse(data));
  
      session.deserialize(stream);
      Log.i('DB', 'Local data loaded');
    }
  
    saveCache(session)
    {
      if (typeof(Storage) === "undefined") {
        return;
      }
  
      var stream = new JSONObject();
      session.serialize(stream);
  
      var data = JSON.stringify(stream.serialize());
      window.localStorage.setItem('personalization', data);
      Log.i('DB', 'Session data saved');
      Log.d('DB', data);
    }
  
}

export default Database;
import { Session as ISession } from '../sdk/Session'
import Event from '../sdk/property/Event'
import JSONObject from '../sdk/JSONObject';
import JSONArray from '../sdk/JSONArray';

import SessionMonitor from './monitors/SessionMonitor';

import IConnectionFactory from './connect/IConnectionFactory';
import BackendConnection from './connect/BackendConnection';

import Device from './Device';
import GlobalsMonitor from './monitors/GlobalsMonitor';
import { RaceResults } from './data/RaceResults';
import ResultsMonitor from './monitors/ResultsMonitor';
import VehicleMonitor from './monitors/VehicleMonitor';
import { Timeslip } from './data/Timeslip';
import { LatestResult } from './data/livescreen/LatestResult';
import { NextRace } from './data/livescreen/NextRace';
import { SetNextRace } from './data/livescreen/SetNextRace';
import PlayersUpload from './data/PlayersUpload';
import PlayersDownload from './data/PlayersDownload';
import PlayersMonitor from './monitors/PlayersMonitor';
import { User } from './data/User';
import { RaceDetails } from './data/RaceDetails';
import { VehicleList } from './data/vehicle/VehicleList';
import { VehicleApplication } from './data/vehicle/VehicleApplication';
import { ApplicationsMine } from './data/applications/ApplicationsMine';
import { ApplicationsList } from './data/applications/ApplicationsList';
import ApplicationsMonitor from './monitors/ApplicationsMonitor';
import { Permissions } from './data/Permissions';
import SessionRefresh from './session/SessionRefresh';
import { CreateLeague } from './data/manage/league/CreateLeague';
import LeagueMonitor from './monitors/LeagueMonitor';
import SessionLogin from './session/SessionLogin';
import SessionPersonalize from './session/SessionPersonalize';
import SessionActivate from './session/SessionActivate';
import SessionRecover from './session/SessionRecover';
import SessionChangePassword from './session/SessionChangePassword';
import ClassMonitor from './monitors/ClassMonitor';
import EventMonitor from './monitors/EventMonitor';
import { Vehicle } from './data/vehicle/Vehicle';
import RawResult from './data/manage/result/RawResult';
import RawResultsMonitor from './monitors/RawResultsMonitors';
import FiltersMonitor from './monitors/FiltersMonitor';
import AttendanceMonitor from './monitors/AttendanceMonitor';
import { Attendance } from './data/attendance/Attendance';
import { AttendanceList } from './data/attendance/AttendanceList';
import { AttendanceDetails } from './data/attendance/AttendanceDetails';
import ChatMonitor from './monitors/ChatMonitor';
import { Chat } from './data/chat/Chat';
import { AttendanceRegister } from './data/attendance/AttendanceRegister';
import { AttendanceAccept } from './data/attendance/AttendanceAccept';
import { AttendanceReject } from './data/attendance/AttendanceReject';
import LivescreenMonitor from './monitors/LivescreenMonitor';
import { CurrentResult, CurrentResults } from './data/livescreen/CurrentResults';
import { AttendanceEmail } from './data/attendance/AttendanceEmail';

export class Session extends ISession
{
    onConfigurationRequested = new Event();

    constructor(/*IConnectionFactory*/ factory, security)
    {
        super();

        this.mDevice = new Device();

        this.mConnection = new BackendConnection(factory);
        /* Events */
        this.onSessionLoginCreated = new Event();
        this.onSessionPersonalizeCreated = new Event();
        this.onSessionRecoverCreated = new Event();
        this.onSessionActivateCreated = new Event();
        this.onSessionRefreshCreated = new Event();
        this.onSessionChangePasswordCreated = new Event();
        this.onRaceResultsCreated = new Event();
        this.onRaceDetailsCreated = new Event();
        this.onTimeslipCreated = new Event();
        this.onLatestResultCreated = new Event();
        this.onNextRaceCreated = new Event();
        this.onSetNextRaceCreated = new Event();
        this.onCurrentResultsCreated = new Event();
        this.onPlayersUploadCreated = new Event();
        this.onPlayersDownloadCreated = new Event();
        this.onVehicleCreated = new Event();
        this.onVehicleListCreated = new Event();
        this.onVehicleApplicationCreated = new Event();
        this.onApplicationsMineCreated = new Event();
        this.onApplicationsListCreated = new Event();
        this.onCreateLeagueCreated = new Event();
        this.onRawResultCreated = new Event();
        this.onAttendanceCreated = new Event();
        this.onAttendanceRegisterCreated = new Event();
        this.onAttendanceListCreated = new Event();
        this.onAttendanceDetailsCreated = new Event();
        this.onAttendanceAcceptCreated = new Event();
        this.onAttendanceRejectCreated = new Event();
        this.onAttendanceEmailCreated = new Event();
        this.onChatCreated = new Event();

        this.monitors = {};
        this.monitors.sessionMonitor = new SessionMonitor(this);
        this.monitors.globalsMonitor = new GlobalsMonitor(this);
        this.monitors.resultsMonitor = new ResultsMonitor(this);
        this.monitors.playersMonitor = new PlayersMonitor(this);
        this.monitors.vehicleMonitor = new VehicleMonitor(this);
        this.monitors.applicationsMonitor = new ApplicationsMonitor(this);
        this.monitors.leagueMonitor = new LeagueMonitor(this);
        this.monitors.classMonitor = new ClassMonitor(this);
        this.monitors.eventMonitor = new EventMonitor(this);
        this.monitors.rawResultsMonitor = new RawResultsMonitor(this);
        this.monitors.filtersMonitor = new FiltersMonitor(this);
        this.monitors.attendanceMonitor = new AttendanceMonitor(this);
        this.monitors.chatMonitor = new ChatMonitor(this);
        this.monitors.livescreenMonitor = new LivescreenMonitor(this);

        this.session = null;
        this.security = security;

        this.leagues = null;
        this.filters = null;

        this.user = null;
        this.permissions = new Permissions();
    }

    init()
    {
        this.monitors.globalsMonitor.fetchEvents();
    }

    getSecurityProvider()
    {
        return this.security;
    }

    getPersonalized()
    {
        if ( null == this.session )
        {
            return false;
        }
        if ( null == this.session.id )
        {
            return false;
        }
        return true;
    }

    getUser()
    {
        return this.user;
    }

    getPermissions()
    {
        return this.permissions;
    }

    createSessionLogin()
    {
        var retval = new SessionLogin();
        this.onSessionLoginCreated.callback(this, retval);
        return retval;
    }

    createSessionPersonalize()
    {
        var retval = new SessionPersonalize();
        this.onSessionPersonalizeCreated.callback(this, retval);
        return retval;
    }

    createSessionRecover()
    {
        var retval = new SessionRecover();
        this.onSessionRecoverCreated.callback(this, retval);
        return retval;
    }

    createSessionActivate()
    {
        var retval = new SessionActivate();
        this.onSessionActivateCreated.callback(this, retval);
        return retval;
    }

    createSessionRefresh()
    {
        var retval = new SessionRefresh();
        this.onSessionRefreshCreated.callback(this, retval);
        return retval;
    }

    createSessionChangePassword()
    {
        var retval = new SessionChangePassword();
        this.onSessionChangePasswordCreated.callback(this, retval);
        return retval;
    }


    createRaceResults()
    {
        var retval = new RaceResults();
        this.onRaceResultsCreated.callback(this, retval);
        return retval;
    }

    createRaceDetails()
    {
        var retval = new RaceDetails();
        this.onRaceDetailsCreated.callback(this, retval);
        return retval;
    }

    createTimeslip(token)
    {
        var retval = new Timeslip(token);
        this.onTimeslipCreated.callback(this, retval);
        return retval;
    }

    createLatestResult()
    {
        var retval = new LatestResult();
        this.onLatestResultCreated.callback(this, retval);
        return retval;
    }

    createNextRace()
    {
        var retval = new NextRace();
        this.onNextRaceCreated.callback(this, retval);
        return retval;
    }
    createSetNextRace()
    {
        var retval = new SetNextRace();
        this.onSetNextRaceCreated.callback(this, retval);
        return retval;
    }
    createCurrentResults()
    {
        var retval = new CurrentResults();
        this.onCurrentResultsCreated.callback(this, retval);
        return retval;
    }

    createPlayersUpload()
    {
        var retval = new PlayersUpload();
        this.onPlayersUploadCreated.callback(this, retval);
        return retval;
    }

    createPlayersDownload()
    {
        var retval = new PlayersDownload();
        this.onPlayersDownloadCreated.callback(this, retval);
        return retval;
    }

    createVehicle()
    {
        var retval = new Vehicle();
        this.onVehicleCreated.callback(this, retval);
        return retval;
    }


    createVehicleList()
    {
        var retval = new VehicleList();
        this.onVehicleListCreated.callback(this, retval);
        return retval;
    }

    createVehicleApplication()
    {
        let retval = new VehicleApplication();
        this.onVehicleApplicationCreated.callback(this, retval);
        return retval;
    }

    createApplicationsMine()
    {
        let retval = new ApplicationsMine();
        this.onApplicationsMineCreated.callback(this, retval);
        return retval;
    }

    createApplicationsList()
    {
        let retval = new ApplicationsList();
        this.onApplicationsListCreated.callback(this, retval);
        return retval;
    }

    createCreateLeague()
    {
        let retval = new CreateLeague();
        this.onCreateLeagueCreated.callback(this, retval);
        return retval;
    }

    createRawResult()
    {
        let retval = new RawResult();
        this.onRawResultCreated.callback(this, retval);
        return retval;
    }

    createAttendance(token, type)
    {
        let retval = new Attendance();

        retval.token = token;
        retval.type = type;

        this.onAttendanceCreated.callback(this, retval);

        return retval;
    }

    createAttendanceRegister(type, token, vehicle)
    {
        let retval = new AttendanceRegister();
        
        retval.type = type;
        retval.token = token;
        
        retval.vehicle = vehicle;

        this.onAttendanceRegisterCreated.callback(this, retval);
        return retval;
    }

    createAttendanceList(token)
    {
        let retval = new AttendanceList();
        
        retval.token = token;

        this.onAttendanceListCreated.callback(this, retval);
        return retval;
    }

    
    createAttendanceDetails(token)
    {
        let retval = new AttendanceDetails();
        
        retval.token = token;

        this.onAttendanceDetailsCreated.callback(this, retval);
        return retval;
    }

    createAttendanceAccept(token)
    {
        let retval = new AttendanceAccept();

        retval.token = token;

        this.onAttendanceAcceptCreated.callback(this, retval);
        return retval;
    }

    createAttendanceReject(token)
    {
        let retval = new AttendanceReject();

        retval.token = token;

        this.onAttendanceRejectCreated.callback(this, retval);
        return retval;
    }
    createAttendanceEmail()
    {
        let retval = new AttendanceEmail();
        this.onAttendanceEmailCreated.callback(this, retval);
        return retval;
    }

    createChat(token)
    {
        let retval = new Chat();
        retval.token = token;

        this.onChatCreated.callback(this, retval);
        return retval;
    }

    Reset()
    {
        this.user = null;
        this.permissions = new Permissions(); // .Reset();
        this.session = null;
        this.onPermissionsChanged.callback(this, this.permissions);
        this.onPersonalizeChanged.callback(this, false);
    }

    serialize(/*JSONObject*/ stream)
    {
        {
            var _device = new JSONObject();
            this.mDevice.serialize(_device);
            stream.put('device', _device);
        }
        if ( this.session )
        {
            var _session = new JSONObject();
            _session.put('sid', this.session.id);
            stream.put('session', _session);

            if ( this.permissions )
            {
                var _permissions = new JSONObject();
                this.permissions.serialize(_permissions);
                stream.put('permissions', _permissions);
            }
        }
        if ( this.user )
        {
            var _user = new JSONObject();
            this.user.serialize(_user);
            stream.put('user', _user);
        }

        return true;
    }

    deserialize(/*JSONObject*/ stream)
    {
        var _device = stream.opt('device', null);
        if ( _device )
        {
            this.mDevice.deserialize(_device);
        }
        var _user = stream.opt('user', null);
        if ( _user )
        {
            var user = new User();
            user.deserialize(_user);
            this.user = user;
        }
        var _session = stream.opt('session', null);
        if ( _session )
        {
            var sid = _session.opt('sid', null);
            if ( sid )
            {
                this.session = { id: sid };

                var _permissions = stream.opt('permissions', null )
                if ( _permissions )
                {
                    var newPermissions = new Permissions();
                    newPermissions.deserialize(_permissions);

                    this.permissions = newPermissions;
                    this.onPermissionsChanged.callback(this, this.permissions);
                }                
            }
        }
        return true;
    }

    SessionRequest(/*String*/ worker, /*Result*/ result, /*Request*/ request )
    {
        this.mConnection.Push(worker, this, result, request);
    }

    getSessionId()
    {
        return null;
    }

    getInstallationId()
    {
        return null;
    }

    getLeagues()
    {
        return this.leagues;
    }

    getFilters()
    {
        return this.filters;
    }

    serializeEnvelope(/*JSONObject*/ outdata)
    {
        if ( null != this.session )
        {
            var _session = new JSONObject();
            _session.put('sid', this.session.id);
            outdata.put('session', _session);
        }
        {
            var _device = new JSONObject();
            if ( this.mDevice.serialize(_device) )
            {
                outdata.put('device', _device);
            }
        }
    }

    deserializeEnvelope(/*JSONObject*/ outdata)
    {
        if ( outdata.has('user') )
        {
            var user = outdata.get('user');
            if ( null == this.user )
            {
                this.user = new User();
            }
            this.user.login = user.opt('login', null);
            this.user.name = user.opt('name', null);
            this.user.nick = user.opt('nick', null);
            this.user.email = user.opt('email', null);
            this.user.avatar_url = user.opt('avatar_url', null);
        }
        if ( outdata.has('permissions') )
        {
            var data = outdata.get('permissions');

            let newPermission =  new Permissions();
            newPermission.process(data);
            
            if ( !newPermission.Compare(this.permissions) )
            {
                this.permissions = newPermission;
                this.onPermissionsChanged.callback(this, this.permissions);
            }
        }         
        if ( outdata.has('session') )
        {
            var session = outdata.get('session');
            var closed = session.get('session_closed');
            if ( closed )
            {
                this.session = null;
                this.user = null;
                this.onPersonalizeChanged.callback(this, false);
            }
            else
            {
                var sid = session.get('sid');
                if ( this.session )
                {
                    if ( sid != this.session.id )
                    {
                        this.session.id = sid;
                        this.onPersonalizeChanged.callback(this, true);
                    }
                }
                else
                {
                    this.session = { id: session.get('sid') };
                    this.onPersonalizeChanged.callback(this, true);
                }
            }
        }

        if ( outdata.has('leagues') )
        {
            this.monitors.globalsMonitor.process(outdata.get('leagues'));
        }
        if ( outdata.has('filters') )
        {
            this.monitors.filtersMonitor.process(outdata.get('filters'));
        }
    }

}
import moment from 'moment';

export default class Utils
{
    static time2string(value)
    {
        if ( null == value || value.length < 1 )
        {
            return null;
        }
        let fvalue = parseFloat(value);
        return fvalue.toFixed(3) + 's ';
    }
    static speed2string(value)
    {
        if ( null == value || value.length < 1 )
        {
            return null;
        }
        let fvalue = parseFloat(value);
        return fvalue.toFixed(2) + 'kmh';   
    }
    static concatTimeSpeed(time, speed)
    {
        if ( null == time && null == speed )
        {
            return null;
        }
        if ( null == time )
        {
            return '??? @ ' + Utils.speed2string(speed);
        }
        if ( null == speed )
        {
            return Utils.time2string(time);
        }
  
        return Utils.time2string(time) + ' @ ' + Utils.speed2string(speed);
    }

    static addTimes(et, rt)
    {
        if ( null == et && null == rt )
        {
            return null;
        }
        if ( null == et )
        {
            return rt;
        }
        if ( null == rt )
        {
            return et;
        }
        let retval = parseFloat(et) + parseFloat(rt);
        return retval.toFixed(3);
    }

    static concatCarData(car, power, mass)
    {
        if ( null == car && null == power && null == mass )
        {
            return null;
        }
        if ( null == car )
        {
            return null;
        }

        if ( null != power && null != mass )
        {
            return car + ' [ ' + power + 'KM @ ' + mass + 'kg ]';
        }
        if ( null != power )
        {
            return car + ' [ ' + power + 'KM ]';
        }
        return car;
    }

    static concatDriverData(name, number)
    {
        if ( null == name && null == number )
        {
            return null;
        }
        if ( null == number )
        {
            return name;
        }
        if ( null == name )
        {
            return number;
        }

        return name + ' - ' + number;
    }

    static map_values(dictionary, value)
    {
        if ( dictionary[value] )
        {
            return dictionary[value];
        }
        return value;
    }

    static timestamps_to_string(from, to, mask)
    {
        var retval = '';
        if ( from )
        {
            retval = from.format(mask);
        }
        if ( to )
        {
            if ( retval.length > 0)
            {
                retval = retval + ' - ' + to.format(mask);
            }
            else
            {
                retval = to.format(mask);
            }
        }

        return retval;
    }

    static check_value_in_dictionary(dictionary, value)
    {
        if ( dictionary.includes(value) )
        {
            return null;
        }

        return "Dozwolone wartosci: " + dictionary.join(", ");
    }

    static validate_password(password)
    {
        if ( !password )
        {
            return null
        }
        if ( password.length < 1 )
        {
            return null;
        }
        if ( password.length < 8 )
        {
            return 'Hasło musi zawierać conajmniej 8 znaków';
        }

        let low_letters = 0;
        let high_letters = 0;
        let digits = 0;
        let symbols = 0;

        for( var i = 0; i < password.length; ++i )
        {
            let c = password[i];
            if ( c >= 'a' && c <= 'z' )
            {
                low_letters++;
                continue;
            }
            if ( c >= 'A' && c <= 'Z' )
            {
                high_letters++;
                continue;
            }
            if ( c >= '0' && c <= '9' )
            {
                digits++;
                continue;
            }
            symbols++;
        }

        if ( low_letters < 1 )
        {
            return 'Hasło musi zawierać conajmniej jedna małą litere';
        }
        if ( high_letters < 1 )
        {
            return 'Hasło musi zawierać conajmniej jedna dużą litere';
        }
        if ( digits < 1 )
        {
            return 'Hasło musi zawierać conajmniej jedna cyfre';
        }
        if ( symbols < 1 )
        {
            return 'Hasło musi zawierać conajmniej jeden symbol';
        }
        return null;
    }


    static validate_regex(pattern)
    {
        if ( !pattern )
        {
            return null
        }
        if ( pattern.length < 1 )
        {
            return null;
        }
        try
        {
            const regex = new RegExp(pattern);
        }
        catch ( ex )
        {
            return 'Niepoprawna maska RegEx';
        }
        return null;
    }

    static validate_datetime(value)
    {
        if ( !value )
        {
            return null
        }
        if ( value.length < 1 )
        {
            return null;
        }
        if ( value.length < 16 )
        {
            return 'Format: 2022-01-01 18:00';
        }

        const data = moment(value, 'YYYY-MM-DD HH:mm', true);
        if (! data.isValid() )
        {
            return 'Format: 2022-01-01 18:00';
        }
        return null;
    }

    static validate_timestamp(value)
    {
        if ( !value )
        {
            return null
        }
        if ( value.length < 1 )
        {
            return null;
        }
        if ( value.length < 19 )
        {
            return 'Format: 2022-01-01 18:00:00';
        }

        const data = moment(value, 'YYYY-MM-DD HH:mm:SS', true);
        if (! data.isValid() )
        {
            return 'Format: 2022-01-01 18:00:00';
        }
        return null;
    }

    static validate_race_formula(value)
    {
        if ( null == value || value.length < 1)
        {
            return null;
        }
        const retval = Utils.check_value_in_dictionary(['ET1000ft', 'ET660ft', 'ET60ft', 'RT', 'RT+ET1320ft', 'RT+ET1000ft', 'RT+ET660ft', 'RT+ET60ft' ], value);
        return retval;
    }

    static validate_gps(value)
    {
        if ( !value )
        {
            return null
        }
        if ( value.length < 1 )
        {
            return null;
        }

        var regex = new RegExp('^[0-9]{1,2}\.[0-9]{5,}, *[0-9]{1,2}\.[0-9]{5,}$');
        if ( !regex.test(value) )
        {
            return "Koordynaty z google maps: 53.16336844797926, 16.703405168562206"
        }

        return null;
    }

    static validate_et(value)
    {
        if ( !value )
        {
            return null
        }
        if ( value.length < 1 )
        {
            return null;
        }

        var regex = new RegExp('^[0-9]{1,2}\.[0-9]{3}$');
        if ( !regex.test(value) )
        {
            return "Format: XX.YYY"
        }

        return null;
    }

    static validate_speed(value)
    {
        if ( !value )
        {
            return null
        }
        if ( value.length < 1 )
        {
            return null;
        }

        var regex = new RegExp('^[0-9]{1,3}\.[0-9]{3}$');
        if ( !regex.test(value) )
        {
            return "Format: XXX.YYY"
        }

        return null;
    }

    static validate_amount(value)
    {
        if ( !value )
        {
            return null
        }
        if ( value.length < 1 )
        {
            return null;
        }

        var regex = new RegExp('^[0-9]+(\.[0-9]{2})?$');
        if ( !regex.test(value) )
        {
            return "Format: XXX.YY"
        }

        return null;
    }

    static getDataTransferFiles(event) {
        let dataTransferItemsList = [];
        if (event.dataTransfer) {
            const dt = event.dataTransfer;
            if (dt.files && dt.files.length) {
                dataTransferItemsList = dt.files;
            } else if (dt.items && dt.items.length) {
                // During the drag even the dataTransfer.files is null
                // but Chrome implements some drag store, which is accesible via dataTransfer.items
                dataTransferItemsList = dt.items;
            }
        } else if (event.target && event.target.files) {
            dataTransferItemsList = event.target.files;
        }
    
    
        // Convert from DataTransferItemsList to the native Array
        return Array.prototype.slice.call(dataTransferItemsList);
    }
}
import Event from '../../property/Event';

export default class ISetNextRace {
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    setLeague(league) {
        throw new TypeError("Method not implemented");
    }

    setEvent(event) {
        throw new TypeError("Method not implemented");
    }

    getLeft() {
        throw new TypeError("Method not implemented");
    }
    setLeft(value) {
        throw new TypeError("Method not implemented");
    }
    getRight() {
        throw new TypeError("Method not implemented");
    }
    setRight(value) {
        throw new TypeError("Method not implemented");
    }
    Commit() {
        throw new TypeError("Method not implemented");
    }

}
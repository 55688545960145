import React, { Component } from 'react';
import update from "react-addons-update";
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { Route } from 'react-router-dom';

import Database from './Database';
import './App.css';
import { Dashboard } from './screen/dashboard/Dashboard';
import { EventsScreen } from './screen/events/EventsScreen';
import { EventScreen } from './screen/events/EventScreen';
import ResultsScreen from './screen/events/ResultsScreen';
import { ResultsScreen2} from './screen/events/ResultsScreen2';
import { TimeslipScreen } from './screen/events/TimeslipScreen';
import { LoginScreen } from './screen/login/LoginScreen';
import LiveScreen from './screen/livescreen/LiveScreen';
import { LivePilotScreen } from './screen/livescreen/LivePilotScreen';
import { PlayersScreen } from './screen/players/PlayersScreen';
import { LeagueScreen } from './screen/events/LeagueScreen';
import { RaceScreen } from './screen/events/RaceScreen';
import { ProfileScreen } from './screen/dashboard/ProfileScreen';
import { GarageScreen } from './screen/dashboard/GarageScreen';
import { VehicleRegisterScreen } from './screen/vehicle/VehicleRegisterScreen';
import { ApplicationsScreen } from './screen/dashboard/ApplicationsScreen';

import moment from 'moment';
import { ApplicationsViewScreen } from './screen/admin/ApplicationsViewScreen';
import LeagueAddScreen from './screen/manage/league/LeagueAddScreen';
import LeagueEditScreen from './screen/manage/league/LeagueEditScreen';
import { RegisterScreen } from './screen/login/RegisterScreen';
import { ActivateScreen } from './screen/login/ActivateScreen';
import { RecoverScreen } from './screen/login/RecoverScreen';
import ClassAddScreen from './screen/manage/class/ClassAddScreen';
import ClassEditScreen from './screen/manage/class/ClassEditScreen';
import EventAddScreen from './screen/manage/event/EventAddScreen';
import { EventEditScreen } from './screen/manage/event/EventEditScreen';
import PlayersListScreen from './screen/manage/players/PlayersListScreen';
import PlayerEditScreen from './screen/manage/players/PlayerEditScreen';
import ResultEditScreen from './screen/manage/results/ResultEditScreen';
import EventResultsScreen from './screen/manage/event/EventResultsScreen';
import { ClassesScreen } from './screen/events/ClassesScreen';
import { ClassScreen } from './screen/events/ClassScreen';
import ShortcutsScreen from './screen/dashboard/ShortcutsScreen';
import { AttendanceAdminScreen } from './screen/attendance/AttendanceAdminScreen';
import { AttendancePlayerScreen } from './screen/attendance/AttendancePlayerScreen';
import ChatScreen from './screen/chat/ChatScreen';
import RouteWithLayout from './screen/RouteWithLayout';
import { ChangePasswordScreen } from './screen/login/ChangePasswordScreen';
import { LayoutHeader } from './screen/LayoutHeader';
import { LayoutMaterial } from './screen/LayoutMaterial';

class App extends Component {

  constructor()
  {
    super()
    Database.initialize();
    this.state = {};

    this.session =  Database.instance().getSession();
  }

  componentDidMount()
  {
    if ( this.session.getPersonalized() )
    {
        var action = this.session.createSessionRefresh();
        action.OnCommitFinished.register(this.onSessionRefreshCommitFinished.bind(this));
        action.Commit();
    }
  }

  onSessionRefreshCommitFinished(sender, params)
  {
    //this.setState((oldState) => update(oldState, { timestamp: { $set: moment() } }));
  }
  
  render() {
    return (
      <Router>
        <div id="frame">
          <Routes>
              <Route path='/' exact element={<RouteWithLayout screen={Dashboard} layout={LayoutMaterial} session={this.session} />}/>
              <Route path='/livescreen' exact element={<LiveScreen session={this.session}/>}/>
              <Route path='/livescreen/pilot' exact element={<RouteWithLayout screen={LivePilotScreen} layout={LayoutMaterial} session={this.session}/>}/>
              <Route path='/account/activate' exact element={<RouteWithLayout screen={ActivateScreen} layout={LayoutMaterial} session={this.session} />}/>
              <Route path='/account/register' exact element={<RouteWithLayout screen={RegisterScreen} layout={LayoutMaterial} session={this.session} />}/>
              <Route path='/account/recover' exact element={<RouteWithLayout screen={RecoverScreen} layout={LayoutMaterial} session={this.session} />}/>
              <Route path='/account/login' exact element={<RouteWithLayout screen={LoginScreen} layout={LayoutMaterial} session={this.session} />}/>

              <Route path='/account/password' exact element={<RouteWithLayout screen={ChangePasswordScreen} layout={LayoutMaterial} session={this.session} require_user={true}/>}/>
              <Route path="/account/profile" exact element={<RouteWithLayout screen={ProfileScreen} layout={LayoutMaterial} session={this.session} require_user={true} />} />
              <Route path="/account/shortcuts" exact element={<ShortcutsScreen session={this.session} require_user={true} />} />
              <Route path='/events' element={<RouteWithLayout screen={EventsScreen} layout={LayoutMaterial} session={this.session}/>}/>
              <Route path='/league/:leagueId' element={<RouteWithLayout screen={LeagueScreen} layout={LayoutMaterial} session={this.session}/>}/>
              <Route path='/event/:eventId' element={<RouteWithLayout screen={EventScreen} layout={LayoutMaterial} session={this.session} />} />
              <Route path='/players/:leagueId' element={<RouteWithLayout screen={PlayersScreen} layout={LayoutMaterial} session={this.session} />} />
              <Route path='/classes/:leagueId' element={<RouteWithLayout screen={ClassesScreen} layout={LayoutMaterial} session={this.session}/>}/>
              <Route path='/class/:classId' element={<RouteWithLayout screen={ClassScreen} layout={LayoutMaterial} session={this.session}/>}/>

              <Route path='/results/:eventId/best' element={<RouteWithLayout screen={ResultsScreen2} layout={LayoutMaterial} session={this.session} show_best={true} />} />
              <Route path='/results/:eventId/player/:playerId' element={<RouteWithLayout screen={ResultsScreen2} layout={LayoutMaterial} session={this.session} show_best={false} />} />
              <Route path='/results/:eventId/class/:classId/best' element={<RouteWithLayout screen={ResultsScreen2} layout={LayoutMaterial} session={this.session} show_best={true} />} />
              <Route path='/results/:eventId/class/:classId' element={<RouteWithLayout screen={ResultsScreen2} layout={LayoutMaterial} session={this.session} show_best={false} />} />
              <Route path='/results/:eventId' element={<RouteWithLayout screen={ResultsScreen2} layout={LayoutMaterial} session={this.session} show_best={false} />} />

              <Route path='/race/:raceId' element={<RouteWithLayout screen={RaceScreen} layout={LayoutMaterial} session={this.session} />} />
              <Route path='/timeslip/:timeslipId' element={<RouteWithLayout screen={TimeslipScreen} layout={LayoutMaterial} session={this.session} />} />
              
              <Route path="/garage" element={<RouteWithLayout screen={GarageScreen} layout={LayoutMaterial} session={this.session} require_user={true} />} />
              <Route path="/vehicle/register" element={<RouteWithLayout screen={VehicleRegisterScreen} layout={LayoutMaterial} session={this.session} require_user={true} />} />
              <Route path="/applications/mine" element={<RouteWithLayout screen={ApplicationsScreen} layout={LayoutMaterial} session={this.session} require_user={true} />} />
              <Route path="/applications/view" element={<RouteWithLayout screen={ApplicationsViewScreen} layout={LayoutMaterial} session={this.session} require_user={true} />} />

              <Route path='/manage/league/add' exact element={<LeagueAddScreen require_user={true} session={this.session}/>}/>
              <Route path='/manage/league/:leagueId' element={<LeagueEditScreen require_user={true} session={this.session}/>}/>
              
              <Route path='/manage/class/:leagueId/add' element={<ClassAddScreen require_user={true} session={this.session}/>}/>
              <Route path='/manage/class/:classId/edit' element={<ClassEditScreen require_user={true} session={this.session}/>}/>

              <Route path='/manage/event/:leagueId/add' element={<EventAddScreen require_user={true} session={this.session}/>}/>
              <Route path='/manage/event/:eventId/edit' element={<RouteWithLayout screen={EventEditScreen} layout={LayoutMaterial} require_user={true} session={this.session}/>}/>
              <Route path='/manage/event/:eventId/results' element={<EventResultsScreen require_user={true} session={this.session}/>}/>

              <Route path='/manage/players/:leagueId' exact element={<PlayersListScreen require_user={true} session={this.session}/>}/>
              <Route path='/manage/players/:leagueId/:playerId/edit' exact element={<PlayerEditScreen require_user={true} session={this.session}/>}/>
              
              <Route path='/manage/result/:resultId/edit' exact element={<ResultEditScreen require_user={true} session={this.session}/>} />
              <Route path='/attendance/admin/:eventId' exact element={<RouteWithLayout screen={AttendanceAdminScreen} layout={LayoutMaterial} require_user={true} session={this.session}/>} />
              <Route path='/attendance/player/:eventId' exact element={<RouteWithLayout screen={AttendancePlayerScreen} layout={LayoutMaterial} require_user={true} session={this.session}/>} />

              <Route path='/chat/:chatId' exact element={<ChatScreen require_user={true} session={this.session}/>} />

              <Route path='*' element={<Navigate to='/'/>}/>
          </Routes>
        </div>
      </Router>
    );
  }
}

export default App;

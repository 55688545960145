import IApplicationsMine from "../../../sdk/data/applications/IApplicationsMine";

export class ApplicationsMine extends IApplicationsMine
{
    constructor()
    {
        super();
        this.vehicles = null;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getVehicleApplications()
    {
        return this.vehicles;
    }
}
import React, { Component, useEffect, useRef, useState } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useNavigate, useSearchParams } from 'react-router-dom';

import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import './LivePilotScreen.css'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import moment from 'moment';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export const LivePilotScreen = ({session}) =>  {
    const [isAsyncOperation, setAsyncOperation] = useState(false);
    const [commitErrors, setCommitErrors] = useState(null);

    const [ leagues, setLeagues ] = useState(null);
    const [ leaguesData, setLeaguesData ] = useState(null);

    const [ selectedLeague, setSelectedLeague ] = useState(null);
    const [ selectedEvent, setSelectedEvent ] = useState(null);
    
    const [canEditLiveScreen, setCanEditLiveScreen] = useState(false);

    const [leftPlayer, setLeftPlayer] = useState(null);
    const [leftPlayerErrors, setLeftPlayerErrors] = useState(null);
    const [leftPlayerSuccess, setLeftPlayerSuccess] = useState(null);

    const [rightPlayer, setRightPlayer] = useState(null);
    const [rightPlayerErrors, setRightPlayerErrors] = useState(null);
    const [rightPlayerSuccess, setRightPlayerSuccess] = useState(null);

    
    useEffect( () => { 
        const leagueChangedCallback = (sender, leagues) => {
            setLeagues(leagues) ;
        }
        session.onLeaguesChanged.register(leagueChangedCallback);

        setLeagues(session.getLeagues()) ;
        return () => {
            session.onPersonalizeChanged.unregister(leagueChangedCallback);
        }
    }, [])

    
    useEffect( () => {
        if (null == leagues) {
            return;
        }
        let _leagues = [];
        for (const l of leagues) {
            let league = {};
            league.external = l.getExternal();
            league.shortname = l.getShortname();
            league.is_finished = l.getIsFinished();

            let players = l.getPlayers().map((x) => {
                let plr = {};
                plr.class = x.getClass();
                plr.external = x.getExternal();
                plr.player = x.getPlayer();
                plr.shortname = x.getPlayerCar();
                plr.driver = x.getPlayerName();
                return plr;
            })

            let events = l.getEvents().map((x) => {
                let evt = {};

                evt.external = x.getExternal();
                evt.shortname = x.getShortname();
                evt.timestamp_start = x.getTimestampStart();
                evt.timestamp_end = x.getTimestampEnd();

                return evt;
            });

            league.players = players;
            league.events = events;
            _leagues.push(league);
        }
        setLeaguesData(_leagues);
    }, [leagues]);

    useEffect( () => {
        if ( !leaguesData )
        {
            return
        }
        let window_from = moment().add(-2, 'days');
        let window_to = moment().add(2, 'days');

        for ( const l of leaguesData )
        {
            if ( !l.events )
            {
                continue
            }
            for ( const e of l.events )
            {
                if ( window_from.isAfter(e.timestamp_end) )
                {
                  continue;
                }
                if ( window_to.isBefore(e.timestamp_start) )
                {
                  continue;
                }

                setSelectedLeague(l);
                setSelectedEvent(e);
                return
            }
        }
    }, [leaguesData]);
    
    const selectLeague = (value) => {
        setSelectedLeague(value);
        setSelectedEvent(null);
    }

    const selectLeagueEvent = (value) => {
        setSelectedEvent(value);
    }

    useEffect( () => { 
       
        const permissionsCallback = (sender, permissions) => {
          setCanEditLiveScreen(permissions.hasPermissionGlobal('can_edit_livescreen'));
        }
        session.onPermissionsChanged.register(permissionsCallback);

        
        permissionsCallback(session, session.getPermissions());
        return () => {
            session.onPermissionsChanged.unregister(permissionsCallback);
        }
    }, [])

    const validateLeftPlayerNumber = (value) => {
        var regex = new RegExp("^[0-9]*$");
        if ( !regex.test(value) )
        {
            setLeftPlayerErrors("Numer zawodnika");
            setLeftPlayerSuccess(null);
            return;
        }
        setLeftPlayer(value);
    }

    useEffect( () => {
        setLeftPlayerErrors(null);
        setLeftPlayerSuccess(null);

        if ( !leftPlayer )
        {
            return
        }
        
        if ( selectedLeague )
        {
            let player = selectedLeague.players.find((x) => x.player == leftPlayer)
            if ( !player )
            {
                setLeftPlayerErrors("Player not found");
            }
            else
            {
                setLeftPlayerSuccess(player.shortname + " - " + player.driver);
            }
        }
    }, [leftPlayer])

    const validateRightPlayerNumber = (value) => {
        var regex = new RegExp("^[0-9]*$");
        if ( !regex.test(value) )
        {
            setRightPlayerErrors("Numer zawodnika");
            setRightPlayerSuccess(null);
            return;
        }
        setRightPlayer(value);
    }

    useEffect( () => {
        if ( !rightPlayer )
        {
            return
        }

        setRightPlayerErrors(null);
        setRightPlayerSuccess(null);
        
        if ( selectedLeague )
        {
            let player = selectedLeague.players.find((x) => x.player == rightPlayer)
            if ( !player )
            {
                setRightPlayerErrors("Player not found");
            }
            else
            {
                setRightPlayerSuccess(player.shortname + " - " + player.driver);
            }
        }
    }, [rightPlayer])

    const commitPrestage = () => {
        const commitRequested = (sender, params) => {
            setAsyncOperation(true);
        }
        const commitFinished = (sender, params) => {
            setAsyncOperation(false);
            setLeftPlayer("");
            setRightPlayer("");
        }
        const commitError = (sender, error) => {
            setAsyncOperation(false);
            setCommitErrors(error.message);
        }

        let action = session.createSetNextRace();

        action.setLeague(selectedLeague.external);
        action.setEvent(selectedEvent.external);

        action.setLeft(leftPlayer);
        action.setRight(rightPlayer);

        action.OnCommitRequested.register(commitRequested);
        action.OnCommitFinished.register(commitFinished);
        action.OnCommitError.register(commitError);

        action.Commit();
    }

    return (
        <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={commitErrors != null} autoHideDuration={6000} onClose={() => {  setCommitErrors(null); }} sx={{minWidth: '50%'}} >
                <Alert severity="error" sx={{ width: '100%' }}>{commitErrors}</Alert>
            </Snackbar>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isAsyncOperation} >
                <CircularProgress color="inherit" />
            </Backdrop>

            {!canEditLiveScreen && 
                <Box>Brak uprawnień</Box>
            }
            {canEditLiveScreen && leaguesData && 
                <>
                    <Box>
                        <h5><b>Pilot</b></h5>
                        <Box>
                            <FormControl sx={{ m: 1, width: '50ch', maxWidth: '90%' }} variant="outlined">
                                <InputLabel id="league-label" shrink={selectedLeague != null}>Liga</InputLabel>
                                <Select
                                    labelId="league-label"
                                    id="league-select"
                                    value={selectedLeague}
                                    label="Liga"
                                    onChange={(event) => selectLeague(event.target.value)}
                                    
                                >
                                    {leaguesData.map((x) => {
                                        return (<MenuItem value={x}>{x.shortname}</MenuItem>)
                                    })}
                                    
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, width: '50ch', maxWidth: '90%'  }} variant="outlined">
                                <InputLabel id="event-label" shrink={selectedEvent != null}>Wydarzenie</InputLabel>
                                <Select
                                    labelId="event-label"
                                    id="event-select"
                                    value={selectedEvent}
                                    label="Wydarzenie"
                                    onChange={(event) => selectLeagueEvent(event.target.value)}
                                >
                                    {null != selectedLeague && selectedLeague.events.map((x) => {
                                        return (<MenuItem value={x}>{x.shortname}</MenuItem>)
                                    })}
                                    
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl sx={{ m: 1, width: '50ch', maxWidth: '90%'  }} variant="outlined">
                                <InputLabel htmlFor="lane-left">Lewy tor</InputLabel>
                                <OutlinedInput label="Lewy tor" variant="standard" type={'number'} value={leftPlayer} 
                                    onChange={(ev) => { validateLeftPlayerNumber(ev.target.value)}} id="lane-left" 
                                    error={leftPlayerErrors ? true : false} helperText={leftPlayerErrors} />

                                {leftPlayerErrors && (
                                    <FormHelperText error id="left-error">
                                        {leftPlayerErrors}
                                    </FormHelperText>)}
                                {leftPlayerSuccess && (
                                <FormHelperText id="left-success" sx={{color: 'green'}}>
                                    {leftPlayerSuccess}
                                </FormHelperText>)}
                            </FormControl>
                            <FormControl sx={{ m: 1, width: '50ch', maxWidth: '90%'  }} variant="outlined">
                                <InputLabel htmlFor="lane-right">Prawy tor</InputLabel>
                                <OutlinedInput label="Prawy tor" variant="standard" type={'number'} value={rightPlayer} 
                                    onChange={(ev) => { validateRightPlayerNumber(ev.target.value)}} id="lane-right" 
                                    error={rightPlayerErrors ? true : false} helperText={rightPlayerErrors} />

                                {rightPlayerErrors && (
                                    <FormHelperText error id="password-error">
                                        {rightPlayerErrors}
                                    </FormHelperText>)}
                                {rightPlayerSuccess && (
                                    <FormHelperText id="left-success" sx={{color: 'green'}}>
                                        {rightPlayerSuccess}
                                    </FormHelperText>)}                                    
                            </FormControl>
                        </Box>
                        <Divider sx={{ margin: "14px" }}/>
                        <Stack>
                            <Button variant="contained" size="small" sx={{maxWidth: "200px"}} disabled={leftPlayer == null && rightPlayer == null} onClick={commitPrestage}>Ustaw</Button>
                        </Stack>
                    </Box>
                </>
            }
        </Box>
    )
}
import JSONArray from "./JSONArray";

export default class JSONObject
{
    constructor(data)
    {
        if ( null != data )
        {
            this.deserialize(data);
        }
    }
    put(/*String*/ key, /*Object*/ value)
    {
        this[key] = value;
    }

    opt(/*String*/ key, /*Object*/ fallback)
    {
        if ( this.hasOwnProperty(key) )
        {
            return this[key];
        }
        return fallback;
    }

    get(/*String*/ key)
    {
        return this[key];
    }

    has(/*String*/ key)
    {
        if ( this.hasOwnProperty(key) )
        {
            return true;
        }
        return false;
    }

    keys()
    {
        var retval = [];
        for ( var key in this )
        {
            retval.push(key); 
        }
        return retval;
    }

    serialize()
    {
        var retval = {};
        for ( var key in this )
        {
            if ( this[key] instanceof JSONArray )
            {
                retval[key] = this[key].serialize();
                continue;
            }
            if ( this[key] instanceof JSONObject )
            {
                retval[key] = this[key].serialize();
                continue;
            }
            retval[key] = this[key];
        }
        return retval;
    }

    deserialize(/*String*/ serialized)
    {
        for ( var key in serialized )
        {
            var value = serialized[key];
            if ( Array.isArray(value) )
            {
                var sub = new JSONArray();
                if ( sub.deserialize(value) )
                {
                    this.put(key, sub);
                }
                continue;
            }
            else if ( value === Object(value) )
            {
                var sub = new JSONObject();
                if ( sub.deserialize(value) )
                {
                    this.put(key, sub);
                }
                continue;
            }
            this.put(key, value);
        }
        return true;
    }
}
import IVehicleList from "../../../sdk/data/vehicle/IVehicleList";

export class VehicleList extends IVehicleList
{
    constructor()
    {
        super();
        this.vehicles = null;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getVehicles()
    {
        return this.vehicles;
    }
}
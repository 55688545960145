import ILeagueEvent from '../../sdk/data/ILeagueEvent';
import { RemoveEvent } from './manage/event/RemoveEvent';
import Event from '../../sdk/property/Event';
import { UpdateEvent } from './manage/event/UpdateEvent';
import { RemoveResults } from './manage/event/RemoveResults';
import { UploadResults } from './manage/event/UploadResults';
import { ExampleEmail } from './manage/event/ExampleEmail';

export class LeagueEvent extends ILeagueEvent
{
    constructor()
    {
        super();
        
        this.external = null;
        this.shortname = null;
        this.description = null;
        this.thumbnail = null;
        this.location = null;
        this.gps = null;
        this.timestamp_start = null;
        this.timestamp_end = null;
        this.is_locked = null;
        this.is_registration_open = null;

        this.registration_fee_onsite = null;
        this.registration_fee_transfer = null;
        this.registration_fee_online = null;
        this.registration_fee_crew = null;

        this.timestamp_attendance_start = null;
        this.timestamp_attendance_transfer_end = null;
        this.timestamp_attendance_end = null;

        this.template_attendance_email = null;
        this.race_formula = null;


        this.onUpdateEventCreated = new Event();
        this.onRemoveEventCreated = new Event();
        
        this.onRemoveResultsCreated = new Event();
        this.onUploadResultsCreated = new Event();
        
        this.onExampleEmailCreated = new Event();
    }

    getExternal()
    {
        return this.external;
    }
    getShortname()
    {
        return this.shortname;
    }
    getDescription()
    {
        return this.description;
    }
    getThumbnail()
    {
        return this.thumbnail;
    }

    getLocation()
    {
        return this.location;
    }
    getGps()
    {
        return this.gps;
    }
    getTimestampStart()
    {
        return this.timestamp_start;
    }
    getTimestampEnd()
    {
        return this.timestamp_end;
    }
    getIsLocked()
    {
        return this.is_locked;
    }

    getIsRegistrationOpen()
    {
        return this.is_registration_open;
    }

    
    getRegistrationFeeOnsite()
    {
        return this.registration_fee_onsite;
    }
    getRegistrationFeeTransfer()
    {
        return this.registration_fee_transfer;
    }
    getRegistrationFeeOnline()
    {
        return this.registration_fee_online;
    }
    getRegistrationFeeCrew()
    {
        return this.registration_fee_crew;
    }
    getTimestampAttendanceStart()
    {
        return this.timestamp_attendance_start;
    }
    getTimestampAttendanceTransferEnd()
    {
        return this.timestamp_attendance_transfer_end;
    }
    getTimestampAttendanceEnd()
    {
        return this.timestamp_attendance_end;
    }
    getTemplateAttendanceEmail()
    {
        return this.template_attendance_email;
    }
    getRaceFormula()
    {
        return this.race_formula;
    }

    createUpdateEvent()
    {
        let retval = new UpdateEvent();
        retval.external = this.external;
        
        retval.shortname = this.shortname;
        retval.description = this.description;

        retval.location = this.location;
        retval.gps = this.gps;

        retval.timestamp_start = this.timestamp_start;
        retval.timestamp_end = this.timestamp_end;

        retval.registration_fee_onsite = this.registration_fee_onsite;
        retval.registration_fee_transfer = this.registration_fee_transfer;
        retval.registration_fee_online = this.registration_fee_online;
        retval.registration_fee_crew = this.registration_fee_crew;

        retval.timestamp_attendance_start = this.timestamp_attendance_start;
        retval.timestamp_attendance_transfer_end = this.timestamp_attendance_transfer_end;
        retval.timestamp_attendance_end = this.timestamp_attendance_end;

        retval.template_attendance_email = this.template_attendance_email;
        retval.race_formula = this.race_formula;
        
        this.onUpdateEventCreated.callback(this, retval);
        
        return retval;
    }  

    createRemoveEvent()
    {
        let retval = new RemoveEvent();
        retval.external = this.external;

        this.onRemoveEventCreated.callback(this, retval);

        return retval;
    }

    createRemoveResults()
    {
        let retval = new RemoveResults();
        retval.external = this.external;

        this.onRemoveResultsCreated.callback(this, retval);

        return retval;
    }

    createUploadResults()
    {
        let retval = new UploadResults();
        retval.external = this.external;

        this.onUploadResultsCreated.callback(this, retval);

        return retval;
    }

    createExampleEmail()
    {
        let retval = new ExampleEmail();
        retval.external = this.external;

        this.onExampleEmailCreated.callback(this, retval);

        return retval;
    }
}
import IServiceRequest from '../../connect/IServiceRequest'

export default class PlayersDownloadCommit extends IServiceRequest
{
    constructor()
    {
        super('www/players_download');
    }

    setLeague(league)
    {
        this.appendRequest("league", league);
    }
}
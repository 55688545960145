import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom'
import update from "react-addons-update";

import autoBind from 'react-autobind';
import './LeagueAddScreen.css'
import BaseScreenHeader from '../../BaseScreenHeader';
import InputField from '../../../components/common/InputField';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import PhotoBox from '../../../components/common/PhotoBox';
import Button from '../../../components/common/Button';
import withRouter from '../../../utils/withRouter';

class LeagueAddScreen extends BaseScreenHeader {
    static defaultProps = {
        session: null
    }
    constructor(props) {
        super(props)
        autoBind(this);
    }

    validatePermission()
    {
        return this.props.session.getPermissions().hasPermissionGlobal('can_create_league');
    }

    bindDelegates() {
        super.bindDelegates();
    }
    unbindDelegates() {
        super.unbindDelegates();
    }

    onInitParameters(bundle) {
        return true;
    }

    setLeagueName(name)
    {
        this.setState((oldState) => { return update(oldState, { league_name: { $set: name } }); })
    }

    setLeagueDescription(description)
    {
        this.setState((oldState) => { return update(oldState, { league_description: { $set: description } }); })
    }

    commitLeague()
    {
        var action = this.props.session.createCreateLeague();

        action.setShortname(this.state.league_name);
        action.setDescription(this.state.league_description);
        
        action.OnCommitRequested.register(this.onCreateLeagueCommitRequested);
        action.OnCommitFinished.register(this.onCreateLeagueCommitFinished);
        action.OnCommitError.register(this.onCreateLeagueCommitError);

        action.Commit();
    }

    onCreateLeagueCommitRequested(sender, params) {
        this.beginAsyncOperation();
    }

    onCreateLeagueCommitFinished(sender, params) {
        this.endAsyncOperation();
        this.showAndRedirect('Liga zostala dodana', '/manage/league/' + sender.getExternal());
    }

    onCreateLeagueCommitError(sender, err) {
        this.endAsyncOperation();

        this.showError(err);
    }

    renderScreen(child) {
        return super.renderScreen(
            <header className="w3-container">
                <h5>Nowa liga</h5>
                <div className="w3-container">
                    <h6>Nazwa cyklu</h6>
                    <InputField id="league-name" onCommit={(value) => this.setLeagueName(value) } />
                    <h6>Opis cyklu</h6>
                    <InputField id="league-description" onCommit={(value) => this.setLeagueDescription(value) }/>
                </div>

                <div className="w3-quarter w3-right w3-margin">
                    <Button onClicked={() => { this.commitLeague()}} disabled={this.state.asyncOperation || null == this.state.league_name || null == this.state.league_description} text="Dodaj lige" iconLeft={this.state.asyncOperation ? faSync : null} />
                </div>                
            </header>);

    }
}
export default withRouter(LeagueAddScreen);
import Event from '../../property/Event';

export default class IVehicleUpdate
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    getToken()
    {
        throw new TypeError("Method not implemented");
    }

    setCarShortname(data)
    {
        throw new TypeError("Method not implemented");
    }
    getCarShortname()
    {
        throw new TypeError("Method not implemented");
    }    
    setCarDescription(data)
    {
        throw new TypeError("Method not implemented");
    } 
    getCarDescription()
    {
        throw new TypeError("Method not implemented");
    } 
    setCarPower(data)
    {
        throw new TypeError("Method not implemented");
    }    
    getCarPower()
    {
        throw new TypeError("Method not implemented");
    }    
    setCarMass(data)
    {
        throw new TypeError("Method not implemented");
    }  
    getCarMass()
    {
        throw new TypeError("Method not implemented");
    }  
    setCarDriver(data)
    {
        throw new TypeError("Method not implemented");
    }    
    getCarDriver()
    {
        throw new TypeError("Method not implemented");
    }    
    setCarTeam(data)
    {
        throw new TypeError("Method not implemented");
    }  
    getCarTeam()
    {
        throw new TypeError("Method not implemented");
    }  

    Commit()
    {
        throw new TypeError("Method not implemented");
    }
}
import moment from 'moment';

import { v4 as uuidv4 } from 'uuid';

export default class ApiUtils
{
    static /*String*/ getUuid()
    {
        return uuidv4();
    }

    static serializeDateTime(value)
    {
        if ( null == value )
        {
            return null;
        }
        return value.format('YYYY-MM-DDTHH:mm:ss');
    }

    static deserializeDateTime(value)
    {
        if ( null == value )
        {
            return null;
        }
        return moment(value);
    }
}
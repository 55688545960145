import React, { Component } from 'react';
import autoBind from 'react-autobind';
import './Button.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Button extends Component {
  static defaultProps = {
    onClicked: null,
    project: null,
    iconLeft: null,
    iconRight: null,
    inverted: false,
    disabled: false
  }

  constructor(props) {
    super(props)
    autoBind(this);
    this.state = this.processProps(props);
  }

  componentWillReceiveProps(props)
  {
    this.setState(this.processProps(props));
  }

  processProps(props)
  {
    return { text: props.text };
  }

  render() {
    let callback = this.props.onClicked;
    if (null == callback) {
      callback = function () { }
    }

    let className = "Button-normal";
    if ( this.props.inverted )
    {
      className = "Button-inverted";
    }

    if ( this.props.disabled )
    {
      className += "-disabled";
      callback = function () {}
    }

    return (
      <div className="Button"  onClick={callback}>
        <div className={className}>
          {this.props.iconLeft && 
            <div id="iconLeft">
              <FontAwesomeIcon icon={this.props.iconLeft} />
            </div>}
          <div id="caption">{this.state.text}</div>
          {this.props.iconRight && <div id="iconRight"><img src={this.props.iconRight} /></div>}
        </div>
      </div>
    );
  }
}

export default Button;
import Event from "../../property/Event";

export default class IChat
{
    getToken()
    {
        throw new TypeError("Method not implemented");
    }

    OnQueryRequested = new Event();
    OnQueryFinished = new Event();
    OnQueryError = new Event();

    Query()
    {
        throw new TypeError("Method not implemented");
    }

    getTopic()
    {
        throw new TypeError("Method not implemented");
    }

    getIsLocked()
    {
        throw new TypeError("Method not implemented");
    }

    getFirstMessageTimestamp()
    {
        throw new TypeError("Method not implemented");
    }

    getLastMessageTimestamp()
    {
        throw new TypeError("Method not implemented");
    }

    getMessages()
    {
        throw new TypeError("Method not implemented");
    }

    OnMessagesChanged = new Event();

    CreateMessage()
    {
        throw new TypeError("Method not implemented");
    }
}
import Session from '../Session'

import IServiceResult from '../connect/IServiceResult';
import Engine, { Error } from '../Engine';
import LeagueCreateCommit from '../api/manage/league/LeagueCreateCommit';
import ApiUtils from '../api/ApiUtils';
import LeagueUpdateCommit from '../api/manage/league/LeagueUpdateCommit';

export default class LeagueMonitor {
    constructor(/*Session*/ session) {
        this.mSession = session;
        session.onCreateLeagueCreated.register(this.onCreateLeagueCreated.bind(this));
        session.onLeaguesChanged.register(this.onLeaguesChanged.bind(this));
    }
    
    onLeaguesChanged(/*Object*/ sender, leagues)
    {
        if ( !leagues )
        {
            return;
        }
        for ( const league of leagues )
        {
            league.onUpdateLeagueCreated.register(this.onUpdateLeagueCreated.bind(this));
        }
    }

    onCreateLeagueCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitRequested.register(this.onCreateLeagueCommitRequested.bind(this));
    }


    onCreateLeagueCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new LeagueCreateCommit();

        request.setShortname(action.shortname);
        request.setDescription(action.description);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.external = reply.opt('external', null);
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("MANAGE", result, request);
    }

    
    onUpdateLeagueCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitRequested.register(this.onUpdateLeagueCommitRequested.bind(this));
    }


    onUpdateLeagueCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new LeagueUpdateCommit();

        request.setToken(action.external);
        request.setDescription(action.description);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("MANAGE", result, request);
    }
}
import Event from '../../property/Event';

export class IPlayerResult
{
    getDriverNumber() {
        throw new TypeError("Method not implemented");
    }
    getDriverName() {
        throw new TypeError("Method not implemented");
    }
    getDriverCar() {
        throw new TypeError("Method not implemented");
    }
    getBestEt() {
        throw new TypeError("Method not implemented");
    }
    getBestSpeed() {
        throw new TypeError("Method not implemented");
    }
}

export class IClassResults
{
    getClassName() {
        throw new TypeError("Method not implemented");
    }
    getTimestamp() {
        throw new TypeError("Method not implemented");
    }

    getResults() {
        throw new TypeError("Method not implemented");
    }
}

export default class ICurrentResults {
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    Commit() {
        throw new TypeError("Method not implemented");
    }

    hasResults() {
        throw new TypeError("Method not implemented");
    }

    getResults() {
        throw new TypeError("Method not implemented");
    }

    
}
import Event from "../../property/Event";

export default class IAttendanceAccept
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    setPaymentMethod(method)
    {
        throw new TypeError("Method not implemented");
    }

    setPaymentAmount(amount)
    {
        throw new TypeError("Method not implemented");
    }

    setPaymentDate(date)
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }
}
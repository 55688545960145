import IServiceRequest from '../../connect/IServiceRequest'

export default class VehicleApplicationCancel extends IServiceRequest
{
    constructor()
    {
        super('www/vehicle/application/cancel');
    }

    setExternal(token)
    {
        this.appendRequest('token', token);
    }
}
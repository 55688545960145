import React, { Component } from 'react';
import autoBind from 'react-autobind';
import './CommentBox.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import avatar3 from '../../images/avatar3.png';
import LoaderBar from './LoaderBar';

class CommentBox extends Component {
  static defaultProps = {
      topic: null
  }

  constructor(props) {
    super(props)
    autoBind(this);
    this.state = this.processProps(props);
  }

  componentWillReceiveProps(props)
  {
    this.setState(this.processProps(props));
  }

  processProps(props)
  {
    return { topic: props.topic, loading: false };
  }

  renderComments()
  {
      if ( this.state.loading )
      {
          return (
          
            <div className="w3-row w3-blur">
                <div className="w3-col m2 text-center">
                    <img className="w3-circle" src={avatar3} style={{width:'96px', height: '96px'}}/>
                </div>
                <div className="w3-col m10 w3-container">
                    <h4>Nick <span className="w3-opacity w3-medium">2020-01-01, 12:32</span></h4>
                    <p><LoaderBar /></p>
                    <br/>
                </div>
            </div>
          );
      }

      let data = null; // [ { avatar: "/w3images/avatar3.png", nick: "John", timestamp: '2021-02-32, 13:25', comment:"Keep up the GREAT work! I am cheering for you!! Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}];

      if ( null == data || data.length < 1 )
      {
        return (<div className="w3-container w3-gray w3-text-white w3-margin">
        <h4>Brak komentarzy</h4>
    </div>);
      }

      let retval = data.map((x) => {
         return (
            <div className="w3-row">
                <div className="w3-col m2 text-center">
                    <img className="w3-circle" src={x.avatar} style={{width:'96px', height: '96px'}}/>
                </div>
                <div className="w3-col m10 w3-container">
                    <h4>{x.nick} <span className="w3-opacity w3-medium">{x.timestamp}</span></h4>
                    <p>{x.comment}</p>
                    <br/>
                </div>
            </div>)
      });
      return retval;
  }
  

  render() 
  {
    return (
        <div className="w3-container">
            <div className="w3-container">
                <h5 className="w3-left">Komentarze</h5>
                <h5 className="w3-right">
                    <FontAwesomeIcon icon={faPlusCircle} />
                </h5>
            </div>
            {this.renderComments()}

        </div>);
  }
}

export default CommentBox;
import Event from "../../property/Event";

export default class ICreateMessage
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    getMessage()
    {
        throw new TypeError("Method not implemented");
    }

    setMessage(value)
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }
}
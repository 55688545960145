import Session from '../Session'

import IServiceResult from '../connect/IServiceResult';

import Engine, {Error} from '../Engine';
import sha256 from 'js-sha256';

import Log from '../logger/Log';
import SessionRefreshCommit from '../api/session/SessionRefreshCommit';
import SessionPersonalizeCommit from '../api/session/SessionPersonalizeCommit';
import SessionLoginCommit from '../api/session/SessionLoginCommit';
import SessionActivateCommit from '../api/session/SessionActivateCommit';
import SessionRecoverCommit from '../api/session/SessionRecoverCommit';
import SessionChangePasswordCommit from '../api/session/SessionChangePasswordCommit';


export default class SessionMonitor
{
    constructor(/*Session*/ session)
    {
        this.mSession = session;

        session.onSessionLoginCreated.register(this.onSessionLoginCreated.bind(this));
        session.onSessionPersonalizeCreated.register(this.onSessionPersonalizeCreated.bind(this));
        session.onSessionRecoverCreated.register(this.onSessionRecoverCreated.bind(this));
        session.onSessionActivateCreated.register(this.onSessionActivateCreated.bind(this));
        session.onSessionRefreshCreated.register(this.onSessionRefreshCreated.bind(this));
        session.onSessionChangePasswordCreated.register(this.onSessionChangePasswordCreated.bind(this));
    }

    onSessionLoginCreated(/*Object*/ sender, /*{ProjectCreate}*/ action)
    {
        action.OnCommitRequested.register(this.onSessionLoginCommitRequested.bind(this));
    }

    onSessionLoginCommitRequested(/*SessionPersonalize*/ sender, /*Void*/ params)
    {
        var /*ProjectCreate*/ action = sender;
        var request = new SessionLoginCommit();
        request.setEmail(action.email);
        request.setType(action.type);

        var hash = sha256.create();
        hash.update(action.security);
        hash.update('eat-shit');
        var pass = hash.hex();
        request.setSecurity(pass);

        var monitor = this;

        var result = new IServiceResult(
            function(/*Object*/ reply)
            {
                action.OnCommitFinished.callback(sender, null);
            }, 
            function(/*Object*/ error)
            {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("SESSION", result, request);
    }

    
    onSessionPersonalizeCreated(/*Object*/ sender, /*{ProjectCreate}*/ action)
    {
        action.OnCommitRequested.register(this.onSessionPersonalizeCommitRequested.bind(this));
    }

    onSessionPersonalizeCommitRequested(/*SessionPersonalize*/ sender, /*Void*/ params)
    {
        var /*ProjectCreate*/ action = sender;
        var request = new SessionPersonalizeCommit();
        request.setName(action.name);
        request.setNick(action.nick);
        request.setEmail(action.email);

        var hash = sha256.create();
        hash.update(action.security);
        hash.update('eat-shit');
        var pass = hash.hex();
        request.setSecurity(pass);

        var monitor = this;

        var result = new IServiceResult(
            function(/*Object*/ reply)
            {
                action.OnCommitFinished.callback(sender, null);
            }, 
            function(/*Object*/ error)
            {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("SESSION", result, request);
    }

    onSessionRefreshCreated(/*Object*/ sender, /*{SessionRefresh}*/ action)
    {
        action.OnCommitRequested.register(this.onSessionRefreshCommitRequested.bind(this));
    }

    onSessionRefreshCommitRequested(/*SessionRefresh*/ sender, /*Void*/ params)
    {
        var /*ProjectCreate*/ action = sender;
        var request = new SessionRefreshCommit();

        var monitor = this;

        var result = new IServiceResult(
            function(/*Object*/ reply)
            {
                action.OnCommitFinished.callback(sender, null);
            }, 
            function(/*Object*/ error)
            {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("SESSION", result, request);
    }    

    
    onSessionActivateCreated(/*Object*/ sender, /*{ProjectCreate}*/ action)
    {
        action.OnCommitRequested.register(this.onSessionActivateCommitRequested.bind(this));
    }

    onSessionActivateCommitRequested(/*SessionPersonalize*/ sender, /*Void*/ params)
    {
        var /*ProjectCreate*/ action = sender;
        var request = new SessionActivateCommit();
        request.setUid(action.uid);
        request.setToken(action.token);

        var monitor = this;

        var result = new IServiceResult(
            function(/*Object*/ reply)
            {
                action.OnCommitFinished.callback(sender, null);
            }, 
            function(/*Object*/ error)
            {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("SESSION", result, request);
    }

    
    onSessionRecoverCreated(/*Object*/ sender, /*{ProjectCreate}*/ action)
    {
        action.OnCommitRequested.register(this.onSessionRecoverCommitRequested.bind(this));
    }

    onSessionRecoverCommitRequested(/*SessionPersonalize*/ sender, /*Void*/ params)
    {
        var /*ProjectCreate*/ action = sender;
        var request = new SessionRecoverCommit();
        request.setEmail(action.email);

        var hash = sha256.create();
        hash.update(action.security);
        hash.update('eat-shit');
        var pass = hash.hex();
        request.setSecurity(pass);

        var monitor = this;

        var result = new IServiceResult(
            function(/*Object*/ reply)
            {
                action.OnCommitFinished.callback(sender, null);
            }, 
            function(/*Object*/ error)
            {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("SESSION", result, request);
    }


    onSessionChangePasswordCreated(/*Object*/ sender, /*{ProjectCreate}*/ action)
    {
        action.OnCommitRequested.register(this.onSessionChangePasswordCommitRequested.bind(this));
    }

    onSessionChangePasswordCommitRequested(/*SessionPersonalize*/ sender, /*Void*/ params)
    {
        var /*ProjectCreate*/ action = sender;
        var request = new SessionChangePasswordCommit();
        {
            var hash = sha256.create();
            hash.update(action.old_password);
            hash.update('eat-shit');
            var pass = hash.hex();
            request.setOldPassword(pass);
        }
        {
            var hash = sha256.create();
            hash.update(action.new_password);
            hash.update('eat-shit');
            var pass = hash.hex();
            request.setNewPassword(pass);
        }
        var monitor = this;

        var result = new IServiceResult(
            function(/*Object*/ reply)
            {
                action.OnCommitFinished.callback(sender, null);
            }, 
            function(/*Object*/ error)
            {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("SESSION", result, request);
    }
}
import Event from '../../property/Event';

export default class IVehicleImageLivecam
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    setData(data)
    {
        throw new TypeError("Method not implemented");
    }

    getToken()
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }

    getPictureLivecam()
    {
        throw new TypeError("Method not implemented");
    }
}
import IServiceRequest from "../../connect/IServiceRequest";

export default class ChatAdd extends IServiceRequest
{
    constructor()
    {
        super('www/chat/add');
    }
    
    setChat(value)
    {
        this.appendRequest("chat", value);
    }

    setMessage(value)
    {
        this.appendRequest("message", value);
    }
}

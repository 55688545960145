import IServiceRequest from "../../../connect/IServiceRequest";

export default class ClassCreateCommit extends IServiceRequest
{
    constructor()
    {
        super('www/class/add');
    }
    setLeague(value)
    {
        this.appendRequest("league", value);
    }

    setShortname(value)
    {
        this.appendRequest("shortname", value);
    }

    setDescription(value)
    {
        this.appendRequest("description", value);
    }    

    setPriority(value)
    {
        this.appendRequest("priority", value);
    }    

    setPlayerStart(value)
    {
        this.appendRequest("player_start", value);
    }   
    
    setPlayerMask(value)
    {
        this.appendRequest("player_mask", value);
    } 
}

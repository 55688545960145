import IAttendanceList from "../../../sdk/data/attendance/IAttendanceList";

export class AttendanceList extends IAttendanceList
{
    constructor()
    {
        super();
        this.token = null; 
        this.registered = null;
        this.data = null;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getRegistered()
    {
        return this.registered;
    }

    Download()
    {
        this.OnDownloadRequested.callback(this, null);
    }

    getData()
    {
        return this.data;
    }
}
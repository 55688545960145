import Event from '../../property/Event';

export default class ILatestResult
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();
  
    Commit()
    {
        throw new TypeError("Method not implemented");
    }
    hasResults()
    {
        throw new TypeError("Method not implemented");
    }
    getLeft()
    {
        throw new TypeError("Method not implemented");
    }
    getRight()
    {
        throw new TypeError("Method not implemented");
    }
}
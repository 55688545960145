import Event from '../property/Event';

export default class IFilter
{
    getExternal()
    {
        throw new TypeError("Method not implemented");
    }
    getShortname()
    {
        throw new TypeError("Method not implemented");
    }
    getUrl()
    {
        throw new TypeError("Method not implemented");
    }
    getIsGlobal()
    {
        throw new TypeError("Method not implemented");
    }
}
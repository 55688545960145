import IAttendanceReject from "../../../sdk/data/attendance/IAttendanceReject";

export class AttendanceReject extends IAttendanceReject
{
    constructor()
    {
        super();
        this.token = null; 
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }
}
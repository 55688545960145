import ITimeslip from "../../sdk/data/ITimeslip";

export class Timeslip extends ITimeslip
{
    constructor(token)
    {
        super();
        this.token = token;

        this.league_name = null;
        this.event_name = null;
        
        this.player_number = null;
        this.player_name = null;
        this.player_team = null;
        this.player_car = null;
        this.player_class = null;
        
        this.reaction_time = null;

        this.time_60ft = null;
        this.time_660ft = null;
        this.time_1320ft = null;

        this.speed_660ft = null;
        this.speed_1320ft = null;

        this.timestamp_race = null;

        this.picture_driver = null;
        this.picture_car = null;

        this.is_invalid = null;
        this.is_public = null;
    }

    getToken()
    {
        return this.token;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    
    getLeagueName()
    {
        return this.league_name;
    }

    getEventName()
    {
        return this.event_name;
    }

    getPlayerName()
    {
        return this.player_name;
    }

    getPlayerNumber()
    {
        return this.player_number;
    }

    getPlayerTeam()
    {
        return this.player_team;
    }
    
    getPlayerClass()
    {
        return this.player_class;
    }

    getPlayerCar()
    {
        return this.player_car;
    }

    getReactionTime()
    {
        return this.reaction_time;
    }

    getTime60ft()
    {
        return this.time_60ft;
    }
    
    getTime660ft()
    {
        return this.time_660ft;
    }
    
    getTime1320ft()
    {
        return this.time_1320ft;
    }

    getSpeed660ft()
    {
        return this.speed_660ft;
    }

    getSpeed1320ft()
    {
        return this.speed_1320ft;
    }

    getTimestampRace()
    {
        return this.timestamp_race;
    }  
    
    getPictureDriver()
    {
        return this.picture_driver;
    }

    getPictureCar()
    {
        return this.picture_car;
    }

    getIsInvalid()
    {
        return this.is_invalid;
    }   

    getIsPublic()
    {
        return this.is_public;
    }  

}
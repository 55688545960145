export default class HttpConnection
{
    constructor(/*String*/ name)
    {
        this.mName = name;
        this.mCounter = 0;
    }

    InitializeHost(/*String*/ protocol, /*String*/ host, /*String*/ path)
	{
		this.mProtocol = protocol;
		this.mHost = host;
		this.mPath = path;
    }
   
}


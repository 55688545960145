import IServiceRequest from "../../connect/IServiceRequest";

export default class AttendanceDownloadCommit extends IServiceRequest
{
    constructor()
    {
        super('www/attendance/download');
    }
    
    setToken(value)
    {
        this.appendRequest("token", value);
    }
}

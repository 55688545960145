import Event from "../../property/Event";

export default class IAttendanceEmail {
  OnTestRequested = new Event();
  OnTestFinished = new Event();
  OnTestError = new Event();

  OnCommitRequested = new Event();
  OnCommitFinished = new Event();
  OnCommitError = new Event();

  setTokens(tokens) {
    throw new TypeError("Method not implemented");
  }

  setEmailTitle(title) {
    throw new TypeError("Method not implemented");
  }
  setEmailTemplate(template) {
    throw new TypeError("Method not implemented");
  }

  setEmailReplyTo(email) {
    throw new TypeError("Method not implemented");
  }

  Test() {
    throw new TypeError("Method not implemented");
  }

  Commit() {
    throw new TypeError("Method not implemented");
  }
}

import Event from '../property/Event';

export default class IRaceResults
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    setEventId(id)
    {
        throw new TypeError("Method not implemented");
    }
    setPlayerId(id)
    {
        throw new TypeError("Method not implemented");
    }
    setClassId(id)
    {
        throw new TypeError("Method not implemented");
    }    
    setAllowedPlayers(players)
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }

    getResults()
    {
        throw new TypeError("Method not implemented");
    }
    getTimestamp()
    {
        throw new TypeError("Method not implemented");
    }
    getInterval()
    {
        throw new TypeError("Method not implemented");
    }
    isFinished()
    {
        throw new TypeError("Method not implemented");
    }
}
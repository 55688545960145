import ICreateClass from "../../../../sdk/data/manage/class/ICreateClass";

export class CreateClass extends ICreateClass
{
    constructor()
    {
        super();
        this.league = null;
        
        this.shortname = null;
        this.description = null;

        this.priority = null;
        this.player_start = null;
        this.player_mask = null;

        this.external = null;
    }

    setShortname(value)
    {
        this.shortname = value;
    }
    getShortname()
    {
        return this.shortname;
    }

    setDescription(value)
    {
        this.description = value;
    }

    getDescription()
    {
        return this.description;
    }

    getPriority()
    {
        return this.priority;
    }
    setPriority(value)
    {
        this.priority = value;
    }

    getPlayerStart()
    {
        return this.player_start;
    }
    setPlayerStart(value)
    {
        this.player_start = value;
    }
    getPlayerMask()
    {
        return this.player_mask;
    }
    setPlayerMask(value)
    {
        this.player_mask = value;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getExternal()
    {
        return this.external;
    }
}
import IAttendingUser from "../../../sdk/data/attendance/IAttendingUser";

export class AttendingUser extends IAttendingUser
{
    constructor()
    {
        super();
        this.external = null;
        this.name = null;
        this.description = null;
        this.assigned_number = null;
        this.event = null;
        this.email = null;     
        this.crew_count = null;
        this.status = null;   
        this.accepted_by = null;
        this.timestamp_creation = null;
        this.payment_type = null;
        this.timestamp_acceptance = null;
    }

    getExternal()
    {
        return this.external;
    }

    getName()
    {
        return this.name;
    }

    getDescription()
    {
        return this.description;
    }

    getAssignedNumber()
    {
        return this.assigned_number;
    }

    getEvent()
    {
        return this.event;
    }

    getEmail()
    {
        return this.email;
    }

    getCrewCount()
    {
        return this.crew_count;
    }

    getStatus()
    {
        return this.status;
    }

    getAcceptedBy()
    {
        return this.accepted_by;
    }

    getTimestampCreation()
    {
        return this.timestamp_creation;
    }

    getPaymentType()
    {
        return this.payment_type;
    }

    getTimestampAcceptance()
    {
        return this.timestamp_acceptance;
    }
}
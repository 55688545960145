import IServiceRequest from '../../connect/IServiceRequest'

export default class SessionActivateCommit extends IServiceRequest
{
    constructor()
    {
        super('www/session/activate');
    }

    setUid(value)
    {
        this.appendRequest('uid', value);
    }

    setToken(value)
    {
        this.appendRequest('token', value);
    }
}
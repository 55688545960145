import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import './PlayersScreen.css'
import { DrawBottomRight } from '../LayoutHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch, faFlagCheckered, faCog, faCar, faSmile } from '@fortawesome/free-solid-svg-icons';
import AttendanceWidget from '../../components/widgets/AttendanceWidget';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SosIcon from '@mui/icons-material/Sos';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';


import Box from '@mui/material/Box';
import HelpIcon from '../../components/common/HelpIcon';


export const PlayersScreen = ({session}) =>  {
    const urlParams = useParams();
    const navigate = useNavigate();
    
    const [ leagueId, setLeagueId ] = useState(undefined);
    const [ leagues, setLeagues ] = useState(null);

    const [ league, setLeague ] = useState(null);
    const [ classes, setClasses ] = useState(null);
    const [ players, setPlayers ] = useState(null);

    const [isAsyncOperation, setAsyncOperation] = useState(0);
    const [canEditPlayers, setCanEditPlayers] = useState(false);

    useEffect(() => {
        setLeagueId(urlParams.leagueId);
    }, [urlParams])


    useEffect( () => { 
        const leagueChangedCallback = (sender, leagues) => {
            setLeagues(leagues) ;
        }
        session.onLeaguesChanged.register(leagueChangedCallback);

        setLeagues(session.getLeagues()) ;
        return () => {
            session.onPersonalizeChanged.unregister(leagueChangedCallback);
        }
    }, [])

    
    useEffect( () => {
        if ( null == leagueId )
        {
            return;
        }
        if ( null == leagues )
        {
            return;
        }
        for ( const l of leagues )
        {
            if ( l.getExternal() != leagueId )
            {
                continue;
            }
            {
                let league = {};
                league.external = l.getExternal();
                league.shortname = l.getShortname();
                league.description = l.getDescription();
                league.thumbnail = l.getThumbnail();
                league.is_finished = l.getIsFinished();

                setLeague(league)
            }
            {
                let classes = [];
                for ( const cls of l.getClasses() )
                {
                    let data = {}
                    data.external = cls.getExternal();
                    data.shortname = cls.getShortname();
                    data.description = cls.getDescription();
                    data.priority = cls.getPriority();
        
                    classes[data.external] = data;
                }

                setClasses(classes);
            }
            {
                let players = [];
                for ( const cls of l.getPlayers() )
                {
                    let item = {}
                    item.external = cls.getExternal();
                    item.player = cls.getPlayer();
                    item.class = cls.getClass();
                    item.vehicle = cls.getVehicle();
                    item.player_name = cls.getPlayerName();
                    item.player_car = cls.getPlayerCar();
                    item.car_power = cls.getCarPower();
                    item.car_mass = cls.getCarMass();
                    item.picture_car = cls.getPictureCar();
                    item.picture_driver = cls.getPictureDriver();

                    players.push(item);
                }
                setPlayers(players);
            }
            break;
        }

    }, [leagueId, leagues]);

    useEffect(() => {
        if ( null == leagues )
        {
            setCanEditPlayers(false);
            return;
        }

        let perm = session.getPermissions().hasPermissionContext('can_edit_players', leagueId) || session.getPermissions().hasPermissionGlobal('can_edit_players');
        setCanEditPlayers(perm);
    }, [leagueId, leagues])
   

  const createPicture = (url) => {
  
      return <img src={url} width={400} maxHeight={400} style={{objectFit: "contain"}} />
  }
  
  const renderSpeedMenu = () => {
        if ( !canEditPlayers )
        {
            return null;
        }
        let actions = [];
        if ( canEditPlayers )
        {
            actions.push(<SpeedDialAction
                key='create'
                icon={<AppRegistrationIcon />}
                tooltipTitle={'Edytuj zawodników'}
                tooltipOpen
                onClick={() => navigate('/manage/players/' + leagueId)}
                /> );
        }

        return (
            <DrawBottomRight>
                <SpeedDial
                    ariaLabel="SpeedDial playground example"
                    hidden={false}
                    icon={<SpeedDialIcon />}
                    direction={'up'}
                    sx={{position: 'absolute', bottom: 0, right: 0}}
                >
                {actions}
                </SpeedDial>
            </DrawBottomRight>    
        )
    }

  return (
    <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
        {null == league && 
            <Box>Trwa wczytywanie danych</Box>
        }
        {null != league &&
            <>
                {renderSpeedMenu()}
                <h5>Zawodnicy ligi <Link to={'/league/' + leagueId}>{league.shortname}</Link></h5>

                <TableContainer >
            <Table stickyHeader aria-label="sticky table" className="w3-table w3-striped w3-white w3-bordered">
                <TableHead>
                    <TableRow>
                        <TableCell key={'lp'} align={'left'} style={{ minWidth: '30px' }} >LP</TableCell>
                        <TableCell key={'player'} align={'left'} style={{ minWidth: '30px' }} >Nr</TableCell>
                        <TableCell key={'shortname'} align={'left'} style={{ minWidth: '30px' }} >Zawodnik</TableCell>
                        <TableCell key={'classname'} align={'left'} style={{ minWidth: '30px' }} >Klasa</TableCell>
                        <TableCell key={'car'} align={'left'} style={{ minWidth: '30px' }} >Auto</TableCell>
                        <TableCell key={'power'} align={'left'} style={{ minWidth: '30px' }} >Moc</TableCell>
                        <TableCell key={'mass'} align={'left'} style={{ minWidth: '30px' }} >Masa</TableCell>
                        <TableCell key={'photos'} align={'left'} style={{ minWidth: '30px' }} >Fotki</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {players.map((x, index) => {
                        let className = "";
                        let classId = null;
                        if ( x.class )
                        {
                            if ( classes && classes[x.class] )
                            {
                                className = classes[x.class].shortname;
                                classId = classes[x.class].external;
                            }
                        }
                        
                        return ( <TableRow key={x.external}>
                        <TableCell key={x.external + "-index"} sx={{padding: '2px'}}>{index++}</TableCell>
                        <TableCell sx={{padding: '2px'}}>{x.player}</TableCell>
                        <TableCell sx={{padding: '2px'}}><Link to={'/results/' + leagueId + '/player/' + x.player}>{x.player_name}</Link></TableCell>
                        <TableCell sx={{padding: '2px'}}><Link to={'/results/' + leagueId + '/class/' + classId + '/best'}>{className}</Link>{}</TableCell>
                        <TableCell sx={{padding: '2px'}}>{x.player_car}</TableCell>
                        <TableCell sx={{padding: '2px'}}>{x.car_power}</TableCell>
                        <TableCell sx={{padding: '2px'}}>{x.car_mass}</TableCell>
                        <TableCell sx={{padding: '2px'}}>
                            <div className="w3-row-padding">
                            {x.picture_car && 
                                <div className="w3-half">
                                    <HelpIcon  component={createPicture(x.picture_car)} left={true} hover={true} above={false}>
                                        <FontAwesomeIcon icon={faCar}/>
                                    </HelpIcon>
                                </div>
                            } 
                            {x.picture_driver &&
                                <div className="w3-half">
                                    <HelpIcon className="w3-half" component={createPicture(x.picture_driver)} left={true} hover={true} above={false}>
                                        <FontAwesomeIcon icon={faSmile} />
                                    </HelpIcon>
                                </div>
                            }
                            </div>
                        </TableCell>
                        </TableRow>);
                    })}
                </TableBody>
            </Table>
            </TableContainer>
            </>
        }
    </Box>
  )
}
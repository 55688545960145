import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import InputField from '../../components/common/InputField';
import Button from '../../components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faCarSide, faPlusCircle, faMinusCircle, faSync } from '@fortawesome/free-solid-svg-icons';
import Combobox from '../../components/common/Combobox';
import { SimplePopup } from '../../components/common/SimplePopup';

import './VehicleRegisterScreen.css'

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { FormControl, FormHelperText, Input, InputAdornment } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export const VehicleRegisterScreen = ({session}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [isAsyncOperation, setAsyncOperation] = useState(false);
    const [commitErrors, setCommitErrors] = useState(null);
    const [successDialog, setSuccessDialog] = useState(false);

    const [ leagues, setLeagues ] = useState(null);
    const [ leaguesData, setLeaguesData ] = useState(null);

    const [ selectedLeague, setSelectedLeague ] = useState(null);
    const [ selectedEvent, setSelectedEvent ] = useState(null);
    const [ selectedClass, setSelectedClass ] = useState(null);
    const [ assignedNumber, setAssignedNumber ] = useState(null);
    const [ selectedNumber, setSelectedNumber ] = useState(null);
    const [ crewCount, setCrewCount ] = useState(0);

    const [ personalCarShortname, setPersonalCarShortname ] = useState(null);
    const [ personalCarPower, setPersonalCarPower ] = useState(null);
    const [ personalDriverName, setPersonalDriverName ] = useState(null);
    const [ personalComment, setPersonalComment ] = useState(null);
    

    useEffect( () => { 
        const leagueChangedCallback = (sender, leagues) => {
            setLeagues(leagues) ;
        }
        session.onLeaguesChanged.register(leagueChangedCallback);

        setLeagues(session.getLeagues()) ;
        return () => {
            session.onPersonalizeChanged.unregister(leagueChangedCallback);
        }
    }, [])

    useEffect( () => {
        if ( searchParams.get('league') )
        {
            setSelectedLeague(searchParams.get('league'))
        }
        if ( searchParams.get('event') )
        {
            setSelectedEvent(searchParams.get('event'))
        }
    }, [searchParams])


    useEffect( () => {
        if (null == leagues) {
            return;
        }
        let _leagues = [];
        for (const l of leagues) {
            let league = {};
            league.external = l.getExternal();
            league.shortname = l.getShortname();
            league.is_finished = l.getIsFinished();

            let classes = l.getClasses().map((x) => {
                let cls = {};
                cls.external = x.getExternal();
                cls.shortname = x.getShortname();
                cls.description = x.getDescription();
                return cls;
            });

            let players = l.getPlayers().map((x) => {
                let plr = {};
                plr.class = x.getClass();
                plr.external = x.getExternal();
                plr.player = x.getPlayer();
                plr.shortname = x.getPlayerCar();
                plr.driver = x.getPlayerName();
                return plr;
            })

            let events = l.getEvents().map((x) => {
                let evt = {};

                evt.external = x.getExternal();
                evt.shortname = x.getShortname();

                evt.is_registration_open = x.getIsRegistrationOpen();
                evt.registration_fee_onsite = x.getRegistrationFeeOnsite();
                evt.registration_fee_transfer = x.getRegistrationFeeTransfer();
                evt.registration_fee_online = x.getRegistrationFeeOnline();
                evt.registration_fee_crew = x.getRegistrationFeeCrew();

                return evt;
            });

            league.classes = classes;
            league.players = players;
            league.events = events;
            _leagues.push(league);
        }
        setLeaguesData(_leagues);
    }, [leagues]);

    const selectLeague = (value) => {
        setSelectedLeague(value);
        setSelectedClass(null);
        setSelectedEvent(null);
        setAssignedNumber(null);
        setSelectedNumber(null);
    }

    const selectLeagueClass = (value) => {
        setSelectedClass(value);
        setAssignedNumber(null);
        setSelectedNumber(null);
    }

    const selectLeagueEvent = (value) => {
        setSelectedEvent(value);
    }

    const selectAssignedNumber = (value) =>  {
        setAssignedNumber(value);
        setSelectedNumber(null);
    }

    const selectLeagueNumber = (value) => {
        setSelectedNumber(value);
    }

    const selectPersonalCarShortname = (value) => {
        setPersonalCarShortname(value);
    }

    const selectPersonalCarPower = (value) => {
        setPersonalCarPower(value);
    }
    
    const selectPersonalDriverName = (value) => {
        setPersonalDriverName(value);
    }

    const validatePersonalDriverName = (value) => {
        let errorText = null;
        let cls = leaguesData.find((el) => { return el.external == selectedLeague } );
        if ( cls )
        {
            cls.players.filter((x) => { return x.class == selectedClass }).map( (x) => 
                { 
                    if ( x.driver === value )
                    {
                        errorText = "Kierowca ma juz przypisany numer " + x.player;
                    }
                } );
        }
        return errorText;
    }

    const selectComment = (value) => {
        setPersonalComment(value);
    }

    const commitVehicle = () =>
    {
        const commitRequested = (sender, params) => {
            setAsyncOperation(true);
        }
        const commitFinished = (sender, params) => {
            setAsyncOperation(false);
            setSuccessDialog(true);
        }
        const commitError = (sender, error) => {
            setAsyncOperation(false);
            setCommitErrors(error.message);
        }
        let action = session.createVehicleApplication();

        action.setLeague(selectedLeague);
        action.setLeagueClass(selectedClass);
        action.setLeagueEvent(selectedEvent);
        action.setCrewCount(crewCount);
        action.setAssignedNumber(selectedNumber);
        action.setCarShortname(personalCarShortname);
        action.setCarPower(personalCarPower);
        action.setDriverName(personalDriverName);
        action.setComment(personalComment);

        action.OnCommitRequested.register(commitRequested);
        action.OnCommitFinished.register(commitFinished);
        action.OnCommitError.register(commitError);

        action.Commit();
    }

    const RenderAttendance = useCallback(() => {
        let league_events = null;
        let renderCrewData = null;

        if ( null != selectedLeague )
        {
            let cls = leaguesData.find((el) => { return el.external == selectedLeague} );
            if ( cls )
            {
                league_events = cls.events.filter((x) => { return x.is_registration_open } ).map( (x) => { return { label: x.shortname, value: x.external, key: x.external} } );
                league_events.unshift( { label: 'Nie chce sie narazie zapisać', value: null, key: null} );
                if ( null != selectedEvent )
                {
                    let data = cls.events.find((x) => { return x.external == selectedEvent });
                    if ( data )
                    {
                        renderCrewData = (
                            <>
                                <h3>Opłaty dla <b>{data.shortname}</b>:</h3>
                                <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
                                    <tbody>
                                        { data.registration_fee_transfer && 
                                        <tr className="w3-padding-16 w3-hoverable w3-tr-with-link">
                                            <td><span className="w3-small">Płatność przelewem</span></td>
                                            <td><span className="w3-small">{data.registration_fee_transfer} zł</span></td>
                                        </tr>
                                        }
                                        { data.registration_fee_onsite && 
                                        <tr className="w3-padding-16 w3-hoverable w3-tr-with-link">
                                            <td><span className="w3-small">Płatność na bramie</span></td>
                                            <td><span className="w3-small">{data.registration_fee_onsite} zł</span></td>
                                        </tr>
                                        }
                                        { data.registration_fee_online && 
                                        <tr className="w3-padding-16 w3-hoverable w3-tr-with-link">
                                            <td><span className="w3-small">Płatność online</span></td>
                                            <td><span className="w3-small">{data.registration_fee_online} zł</span></td>
                                        </tr>
                                        }
                                        { data.registration_fee_crew && 
                                        <tr className="w3-padding-16 w3-hoverable w3-tr-with-link">
                                            <td><span className="w3-small">Płatność za osobe towarzyszącą</span></td>
                                            <td><span className="w3-small">{data.registration_fee_crew} zł</span></td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                                <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '100px' }}>
                                    <Input
                                    id="standard-adornment-crew"
                                    endAdornment={<InputAdornment position="end">os</InputAdornment>}
                                    aria-describedby="standard-crew-helper-text"
                                    inputProps={{
                                        'aria-label': 'osób',
                                    }}
                                    value={crewCount}
                                    onChange={(ev) => {
                                        if ( !ev.target.value )
                                        {
                                            setCrewCount(null);
                                            return;
                                        }
                                        let value = Number(ev.target.value);
                                        if ( !isNaN(value ))
                                        {
                                            setCrewCount(value);
                                        }
                                    }}
                                    />
                                    <FormHelperText id="standard-crew-helper-text">Ilość dodatkowych osób</FormHelperText>
                                </FormControl>
                            </>);
                    }
                }
            }
        }

        


        return (
            <>
                {league_events &&
                    <div>
                        <h6>Wybierz runde na która chcesz sie zapisać</h6>
                        <Combobox multiple={false} children={league_events} value={selectedEvent} onChange={(value) => selectLeagueEvent(value)} />
                        {renderCrewData}
                    </div>
                }
            </>
        )
    }, [leaguesData, selectedLeague, selectedEvent, crewCount]);

    const renderScreen = () => {

        let leagues = leaguesData.map((x) => {
            return { label: x.shortname, value: x.external, key: x.external }
        });

        let league_classes = null;

        if ( null != selectedLeague )
        {
            let cls = leaguesData.find((el) => { return el.external == selectedLeague} );
            if ( cls )
            {
                league_classes = cls.classes.map( (x) => { return { label: x.shortname, value: x.external, key: x.external } } );
            }
        }

        let available_numbers = null;
        if ( "YES" == assignedNumber )
        {
            let cls = leaguesData.find((el) => { return el.external == selectedLeague} );
            if ( cls )
            {
                available_numbers = cls.players.filter((x) => { return x.class == selectedClass }).map( (x) => 
                    { 
                        let descr = x.player;
                        if ( x.shortname )
                        {
                            descr = descr + " - " + x.shortname;
                        }
                        if ( x.driver )
                        {
                            descr = descr + " - " + x.driver;
                        }
                        return { label: descr, value: x.external, key: x.external } 
                    } );
            }
        }

        return (
            <header className="w3-container">
                <h5>Zgłoś swoje auto do cyklu</h5>
                <div className="w3-container">
                    <h6>Wybierz cykl</h6>
                    <Combobox multiple={false} children={leagues} value={selectedLeague} onChange={(value) => selectLeague(value)} />
                    {selectedLeague &&
                        <div>
                            <h6>Wybierz klase</h6>
                            <Combobox multiple={false} children={league_classes} value={selectedClass} onChange={(value) => selectLeagueClass(value)} />
                        </div>
                    }
                    {selectedClass &&
                        <div>
                            <h6>Czy masz juz przypisany numer startowy?</h6>
                            <div>
                                <input className="w3-radio" type="radio" id="assigned_no" name="assignednumber" checked={"NO" == assignedNumber} value={"NO"} onChange={(x) => selectAssignedNumber("NO")} />
                                <label className="w3-large" htmlFor="assigned_no">NIE</label>
                            </div>
                            <div>
                                <input className="w3-radio" type="radio" id="assigned_yes" name="assignednumber" checked={"YES" == assignedNumber}  value={"YES"} onChange={(x) => selectAssignedNumber("YES")} />
                                <label className="w3-large" htmlFor="assigned_yes">TAK</label>
                            </div>
                        </div>
                    }
                    {"YES" == assignedNumber && 
                        <div>
                            <RenderAttendance />
                            <h6>Wybierz swój numer</h6>
                            <Combobox multiple={false} children={available_numbers} value={selectedNumber} onChange={(value) => selectLeagueNumber(value)} />
                            <h6>Komentarz dla organizatora</h6>
                            <InputField onCommit={(value) => selectComment(value)}/>
                            <div className="w3-quarter w3-right w3-margin">
                                <Button onClicked={() => { commitVehicle()}} disabled={isAsyncOperation || null == selectedNumber} text="Wyślij zgłoszenie" iconLeft={isAsyncOperation ? faSync : null} />
                            </div>
                        </div>
                    }
                    {"NO" == assignedNumber &&
                        <div>
                            <RenderAttendance />
                            <h6>Model twojego samochodu</h6>
                            <InputField onCommit={(value) => selectPersonalCarShortname(value)} />
                            <h6>Moc auta</h6>
                            <InputField onCommit={(value) => selectPersonalCarPower(value)} characters="1234567890" />
                            <h6>Imie i nazwisko kierowcy</h6>
                            <InputField onCommit={(value) => selectPersonalDriverName(value)} validate={(value) => validatePersonalDriverName(value)} />
                            <h6>Komentarz dla organizatora</h6>
                            <InputField onCommit={(value) => selectComment(value)}/>
                            <div className="w3-quarter w3-right w3-margin">
                                <Button onClicked={() => { commitVehicle()}} disabled={isAsyncOperation || null == personalCarShortname || null == personalCarPower || null == personalDriverName} text="Wyślij zgłoszenie" iconLeft={isAsyncOperation ? faSync : null} />
                            </div>
                        </div>                    
                    }
                </div>
            </header>);
    }

    return (
        <Box>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={commitErrors != null} autoHideDuration={6000} onClose={() => {  setCommitErrors(null); }} sx={{minWidth: '50%'}} >
                <Alert severity="error" sx={{ width: '100%' }}>{commitErrors}</Alert>
            </Snackbar>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isAsyncOperation} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SimplePopup open={successDialog} fullScreen={false} title='Zgłoszenie zostało zarejestrowane. Zostaniesz powiadomony gdy zostanie przetworzone'  accept='OK' reject={null} onAccepted={() => { navigate('/applications/mine') }}  />
            {null == leaguesData &&
                <Box>Trwa wczytywanie danych</Box>
            }
            {null != leaguesData && 
                <Box>
                    {renderScreen()}
                </Box>
            }
        </Box>
    )
}

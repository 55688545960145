import IVehicleImageProfile from "../../../sdk/data/vehicle/IVehicleImageProfile";

export class VehicleImageProfile extends IVehicleImageProfile
{
    constructor(token)
    {
        super();
        this.token = token;
        this.data = null;

        this.picture_livecam = null;
    }

    setData(data)
    {
        this.data = data;
    }
    
    getToken()
    {
        return this.token;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getPictureProfile()
    {
        return this.picture_profile;
    }
}
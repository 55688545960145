import IVehicle from "../../../sdk/data/vehicle/IVehicle";
import { VehicleImageLeaderboard } from "./VehicleImageLeaderboard";
import Event from "../../../sdk/property/Event";
import { VehicleImageLivecam } from "./VehicleImageLivecam";
import { VehicleImageProfile } from "./VehicleImageProfile";
import { VehicleImageTimeslip } from "./VehicleImageTimeslip";
import { VehicleUpdate } from "./VehicleUpdate";

export class Vehicle extends IVehicle
{
    onVehicleImageLeaderboardCreated = new Event();
    onVehicleImageLivecamCreated = new Event();
    onVehicleImageProfileCreated = new Event();
    onVehicleImageTimeslipCreated = new Event();

    onVehicleUpdateCreated = new Event();

    constructor()
    {
        super();
        this.external = null;

        this.assigned_number = null;

        this.league_id = null;
        this.league_shortname = null;

        this.league_class_id = null;
        this.league_class_shortname = null;
        
        this.car_external = null;
        this.car_shortname = null;
        this.car_description = null;
        this.car_driver = null;
        
        this.car_power = null;
        this.car_mass = null;
        this.car_team = null;

        this.picture_leaderboard = null;
        this.picture_timeslip = null;
        this.picture_livecam = null;
        this.picture_profile = null;
    }

    setExternal(value)
    {
        this.external = value;
    }

    Query()
    {
        this.OnQueryRequested.callback(this, null);
        return true;
    }

    getExternal()
    {
        return this.external;
    }

    getAssignedNumber()
    {
        return this.assigned_number;
    }

    getLeagueId()
    {
        return this.league_id;
    }

    getLeagueShortname()
    {
        return this.league_shortname;
    }

    getLeagueClassId()
    {
        return this.league_class_id;
    }

    getLeagueClassShortname()
    {
        return this.league_class_shortname;
    }

    getCarExternal()
    {
        return this.car_external;
    }

    getCarShortname()
    {
        return this.car_shortname;
    }

    getCarDescription()
    {
        return this.car_description;
    }

    getCarDriver()
    {
        return this.car_driver;
    }

    getCarPower()
    {
        return this.car_power;
    }

    getCarMass()
    {
        return this.car_mass;
    }

    getCarTeam()
    {
        return this.car_team;
    }

    getPictureLeaderboard()
    {
        return this.picture_leaderboard;
    }
    getPictureTimeslip()
    {
        return this.picture_timeslip;
    }
    getPictureLivecam()
    {
        return this.picture_livecam;
    }
    getPictureProfile()
    {
        return this.picture_profile;
    }

    createVehicleImageLeaderboard()
    {
        let retval = new VehicleImageLeaderboard(this.car_external);
        this.onVehicleImageLeaderboardCreated.callback(this, retval);
        return retval;
    }

    createVehicleImageLivecam()
    {
        let retval = new VehicleImageLivecam(this.car_external);
        this.onVehicleImageLivecamCreated.callback(this, retval);
        return retval;
    }

    createVehicleImageProfile()
    {
        let retval = new VehicleImageProfile(this.car_external);
        this.onVehicleImageProfileCreated.callback(this, retval);
        return retval;
    }

    createVehicleImageTimeslip()
    {
        let retval = new VehicleImageTimeslip(this.car_external);
        this.onVehicleImageTimeslipCreated.callback(this, retval);
        return retval;
    }    

    createVehicleUpdate()
    {
        let retval = new VehicleUpdate(this.car_external);

        retval.car_shortname = this.car_shortname;
        retval.car_description = this.car_description;

        retval.car_power = this.car_power;
        retval.car_mass = this.car_mass

        retval.car_driver = this.car_driver;
        retval.car_team = this.car_team;

        this.onVehicleUpdateCreated.callback(this, retval);
        return retval;
    }
}
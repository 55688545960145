import IServiceRequest from "../../connect/IServiceRequest";
import ApiUtils from "../ApiUtils";

export default class AttendanceRejectCommit extends IServiceRequest
{
    constructor()
    {
        super('www/attendance/reject');
    }

    setToken(value)
    {
        this.appendRequest("token", value);
    }
}

export default class RequestData
{
    constructor()
    {
        this.method = null;
        this.data = null;

        this.deviceId = null;
        this.sessionId = null;
    }
}
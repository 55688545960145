import IServiceRequest from '../../connect/IServiceRequest'

export default class SessionLoginCommit extends IServiceRequest
{
    constructor()
    {
        super('www/session/login');
    }

    setEmail(value)
    {
        this.appendRequest('email', value);
    }

    setType(value)
    {
        this.appendRequest('type', value);
    }

    setSecurity(value)
    {
        this.appendRequest('security', value);
    }

}
import IPlayersUpload from '../../sdk/data/IPlayersUpload';

export default class PlayersUpload extends IPlayersUpload
{
    constructor()
    {
        super();
        this.league = null;
        this.data = null;
    }

    setLeague(league)
    {
        this.league = league;
    }

    setData(data)
    {
        this.data = data;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }
}
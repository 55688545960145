import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import autoBind from 'react-autobind';
import update from "react-addons-update";
import './ResultTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnkh, faBook, faPeopleArrows, faPersonBooth, faMale } from '@fortawesome/free-solid-svg-icons';

class ResultTable extends Component {
  static defaultProps = {
    session: null,
    token: null,
    type: null
  }

  constructor(props)
  {
    super(props)
    autoBind(this);
  }

  componentWillReceiveProps(props)
  {
  }

  render() {
    return (
        <div className="w3-overlayed-container">
            <div className="w3-container w3-orange w3-text-white w3-padding-16 w3-anchor">
                
                <div className="w3-right w3-xxxlarge">
                    <FontAwesomeIcon icon={faBook} />
                </div>
                <div className="w3-clear"></div>
            </div>
        </div>
    );
  }
}

export default ResultTable;
import Event from "../../../property/Event";

export default class IExampleEmail
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    getExternal()
    {
        throw new TypeError("Method not implemented");
    }

    getTemplateAttendanceEmail()
    {
        throw new TypeError("Method not implemented");
    }
    setTemplateAttendanceEmail(value)
    {
        throw new TypeError("Method not implemented");
    }

    getCrewCount()
    {
        throw new TypeError("Method not implemented");
    }
    setCrewCount(value)
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }

    getEmail()
    {
        throw new TypeError("Method not implemented");
    }

}
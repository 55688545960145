export default class IServiceRequest
{
    constructor(/*String*/ service)
    {
        this.mService = service;

        this.mRequest = {};
    }

    getService()
    {
        return this.mService;
    }

    getRequest()
    {
        return this.mRequest;
    }

    appendRequest(key, value)
    {
        this.mRequest[key] = value;
    }
}
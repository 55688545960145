import IServiceRequest from '../../connect/IServiceRequest'

export default class VehicleGet extends IServiceRequest
{
    constructor()
    {
        super('www/vehicle/get');
    }

    setExternal(value)
    {
        this.appendRequest('external', value);
    }
}
import IAttendanceEmail from "../../../sdk/data/attendance/IAttendanceEmail";

export class AttendanceEmail extends IAttendanceEmail {
  constructor() {
    super();
    this.tokens = [];
    this.email_template = null;
    this.email_title = null;
    this.email_reply_to = null;
  }

  setTokens(tokens) {
    this.tokens = tokens;
  }

  setEmailTitle(title) {
    this.email_title = title;
  }
  setEmailTemplate(template) {
    this.email_template = template;
  }

  setEmailReplyTo(email) {
    this.email_reply_to = email;
  }

  Test() {
    this.OnTestRequested.callback(this, null);
  }

  Commit() {
    this.OnCommitRequested.callback(this, null);
  }
}

import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import './ApplicationsScreen.css'
import BaseScreenHeader from '../BaseScreenHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCross, faTrash, faCloud, faComment } from '@fortawesome/free-solid-svg-icons';
import Utils from '../../utils/Utils';
import Tooltip from '../../components/common/Tooltip';
import HelpIcon from '../../components/common/HelpIcon';
import PopupAlert from '../../components/common/PopupAlert';
import { SimplePopup } from '../../components/common/SimplePopup';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export const ApplicationsScreen = ({session}) => {
    const navigate = useNavigate();

    const [refreshAttendance, setRefreshAttendance] = useState(0)
    const [isAsyncOperation, setAsyncOperation] = useState(0);
    const [commitErrors, setCommitErrors] = useState(null);

    const [ leagues, setLeagues ] = useState(null);
    const [ leaguesData, setLeaguesData ] = useState(null);
    const [ vehicles, setVehicles ] = useState(null);
    const [ attendances, setAttendances ] = useState(null);

    const [abortApplicationDialog, setAbortApplicationDialog] = useState(null)

    useEffect( () => { 
        const leagueChangedCallback = (sender, leagues) => {
            setLeagues(leagues) ;
        }
        session.onLeaguesChanged.register(leagueChangedCallback);

        setLeagues(session.getLeagues()) ;
        return () => {
            session.onPersonalizeChanged.unregister(leagueChangedCallback);
        }
    }, [])


    useEffect( () => {
        if (null == leagues) {
            return;
        }
        if (null == leagues) {
            return null;
        }
        let _leagues = [];
        for (const l of leagues) {
            let league = {};
            league.external = l.getExternal();
            league.shortname = l.getShortname();
            league.is_finished = l.getIsFinished();

            let classes = l.getClasses().map((x) => {
                let cls = {};
                cls.external = x.getExternal();
                cls.shortname = x.getShortname();
                cls.description = x.getDescription();
                return cls;
            });

            league.classes = classes;
            _leagues.push(league);
        }
        setLeaguesData(_leagues)
    }, [leagues] );

    const onApplicationVehicleCancelRequested = (sender, params) => {
        setAsyncOperation((prev) => prev + 1);
    }

    const onApplicationVehicleCancelFinished = (sender, params) => {
        setVehicles( (prev) => {
            let found = prev.find((x) => x.external == sender.getExternal());
            found.timestamp_review = sender.getTimestampReview()
            found.status = sender.getStatus();
            return prev;
        })
        setAsyncOperation((prev) => prev - 1);
        setRefreshAttendance((prev) => prev + 1);
    }

    const onApplicationVehicleCancelError = (sender, error) => {
        setAsyncOperation((prev) => prev - 1);

        setCommitErrors(error.message);
    }

    useEffect(() => {
        const commitRequested = (sender, params) => {
            setAsyncOperation((prev) => prev + 1);
        }
        const commitFinished = (sender, params) => {
            setAsyncOperation((prev) => prev - 1);

            let _vehicles = [];

            let data = sender.getVehicleApplications();
            if ( data )
            {
                data.map((x) => {
                    let app = {};
                    app.external = x.getExternal();
                    app.league = x.getLeague();
                    app.class = x.getClass();
                    app.status = x.getStatus();
                    app.timestamp_creation = x.getTimestampCreation();
                    app.timestamp_review = x.getTimestampReview();
    
                    app.assigned_number = x.getAssignedNumber();
                    
                    app.car_shortname = x.getCarShortname();
                    app.car_power = x.getCarPower();
                    app.driver_name = x.getDriverName();
    
                    app.comment = x.getComment();
    
                    app.ob = x;
    
                    x.OnCancelRequested.register(onApplicationVehicleCancelRequested);
                    x.OnCancelFinished.register(onApplicationVehicleCancelFinished);
                    x.OnCancelError.register(onApplicationVehicleCancelError);
    
                    _vehicles.push(app);
                });
            }
            setVehicles(_vehicles)
        }
        const commitError = (sender, error) => {
            setAsyncOperation((prev) => prev - 1);
            setCommitErrors(error.message);
        }

        var action = session.createApplicationsMine();
        action.OnCommitRequested.register(commitRequested);
        action.OnCommitFinished.register(commitFinished);
        action.OnCommitError.register(commitError);
        action.Commit();
    }, []);

    useEffect( () => {

        const commitRequested = (sender, params) => {
            setAsyncOperation((prev) => prev + 1);
        }
        const commitFinished = (sender, params) => {
            setAsyncOperation((prev) => prev - 1);
            setAttendances(sender.getRegistered())
        }
        const commitError = (sender, error) => {
            setAsyncOperation((prev) => prev - 1);
            setCommitErrors(error.message);
        }


        let action = session.createAttendanceDetails();
      
        action.OnCommitRequested.register(commitRequested);
        action.OnCommitFinished.register(commitFinished);
        action.OnCommitError.register(commitError);
  
        action.Commit();
    }, [refreshAttendance]);

    const renderVehicleRequest = () => {
        return (
            <div className="w3-container w3-float-topright">
                <h5><Link to='/vehicle/register'><FontAwesomeIcon icon={faPlusCircle} /> Zgłoś pojazd</Link></h5>
            </div>
        )
    }

    const askAbortApplication = (ob) => {
        setAbortApplicationDialog(ob);
        /*
        this.showDialog(() => 
        { 
          return (<PopupAlert text='Czy na pewno chcesz sie anulowac to zgłoszenie?' accept='TAK' reject='NIE' 
                    onRejected={((e) => { this.clearDialog(); })} 
                    onAccepted={(e) => { this.clearDialog(); this.doAbortApplication(ob) }} 
                  />);
        } 
        */
    }

    const doAbortApplication = (ob) => {
        ob.Cancel();
    }

    const startChat = (ob) => {
        let liga = leaguesData.find((l) => l.external === ob.getLeague());
        let klasa = null;
        if ( liga )
        {
            klasa = liga.classes.find((k) => k.external === ob.getClass());
        }

        let action = ob.createCreateChat();

        action.setTopic('Zgłoszenie do ' + liga.shortname + " ( " + klasa.shortname + " )");

        action.OnCommitRequested.register(onCreateChatCommitRequested);
        action.OnCommitFinished.register(onCreateChatCommitFinished);
        action.OnCommitError.register(onCreateChatCommitError);
        action.Commit();
    }

    
    const onCreateChatCommitRequested = (sender, params) => {
        setAsyncOperation((prev) => prev + 1);
    }

    const onCreateChatCommitFinished = (sender, params) => {
        setAsyncOperation((prev) => prev - 1);

        let url = '/chat/' + sender.getExternal();
        navigate(url);
    }

    const onCreateChatCommitError = (sender, error) => {
        setAsyncOperation((prev) => prev - 1);
        setCommitErrors(error.message);
    }

    const renderScreen = () => {
        let user = session.getUser();

        if ( null == leaguesData )
        {
            return (
                <header className="w3-container">
                    <h5>Twoje zgłoszenia <b>{user.getName()}</b></h5>
                    {renderVehicleRequest()}

                    <h5>Trwa wczytywanie..</h5>
                </header>
            );   
        }

        let _vehicles = null;
        if ( null != vehicles )
        {
            _vehicles = vehicles.map((x) => {
                let liga = leaguesData.find((l) => l.external === x.league);
                let klasa = null;
                if ( liga )
                {
                    klasa = liga.classes.find((k) => k.external === x.class);
                }
                return (
                    <tr key={x.external}>
                        <td data-title="Status">{Utils.map_values({ 'PENDING': 'Oczekujace', 'ACCEPTED': 'Zaakceptowane', 'REJECTED': 'Odrzucone', 'CANCELLED': 'Anulowane' }, x.status)}</td>
                        <td data-title="Liga">{liga.shortname}</td>
                        <td data-title="Klasa">{klasa.shortname}</td>
                        <td data-title="Numer startowy">{x.assigned_number ? x.assigned_number : null}</td>
                        <td data-title="Auto">{x.car_shortname ? x.car_shortname : null}</td>
                        <td data-title="Kierowca">{x.driver_name ? x.driver_name : null}</td>
                        <td data-title="Moc">{x.car_power ? x.car_power : null}</td>
                        <td data-title="Komentarz">{x.comment ? x.comment : null}</td>
                        <td data-title="Data zgłoszenia">{x.timestamp_creation ? x.timestamp_creation.format('YYYY-MM-DD HH:mm:SS') : null}</td>
                        <td data-title="Data decyzji">{x.timestamp_review ? x.timestamp_review.format('YYYY-MM-DD HH:mm:SS') : null}</td>
                        <td data-title="Akcje" className="w3-inline">
                            {'PENDING' == x.status && 
                                <FontAwesomeIcon className="w3-clickable" icon={faTrash} onClick={(e) => askAbortApplication(x.ob)} />
                            }
                            <FontAwesomeIcon className="w3-clickable" icon={faComment} onClick={(e) => startChat(x.ob)} />
                        </td>
                    </tr>
                )
            } );
        }

        let _attendances = null;
        if ( null != attendances )
        {
            _attendances = attendances.map((x, idx) => {
            return (
                <tr key={x.external}>
                    <td data-title="Lp">{idx + 1}</td>
                    <td data-title="Zawodnik">{x.getDescription()}</td>
                    <td data-title="Impreza">{x.getEvent()}</td>
                    <td data-title="Status">{Utils.map_values({ 'PENDING': 'Zgłoszone', 'ACCEPTED': 'Potwierdzone', 'REJECTED': 'Odrzucone', 'CANCELLED': 'Anulowane' }, x.getStatus())}</td>
                    <td data-title="Data zgłoszenia">{x.getTimestampCreation() ? x.getTimestampCreation().format('YYYY-MM-DD HH:mm:ss') : null}</td>
                    <td data-title="Data potwierdzenia">{x.getTimestampAcceptance() ? x.getTimestampAcceptance().format('YYYY-MM-DD HH:mm:ss') : null}</td>
                </tr>
            )
          }
          );
        }

        return (
            <header className="w3-container">
                <h5>Twoje zgłoszenia <b>{user.getName()}</b></h5>
                {renderVehicleRequest()}
                {attendances && 
                    <div className="w3-container">
                        <div className="w3-responsive-table">
                            <h6>Zapisy na imprezy</h6>
                            <table className="w3-table-all">
                                <thead>
                                    <tr>
                                        <th>Lp</th>
                                        <th>Zawodnik</th>
                                        <th>Impreza</th>
                                        <th>Status</th>
                                        <th>Data zgłoszenia</th>
                                        <th>Data potwierdzenia</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_attendances}        
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                {vehicles && 
                    <div className="w3-container">
                        <h5>Zgloszone pojazdy</h5>
                        <div className="w3-responsive-table">
                            <table className="w3-table-all">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Liga</th>
                                        <th>Klasa</th>
                                        <th>Numer startowy</th>
                                        <th>Auto</th>
                                        <th>Kierowca</th>
                                        <th>Moc</th>
                                        <th>Komentarz</th>
                                        <th>Data zgloszenia</th>
                                        <th>Data decyzji</th>
                                        <th>Akcja</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_vehicles}        
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </header>
        );
    }

    return (
        <Box>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={commitErrors != null} autoHideDuration={6000} onClose={() => {  setCommitErrors(null); }} sx={{minWidth: '50%'}} >
                <Alert severity="error" sx={{ width: '100%' }}>{commitErrors}</Alert>
            </Snackbar>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isAsyncOperation > 0} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SimplePopup open={abortApplicationDialog != null} fullScreen={false} title='Czy na pewno chcesz sie anulowac to zgłoszenie?'  accept='TAK' reject='NIE' onAccepted={() => { doAbortApplication(abortApplicationDialog); setAbortApplicationDialog(null) }} onRejected={() => { setAbortApplicationDialog(null) }}  />            
            {renderScreen()}
        </Box>
    )
}
import ICreateLeague from "../../../../sdk/data/manage/league/ICreateLeague";

export class CreateLeague extends ICreateLeague
{
    constructor()
    {
        super();
        
        this.shortname = null;
        this.description = null;

        this.external = null;
    }

    setShortname(value)
    {
        this.shortname = value;
    }
    getShortname()
    {
        return this.shortname;
    }

    setDescription(value)
    {
        this.description = value;
    }

    getDescription()
    {
        return this.description;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getExternal()
    {
        return this.external;
    }
}
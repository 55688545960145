import ISessionLogin from 'pete/sdk/session/ISessionLogin'

export default class SessionLogin extends ISessionLogin
{
    constructor()
    {
        super();
        this.email = null;
        this.type = null;
        this.security = null;
    }

    setEmail(email)
    {
        this.email = email;
    }

    setType(type)
    {
        this.type = type;
    }

    Commit(/*String*/ security)
    {
        this.security = security;
        this.OnCommitRequested.callback(this, null);
    }
}
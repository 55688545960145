import Event from "../../../property/Event";

export default class IUpdatePlayer
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    getExternal()
    {
        throw new TypeError("Method not implemented");
    }
    getPlayerName()
    {
        throw new TypeError("Method not implemented");
    }
    setPlayerName(name)
    {
        throw new TypeError("Method not implemented");
    }

    getPlayerCar()
    {
        throw new TypeError("Method not implemented");
    }
    setPlayerCar(value)
    {
        throw new TypeError("Method not implemented");
    }

    getCarPower()
    {
        throw new TypeError("Method not implemented");
    }
    setCarPower(value)
    {
        throw new TypeError("Method not implemented");
    }

    getCarMass()
    {
        throw new TypeError("Method not implemented");
    }
    setCarMass(value)
    {
        throw new TypeError("Method not implemented");
    }
    getTeamName()
    {
        throw new TypeError("Method not implemented");
    }
    setTeamName(value)
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }
}
import Event from '../property/Event';

export default class IUser
{
    getName()
    {
        throw new TypeError("Method not implemented");
    }
    
    getNick()
    {
        throw new TypeError("Method not implemented");
    }

    getEmail()
    {
        throw new TypeError("Method not implemented");
    }

    getAvatarUrl()
    {
        throw new TypeError("Method not implemented");
    }
}
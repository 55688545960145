import Event from "../../property/Event";

export default class IAttendanceList
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    Commit()
    {
        throw new TypeError("Method not implemented");
    }

    getRegistered()
    {
        throw new TypeError("Method not implemented");
    }

    OnDownloadRequested = new Event();
    OnDownloadFinished = new Event();
    OnDownloadError = new Event();

    Download()
    {
        throw new TypeError("Method not implemented");
    }

    getData()
    {
    }
}
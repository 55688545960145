import { Route } from "react-router-dom";

export default function RouteWithLayout({
    screen: Component, 
    layout: Layout,
    ...rest
  }) {
    return (
        <Layout {...rest}>
          <Component {...rest}  />
        </Layout>
    );
  }
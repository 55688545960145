import React, { Component, useEffect, useRef, useState } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import Utils from '../../utils/Utils';
import { Alert } from '../LayoutHeader';

export const ActivateScreen = ({session}) => {
  const [asyncOperation, setAsyncOperation] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [commitErrors, setCommitErrors] = useState(null);
  const [commitRedirect, setCommitRedirect] = useState(null);


  const executeActivate = () =>
  {
    if ( !searchParams.get('uid') || !searchParams.get('token') )
    {
      return;
    }

    var action = session.createSessionActivate();
    action.setUid(searchParams.get('uid'));
    action.setToken(searchParams.get('token'));

    action.OnCommitRequested.register((sender, params) => { setAsyncOperation(true); });
    action.OnCommitFinished.register((sender, params) => { setAsyncOperation(false);
        setCommitRedirect('/');
        setCommitErrors('Konto zostało aktywowane');
      });
    action.OnCommitError.register((sender, error) => { 
        setAsyncOperation(false);
        setCommitErrors(error.message)
      } );


    action.Commit();
  }

  return (
    <header className="w3-container">

      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={commitErrors != null} autoHideDuration={6000} onClose={() => { 
          setCommitErrors(null); 
          if ( null != commitRedirect ) {
            navigate(commitRedirect);
          }
          }}
          sx={{minWidth: '50%'}} >
        <Alert severity="info" sx={{ width: '100%' }}>{commitErrors}</Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={asyncOperation} >
        <CircularProgress color="inherit" />
      </Backdrop>

      <h5><TimeToLeaveIcon /> <b>Aktywacja konta</b></h5>
      <div className="w3-container register-form">
          <Button variant="contained" color="primary" onClick={executeActivate} disabled={asyncOperation}>Aktywuj konto</Button>
      </div>
    </header>
  );
}
import Event from "../../property/Event";

export default class IAttendingUser
{
    getName()
    {
        throw new TypeError("Method not implemented");
    }
    getEmail()
    {
        throw new TypeError("Method not implemented");
    }
    getExternal()
    {
        throw new TypeError("Method not implemented");
    }
    getEvent()
    {
        throw new TypeError("Method not implemented");
    }
    getDescription()
    {
        throw new TypeError("Method not implemented");
    }
    getAssignedNumber()
    {
        throw new TypeError("Method not implemented");
    }
    getAcceptedBy()
    {
        throw new TypeError("Method not implemented");
    }
    getCrewCount()
    {
        throw new TypeError("Method not implemented");
    }
    getTimestampCreation()
    {
        throw new TypeError("Method not implemented");
    }
    getPaymentType()
    {
        throw new TypeError("Method not implemented");
    }
    getTimestampAcceptance()
    {
        throw new TypeError("Method not implemented");
    }
    getStatus()
    {
        throw new TypeError("Method not implemented");
    }
}
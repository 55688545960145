import IServiceRequest from "../../../connect/IServiceRequest";

export default class LeagueUpdateCommit extends IServiceRequest
{
    constructor()
    {
        super('www/league/update');
    }

    setToken(token)
    {
        this.appendRequest("token", token);
    }

    setDescription(description)
    {
        this.appendRequest("description", description);
    }    
}
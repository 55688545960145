import ICreateChat from "../../../sdk/data/chat/ICreateChat";

export class CreateChat extends ICreateChat
{
    constructor()
    {
        super();
        this.topic = null;
        this.external = null;
    }

    getTopic()
    {
        return this.topic;
    }

    setTopic(value)
    {
        this.topic = value;
    }

    getExternal()
    {
        return this.external;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
        return true;
    }
}
import Event from "../../../property/Event";

export default class ICreateEvent
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    getShortname()
    {
        throw new TypeError("Method not implemented");
    }
    setShortname(name)
    {
        throw new TypeError("Method not implemented");
    }

    getDescription()
    {
        throw new TypeError("Method not implemented");
    }
    setDescription(description)
    {
        throw new TypeError("Method not implemented");
    }

    getLocation()
    {
        throw new TypeError("Method not implemented");
    }
    setLocation(value)
    {
        throw new TypeError("Method not implemented");
    }

    getGps()
    {
        throw new TypeError("Method not implemented");
    }
    setGps(value)
    {
        throw new TypeError("Method not implemented");
    }

    getTimestampStart()
    {
        throw new TypeError("Method not implemented");
    }
    setTimestampStart(value)
    {
        throw new TypeError("Method not implemented");
    }
    getTimestampEnd()
    {
        throw new TypeError("Method not implemented");
    }
    setTimestampEnd(value)
    {
        throw new TypeError("Method not implemented");
    }
    Commit()
    {
        throw new TypeError("Method not implemented");
    }

    getExternal()
    {
        throw new TypeError("Method not implemented");
    }
}
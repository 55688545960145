import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import './ApplicationsViewScreen.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCross, faTrash, faCheckSquare, faTimesCircle, faComment } from '@fortawesome/free-solid-svg-icons';
import Utils from '../../utils/Utils';
import Tooltip from '../../components/common/Tooltip';
import HelpIcon from '../../components/common/HelpIcon';
import PopupAlert from '../../components/common/PopupAlert';
import InputField from '../../components/common/InputField';
import { SimplePopup } from '../../components/common/SimplePopup';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export const ApplicationsViewScreen = ({session}) => {
    const navigate = useNavigate();
    const [isAsyncOperation, setAsyncOperation] = useState(0);
    const [commitErrors, setCommitErrors] = useState(null);

    const [ leagues, setLeagues ] = useState(null);
    const [ leaguesData, setLeaguesData ] = useState(null);

    const [ vehicles, setVehicles ] = useState(null);
    const [ allowedLeagues, setAllowedLeagues ] = useState(null);

    const [ rejectApplicationDialog, setRejectApplicationDialog ] = useState(null);
    const [ acceptApplicationDialog, setAcceptApplicationDialog ] = useState(null);
    const [ temporaryAssignedNumber, setTemporaryAssignedNumber ] = useState(null);
    
    useEffect( () => { 
        const leagueChangedCallback = (sender, leagues) => {
            setLeagues(leagues) ;
        }
        session.onLeaguesChanged.register(leagueChangedCallback);

        setLeagues(session.getLeagues()) ;
        return () => {
            session.onPersonalizeChanged.unregister(leagueChangedCallback);
        }
    }, [])
    
    useEffect( () => {
        if (null == leagues) {
            return;
        }
        let _leagues = [];
        for (const l of leagues) {
            let league = {};
            league.external = l.getExternal();
            league.shortname = l.getShortname();
            league.is_finished = l.getIsFinished();

            let classes = l.getClasses().map((x) => {
                let cls = {};
                cls.external = x.getExternal();
                cls.shortname = x.getShortname();
                cls.description = x.getDescription();
                cls.player_mask = x.getPlayerMask();
                cls.player_start = x.getPlayerStart();
                return cls;
            });

            let players = l.getPlayers().map((x) => {
                let plr = {};
                plr.player = x.getPlayer();
                plr.driver_name = x.getPlayerName();
                plr.league_class = x.getClass();
                return plr;
            })

            league.classes = classes;
            league.players = players;
            _leagues.push(league);
        }
        setLeaguesData(_leagues)
    }, [leagues]);

    useEffect( () => {
        const commitRequested = (sender, params) => {
            setAsyncOperation((prev) => prev + 1);
        }
        const commitFinished = (sender, params) => {
            let _vehicles = [];

            let data = sender.getVehicleApplications();
            if ( data )
            {
                data.map((x) => {
                    let app = {};
                    app.external = x.getExternal();
                    app.user_name = x.getUserName();
                    app.user_nick = x.getUserNick();
                    app.user_external = x.getUserExternal();
                    app.league = x.getLeague();
                    app.class = x.getClass();
                    app.status = x.getStatus();
                    app.timestamp_creation = x.getTimestampCreation();
                    app.timestamp_review = x.getTimestampReview();
    
                    app.assigned_number = x.getAssignedNumber();
                    
                    app.car_shortname = x.getCarShortname();
                    app.car_power = x.getCarPower();
                    app.driver_name = x.getDriverName();
    
                    app.comment = x.getComment();
    
                    app.ob = x;
    
                    x.OnAcceptRequested.register(onApplicationVehicleAcceptRequested);
                    x.OnAcceptFinished.register(onApplicationVehicleAcceptFinished);
                    x.OnAcceptError.register(onApplicationVehicleAcceptError);
    
                    x.OnRejectRequested.register(onApplicationVehicleRejectRequested);
                    x.OnRejectFinished.register(onApplicationVehicleRejectFinished);
                    x.OnRejectError.register(onApplicationVehicleRejectError);
    
                    _vehicles.push(app);
                });
            }
    
            setAllowedLeagues(sender.getLeagues());
            setVehicles(_vehicles);
            setAsyncOperation((prev) => prev - 1);
        }
        const commitError = (sender, error) => {
            setAsyncOperation((prev) => prev - 1);
            setCommitErrors(error.message);
        }
        var action = session.createApplicationsList();
        action.OnCommitRequested.register(commitRequested);
        action.OnCommitFinished.register(commitFinished);
        action.OnCommitError.register(commitError);
        action.Commit();
    }, []);

    const doAcceptApplication = (ob, number) => {
        ob.setAssignedNumber(number)
        ob.Accept();
    }

    const onApplicationVehicleAcceptRequested = (sender, params) => {
        setAsyncOperation((prev) => prev + 1);
    }

    const onApplicationVehicleAcceptFinished = (sender, params) => {
        setAsyncOperation((prev) => prev - 1);

        setVehicles((prev) => {
            let data = prev.find((x) => x.external == sender.getExternal());
            if ( null != data )
            {
                data.status = sender.getStatus();
                data.assigned_number = sender.getAssignedNumber();
                data.timestamp_review = sender.getTimestampReview();
            }
            return prev;
        })
    }

    const onApplicationVehicleAcceptError = (sender, error) => {
        setAsyncOperation((prev) => prev - 1);
        setCommitErrors(error.message);
    }

    const ComplexAcceptPopup = (ob) => {
        let liga = leaguesData.find((l) => l.external === ob.getLeague());

        if ( ob.getAssignedNumber() )
        {
            return ( <SimplePopup 
                        open={true} 
                        fullScreen={false} 
                        title={'Czy na pewno chcesz sie przydzielić auto o numerze ' + ob.getAssignedNumber() + ' (' + ob.getDriverName() +') do użytkownika ' + ob.getUserName() + ' w ' + liga.shortname + '?'}  
                        accept='TAK' reject='NIE' 
                        onAccepted={() => { doAcceptApplication(ob, ob.getAssignedNumber()); setAcceptApplicationDialog(null) }} 
                        onRejected={() => { setAcceptApplicationDialog(null) }}  /> )
        }
        let klasa = liga.classes.find((k) => k.external === ob.getClass());
        let players = liga.players.filter((l) => l.league_class === ob.getClass());

        let highest = null;
        if ( null == players || players.length < 1 )
        {
            highest = klasa.player_start;
        }
        else if ( klasa.player_start )
        {
            for ( var i = 0; i < 100; ++i )
            {
                let current = parseInt(klasa.player_start) + i;

                let exist = players.find((p) => p.player == current );
                if ( exist )
                {
                    continue;
                }
                highest = current.toString();
                break;
            }
        }
        else
        {
            highest = parseInt(players.sort((a,b) => parseInt(b.player) - parseInt(a.player))[0].player) + 1;
        }

        return (
        <>
         <Dialog open={true} fullScreen={false} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth={true} maxWidth="md">
            <DialogTitle id="alert-dialog-title">Przydziel numer startowy</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <table>
                        <tr><td className="PlayerNumberPopupCaption">Uzytkownik</td><td className="PlayerNumberPopupValue">{ob.getUserName()}</td></tr>
                        <tr><td className="PlayerNumberPopupCaption">Kierowca</td><td className="PlayerNumberPopupValue">{ob.getDriverName()}</td></tr>
                        <tr><td className="PlayerNumberPopupCaption">Auto</td><td className="PlayerNumberPopupValue">{ob.getCarShortname()}</td></tr>
                        <tr></tr>
                        <tr><td className="PlayerNumberPopupCaption">Liga</td><td className="PlayerNumberPopupValue">{liga.shortname}</td></tr>
                        <tr><td className="PlayerNumberPopupCaption">Klasa</td><td className="PlayerNumberPopupValue">{klasa.shortname}</td></tr>
                    </table>
                    <InputField hint={'Najnizszy dostepny number: ' + highest} validate={(value) => { 
                                if ( !value )
                                {
                                    return null;
                                }
                                if ( klasa.player_mask )
                                {
                                    const regex = new RegExp(klasa.player_mask);
                                    if ( !regex.test(value) )
                                    {
                                        return 'Numer startowy spoza zakresu';
                                    }
                                }
                                let player = players.find((p) => p.player === value && p.driver_name !== ob.getDriverName() );
                                if ( player )
                                {
                                    let current = parseInt(value);
                                    let available = null;
                                    for ( var i = 0; i < 30; ++i )
                                    {
                                        let cval = current + i;
                                        let exist = players.find((p) => p.player == cval );
                                        if ( exist )
                                        {
                                            continue;
                                        }
                                        available = cval;
                                        break;
                                    }
                                    if ( available )
                                    {
                                        return 'Numer startowy zajety przez ' + player.driver_name + '. Najblizszy mozliwy ' + available;    
                                    }
                                    return 'Numer startowy zajety przez ' + player.driver_name;
                                }
                                return null;
                            } 
                        }
                        onCommit={(value) => {
                            setTemporaryAssignedNumber(value);
                        } } />        
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                    <Button onClick={() => setAcceptApplicationDialog(null)}>Anuluj</Button>
                    <Button onClick={() => doAcceptApplication(ob, temporaryAssignedNumber)} autoFocus>ZATWIERDŹ</Button>
            </DialogActions>
        </Dialog>
        </>);
    };
    
    const ComplexRejectPopup = (ob) => {
        return (<SimplePopup 
            open={true} 
            fullScreen={false} 
            title={'Czy na pewno chcesz sie odrzucić zgłoszenie uzytkownika ' + ob.getUserName()}  
            accept='TAK' 
            reject='NIE' 
            onAccepted={() => { doRejectApplication(ob); setRejectApplicationDialog(null) }} 
            onRejected={() => { setRejectApplicationDialog(null) }} />)
    }

    const onApplicationVehicleRejectRequested = (sender, params) => {
        setAsyncOperation((prev) => prev + 1);
    }

    const onApplicationVehicleRejectFinished = (sender, params) => {
        setAsyncOperation((prev) => prev - 1);

        setVehicles((prev) => {
            let data = prev.find((x) => x.external == sender.getExternal());
            if ( null != data )
            {
                data.status = sender.getStatus();
                data.assigned_number = sender.getAssignedNumber();
                data.timestamp_review = sender.getTimestampReview();
            }
            return prev
        });
    }

    const onApplicationVehicleRejectError = (sender, error) => {
        setAsyncOperation((prev) => prev - 1);
        setCommitErrors(error.message);
    }

    const doRejectApplication = (ob) => {
        ob.Reject();
    }

    
    const startChat = (ob, liga) => {
        leagues.find((l) => l.external === ob.getLeague());
        let klasa = null;
        if ( liga )
        {
            klasa = liga.classes.find((k) => k.external === ob.getClass());
        }

        let action = ob.createCreateChat();

        action.setTopic('Zgłoszenie do ' + liga.shortname + " ( " + klasa.shortname + " )");

        action.OnCommitRequested.register(onCreateChatCommitRequested);
        action.OnCommitFinished.register(onCreateChatCommitFinished);
        action.OnCommitError.register(onCreateChatCommitError);
        action.Commit();
    }

    
    const onCreateChatCommitRequested = (sender, params) => {
        setAsyncOperation((prev) => prev + 1);
    }

    const onCreateChatCommitFinished = (sender, params) => {
        setAsyncOperation((prev) => prev - 1);

        let url = '/chat/' + sender.getExternal();

        navigate(url);
    }

    const onCreateChatCommitError = (sender, error) => {
        setAsyncOperation((prev) => prev - 1);
        setCommitErrors(error.message);
    }

    const renderScreen = () => {
        if ( null == leaguesData )
        {
            return (
                <header className="w3-container">
                    <h5>Trwa wczytywanie..</h5>
                </header>
            );   
        }

        let allowed_leagues = null;
        if ( null != allowedLeagues )
        {
            allowed_leagues = allowedLeagues.map((x) => { 
                let liga = leaguesData.find((l) => l.external === x);
                if ( liga )
                {
                    return liga.shortname;
                }
            } ); // ['Grand Prix Polski 2022', 'Grand Prix Polski 2021', 'Grand Prix Polski 2020'];
        }

        let concat_leagues = null;
        if ( allowed_leagues )
        {
            concat_leagues = allowed_leagues.join(', ');
        }

        let _vehicles = null;
        if ( null != vehicles )
        {
            _vehicles = vehicles.map((x) => {
                let liga = leaguesData.find((l) => l.external === x.league);
                let klasa = null;
                if ( liga )
                {
                    klasa = liga.classes.find((k) => k.external === x.class);
                }
                let className = Utils.map_values({ 'PENDING': 'application-pending', 'ACCEPTED': 'application-accepted', 'REJECTED': 'application-rejected', 'CANCELLED': 'application-cancelled' }, x.status);
                
                return (
                    <tr key={x.external} className={className}>
                        <td data-title="Status">{Utils.map_values({ 'PENDING': 'Oczekujace', 'ACCEPTED': 'Zaakceptowane', 'REJECTED': 'Odrzucone', 'CANCELLED': 'Anulowane' }, x.status)}</td>
                        <td data-title="Liga">{liga.shortname}</td>
                        <td data-title="Klasa">{klasa.shortname}</td>
                        <td data-title="Uzytkownik"><Link to={'/user/' + x.user_external} >{x.user_name}</Link></td>
                        <td data-title="Numer startowy">{x.assigned_number ? x.assigned_number : null}</td>
                        <td data-title="Auto">{x.car_shortname ? x.car_shortname : null}</td>
                        <td data-title="Kierowca">{x.driver_name ? x.driver_name : null}</td>
                        <td data-title="Moc">{x.car_power ? x.car_power : null}</td>
                        <td data-title="Komentarz">{x.comment ? x.comment : null}</td>
                        <td data-title="Data zgłoszenia">{x.timestamp_creation ? x.timestamp_creation.format('YYYY-MM-DD HH:mm:SS') : null}</td>
                        <td data-title="Data decyzji">{x.timestamp_review ? x.timestamp_review.format('YYYY-MM-DD HH:mm:SS') : null}</td>
                        <td data-title="Akcje" className="w3-inline">
                            {'PENDING' == x.status && 
                                <FontAwesomeIcon className="w3-clickable" icon={faCheckSquare} onClick={(e) => setAcceptApplicationDialog(x.ob)} />
                            }
                            {'PENDING' == x.status && 
                                <FontAwesomeIcon className="w3-clickable" icon={faTimesCircle} onClick={(e) => setRejectApplicationDialog(x.ob)} />
                            }
                            <FontAwesomeIcon className="w3-clickable" icon={faComment} onClick={(e) => startChat(x.ob, liga)} />
                        </td>
                    </tr>
                )
            } );
        }

        return (
            <header className="w3-container">
                <h5>Zgłoszenia zawodnikow do lig: <b>{concat_leagues}</b></h5>
                {_vehicles && 
                    <div className="w3-container">
                        <h5>Zgloszone pojazdy</h5>
                        <div className="w3-responsive-table">
                            <table className="w3-table-all">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Liga</th>
                                        <th>Klasa</th>
                                        <th>Uzytkownik</th>
                                        <th>Numer startowy</th>
                                        <th>Auto</th>
                                        <th>Kierowca</th>
                                        <th>Moc</th>
                                        <th>Komentarz</th>
                                        <th>Data zgloszenia</th>
                                        <th>Data decyzji</th>
                                        <th>Akcja</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_vehicles}        
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </header>
        );
    }

    return (
        <Box>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={commitErrors != null} autoHideDuration={6000} onClose={() => {  setCommitErrors(null); }} sx={{minWidth: '50%'}} >
                <Alert severity="error" sx={{ width: '100%' }}>{commitErrors}</Alert>
            </Snackbar>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isAsyncOperation > 0} >
                <CircularProgress color="inherit" />
            </Backdrop>
            {null != rejectApplicationDialog && ComplexRejectPopup(rejectApplicationDialog)}
            {null != acceptApplicationDialog && ComplexAcceptPopup(acceptApplicationDialog)}
            

            {renderScreen()}
        </Box>
    )
}
import ILeague from "../../sdk/data/ILeague";
import Event from "../../sdk/property/Event";
import { UpdateLeague } from "./manage/league/UpdateLeague";
import { CreateClass } from "./manage/class/CreateClass";
import { CreateEvent } from "./manage/event/CreateEvent";

export class League extends ILeague
{
    constructor()
    {
        super();
        this.external = null;
        this.shortname = null;
        this.description = null;
        this.thumbnail = null;
        this.is_finished = null;
        this.events = null;
        this.classes = null;
        this.players = null;

        this.onCreateClassCreated = new Event();
        this.onCreateEventCreated = new Event();
        this.onUpdateLeagueCreated = new Event();
    }

    getExternal()
    {
        return this.external;
    }
    getShortname()
    {
        return this.shortname;
    }
    getDescription()
    {
        return this.description;
    }
    getThumbnail()
    {
        return this.thumbnail;
    }
    getIsFinished()
    {
        return this.is_finished;
    }
    getEvents()
    {
        return this.events;
    }    
    getClasses()
    {
        return this.classes;
    }   
    
    getPlayers()
    {
        return this.players;
    }

    createClassCreate()
    {
        let retval = new CreateClass();
        retval.league = this.external;
        this.onCreateClassCreated.callback(this, retval);
        return retval;
    }

    createEventCreate()
    {
        let retval = new CreateEvent();
        retval.league = this.external;
        this.onCreateEventCreated.callback(this, retval);
        return retval;
    }


    createUpdateLeague()
    {
        let retval = new UpdateLeague();
        retval.external = this.external;
        retval.shortname = this.shortname;
        this.onUpdateLeagueCreated.callback(this, retval);
        return retval;
    }
}
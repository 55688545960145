import IVehicleImageLeaderboard from "../../../sdk/data/vehicle/IVehicleImageLeaderboard";

export class VehicleImageLeaderboard extends IVehicleImageLeaderboard
{
    constructor(token)
    {
        super();
        this.token = token;
        this.data = null;

        this.picture_leaderboard = null;
    }

    setData(data)
    {
        this.data = data;
    }
    
    getToken()
    {
        return this.token;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getPictureLeaderboard()
    {
        return this.picture_leaderboard;
    }
}
import IServiceRequest from '../../connect/IServiceRequest'
import ApiUtils from '../ApiUtils';

export default class TimeslipCommit extends IServiceRequest
{
    constructor()
    {
        super('www/timeslip');
    }

    setToken(value)
    {
        this.appendRequest('token', value);
    }
}
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import './PopupAlert.css';
import Button from './Button';

class PopupAlert extends Component {
  static defaultProps = {
    onAccepted: null,
    onRejected: null,
    text: null,
    accept: "OK",
    reject: null
  }

  constructor(props)
  {
    super(props)
    autoBind(this);
  }

  onAccepted(event)
  {
    if ( null != this.props.onAccepted )
    {
      this.props.onAccepted(event);
    }
  }

  onRejected(event)
  {
    if ( null != this.props.onRejected )
    {
      this.props.onRejected(event);
    }
  }

  render() {
    return (
        <div className="PopupAlert">
          <div id="text" dangerouslySetInnerHTML={{__html: this.props.text}}/>
          <div id="buttons">
            {this.props.reject && 
                <Button text={this.props.reject} onClicked={(e)=>this.onRejected(e)} inverted={true}/>
              }
            {this.props.accept && 
              <Button text={this.props.accept} onClicked={(e)=>this.onAccepted(e)}/>
            }

          </div>
        </div>
    );
  }
}

export default PopupAlert;
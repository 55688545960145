import Event from "../../../property/Event";

export default class IUpdateRawResult {
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    getToken() {
        throw new TypeError("Method not implemented");
    }

    getPlayer() 
    {
        throw new TypeError("Method not implemented");
    }

    setPlayer(value)
    {
        throw new TypeError("Method not implemented");
    }

    getDisplayedReactionTime() 
    {
        throw new TypeError("Method not implemented");
    }
    setDisplayedReactionTime(value) 
    {
        throw new TypeError("Method not implemented");
    }

    getDisplayedTime60ft() {
        throw new TypeError("Method not implemented");
    }
    setDisplayedTime60ft(value) {
        throw new TypeError("Method not implemented");
    }

    getDisplayedTime330ft() {
        throw new TypeError("Method not implemented");
    }
    setDisplayedTime330ft(value) {
        throw new TypeError("Method not implemented");
    }

    getDisplayedTime660ft() {
        throw new TypeError("Method not implemented");
    }
    setDisplayedTime660ft(value) {
        throw new TypeError("Method not implemented");
    }

    getDisplayedTime1000ft() {
        throw new TypeError("Method not implemented");
    }
    setDisplayedTime1000ft(value) {
        throw new TypeError("Method not implemented");
    }

    getDisplayedTime1320ft() {
        throw new TypeError("Method not implemented");
    }
    setDisplayedTime1320ft(value) {
        throw new TypeError("Method not implemented");
    }

    getDisplayedSpeed60ft() {
        throw new TypeError("Method not implemented");
    }
    setDisplayedSpeed60ft(value) {
        throw new TypeError("Method not implemented");
    }
    getDisplayedSpeed330ft() {
        throw new TypeError("Method not implemented");
    }
    setDisplayedSpeed330ft(value) {
        throw new TypeError("Method not implemented");
    }
    getDisplayedSpeed660ft() {
        throw new TypeError("Method not implemented");
    }
    setDisplayedSpeed660ft(value) {
        throw new TypeError("Method not implemented");
    }
    getDisplayedSpeed1000ft() {
        throw new TypeError("Method not implemented");
    }
    setDisplayedSpeed1000ft(value) {
        throw new TypeError("Method not implemented");
    }
    getDisplayedSpeed1320ft() {
        throw new TypeError("Method not implemented");
    }
    setDisplayedSpeed1320ft(value) {
        throw new TypeError("Method not implemented");
    }
    getIsInvalid() {
        throw new TypeError("Method not implemented");
    }
    setIsInvalid(value) {
        throw new TypeError("Method not implemented");
    }
    getIsPublic() {
        throw new TypeError("Method not implemented");
    }
    setIsPublic(value) {
        throw new TypeError("Method not implemented");
    }

    Commit() 
    {
        throw new TypeError("Method not implemented");
    }
}
import React, { Component, useEffect, useRef, useState } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useNavigate, useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import './ProfileScreen.css'

export const ProfileScreen = ({session}) =>  {

    const [tabIndex, setTabIndex] = useState('details');

    const tabIndexChange = (event, newValue) => {
        setTabIndex(newValue);
    };


  const renderAccountDetails = () =>
  {
    let user = session.getUser();
      return ( 
      <div className="w3-container">
        <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
            <thead>
                <tr><th/><th/><th /></tr>
            </thead>
            <tbody>
                <tr>
                    <td>Uzytkownik</td>
                    <td>{user.getName()}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Nick</td>
                    <td>{user.getNick()}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>{user.getEmail()}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Avatar</td>
                    <td>{user.getAvatarUrl()}</td>
                    <td></td>
                </tr>
            </tbody>
        </table>
      </div>);
  }

  const renderSecurityDetails = () =>
  {
    return (
    
    <div className="w3-container">
      <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
          <thead>
              <tr><th/><th/><th /></tr>
          </thead>
          <tbody>
              <tr>
                  <td><Link to='/account/password'>Zmień hasło</Link></td>
              </tr>
              <tr>
                  <td>Aktywne sesje www</td>
              </tr>
              <tr>
                  <td>Połączone aplikacje mobilne</td>
              </tr>
          </tbody>
      </table>
    </div>);
  }

  const renderProfile = () => {

    return ( 
    <div className="w3-container">
      <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
          <thead>
              <tr><th/><th/><th /></tr>
          </thead>
          <tbody>
              <tr>
                  <td>Twoj Avatar</td>
                  <td>Zmień</td>
              </tr>
              <tr>
                  <td>Twoje skróty</td>
                  <td><Link to='/account/shortcuts'>Zmień</Link></td>
              </tr>
          </tbody>
      </table>
    </div>);
  }

  
  return (
      <header className="w3-container">

        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={tabIndexChange} aria-label="lab API tabs example">
                    <Tab label="Szczegóły konta" value="details" />
                    <Tab label="Bezpieczeństwo" value="security" />
                    <Tab label="Profil" value="profile" />
                </TabList>
                </Box>
                <TabPanel value="details">{renderAccountDetails()}</TabPanel>
                <TabPanel value="security">{renderSecurityDetails()}</TabPanel>
                <TabPanel value="profile">{renderProfile()}</TabPanel>
            </TabContext>
        </Box>
      </header>
    );
}
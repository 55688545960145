import IAttendanceRegister from "../../../sdk/data/attendance/IAttendanceRegister";

export class AttendanceRegister extends IAttendanceRegister
{
    constructor()
    {
        super();
        this.type = null; 
        this.token = null; 

        this.vehicle = null;
        this.crew_count = null;

        this.email = null;
        this.payment_options = null;

        this.custom_message = null;
    }

    setVehicle(value)
    {
        this.vehicle = value;
    }
    
    setCrewCount(value)
    {
        this.crew_count = value;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getEmail()
    {
        return this.email;
    }

    getPaymentOptions()
    {
        return this.payment_options;
    }

    getCustomMessage()
    {
        return this.custom_message;
    }
}
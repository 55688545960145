import IRaceResults from "../../sdk/data/IRaceResults";

export class RaceResults extends IRaceResults
{
    constructor()
    {
        super();
        this.event_id = null;
        this.player_id = null;
        this.class_id = null;
        this.allowed_players = null;
        this.timestamp = null;
        this.results = null;
        this.interval = null;

        this.is_finished = true;
    }

    setEventId(id)
    {
        this.event_id = id;
    }

    setPlayerId(id)
    {
        this.player_id = id;
    }

    setClassId(id)
    {
        this.class_id = id;
    }
    setAllowedPlayers(players)
    {
        this.allowed_players = players;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getResults()
    {
        return this.results;
    }

    getTimestamp()
    {
        return this.timestamp;
    }

    getInterval()
    {
        return this.interval;
    }

    isFinished()
    {
        return this.is_finished;
    }
}
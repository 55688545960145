import Event from '../../property/Event';

export default class IVehicleApplication
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    setLeague(data)
    {
        throw new TypeError("Method not implemented");
    }

    setLeagueClass(data)
    {
        throw new TypeError("Method not implemented");
    }

    setLeagueEvent(data)
    {
        throw new TypeError("Method not implemented");
    }

    setCrewCount(data)
    {
        throw new TypeError("Method not implemented");
    }

    setAssignedNumber(data)
    {
        throw new TypeError("Method not implemented");
    }

    setCarShortname(data)
    {
        throw new TypeError("Method not implemented");
    }    

    setCarPower(data)
    {
        throw new TypeError("Method not implemented");
    }    

    setDriverName(data)
    {
        throw new TypeError("Method not implemented");
    }    
    
    setComment(data)
    {
        throw new TypeError("Method not implemented");
    }
    Commit()
    {
        throw new TypeError("Method not implemented");
    }
}
import HttpConnection from './HttpConnection';
import Worker from './Worker';
import JobApi from './JobApi';

export default class BackendConnection
{
    constructor(/*IConnectionFactory*/ factory)
    {
        this.mFactory = factory;
        this.workers = {};
    }

    Push(/*String*/ name, /*ISession*/ session, /*IServiceResult*/ result, /*IServiceRequest*/ request)
    {
        if ( !this.workers.hasOwnProperty(name) )
        {
            var connection = new HttpConnection(name);
            connection.InitializeHost(this.mFactory.getProtocol(), this.mFactory.getHost(), this.mFactory.getPath());

            var worker = new Worker(name, connection);

            this.workers[name] = worker;
            worker.start();
        }

        var /*JobApi*/ job = new JobApi(session, request, result);
        var /*Worker*/ w = this.workers[name];
        w.push(job);
    }
}
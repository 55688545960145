import React, { Component, useEffect, useRef, useState, useCallback } from "react";
import { Link, Navigate } from "react-router-dom";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";

import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import AutoFixOffIcon from "@mui/icons-material/AutoFixOff";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SportsMotorsportsIcon from "@mui/icons-material/SportsMotorsports";
import SportsScoreIcon from "@mui/icons-material/SportsScore";

import FlashOnIcon from "@mui/icons-material/FlashOn";
import FlashOffIcon from "@mui/icons-material/FlashOff";

import LinearProgress from "@mui/material/LinearProgress";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";

import "./ResultsScreen.css";

export const ResultsScreen2 = ({ session, show_best }) => {
  const urlParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [showBest, setShowBest] = useState(true);

  const [eventId, setEventId] = useState(undefined);
  const [leagueId, setLeagueId] = useState(undefined);
  const [playerId, setPlayerId] = useState(undefined);
  const [classId, setClassId] = useState(undefined);

  const [extraPlayers, setExtraPlayers] = useState([]);

  const [leagues, setLeagues] = useState(null);

  const [events, setEvents] = useState(null);
  const [classes, setClasses] = useState(null);
  const [players, setPlayers] = useState(null);

  const [action, setAction] = useState(null);

  const [bestValues, setBestValues] = useState(null);

  const [rawResults, setRawResults] = useState(null);
  const [filteredResults, setFilteredResults] = useState(null);

  const [currentSortColumn, setCurrentSortColumn] = useState(null);
  const [sortedResults, setSortedResults] = useState(null);
  const [dataTimestamp, setDataTimestamp] = useState(null);

  const [columnsExpanded, setColumnsExpanded] = useState(false);

  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [filterEvents, setFilterEvents] = useState([]);
  const [filterClasses, setFilterClasses] = useState([]);
  const [filterPlayers, setFilterPlayers] = useState([]);

  const [visibleColumns, setVisibleColumns] = useState(null);
  const [filterColumns, setFilterColumns] = useState(null);

  const [isAsyncOperation, setAsyncOperation] = useState(0);
  const [triggerRefresh, setTriggerRefresh] = useState(null);
  const [canEditResults, setCanEditResults] = useState(false);

  const columns = [
    {
      id: "player",
      label: "Zawodnik",
      minWidth: 120,
      sortable: true,
      format: (row, value) => {
        let player_display = row.player;
        if (row.player_name) {
          player_display = row.player + " - " + row.player_name;
        }
        return player_display;
      },
      check_best: (best, row, value) => {
        return false;
      },
      onClick: (row) => {
        navigate("/results/" + eventId + "/player/" + row.player);
      },
    },
    {
      id: "time_1320ft",
      label: "ET 1320ft ",
      minWidth: 10,
      sortable: true,
      check_best: (best, row, value) => {
        return null != best?.time_1320ft && best?.time_1320ft == value;
      },
    },
    {
      id: "speed_1320ft",
      label: "SPEED 1320ft",
      minWidth: 10,
      sortable: true,
      check_best: (best, row, value) => {
        return null != best?.speed_1320ft && best?.speed_1320ft == value;
      },
    },
    {
      id: "timestamp_race",
      label: "Data",
      minWidth: 80,
      sortable: true,
      format: (row, value) => value.format("YYYY-MM-DD HH:mm:ss"),
      check_best: (best, row, value) => {
        return false;
      },
    },
    {
      id: "class_name",
      label: "Klasa",
      minWidth: 100,
      sortable: false,
      check_best: (best, row, value) => {
        return false;
      },
      onClick: (row) => {
        navigate("/results/" + eventId + "/class/" + row.class_id + "/best");
      },
    },
    {
      id: "reaction_time",
      label: "RT",
      minWidth: 10,
      sortable: true,
      check_best: (best, row, value) => {
        return null != best?.reaction_time && best?.reaction_time == value;
      },
    },
    {
      id: "time_60ft",
      label: "ET 60ft",
      minWidth: 10,
      sortable: true,
      check_best: (best, row, value) => {
        return null != best?.time_60ft && best?.time_60ft == value;
      },
    },
    {
      id: "time_660ft",
      label: "ET 660ft",
      minWidth: 10,
      sortable: true,
      check_best: (best, row, value) => {
        return null != best?.time_660ft && best?.time_660ft == value;
      },
    },
    {
      id: "speed_660ft",
      label: "SPEED 660ft",
      minWidth: 10,
      sortable: true,
      check_best: (best, row, value) => {
        return null != best?.speed_660ft && best?.speed_660ft == value;
      },
    },
    {
      id: "time_1000ft",
      label: "ET 1000ft",
      minWidth: 10,
      sortable: true,
      check_best: (best, row, value) => {
        return null != best?.time_1000ft && best?.time_1000ft == value;
      },
    },
    {
      id: "speed_1000ft",
      label: "SPEED 1000ft",
      minWidth: 10,
      sortable: true,
      check_best: (best, row, value) => {
        return null != best?.speed_1000ft && best?.speed_1000ft == value;
      },
    },
    {
      id: "race_time",
      label: "RACE",
      minWidth: 10,
      sortable: true,
      check_best: (best, row, value) => {
        return null != best?.race_time && best?.race_time == value;
      },
    },
    {
      id: "event_name",
      label: "Wydarzenie",
      minWidth: 80,
      sortable: true,
      format: (row, value) => value,
      check_best: (best, row, value) => {
        return false;
      },
      onClick: (row) => {
        navigate("/results/" + eventId + "/best");
      },
    },
    {
      id: "actions",
      label: "",
      minWidth: 80,
      sortable: false,
      check_best: (best, row, value) => {
        return false;
      },
    },
  ];

  useEffect(() => {
    let allowed = session.getPermissions().hasPermissionContext("can_edit_results", leagueId) || session.getPermissions().hasPermissionGlobal("can_edit_results");
    setCanEditResults(allowed);
  }, [leagueId]);

  useEffect(() => {
    do {
      var changed = false;
      if (eventId != urlParams.eventId) {
        setEventId(urlParams.eventId);
        changed = true;
      }
      if (playerId != urlParams.playerId) {
        setPlayerId(urlParams.playerId);
        changed = true;
      }
      if (classId != urlParams.classId) {
        setClassId(urlParams.classId);
        changed = true;
      }

      if (changed) {
        break;
      }
      return;
    } while (false);

    setRawResults(null);
    setBestValues(null);
  }, [urlParams]);

  useEffect(() => {
    let data = session.getLeagues();
    if (null == data) {
      return;
    }
    if (null == eventId) {
      return;
    }
    for (const l of data) {
      if (null != l.getEvents()) {
        if (eventId === l.getExternal()) {
          setLeagueId(eventId);
          return;
        }
        for (const e of l.getEvents()) {
          if (eventId === e.getExternal()) {
            continue;
          }
          setLeagueId(l.getExternal());
          return;
        }
      }
    }
    return;
  }, [eventId, leagues]);

  useEffect(() => {
    setShowBest(show_best);
  }, [show_best]);

  useEffect(() => {
    if (null != triggerRefresh) {
      let clear = setTimeout(triggerRefresh.callback, triggerRefresh.delay);
      return () => {
        clearTimeout(clear);
      };
    }
  }, [triggerRefresh]);

  useEffect(() => {
    let data = [];
    columns.forEach((x) => {
      data.push({ column: x.id, visible: true });
    });
    setFilterColumns(data);
  }, []);

  useEffect(() => {
    let data = [];
    if (null != filterColumns) {
      filterColumns.forEach((x) => {
        if (!x.visible) {
          return;
        }
        let col = columns.find((y) => {
          return y.id == x.column;
        });
        if (col) {
          data.push(col);
        }
      });
    }
    setVisibleColumns(data);
  }, [filterColumns]);

  useEffect(() => {
    let allowed_players = searchParams.get("players");
    if (!allowed_players) {
      if (!extraPlayers || extraPlayers.length > 0) {
        setExtraPlayers([]);
      }
      return;
    }
    let data = allowed_players.split(",");
    if (playerId) {
      data.push(playerId);
    }
    setExtraPlayers(data);
  }, [searchParams, playerId]);

  useEffect(() => {
    if (null == events) {
      return;
    }
    let data = [];
    for (const id in events) {
      let x = events[id];
      data.push({ event_shortname: x.shortname, event_external: x.external, league_shortname: x.league_shortname, league_external: x.league_id });
    }
    setFilterEvents(data);
  }, [events]);

  useEffect(() => {
    if (null == classes) {
      return;
    }
    if (!classId) {
      return;
    }
    let data = [];
    for (const id in classes) {
      let x = classes[id];
      data.push({ class_shortname: x.shortname, class_external: x.external });
    }
    setFilterClasses(data);
  }, [classes]);

  useEffect(() => {
    if (null == players) {
      return;
    }
    if (!playerId) {
      return;
    }
    let data = [];
    if (players[playerId]) {
      let x = players[playerId];
      data.push({ player: x.player, player_name: x.player_name, vehicle: x.vehicle });
    }
    if (extraPlayers) {
      extraPlayers.forEach((pid) => {
        let x = players[pid];
        if (x) {
          data.push({ player: x.player, player_name: x.player_name, vehicle: x.vehicle });
        }
      });
    }
    setFilterPlayers(data);
  }, [players, extraPlayers]);

  const calculateBestValues = (results) => {
    if (null === results) {
      console.log("Setting empty best values");
      setBestValues(null);
      return;
    }

    let retval = {
      reaction_time: null,
      time_60ft: null,
      time_660ft: null,
      time_1000ft: null,
      time_1320ft: null,
      speed_660ft: null,
      speed_1000ft: null,
      speed_1320ft: null,
      race_time: null,
    };

    for (const r of results) {
      if (r.getIsInvalid()) {
        continue;
      }
      if (!r.getIsPublic()) {
        continue;
      }
      if (null != r.getReactionTime()) {
        let rt = parseFloat(r.getReactionTime());
        if (!isNaN(rt) && rt > 0.0) {
          if (null == retval.reaction_time || retval.reaction_time > rt) {
            retval.reaction_time = rt;
          }
        }
      }
      if (null != r.getTime60ft()) {
        let t60ft = parseFloat(r.getTime60ft());
        if (!isNaN(t60ft) && t60ft > 0.0) {
          if (null == retval.time_60ft || retval.time_60ft > t60ft) {
            retval.time_60ft = t60ft;
          }
        }
      }
      if (null != r.getTime660ft()) {
        let t660ft = parseFloat(r.getTime660ft());
        if (!isNaN(t660ft) && t660ft > 0.0) {
          if (null == retval.time_660ft || retval.time_660ft > t660ft) {
            retval.time_660ft = t660ft;
          }
        }
      }
      if (null != r.getTime1000ft()) {
        let t1000ft = parseFloat(r.getTime1000ft());
        if (!isNaN(t1000ft) && t1000ft > 0.0) {
          if (null == retval.time_1000ft || retval.time_1000ft > t1000ft) {
            retval.time_1000ft = t1000ft;
          }
        }
      }
      if (null != r.getTime1320ft()) {
        let t1320ft = parseFloat(r.getTime1320ft());
        if (!isNaN(t1320ft) && t1320ft > 0.0) {
          if (null == retval.time_1320ft || retval.time_1320ft > t1320ft) {
            retval.time_1320ft = t1320ft;
          }
        }
      }
      if (null != r.getSpeed660ft()) {
        let s660ft = parseFloat(r.getSpeed660ft());
        if (!isNaN(s660ft) && s660ft > 0.0) {
          if (null == retval.speed_660ft || retval.speed_660ft < s660ft) {
            retval.speed_660ft = s660ft;
          }
        }
      }
      if (null != r.getSpeed1000ft()) {
        let s1000ft = parseFloat(r.getSpeed1000ft());
        if (!isNaN(s1000ft) && s1000ft > 0.0) {
          if (null == retval.speed_1000ft || retval.speed_1000ft < s1000ft) {
            retval.speed_1000ft = s1000ft;
          }
        }
      }
      if (null != r.getSpeed1320ft()) {
        let s1320ft = parseFloat(r.getSpeed1320ft());
        if (!isNaN(s1320ft) && s1320ft > 0.0) {
          if (null == retval.speed_1320ft || retval.speed_1320ft < s1320ft) {
            retval.speed_1320ft = s1320ft;
          }
        }
      }
      if (null != r.getRaceTime()) {
        let trace = parseFloat(r.getRaceTime());
        if (!isNaN(trace) && trace > 0.0) {
          if (null == retval.race_time || retval.race_time > trace) {
            retval.race_time = trace;
          }
        }
      }
    }
    setBestValues(retval);
  };

  const filterResults = (results, filterBest) => {
    if (null == results) {
      return null;
    }
    results = results.filter((x) => {
      return x.is_public;
    });
    if (filterBest) {
      let retval = [];
      var tmp = new Map();

      for (const r of results) {
        if (r.is_invalid) {
          continue;
        }
        let id = r.player;
        if (tmp.has(id)) {
          let rhs = tmp.get(id);
          if (null != rhs.race_time) {
            if (parseFloat(rhs.race_time) < parseFloat(r.race_time)) {
              continue;
            } else if (parseFloat(rhs.race_time) === parseFloat(r.race_time)) {
              if (parseFloat(rhs.speed_1320ft) > parseFloat(r.speed_1320ft)) {
                continue;
              }
            }
          } else {
            if (parseFloat(rhs.time_1320ft) < parseFloat(r.time_1320ft)) {
              continue;
            } else if (parseFloat(rhs.time_1320ft) === parseFloat(r.time_1320ft)) {
              if (parseFloat(rhs.speed_1320ft) > parseFloat(r.speed_1320ft)) {
                continue;
              }
            }
          }
        }
        tmp.set(id, r);
      }

      for (var value of tmp.values()) {
        retval.push(value);
      }

      return retval;
    }

    return results;
  };

  const createResults = (data) => {
    if (null === data) {
      return null;
    }

    let retval = [];

    for (const r of data) {
      let result = {};

      result.token = r.getToken();
      result.player = r.getPlayer();
      result.timestamp_race = r.getTimestampRace();
      result.is_invalid = r.getIsInvalid();
      result.is_public = r.getIsPublic();

      result.reaction_time = r.getReactionTime();
      result.time_60ft = r.getTime60ft();
      result.time_660ft = r.getTime660ft();
      result.time_1000ft = r.getTime1000ft();
      result.time_1320ft = r.getTime1320ft();
      result.speed_660ft = r.getSpeed660ft();
      result.speed_1000ft = r.getSpeed1000ft();
      result.speed_1320ft = r.getSpeed1320ft();

      result.race_time = r.getRaceTime();

      result.class_id = null;
      result.class_name = null;
      result.vehicle_id = null;
      result.event_name = null;
      if (null != events && null !== r.getEvent()) {
        let mapping = events[r.getEvent()];
        if (mapping) {
          result.event_name = mapping.shortname;
        }
      }

      if (null != players && null !== result.player) {
        let mapping = players[result.player];
        if (mapping) {
          result.class_id = mapping.class;
          result.vehicle_id = mapping.vehicle;
          result.player_name = mapping.player_name;
          result.player_car = mapping.player_car;
        }
      }

      if (null !== result.class_id) {
        let cls = classes[result.class_id];
        if (cls) {
          result.class_name = cls.shortname;
        }
      }

      retval.push(result);
    }

    return retval;
  };

  const processResults = (results) => {
    calculateBestValues(results);

    setRawResults(createResults(results));

    // let results = this.sortResults(this.state.sort, this.state.sort_ascending, rawResults);
    // let timestamp = action.getTimestamp();
    // let is_finished = action.isFinished();
  };

  useEffect(() => {
    let filtered = filterResults(rawResults, showBest);
    setFilteredResults(filtered);
  }, [rawResults, showBest]);

  useEffect(() => {
    const leagueChangedCallback = (sender, leagues) => {
      setLeagues(leagues);
    };
    session.onLeaguesChanged.register(leagueChangedCallback);

    setLeagues(session.getLeagues());
    return () => {
      session.onPersonalizeChanged.unregister(leagueChangedCallback);
    };
  }, []);

  useEffect(() => {
    let retval = {};

    if (null == leagues) {
      return;
    }
    for (const l of leagues) {
      if (null != l.getEvents()) {
        for (const e of l.getEvents()) {
          if (eventId !== e.getExternal() && eventId !== l.getExternal()) {
            continue;
          }
          let event = {};
          event.external = e.getExternal();
          event.shortname = e.getShortname();
          event.description = e.getDescription();
          event.location = e.getLocation();
          event.thumbnail = e.getThumbnail();
          event.timestamp_start = e.getTimestampStart();

          event.league_id = l.getExternal();
          event.league_shortname = l.getShortname();

          retval[event.external] = event;
        }
      }
    }
    setEvents(retval);
  }, [leagues, eventId]);

  useEffect(() => {
    let retval = {};

    if (null == leagues) {
      return;
    }
    for (const l of leagues) {
      if (null != l.getEvents()) {
        for (const e of l.getEvents()) {
          if (eventId !== e.getExternal() && eventId !== l.getExternal()) {
            continue;
          }

          for (const cls of l.getClasses()) {
            let data = {};
            data.external = cls.getExternal();
            data.shortname = cls.getShortname();
            data.description = cls.getDescription();
            data.priority = cls.getPriority();

            retval[data.external] = data;
          }
          break;
        }
      }
    }
    setClasses(retval);
  }, [leagues, eventId]);

  useEffect(() => {
    let retval = {};

    if (null == leagues) {
      return;
    }
    for (const l of leagues) {
      if (null != l.getEvents()) {
        for (const e of l.getEvents()) {
          if (eventId !== e.getExternal() && eventId !== l.getExternal()) {
            continue;
          }

          for (const cls of l.getPlayers()) {
            let data = {};
            data.external = cls.getExternal();
            data.player = cls.getPlayer();
            data.class = cls.getClass();
            data.vehicle = cls.getVehicle();
            data.player_name = cls.getPlayerName();
            data.player_car = cls.getPlayerCar();
            data.car_power = cls.getCarPower();
            data.car_mass = cls.getCarMass();

            retval[data.player] = data;
          }
          break;
        }
      }
    }
    setPlayers(retval);
  }, [leagues, eventId]);

  useEffect(() => {
    const callbackCommitRequested = (sender, value) => {
      setAsyncOperation(isAsyncOperation + 1);
    };
    const callbackCommitFinished = (sender, value) => {
      processResults(sender.getResults());
      setDataTimestamp(sender.getTimestamp());
      setAsyncOperation(isAsyncOperation - 1);

      let is_finished = sender.isFinished();
      if (!is_finished) {
        setTriggerRefresh({ callback: () => action.Commit(), delay: action.getInterval() * 1000 });
      }
    };
    const callbackCommitError = (sender, err) => {
      setAsyncOperation(isAsyncOperation - 1);
    };
    if (null == eventId) {
      return;
    }
    if (null == events) {
      return;
    }
    if (null == players) {
      return;
    }
    var action = session.createRaceResults();
    action.setEventId(eventId);
    if (playerId) {
      action.setPlayerId(playerId);
    }
    if (classId) {
      action.setClassId(classId);
    }
    if (extraPlayers) {
      action.setAllowedPlayers(extraPlayers);
    }

    action.OnCommitRequested.register(callbackCommitRequested);
    action.OnCommitFinished.register(callbackCommitFinished);
    action.OnCommitError.register(callbackCommitError);
    action.Commit();

    return () => {
      action.OnCommitRequested.unregister(callbackCommitRequested);
      action.OnCommitFinished.unregister(callbackCommitFinished);
      action.OnCommitError.unregister(callbackCommitError);
    };
  }, [events, players, eventId, classId, playerId, extraPlayers]);

  const sortResults = (column, ascending, data) => {
    if ("timestamp_race" === column) {
      data.sort((l, r) => {
        let lh = l[column].unix();
        let rh = r[column].unix();
        if (ascending) {
          return lh - rh;
        }
        return rh - lh;
      });
    } else if ("event_name" === column || "player" === column) {
      data.sort((l, r) => {
        let lh = l[column];
        let rh = r[column];
        if (ascending) {
          return lh.localeCompare(rh);
        }
        return rh.localeCompare(lh);
      });
    } else {
      data.sort((l, r) => {
        let lh = parseFloat(l[column]);
        let rh = parseFloat(r[column]);
        if (ascending) {
          if (isNaN(lh) && !isNaN(rh)) {
            return 1;
          } else if (!isNaN(lh) && isNaN(rh)) {
            return -1;
          }
          if (l["is_invalid"] && !r["is_invalid"]) {
            return 1;
          } else if (!l["is_invalid"] && r["is_invalid"]) {
            return -1;
          }
          if (lh <= 0.0 && rh > 0.0) {
            return 1;
          } else if (lh > 0.0 && rh <= 0.0) {
            return -1;
          }
          let retval = lh - rh;
          if (lh <= 0.0 && rh <= 0.0) {
            retval = rh - lh;
          }
          if (0 === retval) {
            let lhh = l["timestamp_race"].unix();
            let rhh = r["timestamp_race"].unix();
            return lhh - rhh;
          }
          return retval;
        }
        if (isNaN(lh) && !isNaN(rh)) {
          return 1;
        } else if (!isNaN(lh) && isNaN(rh)) {
          return -1;
        }
        if (l["is_invalid"] && !r["is_invalid"]) {
          return 1;
        } else if (!l["is_invalid"] && r["is_invalid"]) {
          return -1;
        }
        if (lh <= 0.0 && rh > 0.0) {
          return 1;
        } else if (lh > 0.0 && rh <= 0.0) {
          return -1;
        }
        let retval = rh - lh;
        if (lh <= 0.0 && rh <= 0.0) {
          retval = lh - rh;
        }
        if (0 === retval) {
          let lhh = l["timestamp_race"].unix();
          let rhh = r["timestamp_race"].unix();
          return lhh - rhh;
        }
        return retval;
      });
    }
    return data;
  };

  useEffect(() => {
    if (null == filteredResults) {
      setCurrentSortColumn("time_1320ft");
      setSortedResults(null);
      return;
    }
    let sortColumn = searchParams.get("sort");
    if (!sortColumn) {
      sortColumn = "time_1320ft";
      if (bestValues.race_time) {
        sortColumn = "race_time";
      }
    }
    let sorted = sortResults(sortColumn, searchParams.get("asc") == "no" ? false : true, filteredResults.slice(0));
    setCurrentSortColumn(sortColumn);
    setSortedResults(sorted);
  }, [filteredResults, searchParams]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", height: "100%" }}>
      <Toolbar>
        <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
          {dataTimestamp ? "Dane z " + dataTimestamp.format("YYYY-MM-DD HH:mm:ss") : null}
        </Typography>
        {isAsyncOperation > 0 && <LinearProgress sx={{ width: "50%" }} />}
        {!showBest && (
          <Tooltip title="Wszystkie przejazdy">
            <IconButton onClick={() => setShowBest(true)}>
              <FlashOffIcon />
            </IconButton>
          </Tooltip>
        )}
        {showBest && (
          <Tooltip title="Najlepsze przejazdy">
            <IconButton onClick={() => setShowBest(false)}>
              <FlashOnIcon />
            </IconButton>
          </Tooltip>
        )}
        {!columnsExpanded && (
          <Tooltip title="Kolumny">
            <IconButton onClick={() => setColumnsExpanded(true)}>
              <AutoFixHighIcon />
            </IconButton>
          </Tooltip>
        )}
        {columnsExpanded && (
          <Tooltip title="Kolumny">
            <IconButton onClick={() => setColumnsExpanded(false)}>
              <AutoFixOffIcon />
            </IconButton>
          </Tooltip>
        )}
        {!filtersExpanded && (
          <Tooltip title="Lista filtrów">
            <IconButton onClick={() => setFiltersExpanded(true)}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
        {filtersExpanded && (
          <Tooltip title="Schowaj filtry">
            <IconButton onClick={() => setFiltersExpanded(false)}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
      {columnsExpanded && (
        <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
          {columns.map((c) => {
            const labelId = `checkbox-list-label-${c.id}`;

            return (
              <ListItem key={c.id} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={() => {
                    let copy = filterColumns.slice(0);
                    let record = copy.find((x) => {
                      return x.column == c.id;
                    });
                    record.visible = !record.visible;
                    setFilterColumns(copy);
                  }}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={
                        filterColumns.find((x) => {
                          return x.column == c.id;
                        }).visible
                      }
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={c.label} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
      {filtersExpanded && (
        <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
          {filterEvents.map((x) => {
            return (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <SportsScoreIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText secondary="Wydarzenie" primary={x.event_shortname + " - " + x.league_shortname} />
              </ListItem>
            );
          })}
          {filterClasses.map((x) => {
            return (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FormatListBulletedIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText secondary="Klasa" primary={x.class_shortname} />
              </ListItem>
            );
          })}
          {filterPlayers.map((x) => {
            return (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <SportsMotorsportsIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText secondary="Zawodnik" primary={x.player + " - " + x.player_name} />
              </ListItem>
            );
          })}
          {showBest && (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <FlashOnIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText secondary="Zawodnik" primary="Tylko najlepsze przejazdy" />
            </ListItem>
          )}
        </List>
      )}
      <TableContainer sx={{ minHeight: "120%" }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <TableCell key={"lp"} align={"left"} style={{ minWidth: "30px" }}>
                LP
              </TableCell>
              {null != visibleColumns
                ? visibleColumns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.sortable && (
                        <TableSortLabel
                          active={currentSortColumn == column.id}
                          direction={searchParams.get("asc") == "yes" || null == searchParams.get("asc") ? "asc" : "desc"}
                          onClick={() => {
                            let current = {};
                            current.sort = searchParams.get("sort");
                            current.asc = searchParams.get("asc");
                            if (null == searchParams.get("sort") && "time_1320ft" == column.id) {
                              current.sort = "time_1320ft";
                              current.asc = "no";
                            } else if (null == searchParams.get("sort") && "race_time" == column.id) {
                              current.sort = "race_time";
                              current.asc = "no";
                            } else if (column.id == current.sort) {
                              if ("yes" == current.asc) {
                                current.asc = "no";
                              } else {
                                current.asc = "yes";
                              }
                            } else {
                              current.sort = column.id;
                              current.asc = "yes";
                            }

                            let serialized = new URLSearchParams(current).toString();

                            setSearchParams(serialized);
                          }}
                        >
                          {column.label}
                        </TableSortLabel>
                      )}
                      {!column.sortable && column.label}
                    </TableCell>
                  ))
                : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleColumns &&
              sortedResults &&
              sortedResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIdx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.token} sx={{ textDecoration: row.is_invalid ? "line-through" : "none" }}>
                    <TableCell key={"lp-" + rowIdx + page * rowsPerPage} align={"left"}>
                      {rowIdx + page * rowsPerPage + 1}
                    </TableCell>
                    {visibleColumns.map((column) => {
                      if ("actions" == column.id) {
                        return (
                          <TableCell key={row.token + "-" + column.id} align={column.align} sx={{ padding: "2px" }}>
                            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="no-wrap">
                              <Tooltip title="Pokaz wyścig">
                                <Link to={"/race/" + row.token}>
                                  <VisibilityIcon />
                                </Link>
                              </Tooltip>
                              <Tooltip title="Timeslip">
                                <Link to={"/timeslip/" + row.token}>
                                  <PrintIcon />
                                </Link>
                              </Tooltip>
                              {canEditResults && (
                                <Tooltip title="Edytuj">
                                  <Link to={"/manage/result/" + row.token + "/edit"}>
                                    <EditIcon />
                                  </Link>
                                </Tooltip>
                              )}
                            </Stack>
                          </TableCell>
                        );
                      }
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={row.token + "-" + column.id}
                          align={column.align}
                          data-best={column.check_best(bestValues, row, value) ? "yes" : "no"}
                          sx={{ padding: "2px" }}
                          onClick={() => {
                            if (column.onClick) {
                              column.onClick(row);
                            }
                          }}
                        >
                          {column.format ? column.format(row, value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={sortedResults?.length || 0} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
    </Paper>
  );
};

import IVehicleUpdate from "../../../sdk/data/vehicle/IVehicleUpdate";

export class VehicleUpdate extends IVehicleUpdate
{
    constructor(token)
    {
        super();
        this.token = token;
        this.car_shortname = null;
        this.car_description = null;
        this.car_power = null;
        this.car_mass = null;

        this.car_driver = null;
        this.car_team = null;
    }

    
    getToken()
    {
        return this.token;
    }

    setCarShortname(value)
    {
        this.car_shortname = value;
    }   
    
    getCarShortname()
    {
        return this.car_shortname;
    }

    setCarDescription(value)
    {
        this.car_description = value;
    }  
    getCarDescription()
    {
        return this.car_description;
    }
    setCarPower(value)
    {
        this.car_power = value;
    }        
    getCarPower()
    {
        return this.car_power;
    }
    
    setCarMass(value)
    {
        this.car_mass = value;
    }    
    getCarMass()
    {
        return this.car_mass;
    }

    setCarDriver(value)
    {
        this.car_driver = value;
    }    
    getCarDriver()
    {
        return this.car_driver;
    }
    setCarTeam(value)
    {
        this.car_team = value;
    }    
    getCarTeam()
    {
        return this.car_team;
    }
    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }
}
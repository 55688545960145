import IServiceRequest from '../../connect/IServiceRequest'
import ApiUtils from '../ApiUtils';

export default class RaceDetailsCommit extends IServiceRequest
{
    constructor()
    {
        super('www/race_details');
    }

    setToken(value)
    {
        this.appendRequest('token', value);
    }
}
import Session from '../Session'

import IServiceResult from '../connect/IServiceResult';
import Engine, { Error } from '../Engine';

import PlayersUploadCommit from '../api/players/PlayersUploadCommit';
import PlayersDownloadCommit from '../api/players/PlayersDownloadCommit';
import PlayerRemoveCommit from '../api/manage/player/PlayerRemoveCommit';
import PlayerUpdateCommit from '../api/manage/player/PlayerUpdateCommit';


export default class PlayersMonitor {
    constructor(/*Session*/ session) {
        this.mSession = session;
        session.onPlayersUploadCreated.register(this.onPlayersUploadCreated.bind(this));
        session.onPlayersDownloadCreated.register(this.onPlayersDownloadCreated.bind(this));

        session.onLeaguesChanged.register(this.onLeaguesChanged.bind(this));
    }


    onLeaguesChanged(/*Object*/ sender, leagues)
    {
        if ( !leagues )
        {
            return;
        }
        for ( const league of leagues )
        {
            // league.onCreatePlayerCreated.register(this.onCreatePlayerCreated.bind(this));

            for ( const pl of league.players )
            {
                pl.onUpdatePlayerCreated.register(this.onUpdatePlayerCreated.bind(this));
                pl.onRemovePlayerCreated.register(this.onRemovePlayerCreated.bind(this));
            }
        }
    }

    
    onRemovePlayerCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitRequested.register(this.onRemovePlayerCommitRequested.bind(this));
    }


    onRemovePlayerCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new PlayerRemoveCommit();

        request.setExternal(action.external);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("MANAGE", result, request);
    }
    
    onUpdatePlayerCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitRequested.register(this.onUpdatePlayerCommitRequested.bind(this));
    }


    onUpdatePlayerCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new PlayerUpdateCommit();

        request.setExternal(action.external);
        request.setPlayerName(action.player_name);
        request.setPlayerCar(action.player_car);
        request.setCarPower(action.car_power);
        request.setCarMass(action.car_mass);
        request.setTeamName(action.team_name);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("MANAGE", result, request);
    }

    onPlayersUploadCreated(/*Object*/ sender, /*{PlayersUpload}*/ action) {
        action.OnCommitRequested.register(this.onPlayersUploadCommitRequested.bind(this));
    }

    onPlayersUploadCommitRequested(/*RaceResults*/ sender, /*Void*/ params) {
        var /*AdminUpload*/ action = sender;
        var request = new PlayersUploadCommit();
        request.setLeague(action.league);
        request.setData(action.data);
        var monitor = this;

        var result = new IServiceResult(
            function(/*Object*/ reply)
            {
                action.OnCommitFinished.callback(sender, null);
            }, 
            function(/*Object*/ error)
            {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("SESSION", result, request);
    }

    onPlayersDownloadCreated(/*Object*/ sender, /*{PlayersUpload}*/ action) {
        action.OnCommitRequested.register(this.onPlayersDownloadCommitRequested.bind(this));
    }

    onPlayersDownloadCommitRequested(/*SessionPersonalize*/ sender, /*Void*/ params)
    {
        var /*AdminDownload*/ action = sender;
        var request = new PlayersDownloadCommit();
        request.setLeague(action.league);
        var monitor = this;

        var result = new IServiceResult(
            function(/*Object*/ reply)
            {
                action.data = reply.data;
                action.OnCommitFinished.callback(sender, null);
            }, 
            function(/*Object*/ error)
            {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("SESSION", result, request);
    }
}
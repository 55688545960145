import ICreateEvent from "../../../../sdk/data/manage/event/ICreateEvent";

export class CreateEvent extends ICreateEvent
{
    constructor()
    {
        super();
        this.league = null;
        
        this.shortname = null;
        this.description = null;

        this.location = null;
        this.gps = null;

        this.timestamp_start = null;
        this.timestamp_end = null;

        this.external = null;
    }

    setShortname(value)
    {
        this.shortname = value;
    }
    getShortname()
    {
        return this.shortname;
    }

    setDescription(value)
    {
        this.description = value;
    }

    getDescription()
    {
        return this.description;
    }

    getLocation()
    {
        return this.location;
    }
    setLocation(value)
    {
        this.location = value;
    }

    getGps()
    {
        return this.gps;
    }
    setGps(value)
    {
        this.gps = value;
    }

    getTimestampStart()
    {
        return this.timestamp_start;
    }
    setTimestampStart(value)
    {
        this.timestamp_start = value;
    }

    getTimestampEnd()
    {
        return this.timestamp_end;
    }
    setTimestampEnd(value)
    {
        this.timestamp_end = value;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getExternal()
    {
        return this.external;
    }
}
import Event from '../property/Event';

export default class IRace
{
    getToken()
    {
        throw new TypeError("Method not implemented");
    }

    getPlayer()
    {
        throw new TypeError("Method not implemented");
    }

    getPlayerName()
    {
        throw new TypeError("Method not implemented");
    }

    getPlayerCar()
    {
        throw new TypeError("Method not implemented");
    }

    getReactionTime()
    {
        throw new TypeError("Method not implemented");
    }

    getTime60ft()
    {
        throw new TypeError("Method not implemented");
    }
    
    getTime660ft()
    {
        throw new TypeError("Method not implemented");
    }
    getTime1000ft()
    {
        throw new TypeError("Method not implemented");
    }
    getTime1320ft()
    {
        throw new TypeError("Method not implemented");
    }

    getSpeed660ft()
    {
        throw new TypeError("Method not implemented");
    }
    getSpeed1000ft()
    {
        throw new TypeError("Method not implemented");
    }
    getSpeed1320ft()
    {
        throw new TypeError("Method not implemented");
    }
    getRaceTime()
    {
        throw new TypeError("Method not implemented");
    }
    getTimestampRace()
    {
        throw new TypeError("Method not implemented");
    }    

    getIsInvalid()
    {
        throw new TypeError("Method not implemented");
    }   

    getIsPublic()
    {
        throw new TypeError("Method not implemented");
    }    
    
    getPictureUrl()
    {
        throw new TypeError("Method not implemented");
    }
    
    getEvent()
    {
        throw new TypeError("Method not implemented");
    }
}
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const AlertPopup = ({open, fullScreen, title, body, accept='TAK', onAccepted = () => {} }) => {
    return (
    <>
     <Dialog open={open} fullScreen={fullScreen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >
        <DialogTitle id="alert-dialog-title">{title} </DialogTitle>

        <DialogContent>
            <DialogContentText id="alert-dialog-description">{body}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onAccepted} autoFocus>{accept} </Button>
        </DialogActions>
        </Dialog>
    </>);
}
import IServiceRequest from '../../connect/IServiceRequest'

export default class SessionPersonalizeCommit extends IServiceRequest
{
    constructor()
    {
        super('www/session/register');
    }

    setName(value)
    {
        this.appendRequest('name', value);
    }
    setNick(value)
    {
        this.appendRequest('nick', value);
    }

    setEmail(value)
    {
        this.appendRequest('email', value);
    }

    setSecurity(value)
    {
        this.appendRequest('security', value);
    }

}
import React, { Component, createRef } from 'react';
import { Link, Navigate } from 'react-router-dom'
import update from "react-addons-update";

import autoBind from 'react-autobind';
import './PlayersListScreen.css'
import BaseScreenHeader from '../../BaseScreenHeader';
import InputField from '../../../components/common/InputField';
import { faSync, faPlusCircle, faTrashAlt, faCog, faUsers, faFlagCheckered, faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import PhotoBox from '../../../components/common/PhotoBox';
import Button from '../../../components/common/Button';
import InputTextArea from '../../../components/common/InputTextArea';
import Utils from '../../../utils/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopupAlert from '../../../components/common/PopupAlert';
import { base64StringToBlob } from 'blob-util';
import withRouter from '../../../utils/withRouter';

class PlayersListScreen extends BaseScreenHeader 
{
    static defaultProps = {
        session: null
    }
    constructor(props) {
        super(props)
        autoBind(this);

        this.uploadFileInput = createRef();
    }

    validatePermission()
    {
        return true;
        //return this.props.session.getPermissions().hasPermissionGlobal('can_create_league');
    }

    onInitParameters(bundle)
    {
      this.state.league = this.createLeague(this.props.params.leagueId);
      return true;
    }
  
    bindDelegates()
    {
        this.session.onLeaguesChanged.register(this.onLeaguesChanged);
        super.bindDelegates();
    }
    unbindDelegates()
    {
        this.session.onLeaguesChanged.unregister(this.onLeaguesChanged);
        super.unbindDelegates();
    }


    static getDataTransferFiles(event) {
        let dataTransferItemsList = [];
        if (event.dataTransfer) {
            const dt = event.dataTransfer;
            if (dt.files && dt.files.length) {
                dataTransferItemsList = dt.files;
            } else if (dt.items && dt.items.length) {
                // During the drag even the dataTransfer.files is null
                // but Chrome implements some drag store, which is accesible via dataTransfer.items
                dataTransferItemsList = dt.items;
            }
        } else if (event.target && event.target.files) {
            dataTransferItemsList = event.target.files;
        }
    
    
        // Convert from DataTransferItemsList to the native Array
        return Array.prototype.slice.call(dataTransferItemsList);
    }
    
    onFileDropped(event) {
        const me = this;
        event.preventDefault();
        let file = PlayersListScreen.getDataTransferFiles(event).shift();
    
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let action = me.props.session.createPlayersUpload();
            action.setLeague(me.state.league.external);
            action.setData(reader.result);
    
            action.OnCommitRequested.register(me.onPlayersUploadCommitRequested.bind(me));
            action.OnCommitFinished.register(me.onPlayersUploadCommitFinished.bind(me));
            action.OnCommitError.register(me.onPlayersUploadCommitError.bind(me));
            action.Commit();
        };
        reader.onerror = function (error) {
            this.setState((oldState) => { return update(oldState, { error: { $set: error } }); })
        };
      }

      
  onPlayersUploadCommitRequested(sender, params) {
    this.beginAsyncOperation();
  }

  onPlayersUploadCommitFinished(sender, params) {
    this.endAsyncOperation();
    this.showMessage('Konfiguracja została wczytana');
  }

  onPlayersUploadCommitError(sender, error) {
    this.endAsyncOperation();
    this.showError(error);
  }



      downloadPlayers()
      {
        let action = this.props.session.createPlayersDownload();
        action.setLeague(this.state.league.external);
    
        action.OnCommitRequested.register(this.onPlayersDownloadCommitRequested.bind(this));
        action.OnCommitFinished.register(this.onPlayersDownloadCommitFinished.bind(this));
        action.OnCommitError.register(this.onPlayersDownloadCommitError.bind(this));
        action.Commit();
      }
    
      
      
      onPlayersDownloadCommitRequested(sender, params) {
        this.beginAsyncOperation();
      }
    
      onPlayersDownloadCommitFinished(sender, params) {
        this.endAsyncOperation();
    
        var data = sender.getData();
        var blob = base64StringToBlob(data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        var url  = window.URL.createObjectURL(blob);
                
        var anchorElem = document.createElement("a");
        anchorElem.style = "display: none";
        anchorElem.href = url;
        anchorElem.download = "zawodnicy.xls";
    
        document.body.appendChild(anchorElem);
        anchorElem.click();        
        document.body.removeChild(anchorElem);
    
        // On Edge, revokeObjectURL should be called only after
        // a.click() has completed, atleast on EdgeHTML 15.15048
        setTimeout(function() {
            window.URL.revokeObjectURL(url);
        }, 1000);
      }
    
      onPlayersDownloadCommitError(sender, error) {
        this.endAsyncOperation();
        this.showError(error);
      }

    onLeaguesChanged(sender, data)
    {
        var league = this.createLeague(this.props.params.leagueId);
  
        this.setState((oldState) => { return update(oldState, { league: { $set: league }})});
    }
  
    createLeague(id)
    {
        let data = this.session.getLeagues();
        if ( null == data )
        {
            return null;
        }
        for ( const l of data )
        {
            if ( l.getExternal() != id )
            {
                continue;
            }
            let league = {};
            league.external = l.getExternal();
            league.shortname = l.getShortname();
            league.description = l.getDescription();
            league.thumbnail = l.getThumbnail();
            league.is_finished = l.getIsFinished();
  
            let players = [];
            for ( const cls of l.getPlayers() )
            {
                let item = {}
                item.external = cls.getExternal();
                item.player = cls.getPlayer();
                item.class = cls.getClass();
                item.vehicle = cls.getVehicle();
                item.player_name = cls.getPlayerName();
                item.player_car = cls.getPlayerCar();
                item.car_power = cls.getCarPower();
                item.car_mass = cls.getCarMass();
                item.picture_car = cls.getPictureCar();
                item.picture_driver = cls.getPictureDriver();
                item.owner_external = cls.getOwnerExternal();
                item.owner_name = cls.getOwnerName();
                item.ob = cls;
    
                players.push(item);
            }

            league.players = players;
            
            let classes = [];
            for ( const cls of l.getClasses() )
            {
                let data = {}
                data.external = cls.getExternal();
                data.shortname = cls.getShortname();
                data.description = cls.getDescription();
                data.priority = cls.getPriority();
    
                classes[data.external] = data;
            }
            league.classes = classes;

            
            return league;
        }
        return null;
    }

     
    removePlayer(ob)
    {
        this.showDialog(() => 
        { 
          return (<PopupAlert text={'Czy na pewno chcesz sie usunąc zawodnika ' + ob.getPlayer() + ' (' + ob.getPlayerName() + ')?'} 
                    accept='TAK'
                    reject='NIE' 
                    onRejected={((e) => { this.clearDialog(); })} 
                    onAccepted={(e) => { this.clearDialog(); this.doRemovePlayer(ob) }} 
                  />);
        } );
        return;
    }

    
    doRemovePlayer(ob)
    {
        let action = ob.createRemovePlayer();

        action.OnCommitRequested.register(this.onRemovePlayerCommitRequested);
        action.OnCommitFinished.register(this.onRemovePlayerCommitFinished);
        action.OnCommitError.register(this.onRemovePlayerCommitError);

        action.Commit();
    }

    onRemovePlayerCommitRequested(sender, params) {
        this.beginAsyncOperation();
    }

    onRemovePlayerCommitFinished(sender, params) {
        this.endAsyncOperation();
        this.showMessage('Zawodnik został usuniety');
    }

    onRemovePlayerCommitError(sender, err) {
        this.endAsyncOperation();
        this.showError(err);
    }

    renderScreen(child) {
        var league = this.state.league;

        if ( null == league )
        {
            return super.renderScreen(
                <div>Trwa wczytywanie danych</div>
            );
        }


        let players = null;
        if ( league.players )
        {
            let index = 1;
  
            players = league.players.map( (x) => {
                let className = "";
                let classId = null;
                if ( x.class )
                {
                    if ( league.classes && league.classes[x.class] )
                    {
                        className = league.classes[x.class].shortname;
                        classId = league.classes[x.class].external;
                    }
                }
                
                return ( <tr key={x.external}>
                  <td>{index++}</td>
                  <td>{x.player}</td>
                  <td>{x.player_name}</td>
                  <td>{className}</td>
                  <td>{x.player_car}</td>
                  <td>{x.owner_external &&
                    <Link to={'/account/' + x.owner_external} >{x.owner_name}</Link>
                  }</td>
                  <td className="w3-inline">
                        <Link to={'/manage/players/' + league.external + '/' + x.external + '/edit'} ><FontAwesomeIcon icon={faCog} /></Link>
                        <FontAwesomeIcon className="w3-clickable" icon={faTrashAlt} onClick={(e) => {this.removePlayer(x.ob)}} />
                  </td>
                </tr>);
            });
        }
        return super.renderScreen(
          <div className="w3-container w3-anchor">
              <h5>Zawodnicy ligi <Link to={'/league/' + league.external}>{league.shortname}</Link></h5>

            <div className="w3-container">
                <div className="w3-row-padding w3-margin-bottom">
                    <div className="w3-quarter">
                        <div className="w3-container w3-orange w3-text-white w3-padding-16 w3-clickable"
                            onDragOver={(event) => { event.preventDefault(); }}
                            onDragLeave={(event) => { event.preventDefault(); }}
                            onDrop={this.onFileDropped.bind(this)} 
                            onClick={() => this.uploadFileInput.current.click()}>
                            <div className="w3-right w3-xxxlarge">
                                <FontAwesomeIcon icon={faUpload} />
                            </div>
                            <div className="w3-clear"></div>
                            <h4>Wczytaj excel</h4>
                            <input type='file' id='file' ref={this.uploadFileInput} style={{display: 'none'}}
                                onChange={this.onFileDropped.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="w3-quarter">
                        <div className="w3-container w3-teal w3-text-white w3-padding-16 w3-clickable" onClick={(ev) => this.downloadPlayers()}>
                            <div className="w3-right w3-xxxlarge">
                                <FontAwesomeIcon icon={faDownload} />
                            </div>
                            <div className="w3-clear"></div>
                            <h4>Pobierz excel</h4>
                        </div>
                    </div>
                </div>
            </div>
  
              <div className="w3-container">
                  <table className="w3-table w3-striped w3-white w3-bordered">
                      <thead className="">
                          <tr style={{height: '21px'}}>
                              <th className="u-border-1 u-border-grey-50 u-table-cell u-table-cell-narrow">LP</th>
                              <th className="u-border-1 u-border-grey-50 u-table-cell">Nr</th>
                              <th className="u-border-1 u-border-grey-50 u-table-cell">Zawodnik</th>
                              <th className="u-border-1 u-border-grey-50 u-table-cell">Klasa</th>
                              <th className="u-border-1 u-border-grey-50 u-table-cell">Auto</th>
                              <th className="u-border-1 u-border-grey-50 u-table-cell">Konto</th>
                              <th className="u-border-1 u-border-grey-50 u-table-cell">Akcje</th>
                          </tr>
                      </thead>
                      {null != players && 
                          <tbody className="u-table-body">
                              {players}
                          </tbody>
                      }
                  </table>
              </div>
        </div>
        )

    }
}
export default withRouter(PlayersListScreen);
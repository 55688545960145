import ILeagueClass from "../../sdk/data/ILeagueClass";
import { RemoveClass } from "./manage/class/RemoveClass";
import Event from "../../sdk/property/Event";
import { UpdateClass } from "./manage/class/UpdateClass";

export class LeagueClass extends ILeagueClass
{
    constructor()
    {
        super();
        this.external = null;
        this.priority = null;
        this.shortname = null;
        this.description = null;
        this.player_mask = null;
        this.player_start = null;

        this.onUpdateClassCreated = new Event();
        this.onRemoveClassCreated = new Event();
    }

    getExternal()
    {
        return this.external;
    }
    getShortname()
    {
        return this.shortname;
    }
    getDescription()
    {
        return this.description;
    }
    getPriority()
    {
        return this.priority;
    }

    getPlayerMask()
    {
        return this.player_mask;
    }
    getPlayerStart()
    {
        return this.player_start;
    }
    createUpdateClass()
    {
        let retval = new UpdateClass();
        retval.external = this.external;
        
        retval.shortname = this.shortname;
        retval.description = this.description;

        retval.priority = this.priority;
        retval.player_start = this.player_start;
        retval.player_mask = this.player_mask;

        this.onUpdateClassCreated.callback(this, retval);
        
        return retval;
    }  
    createRemoveClass()
    {
        let retval = new RemoveClass();
        retval.external = this.external;

        this.onRemoveClassCreated.callback(this, retval);

        return retval;
    }
}
import React, { Component, createRef } from 'react';
import { Link, Navigate } from 'react-router-dom'
import update from "react-addons-update";

import autoBind from 'react-autobind';
import './PlayerEditScreen.css'
import BaseScreenHeader from '../../BaseScreenHeader';
import InputField from '../../../components/common/InputField';
import { faSync, faPlusCircle, faTrashAlt, faCog, faUsers, faFlagCheckered, faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import PhotoBox from '../../../components/common/PhotoBox';
import Button from '../../../components/common/Button';
import InputTextArea from '../../../components/common/InputTextArea';
import Utils from '../../../utils/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopupAlert from '../../../components/common/PopupAlert';
import { base64StringToBlob } from 'blob-util';
import withRouter from '../../../utils/withRouter';

class PlayerEditScreen extends BaseScreenHeader {
    static defaultProps = {
        session: null
    }
    constructor(props) {
        super(props)
        autoBind(this);

        this.uploadFileInput = createRef();
    }

    validatePermission() {
        return true;
        //return this.props.session.getPermissions().hasPermissionGlobal('can_create_league');
    }

    onInitParameters(bundle) {
        let league = this.createLeague(this.props.params.leagueId, this.props.params.playerId);
        if (null != league) {
            let player = league.player;
            if (null != player) {
                this.initFormData({ player_name: player.player_name, player_car: player.player_car, car_power: player.car_power, car_mass: player.car_mass, team_name: player.team_name });
            }
        }


        this.state.league = league;
        this.state.vehicle = null;
        
        this.fetchVehicle();
        return true;
    }

    fetchVehicle()
    {
        let action = this.props.session.createVehicle();
        action.setExternal(this.props.params.playerId);

        action.OnQueryRequested.register(this.onVehicleQueryRequested);
        action.OnQueryFinished.register(this.onVehicleQueryFinished);
        action.OnQueryError.register(this.onVehicleQueryError);
        action.Query();

        return action;
    }

    
    onVehicleQueryRequested(sender, params) {
        this.beginAsyncOperation();
    }
    onVehicleQueryFinished(sender, params) {
        let vehicle = {}

        vehicle.car_power = sender.getCarPower();
        vehicle.car_mass = sender.getCarMass();
        vehicle.car_driver = sender.getCarDriver();
        vehicle.car_description = sender.getCarDescription();
        vehicle.car_team = sender.getCarTeam();
        vehicle.assigned_number = sender.getAssignedNumber();

        vehicle.picture_leaderboard = sender.getPictureLeaderboard();
        vehicle.picture_timeslip = sender.getPictureTimeslip();
        vehicle.picture_livecam = sender.getPictureLivecam();
        vehicle.picture_profile = sender.getPictureProfile();

        vehicle.ob = sender;

        this.setState((oldState) => update(oldState, { vehicle: { $set: vehicle } }));
        this.endAsyncOperation();
    }
    onVehicleQueryError(sender, params) {
        this.endAsyncOperation();
    }

    bindDelegates() {
        this.session.onLeaguesChanged.register(this.onLeaguesChanged);
        super.bindDelegates();
    }
    unbindDelegates() {
        this.session.onLeaguesChanged.unregister(this.onLeaguesChanged);
        super.unbindDelegates();
    }

    onLeaguesChanged(sender, data) {
        var league = this.createLeague(this.props.params.leagueId, this.props.params.playerId);

        if (null != league) {
            let player = league.player;
            if (null != player) {
                this.initFormData({ player_name: player.player_name, player_car: player.player_car, car_power: player.car_power, car_mass: player.car_mass, team_name: player.team_name });
            }
        }

        this.setState((oldState) => { return update(oldState, { league: { $set: league } }) });
    }

    createLeague(leagueId, playerId) {
        let data = this.session.getLeagues();
        if (null == data) {
            return null;
        }
        for (const l of data) {
            if (l.getExternal() != leagueId) {
                continue;
            }

            let league = {};
            league.external = l.getExternal();
            league.shortname = l.getShortname();
            league.description = l.getDescription();
            league.thumbnail = l.getThumbnail();
            league.is_finished = l.getIsFinished();

            for (const cls of l.getPlayers()) {
                if (cls.getExternal() != playerId) {
                    continue;
                }

                let item = {}
                item.external = cls.getExternal();
                item.player = cls.getPlayer();
                item.class = cls.getClass();
                item.vehicle = cls.getVehicle();
                item.player_name = cls.getPlayerName();
                item.player_car = cls.getPlayerCar();
                item.car_power = cls.getCarPower();
                item.car_mass = cls.getCarMass();
                item.picture_car = cls.getPictureCar();
                item.picture_driver = cls.getPictureDriver();
                item.owner_external = cls.getOwnerExternal();
                item.owner_name = cls.getOwnerName();
                item.team_name = cls.getTeamName();

                item.ob = cls;

                league.player = item;
            }

            let classes = [];
            for (const cls of l.getClasses()) {
                let data = {}
                data.external = cls.getExternal();
                data.shortname = cls.getShortname();
                data.description = cls.getDescription();
                data.priority = cls.getPriority();

                classes[data.external] = data;
            }
            league.classes = classes;


            return league;
        }
        return null;
    }

    onSubmit()
    {
        let errors = [];
  
        let player_name = this.getFormData('player_name');
        if ( !player_name )
        {
            errors.push('Nazwa zawodnika nie moze byc pusta');
        }
        let player_car = this.getFormData('player_car');
        if ( !player_car )
        {
            errors.push('Nazwa auta nie moza byc pusta');
        }  
        let car_power = this.getFormData('car_power');
        let car_mass = this.getFormData('car_mass');
        let team_name = this.getFormData('team_name');
        
        if ( errors.length > 0 )
        {
            this.showMessage(errors);
            return;
        }
  
        var action = this.state.league.player.ob.createUpdatePlayer();

        action.setPlayerName(player_name);
        action.setPlayerCar(player_car);
        action.setCarPower(car_power);
        action.setCarMass(car_mass);
        action.setTeamName(team_name);
  
        action.OnCommitRequested.register(this.onUpdatePlayerCommitRequested);
        action.OnCommitFinished.register(this.onUpdatePlayerCommitFinished);
        action.OnCommitError.register(this.onUpdatePlayerCommitError);
  
        action.Commit();
    }

    onUpdatePlayerCommitRequested(sender, params) {
        this.beginAsyncOperation();
    }

    onUpdatePlayerCommitFinished(sender, params) {
        this.endAsyncOperation();
        this.showAndRedirect('Zawodnik zostal zaktualizowany', '/manage/players/' + this.state.league.external);
    }

    onUpdatePlayerCommitError(sender, err) {
        this.endAsyncOperation();

        this.showError(err);
    }

    
    onVehicleImageLeaderboardCommitRequested(sender, params) {
        this.beginAsyncOperation();

        this.setState((oldState) => update(oldState, { loader_leaderboard: { $set: true } }));
    }

    onVehicleImageLeaderboardCommitFinished(sender, params) {
        this.endAsyncOperation();

        let url = sender.getPictureLeaderboard();

        let vehicle = this.state.vehicle;
        vehicle.picture_leaderboard = url;
        
        this.setState((oldState) => update(oldState, { loader_leaderboard: { $set: false }, vehicle: { $set: vehicle } }));
    }

    onVehicleImageLeaderboardCommitError(sender, err) {
        this.endAsyncOperation();

        this.setState((oldState) => update(oldState, { loader_leaderboard: { $set: false } }));
    }

    uploadImageLeaderboard(ob, picture) 
    {
        var action = ob.createVehicleImageLeaderboard();
        action.setData(picture);

        action.OnCommitRequested.register(this.onVehicleImageLeaderboardCommitRequested);
        action.OnCommitFinished.register(this.onVehicleImageLeaderboardCommitFinished);
        action.OnCommitError.register(this.onVehicleImageLeaderboardCommitError);

        action.Commit();
    }


    onVehicleImageTimeslipCommitRequested(sender, params) {
        this.beginAsyncOperation();

        this.setState((oldState) => update(oldState, { loader_timeslip: { $set: true } }));
    }

    onVehicleImageTimeslipCommitFinished(sender, params) {
        this.endAsyncOperation();

        let url = sender.getPictureTimeslip();

        let vehicle = this.state.vehicle;
        vehicle.picture_timeslip = url;
        
        this.setState((oldState) => update(oldState, { loader_timeslip: { $set: false }, vehicle: { $set: vehicle } }));
    }

    onVehicleImageTimeslipCommitError(sender, err) {
        this.endAsyncOperation();

        this.setState((oldState) => update(oldState, { loader_timeslip: { $set: false } }));
    }


    uploadImageTimeslip(ob, picture) 
    {
        var action = ob.createVehicleImageTimeslip();
        action.setData(picture);

        action.OnCommitRequested.register(this.onVehicleImageTimeslipCommitRequested);
        action.OnCommitFinished.register(this.onVehicleImageTimeslipCommitFinished);
        action.OnCommitError.register(this.onVehicleImageTimeslipCommitError);

        action.Commit();
    }

    onVehicleImageLivecamCommitRequested(sender, params) {
        this.beginAsyncOperation();

        this.setState((oldState) => update(oldState, { loader_livecam: { $set: true } }));
    }

    onVehicleImageLivecamCommitFinished(sender, params) {
        this.endAsyncOperation();

        let url = sender.getPictureLivecam();

        let vehicle = this.state.vehicle;
        vehicle.picture_livecam = url;
        
        this.setState((oldState) => update(oldState, { loader_livecam: { $set: false }, vehicle: { $set: vehicle } }));
    }

    onVehicleImageLivecamCommitError(sender, err) {
        this.endAsyncOperation();

        this.setState((oldState) => update(oldState, { loader_livecam: { $set: false } }));
    }


    uploadImageLivecam(ob, picture) 
    {
        var action = ob.createVehicleImageLivecam();
        action.setData(picture);

        action.OnCommitRequested.register(this.onVehicleImageLivecamCommitRequested);
        action.OnCommitFinished.register(this.onVehicleImageLivecamCommitFinished);
        action.OnCommitError.register(this.onVehicleImageLivecamCommitError);

        action.Commit();
    }

    onVehicleImageProfileCommitRequested(sender, params) {
        this.beginAsyncOperation();

        this.setState((oldState) => update(oldState, { loader_profile: { $set: true } }));
    }

    onVehicleImageProfileCommitFinished(sender, params) {
        this.endAsyncOperation();

        let url = sender.getPictureProfile();

        let vehicle = this.state.vehicle;
        vehicle.picture_profile = url;
        
        this.setState((oldState) => update(oldState, { loader_profile: { $set: false }, vehicle: { $set: vehicle } }));
    }

    onVehicleImageProfileCommitError(sender, err) {
        this.endAsyncOperation();

        this.setState((oldState) => update(oldState, { loader_profile: { $set: false } }));
    }

    uploadImageProfile(ob, picture) 
    {
        var action = ob.createVehicleImageProfile();
        action.setData(picture);

        action.OnCommitRequested.register(this.onVehicleImageProfileCommitRequested);
        action.OnCommitFinished.register(this.onVehicleImageProfileCommitFinished);
        action.OnCommitError.register(this.onVehicleImageProfileCommitError);

        action.Commit();        
    }

    renderScreen(child) {
        var league = this.state.league;

        if (null == league) {
            return super.renderScreen(
                <div>Trwa wczytywanie danych</div>
            );
        }
        var player = league.player;
        var vehicle = this.state.vehicle;


        return super.renderScreen(
            <div className="w3-container w3-anchor">
                <h5>Szczegóły zawodnika <b>{player.player}</b></h5>

                <div className="w3-container">
                    <h6>Numer startowy</h6>
                    <InputField id="player-player" text={player.player} disabled={true} />
                    <h6>Nazwa kierowcy</h6>
                    <InputField id="player-driver-name" text={player.player_name} onCommit={(value) => this.storeFormData('player_name', value)} />
                    <h6>Nazwa auta</h6>
                    <InputField id="player-car-name" text={player.player_car} onCommit={(value) => this.storeFormData('player_car', value)} />
                    <h6>Moc auta</h6>
                    <InputField id="player-car-power" text={player.car_power} onCommit={(value) => this.storeFormData('car_power', value)} />
                    <h6>Masa auta</h6>
                    <InputField id="player-car-mass" text={player.car_mass} onCommit={(value) => this.storeFormData('car_mass', value)} />
                    <h6>Nazwa druzyny</h6>
                    <InputField id="player-team-name" text={player.team_name} onCommit={(value) => this.storeFormData('team_name', value)} />

                    <div className="w3-quarter w3-right w3-margin">
                        <Button onClicked={() => { this.onSubmit() }} disabled={this.state.asyncOperation || null == this.getFormData('player_name') || null == this.getFormData('player_car')} text="Zapisz zmiany" iconLeft={this.state.asyncOperation ? faSync : null} />
                    </div>

                    <div className="w3-container">
                    <h5>Zdjęcia</h5>
                    {!vehicle &&
                        <div className="w3-container garage-photo-container">
                            Trwa wczytywanie..
                        </div>
                    }
                    {vehicle && 
                        <div className="w3-container garage-photo-container">
                            <PhotoBox caption="Zdjęcie kierowcy do tabeli wynikow" loader={this.state.loader_leaderboard} url={vehicle.picture_leaderboard} allowEdit={true} newImageSelected={(data) => this.uploadImageLeaderboard(vehicle.ob, data)} />
                            <PhotoBox caption="Zdjęcie auta do timeslip" loader={this.state.loader_timeslip} url={vehicle.picture_timeslip} allowEdit={true} newImageSelected={(data) => this.uploadImageTimeslip(vehicle.ob, data)} />
                            <PhotoBox caption="Zdjęcie kierowcy dla relacji zawodów" loader={this.state.loader_livecam} url={vehicle.picture_livecam} allowEdit={true} newImageSelected={(data) => this.uploadImageLivecam(vehicle.ob, data)} />
                            <PhotoBox caption="Zdjęcie auta do tabeli wynikow" loader={this.state.loader_profile} url={vehicle.picture_profile} allowEdit={true} newImageSelected={(data) => this.uploadImageProfile(vehicle.ob, data)} />
                        </div>
                    }
                </div>
                </div>
            </div>
        )

    }
}
export default withRouter(PlayerEditScreen);
import ILeaguePlayer from "../../sdk/data/ILeaguePlayer";
import { RemovePlayer } from "./manage/player/RemovePlayer";
import Event from "../../sdk/property/Event";
import { UpdatePlayer } from "./manage/player/UpdatePlayer";

export class LeaguePlayer extends ILeaguePlayer
{
    constructor()
    {
        super();
        this.external = null;
        this.player = null;
        this.class = null;
        this.vehicle = null;
        this.player_name = null;
        this.player_car = null;
        this.car_power = null;
        this.car_mass = null;
        this.picture_car = null;
        this.picture_driver = null;
        this.team_name = null;

        this.owner_external = null;
        this.owner_name = null;

        this.onUpdatePlayerCreated = new Event();
        this.onRemovePlayerCreated = new Event();
    }

    getExternal()
    {
        return this.external;
    }
    getPlayer()
    {
        return this.player;
    }
    getClass()
    {
        return this.class;
    }
    getVehicle()
    {
        return this.vehicle;
    }
    getPlayerName()
    {
        return this.player_name;
    }
    getPlayerCar()
    {
        return this.player_car;
    }

    getCarPower()
    {
        return this.car_power;
    }

    getCarMass()
    {
        return this.car_mass;
    }

    getPictureCar()
    {
        return this.picture_car;
    }
    
    getPictureDriver()
    {
        return this.picture_driver;
    }

    getOwnerExternal()
    {
        return this.owner_external;
    }

    getOwnerName()
    {
        return this.owner_name;
    }

    getTeamName()
    {
        return this.team_name;
    }
    
    createUpdatePlayer()
    {
        let retval = new UpdatePlayer();
        retval.external = this.external;

        this.onUpdatePlayerCreated.callback(this, retval);

        return retval;
    }
    createRemovePlayer()
    {
        let retval = new RemovePlayer();
        retval.external = this.external;

        this.onRemovePlayerCreated.callback(this, retval);

        return retval;
    }
}
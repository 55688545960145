import IApplicationVehicle from "../../../sdk/data/applications/IApplicationVehicle";
import Event from "../../../sdk/property/Event";
import { CreateChat } from "../chat/CreateChat";

export class ApplicationVehicle extends IApplicationVehicle
{
    onChatCreated = new Event();

    constructor()
    {
        super();
        this.external = null;

        this.user_name = null;
        this.user_nick = null;
        this.user_external = null;

        this.league_id = null;
        this.class_id = null;

        this.status = null;

        this.timestamp_creation = null;
        this.timestamp_review = null;

        this.assigned_number = null;
        this.car_shortname = null;
        this.car_power = null;
        this.driver_name = null;

        this.comment = null;
    }

    getExternal()
    {
        return this.external;
    }

    getUserName()
    {
        return this.user_name;
    }
    getUserNick()
    {
        return this.user_nick;
    }
    getUserExternal()
    {
        return this.user_external;
    }

    getLeague()
    {
        return this.league_id;
    }

    getClass()
    {
        return this.class_id;
    }

    getStatus()
    {
        return this.status;
    }

    getTimestampCreation()
    {
        return this.timestamp_creation;
    }

    getTimestampReview()
    {
        return this.timestamp_review;
    }

    getAssignedNumber()
    {
        return this.assigned_number;
    }

    getCarShortname()
    {
        return this.car_shortname;
    }
    getCarPower()
    {
        return this.car_power;
    }

    getDriverName()
    {
        return this.driver_name;
    }

    getComment()
    {
        return this.comment;
    }

    Cancel()
    {
        this.OnCancelRequested.callback(this, null);
    }

    setAssignedNumber(value)
    {
        this.assigned_number = value;
    }

    Accept()
    {
        this.OnAcceptRequested.callback(this, null);
    }    

    Reject()
    {
        this.OnRejectRequested.callback(this, null);
    }    

    createCreateChat()
    {
        let retval = new CreateChat();

        retval.reference_type = "APPLICATION";
        retval.reference_id = this.external;

        this.onChatCreated.callback(this, retval);

        return retval;
    }
}
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import autoBind from 'react-autobind';
import './Header.css';
import HelpIcon from './common/HelpIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs, faAngry } from '@fortawesome/free-solid-svg-icons'
import scslogo from 'images/logo/scslogo.png';

class Header extends Component {
  static defaultProps = {
    settingsRequested: (e) => {},
    logoutRequested: (e) => {}
  }

  constructor(props) {
    super(props)
    autoBind(this);
    this.state = this.processProps(props);
  }

  componentWillReceiveProps(props)
  {
    this.setState(this.processProps(props));
  }

  processProps(props)
  {
    return {};
  }

  render() {
    var personalized = this.props.session.getUser() != null;
    return (
        <header className="u-align-center-sm u-align-center-xs u-clearfix u-header u-header no-print" id="sec-1051">
          <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
            <a href="https://scsclub.pl" className="u-align-left-sm u-align-left-xs u-image u-logo u-image-1" data-image-width="148" data-image-height="70">
              <img src={scslogo} className="u-logo-image u-logo-image-1" data-image-width="63" />
            </a>
          <div className="u-align-right-sm u-align-right-xs u-social-icons u-spacing-10 u-social-icons-1">
            <a className="u-social-url" target="_blank" href="https://facebook.com/Grand-Prix-Polski-14-Mili-1627123787508995">
              <span className="u-icon u-icon-circle u-social-facebook u-social-type-logo u-icon-1">
                <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112">
                  <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-0288"></use>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content" viewBox="0 0 112 112" x="0px" y="0px" id="svg-0288"><path d="M75.5,28.8H65.4c-1.5,0-4,0.9-4,4.3v9.4h13.9l-1.5,15.8H61.4v45.1H42.8V58.3h-8.8V42.4h8.8V32.2 c0-7.4,3.4-18.8,18.8-18.8h13.8v15.4H75.5z"></path>
                </svg>
              </span>
            </a>
            {!personalized && 
              <Link to='/account/login' className="u-social-url" >
                <FontAwesomeIcon icon={faCogs} className="u-icon u-icon-circle u-social-facebook u-social-type-logo u-icon-1" />
              </Link>
            }
            {personalized && 
              <FontAwesomeIcon icon={faAngry} className="u-icon u-icon-circle u-social-facebook u-social-type-logo u-icon-1" onClick={() => this.props.logoutRequested() }/>
            }
          </div>

          <nav className="u-align-left u-menu u-menu-dropdown u-offcanvas u-menu-1">
            <div className="menu-collapse" style={{fontSize: "1rem"}}>
              <a className="u-button-style u-nav-link" href="#">
                <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 302 302"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-8a8f"></use></svg>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="svg-8a8f" x="0px" y="0px" viewBox="0 0 302 302" style={{enableBackground: "new 0 0 302 302"}} xmlSpace="preserve" className="u-svg-content"><g><rect y="36" width="302" height="30"></rect><rect y="236" width="302" height="30"></rect><rect y="136" width="302" height="30"></rect></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
              </a>
            </div>
          <div className="u-custom-menu u-nav-container">
            <ul className="u-nav u-unstyled u-nav-1">
              <li className="u-nav-item">
                  <Link to='/' className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" style={{padding: "10px 20px"}}>Głowna</Link>
              </li>
              <li className="u-nav-item">
                <Link to='/events' className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" style={{padding: "10px 20px"}}>Wydarzenia</Link>
              </li>
              {/* <li className="u-nav-item">
                <Link to='/results' className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" style={{padding: "10px 20px"}}>Wyniki</Link>
              </li>
              <li className="u-nav-item">
                <Link to='/drivers' className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" style={{padding: "10px 20px"}}>Zawodnicy</Link>
              </li>
              <li className="u-nav-item">
                <Link to='/highscores' className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" style={{padding: "10px 20px"}}>Rekordy</Link>
              </li> */}
            </ul>
          </div>
          <div className="u-custom-menu u-nav-container-collapse">
            <div className="u-align-center u-black u-container-style u-inner-container-layout u-opacity u-opacity-95 u-sidenav">
              <div className="u-sidenav-overflow">
                <div className="u-menu-close"></div>
                  <ul className="u-align-center u-nav u-popupmenu-items u-unstyled u-nav-2">
                    <li className="u-nav-item">
                      <Link to='/' className="u-button-style u-nav-link" style={{padding: "10px 20px"}}>Głowna</Link>
                    </li>
                    <li className="u-nav-item">
                      <Link to='/events' className="u-button-style u-nav-link" style={{padding: "10px 20px"}}>Wydarzenia</Link>
                    </li>
                    <li className="u-nav-item">
                      <Link to='/results' className="u-button-style u-nav-link" style={{padding: "10px 20px"}}>Wyniki</Link>
                    </li>
                    <li className="u-nav-item">
                      <Link to='/drivers' className="u-button-style u-nav-link" style={{padding: "10px 20px"}}>Zawodnicy</Link>
                    </li>
                    <li className="u-nav-item">
                      <Link to='/highscores' className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" style={{padding: "10px 20px"}}>Rekordy</Link>
                    </li>
                  </ul>
              </div>
            </div>
            <div className="u-black u-menu-overlay u-opacity u-opacity-70"></div>
          </div>
        </nav>
      </div>
      </header>
    );
  }
}

export default Header;
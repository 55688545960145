import IAttendance from "../../../sdk/data/attendance/IAttendance";

export class Attendance extends IAttendance
{
    constructor()
    {
        super();
        this.token = null;
        this.type = null;

        this.external = null;        
        this.registered = null;
        this.count = null;
        this.is_registration_open = null;
    }

    Query()
    {
        this.OnQueryRequested.callback(this, null);
    }

    getExternal()
    {
        return this.external;
    }

    getRegistered()
    {
        return this.registered;
    }

    getCount()
    {
        return this.count;
    }

    getIsRegistrationOpen()
    {
        return this.is_registration_open;
    }
}
import INextRace from "../../../sdk/data/livescreen/INextRace";

export class NextRace extends INextRace
{
    constructor()
    {
        super();
        this.has_results = null;
        this.left = null;
        this.right = null;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    hasResults()
    {
        return this.has_results;
    }

    getLeft()
    {
        return this.left;
    }

    getRight()
    {
        return this.right;
    }
}
import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom'
import update from "react-addons-update";

import autoBind from 'react-autobind';
import './ShortcutsScreen.css'
import BaseScreenHeader from '../BaseScreenHeader';
import InputField from '../../components/common/InputField';
import Button from '../../components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faCarSide, faPlusCircle, faMinusCircle, faCogs, faCog, faSave, faBan, faSync } from '@fortawesome/free-solid-svg-icons';
import PhotoBox from '../../components/common/PhotoBox';
import withRouter from '../../utils/withRouter';

class ShortcutsScreen extends BaseScreenHeader {
    static defaultProps = {
        session: null
    }
    constructor(props) {
        super(props)
        autoBind(this);
    }

    bindDelegates() {
        super.bindDelegates();
    }
    unbindDelegates() {
        super.unbindDelegates();
    }

    onInitParameters(bundle) {
        return true;
    }


    renderScreen(child) {
        let user = this.props.session.getUser();

        let shortcuts = [];


        return super.renderScreen(
            <header className="w3-container">
                <div className="w3-container">
                    <h5>Twoje skróty</h5>
                    <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
                        <thead>
                            <tr>
                                <th>Lp</th>
                                <th>Nazwa</th>
                                <th>Widoczność</th>
                                <th>Akcje</th>
                            </tr>
                        </thead>
                        <tbody>
                            {shortcuts}
                        </tbody>
                    </table>
                </div>
            </header>
        );
    }
}
export default withRouter(ShortcutsScreen);
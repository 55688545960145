import IServiceRequest from '../../connect/IServiceRequest'

export default class SetNextRaceCommit extends IServiceRequest
{
    constructor()
    {
        super('www/livescreen/set_next_race');
    }

    setLeague(value)
    {
        this.appendRequest("league", value);
    }
    setEvent(value)
    {
        this.appendRequest("event", value);
    }
    setLeft(value)
    {
        this.appendRequest("left", value);
    }
    setRight(value)
    {
        this.appendRequest("right", value);
    }
}
import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import './GarageScreen.css'
import { DrawBottomRight } from '../LayoutHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faCarSide, faPlusCircle, faMinusCircle, faCogs, faCog, faSave, faBan, faSync } from '@fortawesome/free-solid-svg-icons';
import PhotoBox from '../../components/common/PhotoBox';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export const GarageScreen = ({session}) => {
    const [userName, setUserName] = useState(null)
    const [selectedVehicle, setSelectedVehicle] = useState(0);
    const [vehicles, setVehicles] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editModeSave, setEditModeSave] = useState(false);

    const [loaderLeaderboard, setLoaderLeaderboard] = useState(false);
    const [loaderTimeslip, setLoaderTimeslip] = useState(false);
    const [loaderLivecam, setLoaderLivecam] = useState(false);
    const [loaderProfile, setLoaderProfile] = useState(false);
   
    const [carShortname, setCarShortname] = useState(null);
    const [carPower, setCarPower] = useState(null);
    const [carMass, setCarMass] = useState(null);
    const [carDriver, setCarDriver] = useState(null);
    const [carDescription, setCarDescription] = useState(null);
    const [carTeam, setCarTeam] = useState(null);

    const [isAsyncOperation, setAsyncOperation] = useState(0);
    const [commitErrors, setCommitErrors] = useState(null);

    useEffect( () => {
        setUserName(session?.getUser()?.getName())
    }, [])
    
    useEffect( () => {
        const commitRequested = ( sender, params) => {
            setAsyncOperation(true)
        }
        const commitFinished = ( sender, params) => {
            setAsyncOperation(false)

            let vehicles = [];

            let data = sender.getVehicles();
            if ( data )
            {
                let size = data.length;
                for ( var i = 0; i < size; ++i )
                {
                    let ob = data[i];
    
                    let vehicle = {}
                    vehicle.car_external = ob.getCarExternal();
                    vehicle.car_shortname = ob.getCarShortname();
                    vehicle.league_shortname = ob.getLeagueShortname();
                    vehicle.car_power = ob.getCarPower();
                    vehicle.car_mass = ob.getCarMass();
                    vehicle.car_driver = ob.getCarDriver();
                    vehicle.car_description = ob.getCarDescription();
                    vehicle.car_team = ob.getCarTeam();
                    vehicle.assigned_number = ob.getAssignedNumber();
    
                    vehicle.picture_leaderboard = ob.getPictureLeaderboard();
                    vehicle.picture_timeslip = ob.getPictureTimeslip();
                    vehicle.picture_livecam = ob.getPictureLivecam();
                    vehicle.picture_profile = ob.getPictureProfile();
    
                    vehicle.ob = ob;
    
                    vehicles.push(vehicle);
                }
            }

            setVehicles(vehicles);
            setSelectedVehicle(0);
        }
        const commitError = ( sender, error) => {
            setAsyncOperation(false)
            setCommitErrors(error.message)
        }
        var action = session.createVehicleList();
        action.OnCommitRequested.register(commitRequested);
        action.OnCommitFinished.register(commitFinished);
        action.OnCommitError.register(commitError);
        action.Commit();
    }, [])

    const selectVehicle = (idx) =>
    {
        if ( selectedVehicle == idx )
        {
            idx = -1;
        }
        setSelectedVehicle(idx);
        setEditMode(false);
    }

    const uploadImageLeaderboard = (ob, picture) =>
    {
        const commitRequested = (sender, params) => {
            setAsyncOperation(true);
            setLoaderLeaderboard(true);
        }
        const commitFinished = (sender, params) => {
            setAsyncOperation(false);
            setLoaderLeaderboard(false);

                
            let car_external = sender.getToken();
            let url = sender.getPictureLeaderboard();

            let _vehicles = vehicles;
            for ( let i = 0; i < _vehicles.length; ++i )
            {
                let ob = _vehicles[i];

                if ( ob.car_external != car_external )
                {
                    continue;
                }

                ob.picture_leaderboard = url;
            }

            setVehicles(vehicles);
        }
        const commitError = (sender, error) => {
            setAsyncOperation(false);
            setLoaderLeaderboard(false);
            setCommitErrors(error.message)
        }
        var action = ob.createVehicleImageLeaderboard();
        action.setData(picture);

        action.OnCommitRequested.register(commitRequested);
        action.OnCommitFinished.register(commitFinished);
        action.OnCommitError.register(commitError);

        action.Commit();
    }

    const uploadImageTimeslip = (ob, picture) =>
    {
        const commitRequested = (sender, params) => {
            setAsyncOperation(true);
            setLoaderTimeslip(true);
        }
        const commitFinished = (sender, params) => {
            setAsyncOperation(false);
            setLoaderTimeslip(false);

                
            let car_external = sender.getToken();
            let url = sender.getPictureTimeslip();

            let _vehicles = vehicles;
            for ( let i = 0; i < _vehicles.length; ++i )
            {
                let ob = _vehicles[i];

                if ( ob.car_external != car_external )
                {
                    continue;
                }

                ob.picture_timeslip = url;
            }

            setVehicles(vehicles);
        }
        const commitError = (sender, error) => {
            setAsyncOperation(false);
            setLoaderTimeslip(false);
            setCommitErrors(error.message)
        }
        var action = ob.createVehicleImageTimeslip();
        action.setData(picture);

        action.OnCommitRequested.register(commitRequested);
        action.OnCommitFinished.register(commitFinished);
        action.OnCommitError.register(commitError);

        action.Commit();
    }

    
    const uploadImageLivecam = (ob, picture) =>
    {
        const commitRequested = (sender, params) => {
            setAsyncOperation(true);
            setLoaderLivecam(true);
        }
        const commitFinished = (sender, params) => {
            setAsyncOperation(false);
            setLoaderLivecam(false);

                
            let car_external = sender.getToken();
            let url = sender.getPictureLivecam();

            let _vehicles = vehicles;
            for ( let i = 0; i < _vehicles.length; ++i )
            {
                let ob = _vehicles[i];

                if ( ob.car_external != car_external )
                {
                    continue;
                }

                ob.picture_livecam = url;
            }

            setVehicles(vehicles);
        }
        const commitError = (sender, error) => {
            setAsyncOperation(false);
            setLoaderLivecam(false);
            setCommitErrors(error.message)
        }
        var action = ob.createVehicleImageLivecam();
        action.setData(picture);

        action.OnCommitRequested.register(commitRequested);
        action.OnCommitFinished.register(commitFinished);
        action.OnCommitError.register(commitError);

        action.Commit();
    }

    const uploadImageProfile = (ob, picture) =>
    {
        const commitRequested = (sender, params) => {
            setAsyncOperation(true);
            setLoaderProfile(true);
        }
        const commitFinished = (sender, params) => {
            setAsyncOperation(false);
            setLoaderProfile(false);

                
            let car_external = sender.getToken();
            let url = sender.getPictureProfile();

            let _vehicles = vehicles;
            for ( let i = 0; i < _vehicles.length; ++i )
            {
                let ob = _vehicles[i];

                if ( ob.car_external != car_external )
                {
                    continue;
                }

                ob.picture_profile = url;
            }

            setVehicles(vehicles);
        }
        const commitError = (sender, error) => {
            setAsyncOperation(false);
            setLoaderProfile(false);
            setCommitErrors(error.message)
        }
        var action = ob.createVehicleImageProfile();
        action.setData(picture);

        action.OnCommitRequested.register(commitRequested);
        action.OnCommitFinished.register(commitFinished);
        action.OnCommitError.register(commitError);

        action.Commit();
    }


    const renderVehicleRequest = () =>
    {
        return (
            <div className="w3-container w3-float-topright">
                <h5><Link to='/vehicle/register'><FontAwesomeIcon icon={faPlusCircle} /> Zgłoś pojazd</Link></h5>
            </div>
        )
    }


    const saveCarData = (car) => 
    {
        const commitRequested = (sender, params) => {
            setAsyncOperation(true);
            setEditModeSave(true);
        }
        const commitFinished = (sender, params) => {
            setAsyncOperation(false);
            setEditModeSave(false);

            let car_external = sender.getToken();

            let _vehicles = vehicles;
            for ( let i = 0; i < _vehicles.length; ++i )
            {
                let ob = _vehicles[i];

                if ( ob.car_external != car_external )
                {
                    continue;
                }

                ob.car_shortname = sender.getCarShortname();
                ob.car_description = sender.getCarDescription();

                ob.car_power = sender.getCarPower();
                ob.car_mass = sender.getCarMass();

                ob.car_driver = sender.getCarDriver();
                ob.car_team = sender.getCarTeam();
                break;
            }

            setVehicles(_vehicles);
        }
        const commitError = (sender, error) => {
            setAsyncOperation(false);
            setEditModeSave(false);
            setCommitErrors(error.message)
        }
        let action = car.createVehicleUpdate();

        action.setCarShortname(carShortname);
        action.setCarDescription(carDescription);
        
        action.setCarPower(carPower);
        action.setCarMass(carMass);
        
        action.setCarDriver(carDriver);
        action.setCarTeam(carTeam);
        
        action.OnCommitRequested.register(commitRequested);
        action.OnCommitFinished.register(commitFinished);
        action.OnCommitError.register(commitError);

        action.Commit();
    }


    const toggleEditMode = () =>
    {
        let value = !editMode;
        setEditMode(value);

        if ( value )
        {
            let data = vehicles[selectedVehicle];
            setCarShortname(data.car_shortname);
            setCarPower(data.car_power);
            setCarMass(data.car_mass);
            setCarDriver(data.car_driver);
            setCarDescription(data.car_description);
            setCarTeam(data.car_team);
        }
        else
        {
            setCarShortname(null);
            setCarPower(null);
            setCarMass(null);
            setCarDriver(null);
            setCarDescription(null);
            setCarTeam(null);
        }
    }

    const renderEditVehicle = (car) =>
    {
        if ( editMode )
        {
            if ( editModeSave )
            {
                return (
                    <div className="w3-container w3-float-topright w3-inline">
                        <h5 ><FontAwesomeIcon icon={faSync} className="w3-spin" /> Zapisywanie..</h5>
                    </div>
                )
            }
            return (
                <div className="w3-container w3-float-topright w3-inline">
                    <h5 onClick={() => { toggleEditMode() }} className="w3-clickable" ><FontAwesomeIcon icon={faBan} /> Anuluj</h5>
                    <h5 onClick={() => { saveCarData(car)} } className="w3-clickable"><FontAwesomeIcon icon={faSave} /> Zapisz</h5>
                </div>
            )
        }
        return (
            <div className="w3-container w3-float-topright">
                <h5 onClick={() => toggleEditMode()} className="w3-clickable"><FontAwesomeIcon icon={faCog} /> Edytuj pojazd</h5>
            </div>
        )
    }


    const renderVehicle = (data)  =>
    {
        let action = data.ob;
        return (
            <div key={data.car_external} className="w3-anchor">
                <h5>Szczegóły pojazdu:</h5>
                <table className="w3-table w3-white">
                    <tbody>
                        <tr>
                            <td>Nazwa</td>
                            <td>
                                {editMode && 
                                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                                        <InputLabel htmlFor="car-name" shrink>Nazwa</InputLabel>
                                        <OutlinedInput label="Nazwa" variant="standard" type={'text'} value={carShortname} onChange={(ev) => { setCarShortname(ev.target.value)}} id="car-name" />
                                    </FormControl>}
                                {!editMode &&  data.car_shortname }
                            </td>
                        </tr>
                        <tr>
                            <td>Przypisana liga</td>
                            <td>{data.league_shortname}</td>
                        </tr>
                        <tr>
                            <td>Numer startowy</td>
                            <td>{data.assigned_number}</td>
                        </tr>
                        <tr>
                            <td>Moc auta</td>
                            <td>
                                {editMode && 
                                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                                        <InputLabel htmlFor="car-power" shrink>Moc auta</InputLabel>
                                        <OutlinedInput label="Moc auta" variant="standard" type={'number'} value={carPower} onChange={(ev) => { setCarPower(ev.target.value)}} id="car-power" />
                                    </FormControl>
                                }
                                {!editMode && data.car_power}
                            </td>
                        </tr>
                        <tr>
                            <td>Masa auta</td>
                            <td>
                                {editMode && 
                                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                                        <InputLabel htmlFor="car-mass" shrink>Masa auta</InputLabel>
                                        <OutlinedInput label="Masa auta" variant="standard" type={'number'} value={carMass} onChange={(ev) => { setCarMass(ev.target.value)}} id="car-mass" />
                                    </FormControl>
                                }
                                {!editMode && data.car_mass }
                            </td>
                        </tr>
                        <tr>
                            <td>Kierowca</td>
                            <td>
                                {editMode && 
                                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                                        <InputLabel htmlFor="car-driver" shrink>Kierowca</InputLabel>
                                        <OutlinedInput label="Kierowca" variant="standard" type={'text'} value={carDriver} onChange={(ev) => { setCarDriver(ev.target.value)}} id="car-mass" />
                                    </FormControl>
                                }
                                {!editMode && data.car_driver }
                            </td>
                        </tr>
                        <tr>
                            <td>Opis</td>
                            <td>
                                {editMode && 
                                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                                        <InputLabel htmlFor="car-description" shrink>Opis</InputLabel>
                                        <OutlinedInput label="Opis" variant="standard" type={'text'} value={carDescription} onChange={(ev) => { setCarDescription(ev.target.value)}} id="car-description" />
                                    </FormControl>
                                }
                                {!editMode && data.car_description }
                            </td>                            
                        </tr>
                        <tr>
                            <td>Nazwa druzyny</td>
                            <td>
                                {editMode && 
                                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                                        <InputLabel htmlFor="car-team" shrink>Nazwa druzyny</InputLabel>
                                        <OutlinedInput label="Nazwa druzyny" variant="standard" type={'text'} value={carTeam} onChange={(ev) => { setCarTeam(ev.target.value)}} id="car-team" />
                                    </FormControl>
                                }
                                {!editMode && data.car_team }
                            </td>
                        </tr>
                    </tbody>
                </table>
                {renderEditVehicle(data.ob)}
                <div className="w3-container">
                    <h5>Zdjęcia</h5>
                    <div className="w3-container garage-photo-container">
                        <PhotoBox caption="Zdjęcie kierowcy do tabeli wynikow" loader={loaderLeaderboard} url={data.picture_leaderboard} allowEdit={true} newImageSelected={(data) => uploadImageLeaderboard(action, data)} />
                        <PhotoBox caption="Zdjęcie auta do timeslip" loader={loaderTimeslip} url={data.picture_timeslip} allowEdit={true} newImageSelected={(data) => uploadImageTimeslip(action, data)} />
                        <PhotoBox caption="Zdjęcie kierowcy dla relacji zawodów" loader={loaderLivecam} url={data.picture_livecam} allowEdit={true} newImageSelected={(data) => uploadImageLivecam(action, data)} />
                        <PhotoBox caption="Zdjęcie auta do tabeli wynikow" loader={loaderProfile} url={data.picture_profile} allowEdit={true} newImageSelected={(data) => uploadImageProfile(action, data)} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Box sx={{position: "relative"}}>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={commitErrors != null} autoHideDuration={6000} onClose={() => {  setCommitErrors(null); }} sx={{minWidth: '50%'}} >
                <Alert severity="error" sx={{ width: '100%' }}>{commitErrors}</Alert>
            </Snackbar>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isAsyncOperation} >
                <CircularProgress color="inherit" />
            </Backdrop>
            {null == vehicles && 
                <Box>
                    <h5>Twój garaż <b>{userName}</b></h5>
                    <div className="w3-container">
                        <h6>Trwa wczytywanie..</h6>
                    </div>
                </Box>
            }
            {null != vehicles && vehicles.length < 1 && 
                <Box>
                    <h5>Twój garaż <b>{userName}</b></h5>
                    <div className="w3-container">
                        <h6>Brak przypisanych numerow startowych</h6>
                    </div>
                    <div className="w3-container w3-float-topright">
                        <h5><Link to='/vehicle/register'><FontAwesomeIcon icon={faPlusCircle} /> Zgłoś pojazd</Link></h5>
                    </div>
                </Box>
            }
            {null != vehicles && vehicles.length > 0 && 
                <Box>
                    <h5>Twój garaż <b>{userName}</b></h5>
                    <h5>Przypisane pojazdy</h5>
                    <div className="w3-responsive-table">
                        <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
                            <thead>
                                <tr>
                                    <th>Nazwa</th>
                                    <th>Liga</th>
                                    <th>Numer startowy</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vehicles.map((x, idx) => {
                                    let extraStyle = null;
                                    if ( idx == selectedVehicle )
                                    {
                                        extraStyle = 'selected-row';
                                    }
                                    return (
                                        <tr key={x.car_external} className={extraStyle} onClick={(e) => selectVehicle(idx)}>
                                            <td data-title="Nazwa">{x.car_shortname}</td>
                                            <td data-title="Liga">{x.league_shortname}</td>
                                            <td data-title="Numer startowy">{x.assigned_number}</td>
                                        </tr>)
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    {selectedVehicle >= 0 && selectedVehicle < vehicles.length && 
                        <Box>
                            {renderVehicle(vehicles[selectedVehicle])}
                        </Box>
                    }
                    <div className="w3-container w3-float-topright">
                        <h5><Link to='/vehicle/register'><FontAwesomeIcon icon={faPlusCircle} /> Zgłoś pojazd</Link></h5>
                    </div>
                </Box>
            }
        </Box>
    )
}

import Event from '../../property/Event';

export default class IVehicleList
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    Commit()
    {
        throw new TypeError("Method not implemented");
    }

    getVehicles()
    {
        throw new TypeError("Method not implemented");
    }
}
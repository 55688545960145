import IServiceRequest from "../../../connect/IServiceRequest";

export default class RawResultQuery extends IServiceRequest
{
    constructor()
    {
        super('www/result/get');
    }
    
    setToken(value)
    {
        this.appendRequest("token", value);
    }
}

import Event from '../property/Event';

export default class IRaceDetails
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    setRaceId(id)
    {
        throw new TypeError("Method not implemented");
    }
    Commit()
    {
        throw new TypeError("Method not implemented");
    }

    getLeagueId()
    {
        throw new TypeError("Method not implemented");
    }

    getLeagueName()
    {
        throw new TypeError("Method not implemented");
    }

    getEventId()
    {
        throw new TypeError("Method not implemented");
    }

    getEventName()
    {
        throw new TypeError("Method not implemented");
    }

    getEventLocation()
    {
        throw new TypeError("Method not implemented");
    }

    getTimestampRace()
    {
        throw new TypeError("Method not implemented");
    }

    getLeft()
    {
        throw new TypeError("Method not implemented");
    }
    getRight()
    {
        throw new TypeError("Method not implemented");
    }
}
import Event from "../../../property/Event";

export default class IUploadResults
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    getExternal()
    {
        throw new TypeError("Method not implemented");
    }
    
    setData(data)
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }
}
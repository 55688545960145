export default class IChatMessage
{
    getExternal()
    {
        throw new TypeError("Method not implemented");
    }

    getAuthorName()
    {
        throw new TypeError("Method not implemented");
    }

    getAvatar()
    {
        throw new TypeError("Method not implemented");
    }

    getTimestamp()
    {
        throw new TypeError("Method not implemented");
    }

    getMessage()
    {
        throw new TypeError("Method not implemented");
    }
}
import IVehicleImageTimeslip from "../../../sdk/data/vehicle/IVehicleImageTimeslip";

export class VehicleImageTimeslip extends IVehicleImageTimeslip
{
    constructor(token)
    {
        super();
        this.token = token;
        this.data = null;

        this.picture_timeslip = null;
    }

    setData(data)
    {
        this.data = data;
    }
    
    getToken()
    {
        return this.token;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getPictureTimeslip()
    {
        return this.picture_timeslip;
    }
}
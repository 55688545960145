import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import './RaceScreen.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faFlagCheckered, faStopwatch, faStop, faObjectGroup, faCar, faUserAstronaut, faUser, faGlobeEurope, faCalendar } from '@fortawesome/free-solid-svg-icons'
import Loader from '../../components/common/Loader';
import CommentBox from '../../components/common/CommentBox';
import MovieBox from '../../components/common/MovieBox';
import Utils from '../../utils/Utils';

import Box from '@mui/material/Box';

export const RaceScreen = ({session}) => {
    const urlParams = useParams();
    const navigate = useNavigate();
    
    const [ raceId, setRaceId ] = useState(undefined);

    const [ isAsyncOperation, setAsyncOperation ] = useState(false);


    const [ leagueId, setLeagueId ] = useState(null)
    const [ leagueName, setLeagueName ] = useState(null)
    const [ eventId, setEventId ] = useState(null)
    const [ eventName, setEventName ] = useState(null)
    const [ eventLocation, setEventLocation ] = useState(null)
    const [ timestampRace, setTimestampRace ] = useState(null)
    const [ leftPlayerNumber, setLeftPlayerNumber ] = useState(null)
    const [ leftPlayerName, setLeftPlayerName ] = useState(null)
    const [ leftPlayerPicture, setLeftPlayerPicture ] = useState(null)
    const [ rightPlayerNumber, setRightPlayerNumber ] = useState(null)
    const [ rightPlayerName, setRightPlayerName ] = useState(null)
    const [ rightPlayerPicture, setRightPlayerPicture ] = useState(null)
    const [ raceData, setRaceData ] = useState(null)


    useEffect(() => {
        setRaceId(urlParams.raceId);
    }, [urlParams])

    useEffect(() => {
        const onCommitRequested = (sender, params) => {
            setAsyncOperation(true)
        }
        const onCommitFinished = (sender, params) => {
            setAsyncOperation(false);
            
            let action = sender;

            setLeagueId(action.getLeagueId());
            setLeagueName(action.getLeagueName());
            setEventId(action.getEventId());
            setEventName(action.getEventName());
            setEventLocation(action.getEventLocation());
        
            setTimestampRace(action.getTimestampRace());
            
            var _leftPlayerNumber = null;
            var _leftPlayerName = null;
            var _leftPlayerPicture = null;
        
            var _rightPlayerNumber = null;
            var _rightPlayerName = null;
            var _rightPlayerPicture = null;
        
            let left = action.getLeft();
            if ( null != left )
            {
                _leftPlayerNumber = left.getPlayerNumber();
                _leftPlayerName = left.getPlayerName();
                _leftPlayerPicture = left.getPlayerPicture();
            }
        
            let right = action.getRight()
            if ( null != right )
            {
                _rightPlayerNumber = right.getPlayerNumber();
                _rightPlayerName = right.getPlayerName();
                _rightPlayerPicture = right.getPlayerPicture();
            }

            setLeftPlayerNumber(_leftPlayerNumber);
            setLeftPlayerName(_leftPlayerName);
            setLeftPlayerPicture(_leftPlayerPicture);

            setRightPlayerNumber(_rightPlayerNumber);
            setRightPlayerName(_rightPlayerName);
            setRightPlayerPicture(_rightPlayerPicture);
        
            let race_data = [];
        
            let has_race_result = false;
            // RACE
            do
            {
                let record = { caption: 'RACE' };
                let tleft = null;
                let tright = null;
                if ( null != left )
                {
                    tleft = left.getRaceTime();
                    record.left = Utils.time2string(left.getRaceTime());
                }
                if ( null != right )
                {
                    tright = right.getRaceTime();
                    record.right = Utils.time2string(right.getRaceTime());
                }
                if ( null == record.left && null == record.right )
                {
                    break;
                }
                record.highlight = compareTime(tleft, tright);
        
                race_data.push(record);
                has_race_result = true;
            } while(false);
        
            // et+rt 1/4
            do
            {
                if ( has_race_result )
                {
                    break;
                }
                let record = { caption: 'ET+RT 1/4' };
                let tleft = null;
                let tright = null;
                if ( null != left )
                {
                    tleft = Utils.addTimes(left.getTime1320ft(), left.getReactionTime());
                    record.left = Utils.concatTimeSpeed(tleft, left.getSpeed1320ft());
                }
                if ( null != right )
                {
                    tright = Utils.addTimes(right.getTime1320ft(), right.getReactionTime());
                    record.right = Utils.concatTimeSpeed(tright, right.getSpeed1320ft());
                }
                if ( null == record.left && null == record.right )
                {
                    break;
                }
                record.highlight = compareTime(tleft, tright);
        
                race_data.push(record);
            } while(false);
        
            // et 1/4
            do
            {
                let record = { caption: 'ET 1/4' };
                let tleft = null;
                let tright = null;
                if ( null != left )
                {
                    tleft = left.getTime1320ft();
                    record.left = Utils.time2string(left.getTime1320ft());
                }
                if ( null != right )
                {
                    tright = right.getTime1320ft();
                    record.right = Utils.time2string(right.getTime1320ft());
                }
                if ( null == record.left && null == record.right )
                {
                    break;
                }
                record.highlight = compareTime(tleft, tright);
        
                race_data.push(record);
            } while(false);
            // speed 1/4
            do
            {
                let record = { caption: 'Speed 1/4' };
                let tleft = null;
                let tright = null;
                if ( null != left )
                {
                    tleft = left.getSpeed1320ft();
                    record.left = Utils.speed2string(left.getSpeed1320ft());
                }
                if ( null != right )
                {
                    tright = right.getSpeed1320ft();
                    record.right = Utils.speed2string( right.getSpeed1320ft());
                }
                if ( null == record.left && null == record.right )
                {
                    break;
                }
                record.highlight = compareSpeed(tleft, tright);
        
                race_data.push(record);
            } while(false);
        
            // et 1000ft
            do
            {
                let record = { caption: 'ET 1000ft' };
                let tleft = null;
                let tright = null;
                if ( null != left )
                {
                    tleft = left.getTime1000ft();
                    record.left = Utils.time2string(left.getTime1000ft());
                }
                if ( null != right )
                {
                    tright = right.getTime1000ft();
                    record.right = Utils.time2string(right.getTime1000ft());
                }
                if ( null == record.left && null == record.right )
                {
                    break;
                }
                record.highlight = compareTime(tleft, tright);
        
                race_data.push(record);
            } while(false);
            // speed 1000ft
            do
            {
                let record = { caption: 'Speed 1000ft' };
                let tleft = null;
                let tright = null;
                if ( null != left )
                {
                    tleft = left.getSpeed1000ft();
                    record.left = Utils.speed2string(left.getSpeed1000ft());
                }
                if ( null != right )
                {
                    tright = right.getSpeed1000ft();
                    record.right = Utils.speed2string( right.getSpeed1000ft());
                }
                if ( null == record.left && null == record.right )
                {
                    break;
                }
                record.highlight = compareSpeed(tleft, tright);
        
                race_data.push(record);
            } while(false);
            // rt
            {
                let record = { caption: 'RT' };
                let tleft = null;
                let tright = null;
                if ( null != left )
                {
                    tleft = left.getReactionTime();
                    record.left = Utils.time2string(tleft);
                }
                if ( null != right )
                {
                    tright = right.getReactionTime();
                    record.right = Utils.time2string(tright);
                }
        
                record.highlight = compareTime(tleft, tright);
        
                race_data.push(record);
            }
            // et 1/8
            {
                let record = { caption: 'ET 1/8' };
                let tleft = null;
                let tright = null;
                if ( null != left )
                {
                    tleft = left.getTime660ft();
                    record.left = Utils.time2string(left.getTime660ft());
                }
                if ( null != right )
                {
                    tright = right.getTime660ft();
                    record.right = Utils.time2string(right.getTime660ft());
                }
        
                record.highlight = compareTime(tleft, tright);
        
                race_data.push(record);
            }
            // speed 1/8
            {
                let record = { caption: 'Speed 1/8' };
                let tleft = null;
                let tright = null;
                if ( null != left )
                {
                    tleft = left.getSpeed660ft();
                    record.left = Utils.speed2string(left.getSpeed660ft());
                }
                if ( null != right )
                {
                    tright = right.getSpeed660ft();
                    record.right = Utils.speed2string( right.getSpeed660ft());
                }
        
                record.highlight = compareSpeed(tleft, tright);
        
                race_data.push(record);
            }
            // et 60ft
            {
                let record = { caption: '60ft' };
                let tleft = null;
                let tright = null;
                if ( null != left )
                {
                    tleft = left.getTime60ft();
                    record.left = Utils.time2string(tleft);
                }
                if ( null != right )
                {
                    tright = right.getTime60ft();
                    record.right = Utils.time2string(tright);
                }
        
                record.highlight = compareTime(tleft, tright);
        
                race_data.push(record);
            }
            // klasy aut
            {
                let record = { caption: 'Klasa' };
                if ( null != left )
                {
                    record.left = left.getClassName();
                }
                if ( null != right )
                {
                    record.right = right.getClassName();
                }
        
                record.highlight = 'NONE';
        
                race_data.push(record);
            }
            // druzyny ( jesli sa )
            {
                let record = { caption: 'Druzyna' };
                if ( null != left )
                {
                    record.left = left.getPlayerTeam();
                }
                if ( null != right )
                {
                    record.right = right.getPlayerTeam();
                }
        
                record.highlight = 'NONE';
                if ( null != record.left || null != record.right )
                {
                    race_data.push(record);
                }
            }
            // auta ( jesli sa )
            {
                let record = { caption: 'Auto' };
                if ( null != left )
                {
                    record.left = left.getPlayerCar();
                }
                if ( null != right )
                {
                    record.right = right.getPlayerCar();
                }
        
                record.highlight = 'NONE';
                if ( null != record.left || null != record.right )
                {
                    race_data.push(record);
                }
            }

            setRaceData(race_data);
        }
        const onCommitError = (sender, params) => {
            setAsyncOperation(false);
        }
        if ( !raceId )
        {
            return;
        }

        var action = session.createRaceDetails()
        action.setRaceId(raceId);
    
        action.OnCommitRequested.register(onCommitRequested);
        action.OnCommitFinished.register(onCommitFinished);
        action.OnCommitError.register(onCommitError);
        action.Commit();
    }, [raceId])
  
  const compareTime = (left, right) => {
      if ( null == left && null == right )
      {
          return 'NONE';
      }
      if ( null == right )
      {
          return 'LEFT';
      }
      if ( null == left )
      {
          return 'RIGHT';
      }
      
      let fleft = parseFloat(left);
      let fright = parseFloat(right);

      if ( fleft == fright )
      {
          return 'NONE';
      }
      if ( fleft < fright )
      {
          return 'LEFT';
      }
      return 'RIGHT';
  }

  const compareSpeed = (left, right) => {
      if ( null == left && null == right )
      {
          return 'NONE';
      }
      if ( null == right )
      {
          return 'LEFT';
      }
      if ( null == left )
      {
          return 'RIGHT';
      }
      
      let fleft = parseFloat(left);
      let fright = parseFloat(right);

      if ( fleft == fright )
      {
          return 'NONE';
      }
      if ( fleft > fright )
      {
          return 'LEFT';
      }
      return 'RIGHT';
  }

  const renderRaceData = () => {
    if ( null == raceData )
    {
        return;
    }

    return raceData.map((x) => {
        if ( x.highlight == 'LEFT')
        {
            return (
              <tr>
                  <td className="w3-center"><b>{x.left}</b></td>
                  <td className="w3-center">{x.caption}</td>
                  <td className="w3-center">{x.right}</td>
              </tr>);
        }
        if ( x.highlight == 'RIGHT' )
        {
          return (
              <tr>
                  <td className="w3-center">{x.left}</td>
                  <td className="w3-center">{x.caption}</td>
                  <td className="w3-center"><b>{x.right}</b></td>
              </tr>);
        }
        return(
          <tr>
              <td className="w3-center">{x.left}</td>
              <td className="w3-center">{x.caption}</td>
              <td className="w3-center">{x.right}</td>
          </tr>);
    }) ;
  }

  return ( 
    <Box>
        {null != raceData && 
            <div className="w3-container">
            <h5>Podsumowanie wyścigu</h5>
            <div className="w3-row-padding w3-margin-bottom">
                <div className="w3-third">
                    <div className="w3-container w3-orange w3-text-white w3-padding-16">
                        <div className="w3-right w3-xxxlarge race-screen-player-avatar">
                            {leftPlayerPicture && <img src={leftPlayerPicture} /> }
                            {!leftPlayerPicture && <FontAwesomeIcon icon={faUser} /> }
                        </div>
                        <div className="w3-clear"></div>
                        <h4 className="w3-h4-fixed">{Utils.concatDriverData(leftPlayerName, leftPlayerNumber)}</h4>
                    </div>
                </div>
                <div className="w3-third">
                    <div className="w3-container w3-blue w3-text-white w3-padding-16">
                        <div className="w3-center w3-xxxlarge race-screen-player-avatar">
                            <FontAwesomeIcon icon={faFlagCheckered} />
                        </div>
                        <div className="w3-clear"></div>
                        {timestampRace && 
                            <h4 className="w3-h4-fixed">{timestampRace.format('YYYY-MM-DD HH:mm:ss')}</h4>
                        }
                        {!timestampRace && 
                            <h4 className="w3-h4-fixed"></h4>
                        }
                    </div>
                </div>
                <div className="w3-third">
                    <div className="w3-container w3-orange w3-text-white w3-padding-16">
                        <div className="w3-right w3-xxxlarge race-screen-player-avatar">
                            {rightPlayerPicture && <img src={rightPlayerPicture} /> }
                            {!rightPlayerPicture && <FontAwesomeIcon icon={faUserAstronaut} /> }
                        </div>
                        <div className="w3-clear"></div>
                        <h4 className="w3-h4-fixed">{Utils.concatDriverData(rightPlayerName, rightPlayerNumber)}</h4>
                    </div>
                </div>
            </div>
            <div className="w3-container">
                <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
                    <thead>
                        <tr className="row-captions">
                            <th className="column-data"></th>
                            <th className="column-caption"></th>
                            <th className="column-data"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderRaceData()}
                    </tbody>
                </table>
            </div>
            <div className="w3-row-padding w3-margin-bottom">
                <div className="w3-third">
                    <Link to={'/league/' + leagueId}>
                        <div className="w3-container w3-teal w3-text-white w3-padding-16">
                            <div className="w3-right w3-xxxlarge">
                                <FontAwesomeIcon icon={faFlagCheckered} />
                            </div>
                            <div className="w3-clear"></div>
                            <h4>{leagueName}</h4>
                        </div>
                    </Link>
                </div>
                <div className="w3-third">
                    <Link to={'/event/' + eventId}>
                        <div className="w3-container w3-orange w3-text-white w3-padding-16">
                            <div className="w3-right w3-xxxlarge">
                                <FontAwesomeIcon icon={faCalendar} />
                            </div>
                            <div className="w3-clear"></div>
                            <h4>{eventName}</h4>
                        </div>
                    </Link>
                </div>
                <div className="w3-third">
                    <div className="w3-container w3-green w3-text-white w3-padding-16">
                        <div className="w3-right w3-xxxlarge">
                            <FontAwesomeIcon icon={faGlobeEurope} />
                        </div>
                        <div className="w3-clear"></div>
                        <h4>{eventLocation}</h4>
                    </div>
                </div>
            </div>
            <MovieBox session={session} type={'RACE'} reference={raceId} />
            <CommentBox />
        </div>
        
        }
    </Box>)
}
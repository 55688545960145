import Event from '../../property/Event';

export default class IVehicle
{
    OnQueryRequested = new Event();
    OnQueryFinished = new Event();
    OnQueryError = new Event();

    setExternal( value )
    {
        throw new TypeError("Method not implemented");
    }
    Query()
    {
        throw new TypeError("Method not implemented");
    }

    getExternal()
    {
        throw new TypeError("Method not implemented");
    }
    
    getAssignedNumber()
    {
        throw new TypeError("Method not implemented");
    }

    getLeagueId()
    {
        throw new TypeError("Method not implemented");
    }

    getLeagueShortname()
    {
        throw new TypeError("Method not implemented");
    }

    getLeagueClassId()
    {
        throw new TypeError("Method not implemented");
    }

    getLeagueClassShortname()
    {
        throw new TypeError("Method not implemented");
    }

    getCarExternal()
    {
        throw new TypeError("Method not implemented");
    }

    getCarShortname()
    {
        throw new TypeError("Method not implemented");
    }

    getCarDescription()
    {
        throw new TypeError("Method not implemented");
    }

    getCarDriver()
    {
        throw new TypeError("Method not implemented");
    }

    getCarPower()
    {
        throw new TypeError("Method not implemented");
    }

    getCarMass()
    {
        throw new TypeError("Method not implemented");
    }

    getCarTeam()
    {
        throw new TypeError("Method not implemented");
    }

    getPictureLeaderboard()
    {
        throw new TypeError("Method not implemented");
    }
    getPictureTimeslip()
    {
        throw new TypeError("Method not implemented");
    }
    getPictureLivecam()
    {
        throw new TypeError("Method not implemented");
    }
    getPictureProfile()
    {
        throw new TypeError("Method not implemented");
    }    

    createVehicleImageLeaderboard()
    {
        throw new TypeError("Method not implemented");
    }

    createVehicleImageLivecam()
    {
        throw new TypeError("Method not implemented");
    }

    createVehicleImageProfile()
    {
        throw new TypeError("Method not implemented");
    }

    createVehicleImageTimeslip()
    {
        throw new TypeError("Method not implemented");
    }

    createVehicleUpdate()
    {
        throw new TypeError("Method not implemented");
    }
}
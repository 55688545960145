import React, { Component, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faCarSide, faPlusCircle, faSync, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import Utils from '../../utils/Utils';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { AlertPopup } from '../../components/common/AlertPopup';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const ChangePasswordScreen = ({session}) => {
  const [asyncOperation, setAsyncOperation] = useState(false);
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [newPasswordErrors, setNewPasswordErrors] = useState(null);
  const [commitErrors, setCommitErrors] = useState(null);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const validateNewPassword = (value) => {
    if ( null == value || value.length < 1 )
    {
      setNewPassword(null);
      setNewPasswordErrors("Wprowadź nowe hasło");
      return;
    }
    let errors = Utils.validate_password(value);
    if ( errors )
    {
      setNewPasswordErrors(errors);
    }
    else
    {
      setNewPasswordErrors(null);
    }
    setNewPassword(value);
  }

  const executePasswordChange = () => {
      let errors = [];

      if ( !oldPassword )
      {
          errors.push('Stare haslo nie moze byc puste');
      }     
      
      if ( !newPassword )
      {
          errors.push('Nowe hasło nie może być puste');
      }
      else
      {
        let password_validate = Utils.validate_password(newPassword);
        if ( password_validate )
        {
            errors.push(password_validate);
        }
      }

      if ( errors.length > 0 )
      {
          setCommitErrors(errors);
          return;
      }

      var action = session.createSessionChangePassword();
      action.setOldPassword(oldPassword);
      action.setNewPassword(newPassword);
      
      action.OnCommitRequested.register((sender, params) => { setAsyncOperation(true); });
      action.OnCommitFinished.register((sender, params) => { setAsyncOperation(false); setPasswordChanged(true); });
      action.OnCommitError.register((sender, error) => { setAsyncOperation(false); setCommitErrors(error.message)});

      action.Commit();
  }

  return (
      <header className="w3-container">
        <h5><FontAwesomeIcon icon={faCarSide} /> <b>Zmiana hasła</b></h5>
        <AlertPopup open={passwordChanged} fullScreen={false} title='Haslo zostało zmienione' accept='OK' onAccepted={() => { setPasswordChanged(false); navigate('/account/profile');}  } />
        <Snackbar
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          open={commitErrors != null}
          autoHideDuration={6000}
          onClose={() => { setCommitErrors(null); }}
          message={commitErrors}
        />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={asyncOperation} >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="w3-container login-form">
            <form autoComplete={"true"} onSubmit={(ev) => { ev.preventDefault() } }>
            <div className="w3-container">
                <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                  <InputLabel htmlFor="old-password">Obecne hasło</InputLabel>
                  <OutlinedInput label="Stare hasło" variant="standard" type={showPassword ? 'text' : 'password'} value={oldPassword} onChange={(ev) => { setOldPassword(ev.target.value)}} id="old-password" 
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={(e) => { setShowPassword(!showPassword) }}
                            onMouseDown={(ev) => { ev.preventDefault() }}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      } />
                </FormControl>
              </div>
              <div className="w3-container">
              <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                  <InputLabel htmlFor="new-password">Nowe hasło</InputLabel>
                  <OutlinedInput label="Nowe hasło" variant="standard" type={showPassword ? 'text' : 'password'} value={newPassword} onChange={(ev) => { validateNewPassword(ev.target.value)}} id="old-password" 
                    error={newPasswordErrors ? true : false}
                    helperText={newPasswordErrors}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={(e) => { setShowPassword(!showPassword) }}
                            onMouseDown={(ev) => { ev.preventDefault() }}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      } />

                  {newPasswordErrors && (
                      <FormHelperText error id="password-error">
                        {newPasswordErrors}
                      </FormHelperText>)}
                  </FormControl>
              </div>
              <div className="w3-container">
                <Button variant="contained" color="primary" onClick={executePasswordChange} disabled={asyncOperation || null==newPassword || null != newPasswordErrors }>Zmień</Button>
              </div>
              
            </form>
        </div>
      </header>
    );
}
import IServiceRequest from "../../../connect/IServiceRequest";

export default class EventCreateCommit extends IServiceRequest
{
    constructor()
    {
        super('www/event/add');
    }
    setLeague(value)
    {
        this.appendRequest("league", value);
    }

    setShortname(value)
    {
        this.appendRequest("shortname", value);
    }

    setDescription(value)
    {
        this.appendRequest("description", value);
    }    

    setLocation(value)
    {
        this.appendRequest("location", value);
    }    

    setGps(value)
    {
        this.appendRequest("gps", value);
    }   
    
    setTimestampStart(value)
    {
        this.appendRequest("timestamp_start", value);
    } 

    setTimestampEnd(value)
    {
        this.appendRequest("timestamp_end", value);
    } 
}

import IAttendanceAccept from "../../../sdk/data/attendance/IAttendanceAccept";

export class AttendanceAccept extends IAttendanceAccept
{
    constructor()
    {
        super();
        this.token = null; 

        this.payment_method = null;
        this.payment_amount = null;
        this.payment_date = null;

    }


    setPaymentMethod(method)
    {
        this.payment_method = method;
    }

    setPaymentAmount(amount)
    {
        this.payment_amount = amount;
    }

    setPaymentDate(date)
    {
        this.payment_date = date;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }
}
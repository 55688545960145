import React, { Component } from 'react';
import update from "react-addons-update";
import { Navigate } from 'react-router-dom';

import autoBind from 'react-autobind';
import Loader from '../components/common/Loader';
import './BaseScreen.css'
import PopupAlert from '../components/common/PopupAlert';

class BaseScreen extends Component {

  constructor(props) {
    super(props)
    this.session = props.session
    this.state = { asyncOperation: null, error: false, dialog: null, form: {} };
    autoBind(this);
    console.log('constructor for ' + this)
    if (!this.onInitParameters(this.props)) {
      this.state._close = true;
    }
    if (null == this.state.asyncOperation) {
      this.state.asyncOperation = false;
    }
  }

  componentWillUnmount() {
    if (!this.state._close) {
      this.unbindDelegates();
      this.onPause();
    }
  }

  componentWillReceiveProps(props) {
    this.session = props.session
    this.state.asyncOperation = null;
    this.onCleanupParameters();
    this.onInitParameters(props);
    if (null == this.state.asyncOperation) {
      this.state.asyncOperation = false;
    }
  }

  componentDidMount() {
    if ('parentIFrame' in window) window.parentIFrame.scrollTo(0, 0);
    if (!this.state._close) {
      this.bindDelegates();
      this.onResume();
    }
  }

  onInitParameters(bundle) {
    return true;
  }

  onCleanupParameters() {
    return true;
  }

  bindDelegates() {
  }

  unbindDelegates() {
  }

  onResume() {
  }

  onPause() {
  }

  beginAsyncOperation() {
    if (null == this.state.asyncOperation) {
      this.state.asyncOperation = true;
    }
    else {
      this.setState((oldState) => { return update(oldState, { asyncOperation: { $set: true } }); })
    }
  }

  endAsyncOperation() {
    this.setState((oldState) => { return update(oldState, { asyncOperation: { $set: false } }); })
  }

  showError(_error) {
    var error = {
      code: _error.code,
      text: _error.message
    }
    this.setState((oldState) => { return update(oldState, { error: { $set: error } }); })
  }

  dismissError(e) {
    this.setState((oldState) => { return update(oldState, { error: { $set: null } }); })
  }

  showDialog(dialogFactory) {
    this.setState((oldState) => { return update(oldState, { dialog: { $set: dialogFactory } }); });
  }

  clearDialog() {
    this.setState((oldState) => { return update(oldState, { dialog: { $set: null } }); });
  }

  showMessage(message)
  {
    if ( Array.isArray(message) )
    {
      message = message.join('<br />');
    }
    this.showDialog(() => 
    { 
      return (<PopupAlert text={message} accept='OK' onAccepted={(e) => { 
        this.clearDialog();
       }}  />);
    } );  
  }

  showAndRedirect(message, link)
  {
    if ( !link )
    {
      link = "/";
    }
    this.showDialog(() => 
    { 
      return (<PopupAlert text={message} accept='OK' onAccepted={(e) => { 
        this.setState((oldState) => { return update(oldState, { force_redirect: { $set: link }})})
       }}  />);
    } );
  }

  screenRequiresUser()
  {
    return false;
  }

  validatePermission()
  {
    return true;
  }

  initFormData(data)
  {
    this.state.form = data;
  }
  
  storeFormData(name, value)
  {
    let form = this.state.form;
    form[name] = value;

    this.setState((oldState) => { return update(oldState, { form: { $set: form } }) });
  }

  getFormData(name)
  {
    let form = this.state.form;
    return form[name];
  }

  resetFormData()
  {
    this.setState((oldState) => { return update(oldState, { form: { $set: {} } }) });
  }

  render() {
    if ( this.state.force_redirect )
    {
      return ( <Navigate to={this.state.force_redirect} /> );
    }
    let isLoggedIn = this.props.session.getPersonalized();
    if (this.props.require_user && !isLoggedIn) {
      var NavigateUrl = "/account/login";
      // var url = this.props.location.pathname;
      var url = window.location.pathname;
      if (url.length > 1) {
        NavigateUrl = NavigateUrl + "?navigate=" + encodeURIComponent(url);
      }

      return (
        <Navigate to={NavigateUrl} />
      )
    }

    const isPending = this.state.asyncOperation;
    const isError = this.state.error;
    const screenData = this.renderScreen(null);
    
    const showDialog = this.state.dialog != null;

    return (
      <div className="BaseScreen">
        {/* {isPending && <div id="overlay" ><Loader /></div>} */}
        {showDialog && <div id="popup">{this.state.dialog()}</div>}
        {isError && <div id="popup"><PopupAlert text={this.state.error.text} onAccepted={(e) => this.dismissError(e)} /></div>}
        {screenData}
      </div>
    );
  }

  renderScreen(child) {
    return child;
  }
}

export default BaseScreen;
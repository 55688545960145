import IServiceRequest from "../../connect/IServiceRequest";

export default class ChatCreate extends IServiceRequest
{
    constructor()
    {
        super('www/chat/create');
    }
    
    setTopic(value)
    {
        this.appendRequest("topic", value);
    }

    setReferenceId(value)
    {
        this.appendRequest("reference_id", value);
    }

    setReferenceType(value)
    {
        this.appendRequest("reference_type", value);
    }
}

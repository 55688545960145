import Event from "../../../property/Event";

export default class IUpdateEvent
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    getExternal()
    {
        throw new TypeError("Method not implemented");
    }
    getShortname()
    {
        throw new TypeError("Method not implemented");
    }
    setShortname(name)
    {
        throw new TypeError("Method not implemented");
    }

    getDescription()
    {
        throw new TypeError("Method not implemented");
    }
    setDescription(description)
    {
        throw new TypeError("Method not implemented");
    }

    getLocation()
    {
        throw new TypeError("Method not implemented");
    }
    setLocation(value)
    {
        throw new TypeError("Method not implemented");
    }

    getGps()
    {
        throw new TypeError("Method not implemented");
    }
    setGps(value)
    {
        throw new TypeError("Method not implemented");
    }

    getTimestampStart()
    {
        throw new TypeError("Method not implemented");
    }
    setTimestampStart(value)
    {
        throw new TypeError("Method not implemented");
    }
    getTimestampEnd()
    {
        throw new TypeError("Method not implemented");
    }
    setTimestampEnd(value)
    {
        throw new TypeError("Method not implemented");
    }

    getRegistrationFeeOnsite()
    {
        throw new TypeError("Method not implemented");
    }
    setRegistrationFeeOnsite(value)
    {
        throw new TypeError("Method not implemented");
    }

    getRegistrationFeeTransfer()
    {
        throw new TypeError("Method not implemented");
    }
    setRegistrationFeeTransfer(value)
    {
        throw new TypeError("Method not implemented");
    }

    getRegistrationFeeOnline()
    {
        throw new TypeError("Method not implemented");
    }
    setRegistrationFeeOnline(value)
    {
        throw new TypeError("Method not implemented");
    }

    getRegistrationFeeCrew()
    {
        throw new TypeError("Method not implemented");
    }
    setRegistrationFeeCrew(value)
    {
        throw new TypeError("Method not implemented");
    }

    getTimestampAttendanceStart()
    {
        throw new TypeError("Method not implemented");
    }
    setTimestampAttendanceStart(value)
    {
        throw new TypeError("Method not implemented");
    }

    getTimestampAttendanceTransferEnd()
    {
        throw new TypeError("Method not implemented");
    }
    setTimestampAttendanceTransferEnd(value)
    {
        throw new TypeError("Method not implemented");
    }

    getTimestampAttendanceEnd()
    {
        throw new TypeError("Method not implemented");
    }
    setTimestampAttendanceEnd(value)
    {
        throw new TypeError("Method not implemented");
    }

    getTemplateAttendanceEmail()
    {
        throw new TypeError("Method not implemented");
    }
    setTemplateAttendanceEmail(value)
    {
        throw new TypeError("Method not implemented");
    }

    getRaceFormula()
    {
        throw new TypeError("Method not implemented");
    }
    setRaceFormula(value)
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }

}
import ICreateMessage from "../../../sdk/data/chat/ICreateMessage";

export class CreateMessage extends ICreateMessage
{
    constructor()
    {
        super();
        this.chat = null;
        this.message = null;  

        this.external = null;
        this.author_name = null;
        this.avatar = null;
        this.timestamp = null;
    }

    getExternal()
    {
        return this.external;
    }

    getMessage()
    {
        return this.message;
    }

    setMessage(value)
    {
        this.message = value;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
        return true;
    }

    getExternal()
    {
        return this.external;
    }

    getAuthorName()
    {
        return this.author_name;
    }

    getAvatar()
    {
        return this.avatar;
    }

    getTimestamp()
    {
        return this.timestamp;
    }
}
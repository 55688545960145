import IServiceRequest from '../../connect/IServiceRequest'
import ApiUtils from '../ApiUtils';

export default class ResultsCommit extends IServiceRequest
{
    constructor()
    {
        super('www/results');
    }

    setEvent(value)
    {
        this.appendRequest('token', value);
    }
    setPlayer(value)
    {
        this.appendRequest('player', value);
    }
    setClass(value)
    {
        this.appendRequest('class', value);
    }
    setTimestamp(value)
    {
        this.appendRequest('timestamp', ApiUtils.serializeDateTime(value));
    }
    setAllowedPlayers(values)
    {
        this.appendRequest('players', values);
    }

}
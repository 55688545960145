import Event from "../../property/Event";

export default class IAttendanceRegister
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    setVehicle(vehicle)
    {
        throw new TypeError("Method not implemented");
    }

    setCrewCount(value)
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }

    getEmail()
    {
        throw new TypeError("Method not implemented");
    }

    getPaymentOptions()
    {
        throw new TypeError("Method not implemented");
    }

    getCustomMessage()
    {
        throw new TypeError("Method not implemented");
    }
}
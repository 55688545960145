import Event from "../../property/Event";

export default class IAttendanceDetails
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    Commit()
    {
        throw new TypeError("Method not implemented");
    }

    getRegistered()
    {
        throw new TypeError("Method not implemented");
    }

    getVehicles()
    {
        throw new TypeError("Method not implemented");
    }
}
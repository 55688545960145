import IServiceRequest from "../../../connect/IServiceRequest";

export default class ResultsUploadCommit extends IServiceRequest
{
    constructor()
    {
        super('www/event/results/upload');
    }
    
    setExternal(value)
    {
        this.appendRequest("external", value);
    }
    
    setData(value)
    {
        this.appendRequest("data", value);
    }
}

import IServiceRequest from "../../../connect/IServiceRequest";

export default class UpdateRawResultCommit extends IServiceRequest
{
    constructor()
    {
        super('www/result/update');
    }
    
    setToken(value)
    {
        this.appendRequest("token", value);
    }

    setIsInvalid(value)
    {
        this.appendRequest("is_invalid", value);
    }

    setIsPublic(value)
    {
        this.appendRequest("is_public", value);
    }

    setPlayer(value)
    {
        this.appendRequest("player", value);
    }

    setDisplayedReactionTime(value) 
    {
        this.appendRequest("displayed_reaction_time", value);
    }


    setDisplayedTime60ft(value) {
        this.appendRequest("displayed_time_60ft", value);
    }

    setDisplayedTime330ft(value) {
        this.appendRequest("displayed_time_330ft", value);
    }

    setDisplayedTime660ft(value) {
        this.appendRequest("displayed_time_660ft", value);
    }

    setDisplayedTime1000ft(value) {
        this.appendRequest("displayed_time_1000ft", value);
    }

    setDisplayedTime1320ft(value) {
        this.appendRequest("displayed_time_1320ft", value);
    }
    
    setDisplayedSpeed60ft(value) {
        this.appendRequest("displayed_speed_60ft", value);
    }
    setDisplayedSpeed330ft(value) {
        this.appendRequest("displayed_speed_330ft", value);
    }
    setDisplayedSpeed660ft(value) {
        this.appendRequest("displayed_speed_660ft", value);
    }
    setDisplayedSpeed1000ft(value) {
        this.appendRequest("displayed_speed_1000ft", value);
    }
    setDisplayedSpeed1320ft(value) {
        this.appendRequest("displayed_speed_1320ft", value);
    }
}

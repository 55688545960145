import Event from './property/Event';

export class Session
{
    constructor()
    {
        this.onPersonalizeChanged = new Event();
        this.onPermissionsChanged = new Event();
    }

    init()
    {
        throw new TypeError("Method not implemented")
    }

    getSecurityProvider()
    {
        throw new TypeError("Method not implemented")
    }

    Reset()
    {
        throw new TypeError("Method not implemented")
    }

    getPersonalized()
    {
        throw new TypeError("Method not implemented")
    }

    getUser()
    {
        throw new TypeError("Method not implemented")
    }

    Flush()
    {
        this.onSaveRequested.callback(this, null);
    }

    serialize(/*JSONObject */ stream)
    { 
        return false;
    }

    deserialize(/*JSONObject*/ stram)
    {
        return false;
    }

    createSessionLogin()
    {
        throw new TypeError("Method not implemented");
    }

    createSessionPersonalize()
    {
        throw new TypeError("Method not implemented");
    }

    createSessionActivate()
    {
        throw new TypeError("Method not implemented");
    }

    createSessionRecover()
    {
        throw new TypeError("Method not implemented");
    }

    createSessionRefresh()
    {
        throw new TypeError("Method not implemented");
    }
    
    createSessionChangePassword()
    {
        throw new TypeError("Method not implemented");
    }

    getLeagues()
    {
        throw new TypeError("Method not implemented")
    }
    onLeaguesChanged = new Event();

    getFilters()
    {
        throw new TypeError("Method not implemented")
    }
    onFiltersChanged = new Event();

    createRaceResults()
    {
        throw new TypeError("Method not implemented")
    }

    createRaceDetails()
    {
        throw new TypeError("Method not implemented")
    }

    createTimeslip()
    {
        throw new TypeError("Method not implemented");
    }

    createLatestResult()
    {
        throw new TypeError("Method not implemented");
    }

    createNextRace()
    {
        throw new TypeError("Method not implemented");
    }
    createSetNextRace()
    {
        throw new TypeError("Method not implemented");
    }
    
    createCurrentResults()
    {
        throw new TypeError("Method not implemented");
    }

    createPlayersUpload()
    {
        throw new TypeError("Method not implemented");
    }
    
    createPlayersDownload()
    {
        throw new TypeError("Method not implemented");
    }

    createVehicle()
    {
        throw new TypeError("Method not implemented");
    }

    createVehicleList()
    {
        throw new TypeError("Method not implemented");
    }

    createVehicleApplication()
    {
        throw new TypeError("Method not implemented");
    }

    createApplicationsMine()
    {
        throw new TypeError("Method not implemented");
    }

    createApplicationsList()
    {
        throw new TypeError("Method not implemented");
    }

    createCreateLeague()
    {
        throw new TypeError("Method not implemented");
    }

    createRawResult()
    {
        throw new TypeError("Method not implemented")
    }

    createAttendanceRegister(type, token, vehicle)
    {
        throw new TypeError("Method not implemented")
    }

    createAttendanceList(token)
    {
        throw new TypeError("Method not implemented")
    }

    createAttendanceDetails(token)
    {
        throw new TypeError("Method not implemented")
    }

    createAttendanceAccept(external)
    {
        throw new TypeError("Method not implemented")
    }
    createAttendanceReject(external)
    {
        throw new TypeError("Method not implemented")
    }
    createAttendanceEmail()
    {
        throw new TypeError("Method not implemented")
    }
    createChat(token)
    {
        throw new TypeError("Method not implemented")
    }
}
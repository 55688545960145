import ISessionPersonalize from '../../sdk/session/ISessionPersonalize';

export default class SessionPersonalize extends ISessionPersonalize
{
    constructor()
    {
        super();
        this.name = null;
        this.nick = null;
        this.email = null;
        this.security = null;
    }

    setName(value)
    {
        this.name = value;
    }

    getName()
    {
        return this.name;
    }

    setNick(value)
    {
        this.nick = value;
    }

    getNick()
    {
        return this.nick;
    }
    
    setEmail(value)
    {
        this.email = value;
    }

    getEmail()
    {
        return this.email;
    }

    setSecurity(security)
    {
        this.security = security;
    }

    getSecurity()
    {
        return this.security;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }
}
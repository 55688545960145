import React, { Component, useEffect, useRef, useState } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SosIcon from '@mui/icons-material/Sos';

import './LoginScreen.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faCarSide, faPlusCircle, faSync, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import Utils from '../../utils/Utils';
import { DrawBottomRight } from '../LayoutHeader';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LoginScreen = ({session}) => {
  const [asyncOperation, setAsyncOperation] = useState(false);

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErrors, setPasswordErrors] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const [commitErrors, setCommitErrors] = useState(null);
  const [commitRedirect, setCommitRedirect] = useState(null);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => { 
      const personalizedCallback = (sender, value) => {
          setLoggedIn(value) ;
      }
      session.onPersonalizeChanged.register(personalizedCallback);

      setLoggedIn(session.getPersonalized()) ;
      return () => {
          session.onPersonalizeChanged.unregister(personalizedCallback);
      }
  }, []);

  useEffect( () => {
      if ( !isLoggedIn )
      {
        return;
      }
      if ( searchParams.get('redirect') )
      {
        navigate(searchParams.get('redirect')) 
      }
      else
      {
        navigate('/');
      }
    }, [isLoggedIn]);

    
  const executeLogin = () => {
    let errors = [];
    if ( !email )
    {
        errors.push('Email nie może być pusty');
    }
    if ( !password )
    {
        errors.push('Hasło nie może być puste');
    }
    else
    {
      let password_validate = Utils.validate_password(password);
      if ( password_validate )
      {
          errors.push(password_validate);
      }
    }

    if ( errors.length > 0 )
    {
        setCommitErrors(errors);
        return;
    }

    var action = session.createSessionLogin();
    action.setEmail(email);
    action.setType('PASSWORD');
    
    action.OnCommitRequested.register((sender, params) => { setAsyncOperation(true); });
    action.OnCommitFinished.register((sender, params) => { setAsyncOperation(false); });
    action.OnCommitError.register((sender, error) => { 
        setAsyncOperation(false);
        if ( "ACCOUNT-RECOVERY-NEEDED" === error.code )
        {
          setCommitRedirect('/account/recover');
          setCommitErrors('Konto zostało zablokowane');
           return;
        }
        setCommitErrors(error.message)
      } );

    action.Commit(password);
  }

  
  const validatePassword = (value) => {
    if ( null == value || value.length < 1 )
    {
      setPassword(null);
      setPasswordErrors("Wprowadź hasło");
      return;
    }
    let errors = Utils.validate_password(value);
    if ( errors )
    {
      setPasswordErrors(errors);
    }
    else
    {
      setPasswordErrors(null);
    }
    setPassword(value);
  }

  return (
    <>
      <header className="w3-container">
      <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
        <DrawBottomRight>
          <SpeedDial
            ariaLabel="SpeedDial playground example"
            hidden={false}
            icon={<SpeedDialIcon />}
            direction={'up'}
            sx={{position: 'absolute', bottom: 0, right: 0}}
          >
            {[ <SpeedDialAction
              key='create'
              icon={<AppRegistrationIcon />}
              tooltipTitle={'Załóż konto'}
              tooltipOpen
              onClick={() => navigate('/account/register')}
            />,
            <SpeedDialAction
              key='recover'
              icon={<SosIcon />}
              tooltipTitle={'Odzyskaj konto'}
              tooltipOpen
              onClick={() => navigate('/account/recover')}
            />
            ]}
          </SpeedDial>
        </DrawBottomRight>
        <Snackbar
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={commitErrors != null} autoHideDuration={6000} onClose={() => { 
            setCommitErrors(null); 
            if ( null != commitRedirect ) {
              navigate(commitRedirect);
            }
           }}
           sx={{minWidth: '50%'}} >
          <Alert severity="error" sx={{ width: '100%' }}>{commitErrors}</Alert>
        </Snackbar>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={asyncOperation} >
          <CircularProgress color="inherit" />
        </Backdrop>

        <h5><FontAwesomeIcon icon={faCarSide} /> <b>Podaj dane dostępowe do swojego konta SCS Club</b></h5>
        <div className="w3-container login-form">
            <form className="w3-form" autoComplete={true} onSubmit={(ev) => { ev.preventDefault() } }>
              <div className="w3-container">
                <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                    <InputLabel htmlFor="account-email" shrink>Email przypisany do konta</InputLabel>
                    <OutlinedInput label="Email przypisany do konta" variant="standard" type={'email'} value={email} onChange={(ev) => { setEmail(ev.target.value)}} id="account-email" />
                  </FormControl>
              </div>
              <div className="w3-container">
              <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                  <InputLabel htmlFor="account-password">Hasło do konta</InputLabel>
                  <OutlinedInput label="Hasło do konta" variant="standard" type={showPassword ? 'text' : 'password'} value={password} onChange={(ev) => { validatePassword(ev.target.value)}} id="account-password" 
                    error={passwordErrors ? true : false}
                    helperText={passwordErrors}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={(e) => { setShowPassword(!showPassword) }}
                            onMouseDown={(ev) => { ev.preventDefault() }}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      } />

                  {passwordErrors && (
                      <FormHelperText error id="password-error">
                        {passwordErrors}
                      </FormHelperText>)}
                  </FormControl>
                  
              </div>
              <div className="w3-container w3-button w3-right-align">
                  <Button variant="contained" color="primary" onClick={executeLogin} disabled={asyncOperation || null==password || null != passwordErrors }>Zaloguj</Button>
              </div>
            </form>
        </div>
        </Box>
      </header>
    </>);
}
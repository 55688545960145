import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import autoBind from 'react-autobind';
import update from "react-addons-update";
import './AttendanceWidget.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnkh, faBook, faPeopleArrows, faPersonBooth, faMale } from '@fortawesome/free-solid-svg-icons';

class AttendanceWidget extends Component {
  static defaultProps = {
    session: null,
    token: null,
    type: null
  }

  constructor(props)
  {
    super(props)
    autoBind(this);

    let action = props.session.createAttendance(props.token, props.type);
    this.state = { fetching: true, action: action, count: null, registered: null, count: null };
    
    this.queryData(action);
  }

  componentWillReceiveProps(props)
  {
      if ( this.props.token != props.token )
      {
        let action = props.session.createAttendance(props.token, props.type);
        this.queryData(action);

        this.setState((oldState) => { return update(oldState, { action: { $set: action }, count: { $set: null }, registered: { $set: null }, count:  { $set: null } }) });
      }
  }

  queryData(action)
  {
      action.OnQueryRequested.register(this.onAttendanceQueryRequested);
      action.OnQueryFinished.register(this.onAttendanceQueryFinished);
      action.OnQueryError.register(this.onAttendanceQueryError);
      action.Query();
  }

  
  onAttendanceQueryRequested(sender, params) {
    this.setState((oldState) => { return update(oldState, { fetching: { $set: true } }) });
  }
  onAttendanceQueryFinished(sender, params) {
    let registered = sender.getRegistered();
    let count = sender.getCount();
    let is_registration_open = sender.getIsRegistrationOpen();
    this.setState((oldState) => { return update(oldState, { fetching: { $set: false }, registered: { $set: registered }, count: { $set: count }, is_registration_open: { $set: is_registration_open } }) });
  }
  onAttendanceQueryError(sender, params) {

    this.setState((oldState) => { return update(oldState, { fetching: { $set: false }, registered: { $set: 0 }, count: { $set: null } }) });
  }

  render() {

    var details = null;
    if ( null == this.state.registered )
    {
        details = (
            <h4>...</h4>
        );
    }
    else if ( this.state.registered == 1 )
    {
        details = (
            <Link to={'/attendance/player/' + this.props.token} data-tooltip="Jesteś zapisany">
              <h4 className="w3-clickable">Jesteś zapisany</h4>
            </Link>
        );
    }
    else if ( this.state.registered > 1 )
    {
        details = (
          <Link to={'/attendance/player/' + this.props.token} data-tooltip={"Jesteś zapisany ( " + this.state.registered + " razy )"}>
              <h4 className="w3-clickable">Jesteś zapisany ( {this.state.registered} razy ) </h4>
          </Link>
      );
    }
    else if ( this.state.is_registration_open )
    {
        details = (
          <Link to={'/attendance/player/' + this.props.token} data-tooltip="Zapisz się">
            <h4 className="w3-clickable">Zapisz się</h4>
          </Link>
      );
    }
    else
    {
      details = (
        <h4>&nbsp;</h4>
      );
    }

    let count = null;
    if ( this.props.manage )
    {
        count = (
            <div className="w3-float-topleft">
                <Link to={'/attendance/admin/' + this.props.token} data-tooltip="Przejdź do listy uczestników">
                    <h4 className="w3-margin"><FontAwesomeIcon icon={faMale} /> {this.state.count}</h4>
                </Link>
            </div>);
    }

    if ( this.props.small )
    {
      return (
        <div className="w3-overlayed-container">
            {details}
          {this.state.fetching && <div className="shade"></div> }
          {this.state.fetching && <div className="overlay">
              <div className="lds-roller"><div></div><div></div><div></div><div></div></div>
          </div> }
      </div> )
    }

    return (
        <div className="w3-overlayed-container">
            <div className="w3-container w3-orange w3-text-white w3-padding-16 w3-anchor">
                
                <div className="w3-right w3-xxxlarge">
                    <FontAwesomeIcon icon={faBook} />
                </div>
                <div className="w3-clear"></div>
                {details}
                {count}
            </div>
            {this.state.fetching && <div className="shade"></div> }
            {this.state.fetching && <div className="overlay">
                <div className="lds-roller"><div></div><div></div><div></div><div></div></div>
            </div> }
        </div>
    );
  }
}

export default AttendanceWidget;
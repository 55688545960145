import React, { Component, useEffect, useRef, useState } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import './RegisterScreen.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faCarSide, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import Utils from '../../utils/Utils';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const RegisterScreen = ({session}) => {
  const [asyncOperation, setAsyncOperation] = useState(false);

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [name, setName] = useState("");
  const [nick, setNick] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(null);
  const [passwordErrors, setPasswordErrors] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const [commitErrors, setCommitErrors] = useState(null);
  const [commitRedirect, setCommitRedirect] = useState(null);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => { 
    const personalizedCallback = (sender, value) => {
        setLoggedIn(value) ;
    }
    session.onPersonalizeChanged.register(personalizedCallback);

    setLoggedIn(session.getPersonalized()) ;
    return () => {
        session.onPersonalizeChanged.unregister(personalizedCallback);
    }
  }, []);

  useEffect( () => {
    if ( !isLoggedIn )
    {
      return;
    }
    if ( searchParams.get('navigate') )
    {
      navigate(searchParams.get('navigate')) 
    }
    else
    {
      navigate('/');
    }
  }, [isLoggedIn]);


  const executeRegister = () => {
    let errors = [];
    if ( !name )
    {
        errors.push('Imię nie może byc puste');
    }
    if ( !email )
    {
        errors.push('Email nie może być pusty');
    }
    if ( !password )
    {
        errors.push('Hasło nie może być puste');
    }
    else
    {
      let password_validate = Utils.validate_password(password);
      if ( password_validate )
      {
          errors.push(password_validate);
      }
    }

    if ( errors.length > 0 )
    {
        setCommitErrors(errors);
        return;
    }

    var action = session.createSessionPersonalize();
    action.setName(name);
    action.setNick(nick);
    action.setEmail(email);
    action.setSecurity(password);
    
    action.OnCommitRequested.register((sender, params) => { setAsyncOperation(true); });
    action.OnCommitFinished.register((sender, params) => { setAsyncOperation(false); });
    action.OnCommitError.register((sender, error) => { 
        setAsyncOperation(false);
        setCommitErrors(error.message)
      } );

    action.Commit(password);
  }

  const validatePassword = (value) => {
    if ( null == value || value.length < 1 )
    {
      setPassword(null);
      setPasswordErrors("Wprowadź hasło");
      return;
    }
    let errors = Utils.validate_password(value);
    if ( errors )
    {
      setPasswordErrors(errors);
    }
    else
    {
      setPasswordErrors(null);
    }
    setPassword(value);
  }


  return (
      <header className="w3-container">
        <h5><FontAwesomeIcon icon={faCarSide} /> <b>Zakładanie konta</b></h5>
        <Snackbar
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          open={commitErrors != null}
          autoHideDuration={6000}
          onClose={() => { 
            setCommitErrors(null); 
            if ( null != commitRedirect ) {
              navigate(commitRedirect);
            }
           }}
           sx={{minWidth: '50%'}}
        >
          <Alert severity="error" sx={{ width: '100%' }}>{commitErrors}</Alert>
          </Snackbar>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={asyncOperation} >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="w3-container register-form">
            <form autoComplete={true} onSubmit={(ev) => { ev.preventDefault(); this.onSubmit(ev) } }>
                <div className="w3-container">
                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                      <InputLabel htmlFor="account-name" shrink>Twoje imie i nazwisko [bedzie to widocznie jedynie dla organizatorów]</InputLabel>
                      <OutlinedInput label="Twoje imie i nazwisko" variant="standard" type={'text'} value={name} onChange={(ev) => { setName(ev.target.value)}} id="account-name" />
                    </FormControl>
                </div>
                <div className="w3-container">
                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                      <InputLabel htmlFor="account-nick" shrink>Twój nick [pod tym imieniem bedziesz widoczny dla innych uzytkownikow]</InputLabel>
                      <OutlinedInput label="Twój nick" variant="standard" type={'text'} value={nick} onChange={(ev) => { setNick(ev.target.value)}} id="account-nick" />
                    </FormControl>


                </div>
                <div className="w3-container">
                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                      <InputLabel htmlFor="account-email" shrink>Twój email [dostaniesz na niego link aktywacyjny]</InputLabel>
                      <OutlinedInput label="Twój email" variant="standard" type={'text'} value={email} onChange={(ev) => { setEmail(ev.target.value)}} id="account-email" />
                    </FormControl>
                </div>
                <div className="w3-container">
                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                      <InputLabel htmlFor="account-password">Hasło do konta</InputLabel>
                      <OutlinedInput label="Hasło do konta" variant="standard" type={showPassword ? 'text' : 'password'} value={password} onChange={(ev) => { validatePassword(ev.target.value)}} id="account-password" 
                        error={passwordErrors ? true : false}
                        helperText={passwordErrors}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={(e) => { setShowPassword(!showPassword) }}
                                onMouseDown={(ev) => { ev.preventDefault() }}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          } />

                      {passwordErrors && (
                          <FormHelperText error id="password-error">
                            {passwordErrors}
                          </FormHelperText>)}
                      </FormControl>
                </div>
                <div className="w3-container w3-margin">
                    <Button variant="contained" color="primary" onClick={executeRegister} disabled={asyncOperation || null==password || null != passwordErrors }>Załóż konto</Button>
                </div>
            </form>
        </div>
      </header>
    );
}
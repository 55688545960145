import Event from '../property/Event';

export default class ISessionRefresh
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    Commit()
    {
        throw new TypeError("Method not implemented");
    }
}
import IServiceRequest from '../../connect/IServiceRequest'

export default class VehicleApplicationAccept extends IServiceRequest
{
    constructor()
    {
        super('www/vehicle/application/accept');
    }

    setExternal(token)
    {
        this.appendRequest('token', token);
    }

    setAssignedNumber(number)
    {
        this.appendRequest('assigned_number', number);
    }
}
import IServiceRequest from "../../../connect/IServiceRequest";

export default class PlayerUpdateCommit extends IServiceRequest
{
    constructor()
    {
        super('www/player/update');
    }
    
    setExternal(value)
    {
        this.appendRequest("external", value);
    }

    setPlayerName(value)
    {
        this.appendRequest("player_name", value);
    }
    
    setPlayerCar(value)
    {
        this.appendRequest("player_car", value);
    }

    setCarPower(value)
    {
        this.appendRequest("car_power", value);
    }

    setCarMass(value)
    {
        this.appendRequest("car_mass", value);
    }

    setTeamName(value)
    {
        this.appendRequest("team_name", value);
    }
}

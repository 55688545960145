import Event from "../../sdk/property/Event";
import IFilter from "../../sdk/data/IFilter";

export class Filter extends IFilter
{
    constructor()
    {
        super();
        this.external = null;
        this.shortname = null;
        this.url = null;
        this.is_global = null;
    }

    getExternal()
    {
        return this.external;
    }
    getShortname()
    {
        return this.shortname;
    }
    getUrl()
    {
        return this.url;
    }

    getIsGlobal()
    {
        return this.is_global;
    }
}
import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import { DrawBottomRight } from '../LayoutHeader';
import { faStopwatch, faFlagCheckered, faCog } from '@fortawesome/free-solid-svg-icons';
import AttendanceWidget from '../../components/widgets/AttendanceWidget';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SosIcon from '@mui/icons-material/Sos';

import './AttendancePlayerScreen.css'
import Button from '../../components/common/Button';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Combobox from '../../components/common/Combobox';
import Utils from '../../utils/Utils';
import { SimplePopup } from '../../components/common/SimplePopup';
import Box from '@mui/material/Box';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { FormControl, FormHelperText, Input, InputAdornment } from '@mui/material';

export const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export const AttendancePlayerScreen = ({session}) => {
  const urlParams = useParams();
  const navigate = useNavigate();
  
  const [eventId, setEventId] = useState(null);
  const [leagues, setLeagues] = useState(null);
  const [refreshData, setRefreshData] = useState(0);

  const [event, setEvent] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const [isAsyncOperation, setAsyncOperation] = useState(0);
  const [commitErrors, setCommitErrors] = useState(null);

  const [users, setUsers] = useState(null);
  const [vehicles, setVehicles] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [crewCount, setCrewCount] = useState(0);
  

  const [vehicleFormRedirect, setVehicleFormRedirect] = useState(null);
  const [registerMessage, setRegisterMessage] = useState(null);


  
  useEffect(() => {
    setEventId(urlParams.eventId);
  }, [urlParams])

  
  useEffect( () => { 
    const leagueChangedCallback = (sender, leagues) => {
        setLeagues(leagues) ;
    }
    session.onLeaguesChanged.register(leagueChangedCallback);

    setLeagues(session.getLeagues()) ;
    return () => {
        session.onPersonalizeChanged.unregister(leagueChangedCallback);
    }
  }, [])

  useEffect( () => {
      if ( null == eventId )
      {
        return;
      }
      if ( null == leagues )
      {
        return;
      }
      for ( const l of leagues )
      {
          if ( null != l.getEvents() )
          {
            for ( const e of l.getEvents() )
            {
                if ( eventId != e.getExternal() )
                {
                    continue;
                }
                let event = {};
                event.external = e.getExternal();
                event.shortname = e.getShortname();
                event.description = e.getDescription();
                event.location = e.getLocation();
                event.thumbnail = e.getThumbnail();
                event.timestamp_start = e.getTimestampStart();
                
                event.league_id = l.getExternal();
                event.league_shortname = l.getShortname();

                event.is_registration_open = e.getIsRegistrationOpen();
                event.registration_fee_onsite = e.getRegistrationFeeOnsite();
                event.registration_fee_transfer = e.getRegistrationFeeTransfer();
                event.registration_fee_online = e.getRegistrationFeeOnline();
                event.registration_fee_crew = e.getRegistrationFeeCrew();
                
                event.ob = e;
                
                setEvent(event);
                return
            }
          }
      }
  }, [eventId, leagues])

  useEffect (() => {
      if ( null == eventId )
      {
        return;
      }

      const commitRequested = (sender, params) => {
        setAsyncOperation((prev) => prev + 1);
      }
      const commitFinished = (sender, params) => {
        let users = sender.getRegistered();
        let vehicles = sender.getVehicles();

        setUsers(users);
        setVehicles(vehicles);

        setAsyncOperation((prev) => prev - 1);
      }

      const commitError = (sender, error) => {
        setAsyncOperation((prev) => prev - 1);
        setCommitErrors(error.message);
      }

      let action = session.createAttendanceDetails(eventId);
      
      action.OnCommitRequested.register(commitRequested);
      action.OnCommitFinished.register(commitFinished);
      action.OnCommitError.register(commitError);

      action.Commit();
  }, [eventId, refreshData])

  const doRegister = () => {
    if ( null == selectedVehicle )
    {
        setVehicleFormRedirect("/vehicle/register?league=" + event.league_id + "&event=" + event.external);
        return;
    }

    const commitRequested = (sender, params) => {
      setAsyncOperation((prev) => prev + 1);
    }
    const commitFinished = (sender, params) => {
      setAsyncOperation((prev) => prev - 1);

      let message = sender.getCustomMessage()
      if ( null != message )
      {
        setRegisterMessage(message);
        return;
      }
      let payments = [];

      payments.push('Na twoj adres email ( ' + sender.getEmail() + " ) zostały wysłane szczegóły dotyczące płatności. Mozliwe metody płatności: ");
  
      if ( null != sender.getPaymentOptions() )
      {
        sender.getPaymentOptions().map((x) => {
          if ( "ONSITE" == x.type )
          {
             payments.push("Gotówka w dniu imprezy w kwocie " + x.amount + " zł");
             return;
          }
          if ( "TRANSFER" == x.type )
          {
            payments.push("Przelew w kwocie " + x.amount + " zł ( przelew musi zostać zaksięgowany do ostatniego dnia roboczego przed impreza )");
             return;
          }
          if ( "ONLINE" == x.type )
          {
            payments.push("Płatność online w kwocie " + x.amount + " zł ( płatność musi zostać zrealizowana do ostatniego dnia roboczego przed impreza )");
             return;
          }
          if ( "CREW" == x.type )
          {
            payments.push("Dodatkowo za każda osobe towarzysząca w kwocie " + x.amount + " zł");
             return;
          }
        })
      }
  
      setRegisterMessage(payments);
    }

    const commitError = (sender, error) => {
      setAsyncOperation((prev) => prev - 1);

      setCommitErrors(error.message);
    }

    let action = session.createAttendanceRegister("EVENT", eventId, selectedVehicle);
      
    action.OnCommitRequested.register(commitRequested);
    action.OnCommitFinished.register(commitFinished);
    action.OnCommitError.register(commitError);

    action.setCrewCount(crewCount);

    action.Commit();
  }

  const renderScreen = () =>
  {
      if ( null == users || null == event )
      {
        return <header className="w3-container w3-anchor">
                <h5><b>Trwa wczytywanie danych</b></h5>
            </header>;
      }

      let _users = users.map((x, idx) => {
        return (
            <tr key={x.external}>
                <td data-title="Lp">{idx + 1}</td>
                <td data-title="Zawodnik">{x.getDescription()}</td>
                <td data-title="Status">{Utils.map_values({ 'PENDING': 'Zgłoszone', 'ACCEPTED': 'Potwierdzone', 'REJECTED': 'Odrzucone', 'CANCELLED': 'Anulowane' }, x.getStatus())}</td>
                <td data-title="Płatność">{Utils.map_values({ 'TRANSFER': 'Przelew', 'ONSITE': 'W dniu imprezy' }, x.getPaymentType())}</td>
                <td data-title="Data zgłoszenia">{x.getTimestampCreation() ? x.getTimestampCreation().format('YYYY-MM-DD HH:mm:ss') : null}</td>
                <td data-title="Data potwierdzenia">{x.getTimestampAcceptance()  ? x.getTimestampAcceptance().format('YYYY-MM-DD HH:mm:ss') : null}</td>
            </tr>
        )
      }
      );

      let _vehicles = vehicles.map((x) => {
        let description = x.getAssignedNumber() + " - " + x.getLeagueClassShortname() + " - " + x.getCarShortname();

        return { label: description, value: x.getExternal(), key: x.getExternal() }
      });

      _vehicles.push( { label: 'Nowy pojazd', value: null, key: null })

      return (
        <header className="w3-container w3-anchor">
            <div className="w3-container">
                <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
                    <tbody>
                      <tr className="w3-padding-16 w3-hoverable w3-tr-with-link">
                          <td><span className="w3-xlarge">Cykl</span></td>
                          <td><span className="w3-xlarge">{event.league_shortname}</span></td>
                      </tr>
                      <tr className="w3-padding-16 w3-hoverable w3-tr-with-link">
                          <td><span className="w3-xlarge">Wydarzenie</span></td>
                          <td><span className="w3-xlarge">{event.shortname}</span></td>
                      </tr>
                      <tr className="w3-padding-16 w3-hoverable w3-tr-with-link">
                          <td><span className="w3-xlarge">Lokalizacja</span></td>
                          <td><span className="w3-xlarge">{event.location}</span></td>
                      </tr>
                      <tr className="w3-padding-16 w3-hoverable w3-tr-with-link">
                          <td><span className="w3-xlarge">Start wydarzenia</span></td>
                          <td><span className="w3-xlarge">{event.timestamp_start ? event.timestamp_start.format("YYYY-MM-DD HH:mm") : ""}</span></td>
                      </tr>
                    </tbody>
                </table>
            </div>
            { event.is_registration_open && 
              <div className="w3-container">
                  <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
                      <tbody>
                        { event.registration_fee_transfer && 
                          <tr className="w3-padding-16 w3-hoverable w3-tr-with-link">
                              <td><span className="w3-xlarge">Płatność przelewem</span></td>
                              <td><span className="w3-xlarge">{event.registration_fee_transfer} zł</span></td>
                          </tr>
                        }
                        { event.registration_fee_onsite && 
                          <tr className="w3-padding-16 w3-hoverable w3-tr-with-link">
                              <td><span className="w3-xlarge">Płatność na bramie</span></td>
                              <td><span className="w3-xlarge">{event.registration_fee_onsite} zł</span></td>
                          </tr>
                        }
                        { event.registration_fee_online && 
                          <tr className="w3-padding-16 w3-hoverable w3-tr-with-link">
                              <td><span className="w3-xlarge">Płatność online</span></td>
                              <td><span className="w3-xlarge">{event.registration_fee_online} zł</span></td>
                          </tr>
                        }
                        { event.registration_fee_crew && 
                          <tr className="w3-padding-16 w3-hoverable w3-tr-with-link">
                              <td><span className="w3-xlarge">Płatność za osobe towarzyszącą</span></td>
                              <td><span className="w3-xlarge">{event.registration_fee_crew} zł</span></td>
                          </tr>
                        }
                      </tbody>
                  </table>
              </div>
            }
            <div className="w3-container">
              {showForm && 
                <div className="w3-responsive-table">
                  <h6>Wybierz pojazd który chcesz zgłosić na wydarzenie</h6>
                  <Combobox multiple={false} children={_vehicles} value={selectedVehicle} onChange={(value) => setSelectedVehicle(value)} />
                  <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '100px' }}>
                    <Input
                      id="standard-adornment-crew"
                      endAdornment={<InputAdornment position="end">os</InputAdornment>}
                      aria-describedby="standard-crew-helper-text"
                      inputProps={{
                        'aria-label': 'osób',
                      }}
                      value={crewCount}
                      onChange={(ev) => {
                        if ( !ev.target.value )
                        {
                          setCrewCount(null);
                          return;
                        }
                        let value = Number(ev.target.value);
                        if ( !isNaN(value ))
                        {
                          setCrewCount(value);
                        }
                      }}
                    />
                    <FormHelperText id="standard-crew-helper-text">Ilość dodatkowych osób</FormHelperText>
                  </FormControl>

                  <div className="w3-quarter w3-right w3-margin">
                    <Button onClicked={() => { doRegister()}} disabled={isAsyncOperation > 0} text="Zgłoś" iconLeft={isAsyncOperation > 0 ? faSync : null} />
                  </div>
                </div>
              }
              {!showForm && 
                <div className="w3-quarter w3-right w3-margin">
                    <Button onClicked={() => { setShowForm(true)}} disabled={isAsyncOperation > 0} text="Zapisz się na wydarzenie" iconLeft={isAsyncOperation > 0 ? faSync : null} />
                </div>
              }
            </div>

            {_users.length > 0 && 
              <div className="w3-container">
                <div className="w3-responsive-table">
                    <h6>Twoje zgłoszenia</h6>
                    <table className="w3-table-all">
                        <thead>
                            <tr>
                                <th>Lp</th>
                                <th>Zawodnik</th>
                                <th>Status</th>
                                <th>Płatność</th>
                                <th>Data zgłoszenia</th>
                                <th>Data potwierdzenia</th>
                            </tr>
                        </thead>
                        <tbody>
                            {_users}        
                        </tbody>
                    </table>
                </div>
              </div>
            }
            {_users.length == 0 && 
              <div className="w3-container">
                <h5>Nie jesteś jeszcze zapisany na te wydarzenie</h5>
              </div>
            }
        </header>
      )
  }

  return (
    <Box>
      <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={commitErrors != null} autoHideDuration={6000} onClose={() => {  setCommitErrors(null); }} sx={{minWidth: '50%'}} >
          <Alert severity="error" sx={{ width: '100%' }}>{commitErrors}</Alert>
      </Snackbar>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isAsyncOperation > 0} >
          <CircularProgress color="inherit" />
      </Backdrop>
      {vehicleFormRedirect != null && 
        <SimplePopup open={vehicleFormRedirect != null} fullScreen={false} 
          body="Zostaniesz przekierowany do formularza zglaszania pojazdu do calego cyklu, gdzie zostanie ci nadany numer startowy" 
          accept='OK' reject={false} 
          onAccepted={() => { navigate(vehicleFormRedirect); setVehicleFormRedirect(null) }} />}
      {registerMessage != null && 
        <SimplePopup open={registerMessage != null} fullScreen={false} 
          body={registerMessage}
          accept='OK' reject={false} 
          onAccepted={() => { setRegisterMessage(null); setRefreshData((prev) => prev + 1) }} />}

      {renderScreen()}
    </Box>
  )
}

import Event from '../property/Event';

export default class ISessionPersonalize
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    setName(value)
    {
        throw new TypeError("Method not implemented");
    }

    getName()
    {
        throw new TypeError("Method not implemented");
    }

    setNick(value)
    {
        throw new TypeError("Method not implemented");
    }

    getNick()
    {
        throw new TypeError("Method not implemented");
    }

    setEmail(value)
    {
        throw new TypeError("Method not implemented");
    }

    getEmail()
    {
        throw new TypeError("Method not implemented");
    }

    setSecurity(security)
    {
        throw new TypeError("Method not implemented");
    }

    getSecurity()
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }
}
import Event from '../property/Event';

export default class ILeague
{
    getExternal()
    {
        throw new TypeError("Method not implemented");
    }
    getShortname()
    {
        throw new TypeError("Method not implemented");
    }
    getDescription()
    {
        throw new TypeError("Method not implemented");
    }
    getThumbnail()
    {
        throw new TypeError("Method not implemented");
    }
    getIsFinished()
    {
        throw new TypeError("Method not implemented");
    }
    getEvents()
    {
        throw new TypeError("Method not implemented");
    }
    getClasses()
    {
        throw new TypeError("Method not implemented");
    }

    createClassCreate()
    {
        throw new TypeError("Method not implemented");
    }

    createEventCreate()
    {
        throw new TypeError("Method not implemented");
    }

    getPlayers()
    {
        throw new TypeError("Method not implemented");
    }

    createUpdateLeague()
    {
        throw new TypeError("Method not implemented");
    }
}
import IServiceRequest from '../../connect/IServiceRequest'

export default class VehicleImageProfileCommit extends IServiceRequest
{
    constructor()
    {
        super('www/vehicle/image/profile');
    }

    setToken(token)
    {
        this.appendRequest("token", token);
    }

    setData(data)
    {
        this.appendRequest("image", data);
    }
}
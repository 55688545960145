import Log from './../logger/Log';
// import Engine from '../Engine';
import ApiUtils from '../api/ApiUtils';
import { sprintf } from 'sprintf-js';

import MyWorker from "./http.service.js"

export default class Worker
{
    constructor(/*String*/ name, /*HttpConnection*/ connection)
    {
        this.mName = name;
        this.mConnection = connection;
        this.mWorker = null;
        this.mJobs = null;
    }

    start()
    {
        Log.i('WORKER', 'Starting worker');

        this.mJobs = new Map();
        // create a Worker
        var worker = new MyWorker();
        worker.onmessage = this.finish.bind(this);

        var loglevel = 4;
        if ( "GLOBAL" == this.mName || "SESSION" == this.mName )
        {
            loglevel = 1
        }

        var request = {
            type: 'INITIALIZE',
            name: this.mName,
            connection: this.mConnection,
            log: loglevel
        }

        worker.postMessage(request);

        this.mWorker = worker;
        
        // worker.postMessage("Send some Data"); 
    }

    stop()
    {
        this.mJobs = null;
        this.mWorker.terminate();
        this.mWorker = null;
        Log.i('WORKER', 'Stopping worker');
    }


    push(/*IJob*/ job)
    {
        var /*String*/ uuid = ApiUtils.getUuid();
        var input = job.createInput(); 
 
        if ( null == input )
        {
            return;
        }

        Log.i('WORKER', "pushing job " + uuid);

        var request = {
            key: uuid, 
            input: input, 
            type: 'EXECUTE'
        }

        this.mJobs.set(uuid, job);
        this.mWorker.postMessage( request );
    }

    finish(ev)
    {
        var params = ev.data;
        var uuid = params.key;
        var job = this.mJobs.get(uuid);
        if ( null == job )
        {
            Log.e('WORKER', sprintf('Could not find job %s', uuid));
            return;
        }
        this.mJobs.delete(uuid);

        Log.i('WORKER', sprintf('Response status = %s', params.status));
        job.processOutput(params);
    }
}
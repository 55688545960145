import IServiceRequest from "../../connect/IServiceRequest";
import ApiUtils from "../ApiUtils";

export default class AttendanceEmailCommit extends IServiceRequest {
  constructor() {
    super("www/attendance/email");
  }

  setTokens(values) {
    this.appendRequest("tokens", values);
  }

  setTestMode(value) {
    this.appendRequest("test_mode", value);
  }

  setEmailTemplate(value) {
    this.appendRequest("email_template", value);
  }

  setEmailTitle(value) {
    this.appendRequest("email_title", value);
  }

  setEmailReplyTo(value) {
    this.appendRequest("email_reply_to", value);
  }
}

import Event from '../property/Event';

export default class ISessionChangePassword
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    setOldPassword(login)
    {
        throw new TypeError("Method not implemented");
    }

    setNewPassword(type)
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }
}
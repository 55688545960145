import React, { Component } from 'react';

import update from "react-addons-update";
import autoBind from 'react-autobind';
import Tooltip from './Tooltip';

import './InputTextArea.css';

class InputTextArea extends Component {
  static defaultProps = {
    onCommit: (value) => {},
    onError: (value) => {},
    onEnter: () => {},
    onCancel: () => {},
    id: "input",
    value: null
  }
  constructor(props)
  {
    super(props)
    autoBind(this);

    this.processProps(props);
  }

  escFunction(event)
  {
    if(event.keyCode === 27) 
    {
      this.props.onCancel();
    }
  }

  componentWillReceiveProps(nextProps) 
  {
    this.processProps(nextProps);
  }

  componentDidMount()
  {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount()
  {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  getValue() {
    return this.input.value;
  }

  processProps(nextProps)
  {
      let state = { text: nextProps.text,
                error: null
      };
      
      //state.value = nextProps.text;

      if ( null != nextProps.validate && null != nextProps.text)
      {
        state.error = nextProps.validate(nextProps.text);
      }
      if ( null != this.state )
      {
        this.setState(state);
      }
      else
      {
        this.state = state;
      }
  }

  handleChange(event) {
    this.setState({text: event.target.value});
  }

  onKeyPress(event)
  {
    if ( 13 == event.charCode ) // enter
    {
      this.props.onEnter();
      return;
    }
    if ( null == this.props.characters )
    {
      return;
    }
    if ( !this.props.characters.includes(String.fromCharCode(event.charCode)) )
    {
      event.preventDefault();
      return;
    }
    // Log.i('INPUT', 'Pressed ' + event.charCode);
    // Log.i('INPUT', 'Current ' + event.currentTarget.value);
    // event.preventDefault();
  }

  onChange(e)
  {    
    e.preventDefault();
    // Log.i('INPUT', 'Current ' + e.currentTarget.value);
    do
    {
      if ( null == this.props.validate )
      {
        break;
      }
      var error = this.props.validate(e.currentTarget.value);
      if ( null == error )
      {
        break;
      }
      this.setState( (oldState) => { return update(oldState, { error: { $set: error } })});
      this.props.onError(e.currentTarget.value);
      // Log.i('INPUT', 'Error ' + error );
      return;
    } while(false);

    this.setState( (oldState) => { return update(oldState, { error: { $set: null } })});
    this.props.onCommit(e.currentTarget.value);
  }
  focusAndSelect() 
  {
    if ( null != this.input )
    {
      this.input.focus();
      this.input.select();
    }
  }
// <label htmlFor="input" className="InputField-label">{this.props.label}</label>
  render() {
    return (
      <div className="InputTextArea">
        
        <textarea ref={(r) => this.input = r} 
          type="text"
          className="InputTextArea-value" 
          id={this.props.id}
          defaultValue={this.props.value}
          onKeyPress={this.onKeyPress} 
          onChange={this.onChange} 
          placeholder={this.props.hint}
          onFocus={(e) => e.target.select()}/>
        {this.state.error != null && 
          <div className="InputTextArea-error"><Tooltip text={this.state.error} /></div>
        }
      </div>
    );
  }
}

export default InputTextArea;
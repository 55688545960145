import IUpdateRawResult from "../../../../sdk/data/manage/result/IUpdateRawResult";

export default class UpdateRawResult extends IUpdateRawResult
{
    constructor()
    {
        super();
        this.token = null;

        this.player = null;

        this.displayed_reaction_time = null;
        
        this.displayed_time_60ft = null;
        this.displayed_time_330ft = null;
        this.displayed_time_660ft = null;
        this.displayed_time_1000ft = null;
        this.displayed_time_1320ft = null;
        
        this.displayed_speed_60ft = null;
        this.displayed_speed_330ft = null;
        this.displayed_speed_660ft = null;
        this.displayed_speed_1000ft = null;
        this.displayed_speed_1320ft = null;
        
        this.is_invalid = null;
        this.is_public = null;
    }

    getToken() 
    {
        return this.token;
    }
    
    getPlayer() 
    {
        return this.player;
    }

    setPlayer(value)
    {
        this.player = value;
    }
    
    getDisplayedReactionTime() {
        return this.displayed_reaction_time;
    }
    setDisplayedReactionTime(value) {
        this.displayed_reaction_time = value;
    }

    getDisplayedTime60ft() {
        return this.displayed_time_60ft;
    }
    setDisplayedTime60ft(value) {
        this.displayed_time_60ft = value;
    }
    getDisplayedTime330ft() {
        return this.displayed_time_330ft;
    }
    setDisplayedTime330ft(value) {
        this.displayed_time_330ft = value;
    }
    getDisplayedTime660ft() {
        return this.displayed_time_660ft;
    }
    setDisplayedTime660ft(value) {
        this.displayed_time_660ft = value;
    }
    getDisplayedTime1000ft() {
        return this.displayed_time_1000ft;
    }
    setDisplayedTime1000ft(value) {
        this.displayed_time_1000ft = value;
    }
    getDisplayedTime1320ft() {
        return this.displayed_time_1320ft;
    }
    setDisplayedTime1320ft(value) {
        this.displayed_time_1320ft = value;
    }

    getDisplayedSpeed60ft() {
        return this.displayed_speed_60ft;
    }
    setDisplayedSpeed60ft(value) {
        this.displayed_speed_60ft = value;
    }    
    getDisplayedSpeed330ft() {
        return this.displayed_speed_330ft;
    }
    setDisplayedSpeed330ft(value) {
        this.displayed_speed_330ft = value;
    }
    getDisplayedSpeed660ft() {
        return this.displayed_speed_660ft;
    }
    setDisplayedSpeed660ft(value) {
        this.displayed_speed_660ft = value;
    }

    getDisplayedSpeed1000ft() {
        return this.displayed_speed_1000ft;
    }
    setDisplayedSpeed1000ft(value) {
        this.displayed_speed_1000ft = value;
    }
    getDisplayedSpeed1320ft() {
        return this.displayed_speed_1320ft;
    }
    setDisplayedSpeed1320ft(value) {
        this.displayed_speed_1320ft = value;
    }

    getIsInvalid() {
        return this.is_invalid;
    }
    setIsInvalid(value) {
        this.is_invalid = value;
    }    
    getIsPublic() {
        return this.is_public;
    }
    setIsPublic(value) {
        this.is_public = value;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
        return true;
    }
}
import Event from "../../property/Event";

export default class IAttendance
{
    OnQueryRequested = new Event();
    OnQueryFinished = new Event();
    OnQueryError = new Event();

    Query()
    {
        throw new TypeError("Method not implemented");
    }

    getRegistered()
    {
        throw new TypeError("Method not implemented");
    }

    getCount()
    {
        throw new TypeError("Method not implemented");
    }

    getExternal()
    {
        throw new TypeError("Method not implemented");
    }

    getIsRegistrationOpen()
    {
        throw new TypeError("Method not implemented");
    }
}
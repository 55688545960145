import Session from '../Session'

import IServiceResult from '../connect/IServiceResult';
import Engine, {Error} from '../Engine';

import Log from '../logger/Log';
import EventsList from '../api/globals/EventsList';
import { League } from '../data/League';
import { LeagueEvent } from '../data/LeagueEvent';
import ApiUtils from '../api/ApiUtils';
import { LeagueClass } from '../data/LeagueClass';
import { LeaguePlayer } from '../data/LeaguePlayer';


export default class GlobalsMonitor
{
    constructor(/*Session*/ session)
    {
        this.mSession = session;

        
    }

    fetchEvents()
    {
        var request = new EventsList();

        var monitor = this;

        var result = new IServiceResult(
            function(/*Object*/ reply)
            {
            },
            function(/*Object*/ error)
            {
            }
        );

        this.mSession.SessionRequest("GLOBALS", result, request);
    }


    process(data)
    {
        let leagues = [];
        let size = data.length();
        for ( var i = 0; i < size; ++i )
        {
            let ob = data.get(i);
            let league  = new League();
            league.external = ob.opt('external', null);
            league.shortname = ob.opt('shortname', null);
            league.description = ob.opt('description', null);
            league.thumbnail = ob.opt('thumbnail', null);
            league.is_finished = ob.opt('is_finished', null);

            let evts = ob.opt('events', null);
            if ( evts )
            {
                let events = [];
                let esize = evts.length();
                for ( var j = 0; j < esize; ++j )
                {
                    let ev = evts.get(j);

                    let event = new LeagueEvent();
                    event.external = ev.opt('external', null);
                    event.shortname = ev.opt('shortname', null);
                    event.description = ev.opt('description', null);
                    event.thumbnail = ev.opt('thumbnail', null);
                    event.location = ev.opt('location', null);
                    event.gps = ev.opt('gps', null);
                    event.timestamp_start = ApiUtils.deserializeDateTime(ev.opt('timestamp_start', null));
                    event.timestamp_end = ApiUtils.deserializeDateTime(ev.opt('timestamp_end', null));
                    event.is_locked = ev.opt('is_locked', null);
                    event.is_registration_open = ev.opt('is_registration_open', null);

                    event.registration_fee_onsite = ev.opt('registration_fee_onsite', null);
                    event.registration_fee_transfer = ev.opt('registration_fee_transfer', null);
                    event.registration_fee_online = ev.opt('registration_fee_online', null);
                    event.registration_fee_crew = ev.opt('registration_fee_crew', null);

                    event.timestamp_attendance_start = ApiUtils.deserializeDateTime(ev.opt('timestamp_attendance_start', null));
                    event.timestamp_attendance_transfer_end = ApiUtils.deserializeDateTime(ev.opt('timestamp_attendance_transfer_end', null));
                    event.timestamp_attendance_end = ApiUtils.deserializeDateTime(ev.opt('timestamp_attendance_end', null));

                    event.template_attendance_email = ev.opt('template_attendance_email', null);
                    event.race_formula = ev.opt('race_formula', null);

                    events.push(event);
                }
                league.events = events;
            }

            let clss = ob.opt('classes', null);
            if ( clss )
            {
                let classes = [];
                let csize = clss.length();
                for ( var j = 0; j < csize; ++j )
                {
                    let cls = clss.get(j);

                    let clazz = new LeagueClass();
                    clazz.external = cls.opt('external', null);
                    clazz.shortname = cls.opt('shortname', null);
                    clazz.description = cls.opt('description', null);
                    clazz.priority = cls.opt('priority', null);
                    clazz.player_mask = cls.opt('player_mask', null);
                    clazz.player_start = cls.opt('player_start', null);

                    classes.push(clazz);
                }
                league.classes = classes;
            }
            let plyrs = ob.opt('players', null);
            if ( plyrs )
            {
                let players = [];
                let csize = plyrs.length();
                for ( var j = 0; j < csize; ++j )
                {
                    let data = plyrs.get(j);

                    let player = new LeaguePlayer();
                    player.external = data.opt('external', null);
                    player.player = data.opt('player', null);
                    player.class = data.opt('class', null);
                    player.vehicle = data.opt('vehicle', null);
                    player.player_name = data.opt('player_name', null);
                    player.player_car = data.opt('player_car', null);
                    player.car_power = data.opt('car_power', null);
                    player.car_mass = data.opt('car_mass', null);
                    player.picture_car = data.opt('picture_car', null);
                    player.picture_driver = data.opt('picture_driver', null);

                    player.team_name = data.opt('team_name', null);
                    player.owner_external = data.opt('owner_external', null);
                    player.owner_name = data.opt('owner_name', null);

                    players.push(player);
                }
                league.players = players;
            }
            leagues.push(league);
        }
        this.mSession.leagues = leagues; 
        this.mSession.onLeaguesChanged.callback(this.mSession, leagues);
    }
}
import Event from '../property/Event';

export default class ISessionActivate
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    setUid(login)
    {
        throw new TypeError("Method not implemented");
    }

    setToken(type)
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }
}
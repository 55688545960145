import ISessionRefresh from '../../sdk/session/ISessionRefresh';

export default class SessionRefresh extends ISessionRefresh
{
    constructor()
    {
        super();
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }
}
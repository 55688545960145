import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import './EventScreen.css'
import { DrawBottomRight } from '../LayoutHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch, faFlagCheckered, faCog, faUsers, faCar } from '@fortawesome/free-solid-svg-icons';
import AttendanceWidget from '../../components/widgets/AttendanceWidget';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SosIcon from '@mui/icons-material/Sos';

import Box from '@mui/material/Box';


export const LeagueScreen = ({session}) => {
    const urlParams = useParams();
    const navigate = useNavigate();
    
    const [leagueId, setLeagueId] = useState(undefined);
    const [leagues, setLeagues ] = useState(null);

    const [league, setLeague] = useState(undefined);
    const [canEditLeague, setCanEditLeague] = useState(false);

    useEffect(() => {
        setLeagueId(urlParams.leagueId);
    }, [urlParams])

    useEffect( () => { 
        const leagueChangedCallback = (sender, leagues) => {
            setLeagues(leagues) ;
        }
        session.onLeaguesChanged.register(leagueChangedCallback);

        setLeagues(session.getLeagues()) ;
        return () => {
            session.onPersonalizeChanged.unregister(leagueChangedCallback);
        }
    }, [])

    useEffect(() => {
        if ( null == leagues )
        {
            return;
        }
        for ( const l of leagues )
        {
            if ( l.getExternal() != leagueId )
            {
                continue;
            }
            let league = {};
            league.external = l.getExternal();
            league.shortname = l.getShortname();
            league.description = l.getDescription();
            league.thumbnail = l.getThumbnail();
            league.is_finished = l.getIsFinished();
  
            if ( null != l.getEvents() )
            {
              let events = [];
              for ( const e of l.getEvents() )
              {
                  let event = {};
                  event.external = e.getExternal();
                  event.shortname = e.getShortname();
                  event.description = e.getDescription();
                  event.location = e.getLocation();
                  event.thumbnail = e.getThumbnail();
                  event.timestamp_start = e.getTimestampStart();
                  events.push(event);
              }
              league.events = events;
            }
            setLeague(league)
            return;
        }
        return;
    }, [leagueId, leagues])

    
    useEffect( () => {
        if ( null == leagueId )
        {
            setCanEditLeague(false);
            return;
        }

        let perm = session.getPermissions().hasPermissionContext('can_edit_league', leagueId) || session.getPermissions().hasPermissionGlobal('can_edit_league');
        setCanEditLeague(perm);
    }, [leagueId])

    
    const renderSpeedMenu = () => {
        if ( !canEditLeague )
        {
            return null;
        }
        let actions = [];
        if ( canEditLeague )
        {
            actions.push(<SpeedDialAction
                key='create'
                icon={<AppRegistrationIcon />}
                tooltipTitle={'Edytuj lige'}
                tooltipOpen
                onClick={() => navigate('/manage/league/' + league.external)}
                /> );
        }

        return (
            <DrawBottomRight>
                <SpeedDial
                    ariaLabel="SpeedDial playground example"
                    hidden={false}
                    icon={<SpeedDialIcon />}
                    direction={'up'}
                    sx={{position: 'absolute', bottom: 0, right: 0}}
                >
                {actions}
                </SpeedDial>
            </DrawBottomRight>    
        )
    }

    const renderEvents = () => {
        if ( null == league )
        {
            return;
        }
        return league.events.map((x) => {
            return (
                <tr key={x.external} className="w3-padding-16 w3-hoverable w3-tr-with-link">
                    <td><span className="w3-xlarge">{x.shortname}</span></td>
                    <td><span className="w3-xlarge">{x.timestamp_start ? x.timestamp_start.format("YYYY-MM-DD HH:mm") : ""}</span></td>
                    <td><span className="w3-xlarge">{x.location}</span></td>
                    <Link to={'/event/' + x.external} className="w3-rowlink"/>
                </tr>
            )
        })
    }

    return (
        <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
        {renderSpeedMenu()}
        
        {null == league && 
            <Box>Trwa wczytywanie danych</Box>
        }
        {null != league && 
            <Box>
             <h5><b>{league.shortname}</b></h5>
             <div className="w3-row-padding w3-margin-bottom">
                 <div className="w3-quarter">
                     <Link to={'/players/' + league.external}>
                         <div className="w3-container w3-orange w3-text-white w3-padding-16">
                             <div className="w3-right w3-xxxlarge">
                                 <FontAwesomeIcon icon={faUsers} />
                             </div>
                             <div className="w3-clear"></div>
                             <h4>Zawodnicy</h4>
                         </div>
                     </Link>
                 </div>
                 <div className="w3-quarter">
                     <div className="w3-container w3-teal w3-text-white w3-padding-16">
                         <div className="w3-right w3-xxxlarge">
                             <FontAwesomeIcon icon={faFlagCheckered} />
                         </div>
                         <div className="w3-clear"></div>
                         <h4>Wyniki</h4>
                     </div>
                 </div>
                 <div className="w3-quarter">
                     <Link to={'/results/' + league.external + '/best'}>
                         <div className="w3-container w3-red w3-text-white w3-padding-16">
                             <div className="w3-right w3-xxxlarge">
                                 <FontAwesomeIcon icon={faStopwatch} />
                             </div>
                             <div className="w3-clear"></div>
                             <h4>Przejazdy</h4>
                         </div>
                     </Link>
                 </div>
                 <div className="w3-quarter">
                     <Link to={'/classes/' + league.external}>
                         <div className="w3-container w3-blue w3-text-white w3-padding-16">
                             <div className="w3-right w3-xxxlarge">
                                 <FontAwesomeIcon icon={faCar} />
                             </div>
                             <div className="w3-clear"></div>
                             <h4>Klasy</h4>
                         </div>
                     </Link>
                 </div>
             </div>
             <div className="w3-row-padding">
                 <div className="w3-third">
                     <img src={league.thumbnail}/>
                 </div>
                 <div className="w3-twothird">
                     <span className="w3-medium">{league.description}</span>
                 </div>
             </div>
             <div className="w3-container">
                 <h5>Wydarzenia w cyklu</h5>
                 <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
                     <tbody>
                         {renderEvents()}
                     </tbody>
                 </table>
             </div>
             <div className="w3-container">
                 <h5>Sponsorzy</h5>
             </div>
            </Box>
        }
        </Box>
    );
}


export default class Log
{
    static Levels = {
        DEBUG: 0,
        INFO: 1,
        WARN: 2,
        ERROR: 3
    }

    static set_level(level)
    {
        Log.level = level;
    }
    
    static d(tag, message)
    {
        Log.log(Log.Levels.DEBUG, tag, message);
    }

    static i(tag, message)
    {
        Log.log(Log.Levels.INFO, tag, message);
    }

    static e(tag, message)
    {
        Log.log(Log.Levels.ERROR, tag, message);
    }

    static log(level, tag, message)
    {
        if ( Log.level > level )
        {
            return;
        }
        console.log('[' + tag + '] ' + message);
    } 

    static level = Log.Levels.DEBUG;
}


import Event from '../property/Event';

export default class ILeagueEvent
{
    getExternal()
    {
        throw new TypeError("Method not implemented");
    }
    getShortname()
    {
        throw new TypeError("Method not implemented");
    }
    getDescription()
    {
        throw new TypeError("Method not implemented");
    }
    getThumbnail()
    {
        throw new TypeError("Method not implemented");
    }
    getLocation()
    {
        throw new TypeError("Method not implemented");
    }
    getGps()
    {
        throw new TypeError("Method not implemented");
    }
    getTimestampStart()
    {
        throw new TypeError("Method not implemented");
    }
    getTimestampEnd()
    {
        throw new TypeError("Method not implemented");
    }
    getIsLocked()
    {
        throw new TypeError("Method not implemented");
    }

    getIsRegistrationOpen()
    {
        throw new TypeError("Method not implemented");
    }

    getRegistrationFeeOnsite()
    {
        throw new TypeError("Method not implemented");
    }
    getRegistrationFeeTransfer()
    {
        throw new TypeError("Method not implemented");
    }
    getRegistrationFeeOnline()
    {
        throw new TypeError("Method not implemented");
    }
    getRegistrationFeeCrew()
    {
        throw new TypeError("Method not implemented");
    }
    getTimestampAttendanceStart()
    {
        throw new TypeError("Method not implemented");
    }
    getTimestampAttendanceTransferEnd()
    {
        throw new TypeError("Method not implemented");
    }
    getTimestampAttendanceEnd()
    {
        throw new TypeError("Method not implemented");
    }
    getTemplateAttendanceEmail()
    {
        throw new TypeError("Method not implemented");
    }
    getRaceFormula()
    {
        throw new TypeError("Method not implemented");
    }
    createRemoveEvent()
    {
        throw new TypeError("Method not implemented");
    }

    createUpdateEvent()
    {
        throw new TypeError("Method not implemented");
    }

    createRemoveResults()
    {
        throw new TypeError("Method not implemented");
    }

    createUploadResults()
    {
        throw new TypeError("Method not implemented");
    }  

    createExampleEmail()
    {
        throw new TypeError("Method not implemented");
    }
}
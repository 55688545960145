import React, { Component, useEffect, useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";

import "./EventEditScreen.css";

import Button from "@mui/material/Button";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import CircularProgress from "@mui/material/CircularProgress";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import { Alert } from "../../LayoutHeader";
import Utils from "../../../utils/Utils";
import { IconButton, Stack, Tooltip } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import SendIcon from "@mui/icons-material/Send";

export const EventEditScreen = ({ session }) => {
  const navigate = useNavigate();
  const urlParams = useParams();

  const [asyncOperation, setAsyncOperation] = useState(false);

  const [eventId, setEventId] = useState(undefined);
  const [leagues, setLeagues] = useState(null);
  const [canEditLeague, setCanEditLeague] = useState(false);
  const [event, setEvent] = useState(undefined);
  const [leagueName, setLeagueName] = useState(null);
  const [leagueExternal, setLeagueExternal] = useState(null);

  const [commitErrors, setCommitErrors] = useState(null);
  const [commitRedirect, setCommitRedirect] = useState(null);

  const [dataShortname, setDataShortname] = useState(undefined);
  const [dataDescription, setDataDescription] = useState(undefined);
  const [dataLocation, setDataLocation] = useState(undefined);
  const [dataGps, setDataGps] = useState(undefined);
  const [dataTimestampStart, setDataTimestampStart] = useState(undefined);
  const [dataTimestampEnd, setDataTimestampEnd] = useState(undefined);
  const [dataRaceFormula, setDataRaceFormula] = useState(undefined);

  const [dataRegistrationFeeOnsite, setDataRegistrationFeeOnsite] = useState(undefined);
  const [dataRegistrationFeeTransfer, setDataRegistrationFeeTransfer] = useState(undefined);
  const [dataRegistrationFeeOnline, setDataRegistrationFeeOnline] = useState(undefined);
  const [dataRegistrationFeeCrew, setDataRegistrationFeeCrew] = useState(undefined);

  const [dataRegistrationEmailTemplate, setDataRegistrationEmailTemplate] = useState(undefined);

  const [dataAttendanceTimestampStart, setDataAttendanceTimestampStart] = useState(undefined);
  const [dataAttendanceTimestampEnd, setDataAttendanceTimestampEnd] = useState(undefined);
  const [dataAttendanceTimestampTransferEnd, setDataAttendanceTimestampTransferEnd] = useState(undefined);

  const [errorGps, setErrorGps] = useState(null);
  const [errorTimestampStart, setErrorTimestampStart] = useState(null);
  const [errorTimestampEnd, setErrorTimestampEnd] = useState(null);

  const [errorRaceFormula, setErrorRaceFormula] = useState(null);

  const [errorRegistrationFeeOnsite, setErrorRegistrationFeeOnsite] = useState(null);
  const [errorRegistrationFeeTransfer, setErrorRegistrationFeeTransfer] = useState(null);
  const [errorRegistrationFeeOnline, setErrorRegistrationFeeOnline] = useState(null);
  const [errorRegistrationFeeCrew, setErrorRegistrationFeeCrew] = useState(null);

  const [errorAttendanceTimestampStart, setErrorAttendanceTimestampStart] = useState(null);
  const [errorAttendanceTimestampEnd, setErrorAttendanceTimestampEnd] = useState(null);
  const [errorAttendanceTimestampTransferEnd, setErrorAttendanceTimestampTransferEnd] = useState(null);

  useEffect(() => {
    setEventId(urlParams.eventId);
  }, [urlParams]);

  useEffect(() => {
    const leagueChangedCallback = (sender, leagues) => {
      setLeagues(leagues);
    };
    session.onLeaguesChanged.register(leagueChangedCallback);

    setLeagues(session.getLeagues());
    return () => {
      session.onPersonalizeChanged.unregister(leagueChangedCallback);
    };
  }, []);

  useEffect(() => {
    if (null == eventId) {
      setCanEditLeague(false);
      return;
    }

    let perm = session.getPermissions().hasPermissionContext("can_edit_league", eventId) || session.getPermissions().hasPermissionGlobal("can_edit_league");
    setCanEditLeague(perm);
  }, [eventId]);

  useEffect(() => {
    if (null == eventId) {
      return;
    }
    if (null == leagues) {
      return;
    }
    for (const l of leagues) {
      if (null != l.getEvents()) {
        for (const e of l.getEvents()) {
          if (eventId != e.getExternal()) {
            continue;
          }
          setLeagueExternal(l.getExternal());
          setLeagueName(l.getShortname());

          setDataShortname(e.getShortname());
          setDataDescription(e.getDescription());
          setDataLocation(e.getLocation());
          setDataGps(e.getGps());
          setDataTimestampStart(e.getTimestampStart()?.format("YYYY-MM-DD HH:mm"));
          setDataTimestampEnd(e.getTimestampEnd()?.format("YYYY-MM-DD HH:mm"));

          setDataRegistrationFeeOnsite(e.getRegistrationFeeOnsite());
          setDataRegistrationFeeTransfer(e.getRegistrationFeeTransfer());
          setDataRegistrationFeeOnline(e.getRegistrationFeeOnline());
          setDataRegistrationFeeCrew(e.getRegistrationFeeCrew());

          setDataAttendanceTimestampStart(e.getTimestampAttendanceStart()?.format("YYYY-MM-DD HH:mm"));
          setDataAttendanceTimestampTransferEnd(e.getTimestampAttendanceTransferEnd()?.format("YYYY-MM-DD HH:mm"));
          setDataAttendanceTimestampEnd(e.getTimestampAttendanceEnd()?.format("YYYY-MM-DD HH:mm"));

          setDataRaceFormula(e.getRaceFormula());

          if (null != e.getTemplateAttendanceEmail()) {
            setDataRegistrationEmailTemplate(e.getTemplateAttendanceEmail());
          }

          setEvent(e);
          return;
        }
      }
    }
  }, [eventId, leagues]);

  const setExampleEmailTemplate = () => {
    setDataRegistrationEmailTemplate(
      "<html>\n   <body>\n 	  <div>Dziękujemy za wysłanie zgłoszenia, jest ono aktywne jednak jeszcze nie opłacone. Możesz dokonać płatności przelewem na nasze konto w kwocie ${PAYMENT_FEE_TRANSFER} zł lub w dniu imprezy gotówką w kwocie ${PAYMENT_FEE_ONSITE} zł.</div>\n 	  <div><p>Dane do przelewu:</p>\n 		  <p>STOWARZYSZENIE PROMOCJI SPORTÓW MOTOROWYCH SCS<br/>\n 		  ul. Słowiańska 36/2<br/>\n 		  78-400 Szczecinek<br/>\n 		  KONTO BANKOWE:<br/>\n 		  Credit Agricole<br/>\n 		  <b>44 1940 1076 3055 7420 0000 0000</b></p>\n 	  </div>              \n 	  <div><p>W tytule przelewu proszę podać imię i nazwisko oraz której rundy dotyczy wpłata.</p></div>              \n 	  <div><p>W osobnym mailu poinformujemy o zaksięgowaniu wpłaty, jak również informacja będzie widoczna na koncie zgłoszeniowym zawodnika.</p></div>              \n 	  <div><p>Pozdrawiamy</p></div>              \n 	  <div><p><b>SCSCLUB</b></p></div>\n   </body>\n </html>"
    );
  }

  const sendExampleEmail = () => {
    let action = event.createExampleEmail();

    let template_attendance_email = dataRegistrationEmailTemplate;
    action.setTemplateAttendanceEmail(template_attendance_email);
    action.setCrewCount(3);

    action.OnCommitRequested.register((sender, params) => {
      setAsyncOperation(true);
    });
    action.OnCommitFinished.register((sender, params) => {
      setAsyncOperation(false);
      setCommitErrors("Email zostal wysłany na " + sender.getEmail());
    });
    action.OnCommitError.register((sender, error) => {
      setAsyncOperation(false);
      setCommitErrors(error.message);
    });

    action.Commit();
  }

  const executeSave = () => {
    let errors = [];

    let shortname = dataShortname;
    if (!shortname) {
      errors.push("Nazwa klasy nie moze byc pusta");
    }
    let description = dataDescription;
    if (!description) {
      errors.push("Opis nie może byc pusty");
    }
    let location = dataLocation;
    let gps = dataGps;
    let timestamp_start = dataTimestampStart;
    let timestamp_end = dataTimestampEnd;

    let registration_fee_onsite = dataRegistrationFeeOnsite;
    let registration_fee_transfer = dataRegistrationFeeTransfer;
    let registration_fee_online = dataRegistrationFeeOnline;
    let registration_fee_crew = dataRegistrationFeeCrew;

    let timestamp_attendance_start = dataAttendanceTimestampStart;
    let timestamp_attendance_transfer_end = dataAttendanceTimestampTransferEnd;
    let timestamp_attendance_end = dataAttendanceTimestampEnd;

    let template_attendance_email = dataRegistrationEmailTemplate;
    let race_formula = dataRaceFormula;

    if (errors.length > 0) {
      setCommitErrors(errors);
      return;
    }

    var action = event.createUpdateEvent();

    action.setShortname(shortname);
    action.setDescription(description);
    action.setLocation(location);
    action.setGps(gps);
    action.setTimestampStart(timestamp_start);
    action.setTimestampEnd(timestamp_end);

    action.setRegistrationFeeOnsite(registration_fee_onsite);
    action.setRegistrationFeeTransfer(registration_fee_transfer);
    action.setRegistrationFeeOnline(registration_fee_online);
    action.setRegistrationFeeCrew(registration_fee_crew);

    action.setTimestampAttendanceStart(timestamp_attendance_start);
    action.setTimestampAttendanceTransferEnd(timestamp_attendance_transfer_end);
    action.setTimestampAttendanceEnd(timestamp_attendance_end);

    action.setTemplateAttendanceEmail(template_attendance_email);
    action.setRaceFormula(race_formula);

    action.OnCommitRequested.register((sender, params) => {
      setAsyncOperation(true);
    });
    action.OnCommitFinished.register((sender, params) => {
      setAsyncOperation(false);

      setCommitRedirect("/manage/league/" + leagueExternal);
      setCommitErrors("Wydarzene zostało zaktualizowane");
    });
    action.OnCommitError.register((sender, error) => {
      setAsyncOperation(false);
      setCommitErrors(error.message);
    });

    action.Commit();
  };

  return (
    <header className="w3-container">
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={commitErrors != null}
        autoHideDuration={6000}
        onClose={() => {
          setCommitErrors(null);
          if (null != commitRedirect) {
            navigate(commitRedirect);
          }
        }}
        sx={{ minWidth: "50%" }}
      >
        <Alert severity="info" sx={{ width: "100%" }}>
          {commitErrors}
        </Alert>
      </Snackbar>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={asyncOperation || null == event}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <h5>
        <TimeToLeaveIcon /> Edycja wydarzenia <b>{dataShortname}</b> dla ligi: <b>{leagueName}</b>
      </h5>
      <div className="w3-container register-form">
        <form
          className="w3-form"
          autoComplete={true}
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
        >
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Nazwa wydarzenia"
              variant="standard"
              type={"text"}
              value={dataShortname}
              onChange={(ev) => {
                setDataShortname(ev.target.value);
              }}
              id="data-shortname"
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Opis wydarzenia"
              variant="standard"
              type={"text"}
              value={dataDescription}
              onChange={(ev) => {
                setDataDescription(ev.target.value);
              }}
              id="data-description"
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Lokalizacja wydarzenia"
              variant="standard"
              type={"text"}
              value={dataLocation}
              onChange={(ev) => {
                setDataLocation(ev.target.value);
              }}
              id="data-location"
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Pinezka GPS"
              variant="standard"
              type={"text"}
              value={dataGps}
              onChange={(ev) => {
                setDataGps(ev.target.value);
                setErrorGps(Utils.validate_gps(ev.target.value));
              }}
              id="data-gps"
              error={null != errorGps}
              helperText={errorGps}
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Data i godzina rozpoczecia"
              variant="standard"
              type={"text"}
              value={dataTimestampStart}
              onChange={(ev) => {
                setDataTimestampStart(ev.target.value);
                setErrorTimestampStart(Utils.validate_datetime(ev.target.value));
              }}
              id="data-timestamp-end"
              error={null != errorTimestampStart}
              helperText={errorTimestampStart}
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Data i godzina zakonczenia"
              variant="standard"
              type={"text"}
              value={dataTimestampEnd}
              onChange={(ev) => {
                setDataTimestampEnd(ev.target.value);
                setErrorTimestampEnd(Utils.validate_datetime(ev.target.value));
              }}
              id="data-timestamp-end"
              error={null != errorTimestampEnd}
              helperText={errorTimestampEnd}
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Formula wyscigu"
              variant="standard"
              type={"text"}
              value={dataRaceFormula}
              onChange={(ev) => {
                setDataRaceFormula(ev.target.value);
                setErrorRaceFormula(Utils.validate_race_formula(ev.target.value));
              }}
              id="data-race-formula"
              error={null != errorRaceFormula}
              helperText={errorRaceFormula}
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Start zapisow online"
              variant="standard"
              type={"text"}
              value={dataAttendanceTimestampStart}
              onChange={(ev) => {
                setDataAttendanceTimestampStart(ev.target.value);
                setErrorAttendanceTimestampStart(Utils.validate_datetime(ev.target.value));
              }}
              id="data-attendance-timestamp-start"
              error={null != errorAttendanceTimestampStart}
              helperText={errorAttendanceTimestampStart}
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Koniec mozliwosci placenia przelewem"
              variant="standard"
              type={"text"}
              value={dataAttendanceTimestampTransferEnd}
              onChange={(ev) => {
                setDataAttendanceTimestampTransferEnd(ev.target.value);
                setErrorAttendanceTimestampTransferEnd(Utils.validate_datetime(ev.target.value));
              }}
              id="data-attendance-timestamp-transfer-end"
              error={null != errorAttendanceTimestampTransferEnd}
              helperText={errorAttendanceTimestampTransferEnd}
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Koniec zapisow online"
              variant="standard"
              type={"text"}
              value={dataAttendanceTimestampEnd}
              onChange={(ev) => {
                setDataAttendanceTimestampEnd(ev.target.value);
                setErrorAttendanceTimestampEnd(Utils.validate_datetime(ev.target.value));
              }}
              id="data-attendance-timestamp-end"
              error={null != errorAttendanceTimestampEnd}
              helperText={errorAttendanceTimestampEnd}
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Kwota przy płatności na bramie"
              variant="standard"
              type={"text"}
              value={dataRegistrationFeeOnsite}
              onChange={(ev) => {
                setDataRegistrationFeeOnsite(ev.target.value);
                setErrorRegistrationFeeOnsite(Utils.validate_amount(ev.target.value));
              }}
              id="data-registration-fee-onsite"
              error={null != errorRegistrationFeeOnsite}
              helperText={errorRegistrationFeeOnsite}
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Kwota przy płatności przelewem"
              variant="standard"
              type={"text"}
              value={dataRegistrationFeeTransfer}
              onChange={(ev) => {
                setDataRegistrationFeeTransfer(ev.target.value);
                setErrorRegistrationFeeTransfer(Utils.validate_amount(ev.target.value));
              }}
              id="data-registration-fee-transfer"
              error={null != errorRegistrationFeeTransfer}
              helperText={errorRegistrationFeeTransfer}
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Kwota przy płatności online ( PayU, Dotpay )"
              variant="standard"
              type={"text"}
              value={dataRegistrationFeeOnline}
              onChange={(ev) => {
                setDataRegistrationFeeOnline(ev.target.value);
                setErrorRegistrationFeeOnline(Utils.validate_amount(ev.target.value));
              }}
              id="data-registration-fee-online"
              error={null != errorRegistrationFeeOnline}
              helperText={errorRegistrationFeeOnline}
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <TextField
              label="Kwota za dodatkowa osobe"
              variant="standard"
              type={"text"}
              value={dataRegistrationFeeCrew}
              onChange={(ev) => {
                setDataRegistrationFeeCrew(ev.target.value);
                setErrorRegistrationFeeCrew(Utils.validate_amount(ev.target.value));
              }}
              id="data-registration-fee-crew"
              error={null != errorRegistrationFeeCrew}
              helperText={errorRegistrationFeeCrew}
            />
          </Box>
          <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "100%" } }}>
            <Stack direction="row" useFlexGap flexWrap="wrap" alignItems="center" justifyContent="start" spacing={3}>
              <p>Szablon email rejestracyjny</p>
              <Tooltip title="Ustaw przykladowy">
                <IconButton onClick={() => { setExampleEmailTemplate() }}>
                  <ArticleIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Wyslij testowo do siebie">
                <IconButton onClick={() => { sendExampleEmail() }}>
                  <SendIcon />
                </IconButton>
              </Tooltip>
            </Stack>

            <TextareaAutosize
              variant="standard"
              placeholder="Szablon e-mail"
              minRows={2}
              value={dataRegistrationEmailTemplate}
              onChange={(ev) => {
                setDataRegistrationEmailTemplate(ev.target.value);
              }}
              id="data-registration-email-template"
            />
          </Box>
          <div className="w3-container w3-button w3-right-align">
            <Button variant="contained" color="primary" onClick={executeSave} disabled={asyncOperation || errorGps || errorTimestampStart || errorTimestampEnd || errorRaceFormula || errorRegistrationFeeOnsite || errorRegistrationFeeTransfer || errorRegistrationFeeOnline || errorRegistrationFeeCrew || errorAttendanceTimestampStart || errorAttendanceTimestampEnd || errorAttendanceTimestampTransferEnd}>
              Zapisz zmiany
            </Button>
          </div>
        </form>
      </div>
    </header>
  );
};
//     onUpdateEventCommitFinished(sender, params) {
//         this.endAsyncOperation();
//         this.showAndRedirect('Wydarzene zostało zaktualizowane', '/manage/league/' + this.state.league.external);
//     }

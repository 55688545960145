import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import './ClassesScreen.css'
import { DrawBottomRight } from '../LayoutHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch, faFlagCheckered, faCog, faCar, faSmile } from '@fortawesome/free-solid-svg-icons';
import AttendanceWidget from '../../components/widgets/AttendanceWidget';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SosIcon from '@mui/icons-material/Sos';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';


import Box from '@mui/material/Box';
import HelpIcon from '../../components/common/HelpIcon';


export const ClassesScreen = ({session}) => {
    const urlParams = useParams();
    const navigate = useNavigate();
    
    const [ leagueId, setLeagueId ] = useState(undefined);
    const [ leagues, setLeagues ] = useState(null);

    const [ league, setLeague ] = useState(null);
    const [ classes, setClasses ] = useState(null);

    useEffect(() => {
        setLeagueId(urlParams.leagueId);
    }, [urlParams])


    useEffect( () => { 
        const leagueChangedCallback = (sender, leagues) => {
            setLeagues(leagues) ;
        }
        session.onLeaguesChanged.register(leagueChangedCallback);

        setLeagues(session.getLeagues()) ;
        return () => {
            session.onPersonalizeChanged.unregister(leagueChangedCallback);
        }
    }, [])

    
    useEffect( () => {
        if ( null == leagueId )
        {
            return;
        }
        if ( null == leagues )
        {
            return;
        }
        for ( const l of leagues )
        {
            if ( l.getExternal() != leagueId )
            {
                continue;
            }
            {
                let league = {};
                league.external = l.getExternal();
                league.shortname = l.getShortname();
                league.description = l.getDescription();
                league.thumbnail = l.getThumbnail();
                league.is_finished = l.getIsFinished();

                setLeague(league)
            }
            {
                let classes = [];
                for ( const cls of l.getClasses() )
                {
                    let data = {}
                    data.external = cls.getExternal();
                    data.shortname = cls.getShortname();
                    data.description = cls.getDescription();
                    data.priority = cls.getPriority();
                    data.starting_number = cls.getPlayerStart();
        
                    classes.push(data);
                }

                setClasses(classes);
            }
            break;
        }

    }, [leagueId, leagues]);

    const renderClasses = () => {
        if ( null == classes || classes.length < 1)
        {
            return <Box>Brak zdefiniowanych klas</Box>
        }
        return classes.map((x, index) => {
            return (<TableRow key={x.external} className="w3-padding-16 w3-hoverable w3-tr-with-link">
                <TableCell key={x.external + "-index"} sx={{padding: '2px'}}>{index+1}</TableCell>
                <TableCell sx={{padding: '2px'}}><span className="w3-xlarge">{x.shortname}</span></TableCell>
                <TableCell sx={{padding: '2px'}}>{x.description}</TableCell>
                <TableCell sx={{padding: '2px'}}>{x.starting_number}</TableCell>
                <Link to={'/class/' + x.external} className="w3-rowlink" />
            </TableRow> )
        })
    }

    return (    
        <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
            {null == league && 
                <Box>Trwa wczytywanie danych</Box>
            }
            {null != league &&
                <>
                    <Box>Klasy zawodników w lidze {league.shortname}:</Box>
                    <Table stickyHeader aria-label="sticky table" className="w3-table w3-striped w3-white w3-bordered">
                        <TableHead>
                            <TableRow>
                                <TableCell key={'lp'} align={'left'} style={{ minWidth: '30px' }} >LP</TableCell>
                                <TableCell key={'shortname'} align={'left'} style={{ minWidth: '30px' }} >Nazwa</TableCell>
                                <TableCell key={'description'} align={'left'} style={{ minWidth: '30px' }} >Opis</TableCell>
                                <TableCell key={'starting_number'} align={'left'} style={{ minWidth: '30px' }} >Numery startowe</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderClasses()}
                        </TableBody>
                    </Table>
                </> 
            }
        </Box>
    )
}
import IRaceDetailsPlayer from "../../sdk/data/IRaceDetailsPlayer";

export class RaceDetailsPlayer extends IRaceDetailsPlayer
{
    constructor()
    {
        super();
        this.player_number = null;
        this.reaction_time = null;

        this.time_60ft = null;
        this.time_660ft = null;
        this.time_1000ft = null;
        this.time_1320ft = null;
        this.speed_660ft = null;
        this.speed_1000ft = null;
        this.speed_1320ft = null;
        this.is_invalid = null;
        this.race_time = null;

        this.car_power = null;
        this.car_mass = null;

        this.player_name = null;
        this.player_picture = null;
        this.player_team = null;
        this.player_car = null;

        this.class_id = null;
        this.class_name = null;
    }
    getPlayerNumber()
    {
        return this.player_number;
    }

    getReactionTime()
    {
        return this.reaction_time;
    }
    
    getTime60ft()
    {
        return this.time_60ft;
    }
    
    getTime660ft()
    {
        return this.time_660ft;
    }
    getTime1000ft()
    {
        return this.time_1000ft;
    }
    getTime1320ft()
    {
        return this.time_1320ft;
    }
    getRaceTime()
    {
        return this.race_time;
    }
    getSpeed660ft()
    {
        return this.speed_660ft;
    }
    getSpeed1000ft()
    {
        return this.speed_1000ft;
    }
    getSpeed1320ft()
    {
        return this.speed_1320ft;
    }
    
    getIsInvalid()
    {
        return this.is_invalid;
    }
     
    getCarPower()
    {
        return this.car_power;
    }
    
    getCarMass()
    {
        return this.car_mass;
    }
    
    getPlayerName()
    {
        return this.player_name;
    }

    getPlayerPicture()
    {
        return this.player_picture;
    }
    
    getPlayerTeam()
    {
        return this.player_team;
    }
    
    getPlayerCar()
    {
        return this.player_car;
    }
    
    getClassId()
    {
        return this.class_id;
    }
    
    getClassName()
    {
        return this.class_name;
    }
}

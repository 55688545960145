import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import autoBind from 'react-autobind';
import './Footer.css';

class Footer extends Component {
  static defaultProps = {
  }

  constructor(props) {
    super(props)
    autoBind(this);
    this.state = this.processProps(props);
  }

  componentWillReceiveProps(props)
  {
    this.setState(this.processProps(props));
  }

  processProps(props)
  {
    return {};
  }

  render() {
    return (
        <footer className="u-align-center u-clearfix u-footer u-grey-80 u-footer no-print" id="sec-4d45">
            <div className="u-clearfix u-sheet u-sheet-1">
                <p className="u-small-text u-text u-text-variant u-text-1">Przykładowy tekst. Lorem ipsum dolor sit amet, consectetur adipiscing elit nullam nunc justo sagittis suscipit ultrices.</p>
            </div>
        </footer>
    );
  }
}

export default Footer;
export default class IPlayerStats {
    getPlayer() {
        throw new TypeError("Method not implemented");
    }

    getPlayerClass()
    {
        throw new TypeError("Method not implemented");
    }

    getDriverName() {
        throw new TypeError("Method not implemented");
    }

    getTeamName() {
        throw new TypeError("Method not implemented");
    }

    getPlayerCar() {
        throw new TypeError("Method not implemented");
    }

    getCarPower()
    {
        throw new TypeError("Method not implemented");
    }

    getCarMass()
    {
        throw new TypeError("Method not implemented");
    }

    getSeasonBestTime1320ft()
    {
        throw new TypeError("Method not implemented");
    }
    getSeasonBestSpeed1320ft()
    {
        throw new TypeError("Method not implemented");
    }

    getEventBestTime1320ft()
    {
        throw new TypeError("Method not implemented");
    }
    getEventBestSpeed1320ft()
    {
        throw new TypeError("Method not implemented");
    }
    getPictureUrl()
    {
        throw new TypeError("Method not implemented");
    }    
}
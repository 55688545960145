import Event from '../property/Event';

export default class IPlayersUpload
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();
    
    setLeague(league)
    {
        throw new TypeError("Method not implemented");
    }

    setData(data)
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }
}
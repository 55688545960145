import Event from "../../../property/Event";

export default class IRawResult {
    getToken() {
        throw new TypeError("Method not implemented");
    }
    setToken(token) {
        throw new TypeError("Method not implemented");
    }
    OnQueryRequested = new Event();
    OnQueryFinished = new Event();
    OnQueryError = new Event();

    Query() {
        throw new TypeError("Method not implemented");
    }

    getPlayer() {
        throw new TypeError("Method not implemented");
    }

    getLane() {
        throw new TypeError("Method not implemented");
    }

    getMeasuredReactionTime() {
        throw new TypeError("Method not implemented");
    }
    getDisplayedReactionTime() {
        throw new TypeError("Method not implemented");
    }

    getMeasuredTime60ft() {
        throw new TypeError("Method not implemented");
    }
    getMeasuredTime330ft() {
        throw new TypeError("Method not implemented");
    }
    getMeasuredTime660ft() {
        throw new TypeError("Method not implemented");
    }
    getMeasuredTime1000ft() {
        throw new TypeError("Method not implemented");
    }
    getMeasuredTime1320ft() {
        throw new TypeError("Method not implemented");
    }

    getMeasuredSpeed60ft() {
        throw new TypeError("Method not implemented");
    }
    getMeasuredSpeed330ft() {
        throw new TypeError("Method not implemented");
    }
    getMeasuredSpeed660ft() {
        throw new TypeError("Method not implemented");
    }
    getMeasuredSpeed1000ft() {
        throw new TypeError("Method not implemented");
    }
    getMeasuredSpeed1320ft() {
        throw new TypeError("Method not implemented");
    }

    getDisplayedTime60ft() {
        throw new TypeError("Method not implemented");
    }
    getDisplayedTime330ft() {
        throw new TypeError("Method not implemented");
    }
    getDisplayedTime660ft() {
        throw new TypeError("Method not implemented");
    }
    getDisplayedTime1000ft() {
        throw new TypeError("Method not implemented");
    }
    getDisplayedTime1320ft() {
        throw new TypeError("Method not implemented");
    }

    getDisplayedSpeed60ft() {
        throw new TypeError("Method not implemented");
    }
    getDisplayedSpeed330ft() {
        throw new TypeError("Method not implemented");
    }
    getDisplayedSpeed660ft() {
        throw new TypeError("Method not implemented");
    }
    getDisplayedSpeed1000ft() {
        throw new TypeError("Method not implemented");
    }
    getDisplayedSpeed1320ft() {
        throw new TypeError("Method not implemented");
    }

    getIsInvalid() {
        throw new TypeError("Method not implemented");
    }
    getIsPublic() {
        throw new TypeError("Method not implemented");
    }
    getTimestampRace() {
        throw new TypeError("Method not implemented");
    }

    createUpdateRawResult() {
        throw new TypeError("Method not implemented");
    }
}
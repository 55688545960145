import IPlayersDownload from '../../sdk/data/IPlayersDownload';

export default class PlayersDownload extends IPlayersDownload
{
    constructor()
    {
        super();
        this.data = null;
        this.league = null;
    }

    setLeague(league)
    {
        this.league = league;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getData()
    {
        return this.data;
    }    
}
import Session from '../Session'

import IServiceResult from '../connect/IServiceResult';
import Engine, { Error } from '../Engine';

import Log from '../logger/Log';
import ApiUtils from '../api/ApiUtils';
import { Race } from '../data/Race';
import LatestResultsCommit from '../api/livescreen/LatestResultsCommit';
import NextRaceCommit from '../api/livescreen/NextRaceCommit';
import SetNextRaceCommit from '../api/livescreen/SetNextRaceCommit';
import { PlayerStats } from '../data/PlayerStats';

import CurrentResultsCommit from '../api/livescreen/CurrentResultsCommit';
import { ClassResults, PlayerResult } from '../data/livescreen/CurrentResults';


export default class LivescreenMonitor {
    constructor(/*Session*/ session) {
        this.mSession = session;
        session.onLatestResultCreated.register(this.onLatestResultCreated.bind(this));
        session.onNextRaceCreated.register(this.onNextRaceCreated.bind(this));
        session.onCurrentResultsCreated.register(this.onCurrentResultsCreated.bind(this));
        session.onSetNextRaceCreated.register(this.onSetNextRaceCreated.bind(this));
    }
    
    onLatestResultCreated(/*Object*/ sender, /*{Timeslip}*/ action) {
        action.OnCommitRequested.register(this.onLatestResultCommitRequested.bind(this));
    }


    onLatestResultCommitRequested(/*RaceResults*/ sender, /*Void*/ params) {
        var /*ProjectCreate*/ action = sender;
        var request = new LatestResultsCommit();
        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                let left = reply.opt('left', null);
                if (left != null) {
                    let race = new Race();

                    race.token = left.opt('token', null);
                    race.player = left.opt('player', null);
                    race.player_name = left.opt('player_name', null);
                    race.player_car = left.opt('player_car', null);
                    race.reaction_time = left.opt('reaction_time', null);
                    race.time_60ft = left.opt('time_60ft', null);
                    race.time_660ft = left.opt('time_660ft', null);
                    race.time_1320ft = left.opt('time_1320ft', null);
                    race.speed_660ft = left.opt('speed_660ft', null);
                    race.speed_1320ft = left.opt('speed_1320ft', null);
                    race.timestamp_race = ApiUtils.deserializeDateTime(left.opt('timestamp_race', null));
                    race.is_invalid = left.opt('is_invalid', null);
                    race.is_public = left.opt('is_public', null);
                    race.picture_url = left.opt('picture_url', null);

                    action.left = race;
                }
                let right = reply.opt('right', null);
                if (right != null) {
                    let race = new Race();

                    race.token = right.opt('token', null);
                    race.player = right.opt('player', null);
                    race.player_name = right.opt('player_name', null);
                    race.player_car = right.opt('player_car', null);
                    race.reaction_time = right.opt('reaction_time', null);
                    race.time_60ft = right.opt('time_60ft', null);
                    race.time_660ft = right.opt('time_660ft', null);
                    race.time_1320ft = right.opt('time_1320ft', null);
                    race.speed_660ft = right.opt('speed_660ft', null);
                    race.speed_1320ft = right.opt('speed_1320ft', null);
                    race.timestamp_race = ApiUtils.deserializeDateTime(right.opt('timestamp_race', null));
                    race.is_invalid = right.opt('is_invalid', null);
                    race.is_public = right.opt('is_public', null);
                    race.picture_url = right.opt('picture_url', null);

                    action.right = race;
                }
                action.timestamp = ApiUtils.deserializeDateTime(reply.opt('timestamp', null));
                action.has_results = left != null || right != null;
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );
        this.mSession.SessionRequest("RESULTS", result, request);
    }

    onNextRaceCreated(/*Object*/ sender, /*{Timeslip}*/ action) {
        action.OnCommitRequested.register(this.onNextRaceCommitRequested.bind(this));
    }

    onNextRaceCommitRequested(/*RaceResults*/ sender, /*Void*/ params) {
        var /*ProjectCreate*/ action = sender;
        var request = new NextRaceCommit();
        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                let left = reply.opt('left', null);
                if (left != null) {
                    let playerStats = new PlayerStats();

                    playerStats.player = left.opt('player', null);
                    playerStats.driver_name = left.opt('driver_name', null);
                    playerStats.team_name = left.opt('team_name', null);

                    playerStats.player_car = left.opt('player_car', null);
                    playerStats.car_power = left.opt('car_power', null);
                    playerStats.car_mass = left.opt('car_mass', null);
                    playerStats.player_class = left.opt('player_class', null);
                    playerStats.season_best_time_1320ft = left.opt('season_best_time_1320ft', null);
                    playerStats.season_best_speed_1320ft = left.opt('season_best_speed_1320ft', null);

                    playerStats.event_best_time_1320ft = left.opt('event_best_time_1320ft', null);
                    playerStats.event_best_speed_1320ft = left.opt('event_best_speed_1320ft', null);
                    playerStats.picture_url = left.opt('picture_url', null);

                    action.left = playerStats;
                }
                let right = reply.opt('right', null);
                if (right != null) {
                    let playerStats = new PlayerStats();

                    playerStats.player = right.opt('player', null);
                    playerStats.driver_name = right.opt('driver_name', null);
                    playerStats.team_name = right.opt('team_name', null);

                    playerStats.player_car = right.opt('player_car', null);
                    playerStats.car_power = right.opt('car_power', null);
                    playerStats.car_mass = right.opt('car_mass', null);
                    playerStats.player_class = right.opt('player_class', null);
                    playerStats.season_best_time_1320ft = right.opt('season_best_time_1320ft', null);
                    playerStats.season_best_speed_1320ft = right.opt('season_best_speed_1320ft', null);

                    playerStats.event_best_time_1320ft = right.opt('event_best_time_1320ft', null);
                    playerStats.event_best_speed_1320ft = right.opt('event_best_speed_1320ft', null);
                    playerStats.picture_url = right.opt('picture_url', null);

                    action.right = playerStats;
                }
                action.timestamp = ApiUtils.deserializeDateTime(reply.opt('timestamp', null));
                action.has_results = left != null || right != null;
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );
        this.mSession.SessionRequest("RESULTS", result, request);
    }

    onSetNextRaceCreated(/*Object*/ sender, /*{Timeslip}*/ action) {
        action.OnCommitRequested.register(this.onSetNextRaceCommitRequested.bind(this));
    }

    onSetNextRaceCommitRequested(/*RaceResults*/ sender, /*Void*/ params) {
        var /*ProjectCreate*/ action = sender;
        var request = new SetNextRaceCommit();

        request.setLeague(action.league);
        request.setEvent(action.event);
        request.setLeft(action.left);
        request.setRight(action.right);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.timestamp = ApiUtils.deserializeDateTime(reply.opt('timestamp', null));
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );
        this.mSession.SessionRequest("RESULTS", result, request);
    }

    onCurrentResultsCreated(/*Object*/ sender, /*{Timeslip}*/ action) {
        action.OnCommitRequested.register(this.onCurrentResultsCommitRequested.bind(this));
    }

    onCurrentResultsCommitRequested(/*RaceResults*/ sender, /*Void*/ params) {
        var /*ProjectCreate*/ action = sender;
        var request = new CurrentResultsCommit();
        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                
                let _results = reply.opt('results', null );
                if ( null != _results )
                {
                    var clazzez = [];

                    let size = _results.length();
                    for (var i = 0; i < size; ++i) {
                        let _clazz = _results.get(i);

                        var clazz = new ClassResults();
                        clazz.class_name = _clazz.opt("class_name", null);
                        clazz.timestamp = ApiUtils.deserializeDateTime(_clazz.opt('timestamp', null));

                        let _drivers = _clazz.opt("players", null);
                        if ( null != _drivers )
                        {
                            var results = [];

                            let psize = _drivers.length();
                            for (var y = 0; y < psize; ++y) {
                                let _player = _drivers.get(y);

                                var r = new PlayerResult();
                                r.driver_number = _player.opt("driver_number", null);
                                r.driver_name = _player.opt("driver_name", null);
                                r.driver_car = _player.opt("driver_car", null);
                                r.best_et = _player.opt("best_et", null);
                                r.best_speed = _player.opt("best_speed", null);

                                results.push(r);
                            }

                            clazz.results = results;
                        }

                        clazzez.push(clazz);
                    }
                    action.results = clazzez;
                }
                
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );
        this.mSession.SessionRequest("RESULTS", result, request);
    }
}
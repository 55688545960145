import IRaceDetails from "../../sdk/data/IRaceDetails";

export class RaceDetails extends IRaceDetails
{
    constructor()
    {
        super();
        this.race_id = null;

        this.league_id = null;
        this.league_name = null;

        this.event_id = null;
        this.event_name = null;
        this.event_location = null;

        this.timestamp_race = null;
        this.left = null;
        this.right = null;
    }

    setRaceId(id)
    {
        this.race_id = id;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getLeagueId()
    {
        return this.league_id;
    }

    getLeagueName()
    {
        return this.league_name;
    }

    getEventId()
    {
        return this.event_id;
    }

    getEventName()
    {
        return this.event_name;
    }

    getEventLocation()
    {
        return this.event_location;
    }

    getTimestampRace()
    {
        return this.timestamp_race;
    }

    getLeft()
    {
        return this.left;
    }

    getRight()
    {
        return this.right;
    }
}
import Event from "../../../property/Event";

export default class ICreateClass
{
    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    getShortname()
    {
        throw new TypeError("Method not implemented");
    }
    setShortname(name)
    {
        throw new TypeError("Method not implemented");
    }

    getDescription()
    {
        throw new TypeError("Method not implemented");
    }
    setDescription(description)
    {
        throw new TypeError("Method not implemented");
    }

    getPriority()
    {
        throw new TypeError("Method not implemented");
    }
    setPriority(value)
    {
        throw new TypeError("Method not implemented");
    }

    getPlayerStart()
    {
        throw new TypeError("Method not implemented");
    }
    setPlayerStart(value)
    {
        throw new TypeError("Method not implemented");
    }
    getPlayerMask()
    {
        throw new TypeError("Method not implemented");
    }
    setPlayerMask(value)
    {
        throw new TypeError("Method not implemented");
    }

    Commit()
    {
        throw new TypeError("Method not implemented");
    }

    getExternal()
    {
        throw new TypeError("Method not implemented");
    }
}
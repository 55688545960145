import Log from '../../internal/logger/Log';

export default class Event
{
    constructor()
    {
        this.delegates = [];

		this.add_queue = [];
        this.remove_queue = [];
        this.processing = false;
    }


    callback(sender, params)
    {
        this.processing = true;

        var size = this.delegates.length;
        for ( var i = size -1; i >= 0; --i )
        {
            try
            {
                this.delegates[i](sender, params);
            }
            catch ( /* Exception*/ ex )
            {
                Log.e('EVENT', 'Exception during processing of ' + this.delegates[i] + ':' + ex.toString());
            }
        }

        {
            for ( var d in this.remove_queue )
            {
                this.delegates = this.delegates.filter( i => i !== d );
            }
        }

        this.remove_queue = [];
        {
            for ( var d in this.add_queue )
            {
                this.delegates.push(d);
            }

            this.add_queue = [];
        }

        this.processing = false;
    }

    register(delegate)
    {
        if (! delegate instanceof Function)
        {
            console.log('Registering non function ' + delegate);
        }
        if ( this.processing )
        {
            this.add_queue.push(delegate);
            return;
        }
        this.delegates.push(delegate)
    }

    unregister(delegate)
    {
        if ( this.processing )
        {
            this.remove_queue.push(delegate);
            return;
        }
        this.delegates = this.delegates.filter(i => i !== delegate)
    }
}
import IUpdateLeague from "../../../../sdk/data/manage/league/IUpdateLeague";

export class UpdateLeague extends IUpdateLeague
{
    constructor()
    {
        super();
        
        this.external = null;
        this.shortname = null;
        this.description = null;
    }

    getShortname()
    {
        return this.shortname;
    }

    setDescription(value)
    {
        this.description = value;
    }

    getDescription()
    {
        return this.description;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getExternal()
    {
        return this.external;
    }
}
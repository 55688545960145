import IChatMessage from "../../../sdk/data/chat/IChatMessage";

export class ChatMessage extends IChatMessage
{
    constructor()
    {
        super();
        this.external = null;
        this.author_name = null;
        this.avatar = null;
        this.timestamp = null;
        this.message = null;   
    }

    getExternal()
    {
        return this.external;
    }

    getAuthorName()
    {
        return this.author_name;
    }

    getAvatar()
    {
        return this.avatar;
    }

    getTimestamp()
    {
        return this.timestamp;
    }

    getMessage()
    {
        return this.message;
    }
}
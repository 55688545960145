import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const SimplePopup = ({open, fullScreen, title, body, accept='TAK', reject='NIE', onAccepted = () => {}, onRejected = () => {} }) => {
    let _body = [];
    if ( Array.isArray(body) )
    {
        _body = body.map((x) => {
            return <DialogContentText id="alert-dialog-description" >{x}</DialogContentText>
        })
    }
    else
    {
        _body.push(<DialogContentText id="alert-dialog-description" >{body}</DialogContentText>);
    }
    return (
    <>
     <Dialog open={open} fullScreen={fullScreen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >
        {null != title && 
            <DialogTitle id="alert-dialog-title">{title} </DialogTitle>
        }
        {_body.length > 0 && 
            <DialogContent dividers={true}>
                {_body}
            </DialogContent>
        }
        <DialogActions>
            {reject && 
                <Button onClick={onRejected} >{reject}</Button>
            }
            {accept && 
                <Button onClick={onAccepted} autoFocus>{accept} </Button>
            }
        </DialogActions>
    </Dialog>
    </>);
}
import Session from '../Session'

import IServiceResult from '../connect/IServiceResult';
import Engine, { Error } from '../Engine';

import Log from '../logger/Log';
import ApiUtils from '../api/ApiUtils';
import ResultsCommit from '../api/results/ResultsCommit';
import { Race } from '../data/Race';
import TimeslipCommit from '../api/results/TimeslipCommit';
import { PlayerStats } from '../data/PlayerStats';
import { RaceDetailsPlayer } from '../data/RaceDetailsPlayer';
import RaceDetailsCommit from '../api/results/RaceDetailsCommit';


export default class ResultsMonitor {
    constructor(/*Session*/ session) {
        this.mSession = session;
        session.onRaceResultsCreated.register(this.onRaceResultsCreated.bind(this));
        session.onTimeslipCreated.register(this.onTimeslipCreated.bind(this));
        session.onRaceDetailsCreated.register(this.onRaceDetailsCreated.bind(this));
    }
    onRaceResultsCreated(/*Object*/ sender, /*{RaceResults}*/ action) {
        action.OnCommitRequested.register(this.onRaceResultsCommitRequested.bind(this));
    }

    onRaceResultsCommitRequested(/*RaceResults*/ sender, /*Void*/ params) {
        var /*ProjectCreate*/ action = sender;
        var request = new ResultsCommit();
        request.setEvent(action.event_id);
        if (action.player_id) {
            request.setPlayer(action.player_id);
        }
        if (action.class_id) {
            request.setClass(action.class_id);
        }
        if (action.timestamp) {
            request.setTimestamp(action.timestamp);
        }
        if(action.allowed_players) {
            request.setAllowedPlayers(action.allowed_players);
        }

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                if (reply.results) {
                    let _results = reply.get('results');
                    let results = [];
                    if (action.timestamp) {
                        results = action.results.slice(0);
                    }
                    let size = _results.length();
                    for (var i = 0; i < size; ++i) {
                        let ob = _results.get(i);

                        let status = ob.opt('status', 'ADDED');
                        let token = ob.opt('token', null);
                        if ("UPDATED" == status) {
                            let race = results.find((x) => { return x.token == token });
                            if (null != race) {
                                race.is_invalid = ob.opt('is_invalid', null);
                                race.is_public = ob.opt('is_public', null);
                            }
                            continue;
                        }
                        let race = new Race();

                        race.token = token;
                        race.player = ob.opt('player', null);
                        race.reaction_time = ob.opt('reaction_time', null);
                        race.time_60ft = ob.opt('time_60ft', null);
                        race.time_660ft = ob.opt('time_660ft', null);
                        race.time_1000ft = ob.opt('time_1000ft', null);
                        race.time_1320ft = ob.opt('time_1320ft', null);
                        race.speed_660ft = ob.opt('speed_660ft', null);
                        race.speed_1000ft = ob.opt('speed_1000ft', null);
                        race.speed_1320ft = ob.opt('speed_1320ft', null);
                        race.race_time = ob.opt('race_time', null);
                        race.timestamp_race = ApiUtils.deserializeDateTime(ob.opt('timestamp_race', null));
                        race.is_invalid = ob.opt('is_invalid', null);
                        race.is_public = ob.opt('is_public', null);
                        race.event = ob.opt('event', null);

                        results.push(race);
                    }

                    action.results = results;
                }
                action.is_finished = reply.opt('is_finished', true);
                action.timestamp = ApiUtils.deserializeDateTime(reply.opt('timestamp', null));
                action.interval = reply.opt('interval', null);
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("RESULTS", result, request);
    }

    onTimeslipCreated(/*Object*/ sender, /*{Timeslip}*/ action) {
        action.OnCommitRequested.register(this.onTimeslipCommitRequested.bind(this));
    }


    onTimeslipCommitRequested(/*RaceResults*/ sender, /*Void*/ params) {
        var /*ProjectCreate*/ action = sender;
        var request = new TimeslipCommit();
        request.setToken(action.token);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.league_name = reply.opt('league_name', null);
                action.event_name = reply.opt('event_name', null);

                action.player_name = reply.opt('player_name', null);
                action.player_number = reply.opt('player_number', null);
                action.player_team = reply.opt('player_team', null);
                action.player_car = reply.opt('player_car', null);
                action.player_class = reply.opt('player_class', null);

                action.reaction_time = reply.opt('reaction_time', null);

                action.time_60ft = reply.opt('time_60ft', null);
                action.time_660ft = reply.opt('time_660ft', null);
                action.time_1320ft = reply.opt('time_1320ft', null);

                action.speed_660ft = reply.opt('speed_660ft', null);
                action.speed_1320ft = reply.opt('speed_1320ft', null);

                action.picture_driver = reply.opt('picture_driver', null);
                action.picture_car = reply.opt('picture_car', null);

                action.timestamp_race = ApiUtils.deserializeDateTime(reply.opt('timestamp_race', null));
                action.is_invalid = reply.opt('is_invalid', null);
                action.is_public = reply.opt('is_public', null);

                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("TIMESLIP", result, request);
    }


    onRaceDetailsCreated(/*Object*/ sender, /*{Timeslip}*/ action) {
        action.OnCommitRequested.register(this.onRaceDetailsCommitRequested.bind(this));
    }


    onRaceDetailsCommitRequested(/*RaceDetails*/ sender, /*Void*/ params) {
        var /*RaceDetails*/ action = sender;
        var request = new RaceDetailsCommit();
        request.setToken(action.race_id);
        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {

                action.league_id = reply.opt('league_id', null);
                action.league_name = reply.opt('league_name', null);
                action.event_id = reply.opt('event_id', null);
                action.event_name = reply.opt('event_name', null);
                action.event_location = reply.opt('event_location', null);

                action.timestamp_race = ApiUtils.deserializeDateTime(reply.opt('timestamp_race', null));
                let left = reply.opt('left', null);
                if (left != null) {
                    let playerStats = new RaceDetailsPlayer();

                    playerStats.player_number = left.opt('player_number', null);
                    playerStats.reaction_time = left.opt('reaction_time', null);

                    playerStats.time_60ft = left.opt('time_60ft', null);
                    playerStats.time_660ft = left.opt('time_660ft', null);
                    playerStats.time_1000ft = left.opt('time_1000ft', null);
                    playerStats.time_1320ft = left.opt('time_1320ft', null);
                    playerStats.speed_660ft = left.opt('speed_660ft', null);
                    playerStats.speed_1000ft = left.opt('speed_1000ft', null);
                    playerStats.speed_1320ft = left.opt('speed_1320ft', null);
                    playerStats.is_invalid = left.opt('is_invalid', false);

                    playerStats.race_time = left.opt('race_time', null);

                    playerStats.car_power = left.opt('car_power', null);
                    playerStats.car_mass = left.opt('car_mass', null);

                    playerStats.player_name = left.opt('player_name', null);
                    playerStats.player_picture = left.opt('player_picture', null);
                    playerStats.player_team = left.opt('player_team', null);
                    playerStats.player_car = left.opt('player_car', null);

                    playerStats.class_id = left.opt('class_id', null);
                    playerStats.class_name = left.opt('class_name', null);

                    action.left = playerStats;
                }
                let right = reply.opt('right', null);
                if (right != null) {
                    let playerStats = new RaceDetailsPlayer();

                    playerStats.player_number = right.opt('player_number', null);
                    playerStats.reaction_time = right.opt('reaction_time', null);

                    playerStats.time_60ft = right.opt('time_60ft', null);
                    playerStats.time_660ft = right.opt('time_660ft', null);
                    playerStats.time_1000ft = right.opt('time_1000ft', null);
                    playerStats.time_1320ft = right.opt('time_1320ft', null);
                    playerStats.speed_660ft = right.opt('speed_660ft', null);
                    playerStats.speed_1000ft = right.opt('speed_1000ft', null);
                    playerStats.speed_1320ft = right.opt('speed_1320ft', null);
                    playerStats.is_invalid = right.opt('is_invalid', false);
                    playerStats.race_time = right.opt('race_time', null);

                    playerStats.car_power = right.opt('car_power', null);
                    playerStats.car_mass = right.opt('car_mass', null);

                    playerStats.player_name = right.opt('player_name', null);
                    playerStats.player_picture = right.opt('player_picture', null);
                    playerStats.player_team = right.opt('player_team', null);
                    playerStats.player_car = right.opt('player_car', null);

                    playerStats.class_id = right.opt('class_id', null);
                    playerStats.class_name = right.opt('class_name', null);

                    action.right = playerStats;
                }
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );
        this.mSession.SessionRequest("RESULTS", result, request);
    }
}
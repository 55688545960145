import Session from '../Session'

import IServiceResult from '../connect/IServiceResult';
import Engine, { Error } from '../Engine';
import ApiUtils from '../api/ApiUtils';
import EventCreateCommit from '../api/manage/event/EventCreateCommit';
import EventUpdateCommit from '../api/manage/event/EventUpdateCommit';
import EventRemoveCommit from '../api/manage/event/EventRemoveCommit';
import ResultsRemoveCommit from '../api/manage/event/ResultsRemoveCommit';
import ResultsUploadCommit from '../api/manage/event/ResultsUploadCommit';
import EventExampleEmailCommit from '../api/manage/event/EventExampleEmailCommit';

export default class EventMonitor {
    constructor(/*Session*/ session) {
        this.mSession = session;
        session.onLeaguesChanged.register(this.onLeaguesChanged.bind(this));
    }
    
    onLeaguesChanged(/*Object*/ sender, leagues)
    {
        if ( !leagues )
        {
            return;
        }
        for ( const league of leagues )
        {
            league.onCreateEventCreated.register(this.onCreateEventCreated.bind(this));

            for ( const ev of league.events )
            {
                ev.onUpdateEventCreated.register(this.onUpdateEventCreated.bind(this));
                ev.onRemoveEventCreated.register(this.onRemoveEventCreated.bind(this));

                ev.onRemoveResultsCreated.register(this.onRemoveResultsCreated.bind(this));
                ev.onUploadResultsCreated.register(this.onUploadResultsCreated.bind(this));

                ev.onExampleEmailCreated.register(this.onExampleEmailCreated.bind(this));
            }
        }
    }

    onCreateEventCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitRequested.register(this.onCreateEventCommitRequested.bind(this));
    }


    onCreateEventCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new EventCreateCommit();

        request.setLeague(action.league);
        request.setShortname(action.shortname);
        request.setDescription(action.description);
        request.setLocation(action.location);
        request.setGps(action.gps);
        request.setTimestampStart(action.timestamp_start);
        request.setTimestampEnd(action.timestamp_end);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.external = reply.opt('external', null);
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("MANAGE", result, request);
    }

    
    onRemoveEventCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitRequested.register(this.onRemoveEventCommitRequested.bind(this));
    }


    onRemoveEventCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new EventRemoveCommit();

        request.setExternal(action.external);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("MANAGE", result, request);
    }

      
    onUpdateEventCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitRequested.register(this.onUpdateEventCommitRequested.bind(this));
    }


    onUpdateEventCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new EventUpdateCommit();

        request.setExternal(action.external);

        request.setShortname(action.shortname);
        request.setDescription(action.description);
        request.setLocation(action.location);
        request.setGps(action.gps);
        request.setTimestampStart(action.timestamp_start);
        request.setTimestampEnd(action.timestamp_end);

        request.setRegistrationFeeOnsite(action.registration_fee_onsite);
        request.setRegistrationFeeTransfer(action.registration_fee_transfer);
        request.setRegistrationFeeOnline(action.registration_fee_online);
        request.setRegistrationFeeCrew(action.registration_fee_crew);
        request.setTimestampAttendanceStart(action.timestamp_attendance_start);
        request.setTimestampAttendanceTransferEnd(action.timestamp_attendance_transfer_end);
        request.setTimestampAttendanceEnd(action.timestamp_attendance_end);
        request.setTemplateAttendanceEmail(action.template_attendance_email);
        request.setRaceFormula(action.race_formula);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("MANAGE", result, request);
    }

     
    onRemoveResultsCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitRequested.register(this.onRemoveResultsCommitRequested.bind(this));
    }


    onRemoveResultsCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new ResultsRemoveCommit();

        request.setExternal(action.external);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("MANAGE", result, request);
    }

    
    onUploadResultsCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitRequested.register(this.onUploadResultsCommitRequested.bind(this));
    }


    onUploadResultsCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new ResultsUploadCommit();

        request.setExternal(action.external);
        request.setData(action.data);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("MANAGE", result, request);
    }

    
    onExampleEmailCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitRequested.register(this.onExampleEmailCommitRequested.bind(this));
    }


    onExampleEmailCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new EventExampleEmailCommit();

        request.setExternal(action.external);
        request.setTemplateAttendanceEmail(action.template_attendance_email);
        request.setCrewCount(action.crew_count);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.email = reply.opt('email', null);
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("MANAGE", result, request);
    }

}
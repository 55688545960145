import React, { Component } from 'react';
import autoBind from 'react-autobind';
import './Loader.css';

class Loader extends Component {
  constructor(props)
  {
    super(props)
    autoBind(this);
  }

  render() {
    return (
        <div className="Loader">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35px" height="35px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" xmlSpace="preserve">
            <path fill="#025fb6" stroke="none" className="tire" d="M96.986,49.287H100c-0.034-2.642-0.277-5.234-0.708-7.754l-2.974,0.547l-0.25-1.357l2.97-0.557
              c-0.558-2.803-1.353-5.518-2.358-8.135l-2.812,1.123l-0.514-1.279l2.813-1.133c-1.128-2.69-2.479-5.264-4.033-7.695l-2.528,1.7
              l-0.763-1.162l2.524-1.66c-1.646-2.417-3.496-4.678-5.527-6.768l-2.134,2.139l-0.981-0.977l2.135-2.139
              c-1.855-1.807-3.857-3.477-5.981-4.98l-1.714,2.495l-1.143-0.786l1.713-2.495c-2.338-1.567-4.823-2.935-7.422-4.092L69.116,7.1
              l-1.271-0.547l1.191-2.793c-2.656-1.099-5.43-1.973-8.296-2.598l-0.61,2.979l-1.357-0.278l0.615-2.979C56.566,0.3,53.7,0,50.7,0
              v3.037h-1.377V0c-2.646,0.039-5.234,0.273-7.759,0.703l0.557,2.998l-1.357,0.254l-0.557-2.998c-2.813,0.557-5.537,1.353-8.145,2.358
              l1.133,2.837l-1.3,0.518l-1.133-2.842c-2.695,1.123-5.273,2.48-7.705,4.038l1.68,2.6l-1.152,0.762l-1.68-2.549
              c-2.422,1.641-4.688,3.496-6.768,5.527l2.158,2.158l-0.986,0.977l-2.158-2.158c-1.807,1.865-3.477,3.867-4.98,5.991l2.52,1.733
              L10.91,24.05l-2.52-1.733c-1.572,2.344-2.939,4.829-4.102,7.427l2.822,1.2l-0.537,1.27L3.75,30.972
              c-1.104,2.661-1.973,5.435-2.598,8.306l3.008,0.615L3.877,41.25l-2.998-0.62C0.342,43.5,0,46.4,0,49.331h3.066v1.382H0
              c0.039,2.6,0.3,5.2,0.7,7.764l3.03-0.557l0.254,1.357l-3.018,0.6c0.557,2.8,1.4,5.5,2.4,8.148l2.852-1.143l0.518,1.279l-2.852,1.143
              c1.123,2.7,2.5,5.301,4,7.705l2.559-1.689l0.762,1.162l-2.559,1.681c1.65,2.399,3.5,4.7,5.5,6.769l2.158-2.158l0.977,0.977
              l-2.158,2.2c1.865,1.8,3.9,3.5,6,4.985l1.729-2.521l1.143,0.786l-1.729,2.515c2.344,1.601,4.8,2.9,7.4,4.087l1.211-2.812l1.27,0.547
              l-1.201,2.812c2.656,1.101,5.4,2,8.3,2.588l0.615-2.987l1.357,0.278l-0.615,3c2.822,0.5,5.7,0.799,8.7,0.869v-3.037h1.386V100
              c2.643-0.039,5.234-0.283,7.76-0.713l-0.557-2.988l1.361-0.254l0.557,3c2.803-0.557,5.527-1.357,8.141-2.363l-1.133-2.822
              l1.283-0.518l1.133,2.822c2.695-1.123,5.265-2.48,7.695-4.038l-1.67-2.534l1.157-0.762l1.665,2.529
              c2.417-1.646,4.678-3.496,6.768-5.527l-2.139-2.139l0.977-0.978l2.14,2.101c1.807-1.855,3.478-3.857,4.979-5.986l-2.5-1.709
              l0.791-1.143l2.49,1.709c1.562-2.34,2.93-4.824,4.082-7.422l-2.783-1.191l0.547-1.27l2.783,1.19
              c1.094-2.661,1.963-5.431,2.588-8.302l-2.959-0.604l0.273-1.356l2.964,0.6c0.532-2.816,0.83-5.729,0.869-8.691h-3.013v-1.354H96.986
              z M50,87.936c-20.952,0-37.935-16.982-37.935-37.931c0-20.957,16.982-37.939,37.935-37.939s37.939,17,37.9,37.939
              C87.939,71,71,87.9,50,87.936z"/>
            <path fill="#013d75" stroke="none" className="rim" d="M50.005,16.177c-18.677,0-33.823,15.146-33.823,33.823s15.146,33.8,33.8,33.823C68.654,83.823,83.8,68.677,83.8,50
              S68.677,16.2,50,16.177H50.005z M54.995,22.925c0.552-0.889,1.729-1.611,4.8-0.552c3.071,1.1,5.2,2.4,7,3.6
              c1.709,1.2,5.6,4.5,6.7,6.533c1.128,2,1.399,3,0.8,3.643c-1.094,1.182-10.889,4.204-12.046,4.3
              c-1.162,0.132-3.104,0.518-4.824-0.674c-0.894-0.63-2.03-1.885-2.231-4.766C54.956,32.1,54.4,23.8,55,22.925H54.995z M59.253,49.878
              c-1.328,0.391-2.72-0.366-3.11-1.689c-0.39-1.323,0.366-2.715,1.694-3.105c1.323-0.391,2.7,0.4,3.101,1.7
              c0.399,1.3-0.361,2.716-1.638,3.094H59.253z M53.604,55.293c1.144-0.775,2.695-0.479,3.5,0.664c0.776,1.1,0.5,2.695-0.658,3.5
              c-1.139,0.775-2.695,0.482-3.473-0.659C52.168,57.6,52.5,56.1,53.6,55.293H53.604z M50,38.594c1.382,0,2.5,1.1,2.5,2.5
              c0,1.4-1.118,2.5-2.5,2.5c-1.377,0-2.5-1.118-2.5-2.5C47.5,39.712,48.623,38.6,50,38.594z M46.597,58.604
              c-0.835,1.095-2.402,1.313-3.501,0.5c-1.099-0.83-1.313-2.401-0.479-3.5c0.835-1.104,2.402-1.318,3.506-0.483
              c1.099,0.78,1.277,2.38,0.477,3.483H46.597z M40.757,49.717c-1.313-0.415-2.056-1.816-1.636-3.135
              c0.41-1.318,1.816-2.051,3.135-1.636c1.318,0.4,2.1,1.8,1.6,3.1C43.477,49.4,42.1,50.1,40.8,49.717H40.757z M26.66,32.266
              c1.953-2.598,3.857-4.263,5.522-5.532c1.65-1.27,5.967-3.945,8.257-4.385c2.295-0.439,3.311-0.381,3.7,0.337
              c0.791,1.4,0.7,11.6,0.5,12.793c-0.229,1.143-0.449,3.11-2.109,4.4c-0.869,0.669-2.412,1.362-5.225,0.679
              c-2.803-0.693-10.884-2.744-11.563-3.535C25.039,36.2,24.7,34.9,26.7,32.266H26.66z M30.488,69.486
              c-0.957,0.41-2.334,0.343-4.253-2.284c-1.929-2.617-2.974-4.933-3.696-6.886c-0.732-1.952-2.051-6.854-1.807-9.174
              c0.254-2.325,0.605-3.282,1.416-3.462c1.563-0.356,11.3,2.7,12.4,3.276c1.025,0.601,2.8,1.3,3.6,3.3c0.381,1,0.6,2.705-0.869,5.195
              c-1.469,2.49-5.834,9.648-6.779,10.035H30.488z M57.749,78.32c-3.136,0.873-5.659,1.039-7.744,1c-2.09,0-7.144-0.498-9.233-1.533
              c-2.08-1.051-2.856-1.714-2.744-2.529c0.22-1.597,6.533-9.673,7.417-10.439c0.884-0.766,2.251-2.196,4.341-2.196
              c1.099,0,2.7,0.399,4.601,2.637c1.812,2.3,7,8.8,7,9.834C61.432,76.2,60.9,77.4,57.7,78.32H57.749z M73.525,67.5
              c-1.637,1.66-2.51,2.188-3.248,1.826c-1.454-0.708-7.152-9.229-7.606-10.309c-0.454-1.068-1.382-2.822-0.731-4.805
              c0.342-1.05,1.229-2.495,3.931-3.511c2.7-1.016,10.526-3.887,11.532-3.608c1.017,0.3,2,1.2,1.9,4.4
              c-0.15,3.242-0.775,5.697-1.426,7.676c-0.66,2.03-2.677,6.63-4.377,8.296L73.525,67.5z"/>
          </svg>
        </div>
    );
  }
}

export default Loader;
import Session from '../Session'

import IServiceResult from '../connect/IServiceResult';
import Engine, { Error } from '../Engine';

import Log from '../logger/Log';
import ApiUtils from '../api/ApiUtils';
import ResultsCommit from '../api/results/ResultsCommit';
import { Race } from '../data/Race';
import RawResultQuery from '../api/manage/result/RawResultQuery';
import UpdateRawResultCommit from '../api/manage/result/UpdateRawResultCommit';

export default class RawResultsMonitor {
    constructor(/*Session*/ session) {
        this.mSession = session;
        session.onRawResultCreated.register(this.onRawResultCreated.bind(this));
    }
    onRawResultCreated(/*Object*/ sender, /*{RaceResults}*/ action) {
        action.OnQueryRequested.register(this.onRawResultQueryRequested.bind(this));
        action.onUpdateRawResultCreated.register(this.onUpdateRawResultCreated.bind(this));
    }

    onRawResultQueryRequested(/*RaceResults*/ sender, /*Void*/ params) {
        var /*ProjectCreate*/ action = sender;
        var request = new RawResultQuery();
        request.setToken(action.token);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.player = reply.opt('player', null);
                action.lane = reply.opt('lane', null);

                action.measured_reaction_time = reply.opt('measured_reaction_time', null);
                action.displayed_reaction_time = reply.opt('displayed_reaction_time', null);

                action.measured_time_60ft = reply.opt('measured_time_60ft', null);
                action.measured_time_330ft = reply.opt('measured_time_330ft', null);
                action.measured_time_660ft = reply.opt('measured_time_660ft', null);
                action.measured_time_1000ft = reply.opt('measured_time_1000ft', null);
                action.measured_time_1320ft = reply.opt('measured_time_1320ft', null);

                action.measured_speed_60ft = reply.opt('measured_speed_60ft', null);
                action.measured_speed_330ft = reply.opt('measured_speed_330ft', null);
                action.measured_speed_660ft = reply.opt('measured_speed_660ft', null);
                action.measured_speed_1000ft = reply.opt('measured_speed_1000ft', null);
                action.measured_speed_1320ft = reply.opt('measured_speed_1320ft', null);

                action.displayed_time_60ft = reply.opt('displayed_time_60ft', null);
                action.displayed_time_330ft = reply.opt('displayed_time_330ft', null);
                action.displayed_time_660ft = reply.opt('displayed_time_660ft', null);
                action.displayed_time_1000ft = reply.opt('displayed_time_1000ft', null);
                action.displayed_time_1320ft = reply.opt('displayed_time_1320ft', null);

                action.displayed_speed_60ft = reply.opt('displayed_speed_60ft', null);
                action.displayed_speed_330ft = reply.opt('displayed_speed_330ft', null);
                action.displayed_speed_660ft = reply.opt('displayed_speed_660ft', null);
                action.displayed_speed_1000ft = reply.opt('displayed_speed_1000ft', null);
                action.displayed_speed_1320ft = reply.opt('displayed_speed_1320ft', null);

                action.is_invalid = reply.opt('is_invalid', null);
                action.is_public = reply.opt('is_public', null);

                action.timestamp_race = ApiUtils.deserializeDateTime(reply.opt('timestamp_race', null));
                action.OnQueryFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnQueryError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("MANAGE", result, request);
    }

    onUpdateRawResultCreated(/*Object*/ sender, /*{RaceResults}*/ action) {
        action.OnCommitRequested.register(this.onUpdateRawResultCommitRequested.bind(this));
    }

    
    onUpdateRawResultCommitRequested(/*RaceResults*/ sender, /*Void*/ params) {
        var /*ProjectCreate*/ action = sender;
        var request = new UpdateRawResultCommit();
        request.setToken(action.token);

        request.setPlayer(action.player);

        request.setDisplayedReactionTime(action.displayed_reaction_time);
    
        request.setDisplayedTime60ft(action.displayed_time_60ft);
        request.setDisplayedTime330ft(action.displayed_time_330ft);
        request.setDisplayedTime660ft(action.displayed_time_660ft);
        request.setDisplayedTime1000ft(action.displayed_time_1000ft);
        request.setDisplayedTime1320ft(action.displayed_time_1320ft);
        
        request.setDisplayedSpeed60ft(action.displayed_speed_60ft);        
        request.setDisplayedSpeed330ft(action.displayed_speed_330ft);
        request.setDisplayedSpeed660ft(action.displayed_speed_660ft);
        request.setDisplayedSpeed1000ft(action.displayed_speed_1000ft);
        request.setDisplayedSpeed1320ft(action.displayed_speed_1320ft);
        request.setIsInvalid(action.is_invalid);
        request.setIsPublic(action.is_public);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("MANAGE", result, request);
    }
}
import IUploadResults from "../../../../sdk/data/manage/event/IUploadResults";

export class UploadResults extends IUploadResults
{
    constructor()
    {
        super();
        this.external = null;
        this.data = null;
    }

    getExternal()
    {
        return this.external;
    }

    setData(data)
    {
        this.data = data;
    }
    
    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

}
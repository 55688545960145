import IRace from "../../sdk/data/IRace";

export class Race extends IRace
{
    constructor()
    {
        super();
        this.token = null;
        this.player = null;
        this.player_name = null;
        this.player_car = null;
        this.reaction_time = null;
        this.time_60ft = null;
        this.time_660ft = null;
        this.time_1000ft = null;
        this.time_1320ft = null;
        this.speed_660ft = null;
        this.speed_1000ft = null;
        this.speed_1320ft = null;
        this.race_time = null;
        this.timestamp_race = null;
        this.is_invalid = null;
        this.is_public = null;
        this.picture_url = null;
        this.event = null;
    }

    getToken()
    {
        return this.token;
    }

    getPlayer()
    {
        return this.player;
    }

    getPlayerName()
    {
        return this.player_name;
    }

    getPlayerCar()
    {
        return this.player_car;
    }

    getReactionTime()
    {
        return this.reaction_time;
    }

    getTime60ft()
    {
        return this.time_60ft;
    }
    
    getTime660ft()
    {
        return this.time_660ft;
    }
    getTime1000ft()
    {
        return this.time_1000ft;
    }
    getTime1320ft()
    {
        return this.time_1320ft;
    }

    getSpeed660ft()
    {
        return this.speed_660ft;
    }
    getSpeed1000ft()
    {
        return this.speed_1000ft;
    }
    getSpeed1320ft()
    {
        return this.speed_1320ft;
    }

    getRaceTime()
    {
        return this.race_time;
    }

    getTimestampRace()
    {
        return this.timestamp_race;
    }    

    getIsInvalid()
    {
        return this.is_invalid;
    }   
    getIsPublic()
    {
        return this.is_public;
    }

    getPictureUrl()
    {
        return this.picture_url;
    }

    getEvent()
    {
        return this.event;
    }    
}

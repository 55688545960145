import IPersmissions from "../../sdk/data/IPermissions";
import JSONObject from "../../sdk/JSONObject";

export class Permissions extends IPersmissions
{
    constructor()
    {
        super();

        this.Reset();
    }

    Reset()
    {
        this.global = {};
        this.context = {};
    }

    Compare(other)
    {
        if ( null == other || undefined == other )
        {
            return false;
        }
        for ( var ref in this.global )
        {
            if ( this.global[ref] !== other.global[ref] ) 
            {
                return false;
            }
        }
        for ( var ref in this.context )
        {
            if ( this.context[ref] !== other.context[ref] ) 
            {
                return false;
            }
        }
        return true;
    }

    hasPermissionGlobal(permission)
    {
        if ( this.global[permission] ===  undefined )
        {
            return false;
        }
        return this.global[permission];
    }

    hasPermissionContext(permission, context)
    {
        let db = this.context[context];

        if ( db ===  undefined )
        {
            return false;
        }
        if ( db[permission] === undefined )
        {
            return false;
        }
        return db[permission];
    }

    process(stream)
    {
        let glob = stream.opt('global', null);
        if ( glob instanceof JSONObject )
        {
            glob.keys().forEach( (x) => {
                this.global[x] = glob.opt(x, null);
            });
        }
        let context = stream.opt('context', null);
        if ( context instanceof JSONObject )
        {
            context.keys().forEach( (x) => {
                let ctx = {};

                let val = context[x];
                val.keys().forEach( (k) => {
                    ctx[k] = val[k];
                });

                this.context[x] = ctx;
            } );
        }

        return true;
    }
    
    deserialize(stream)
    {
        let glob = stream.opt('global', null);
        if ( glob )
        {
            glob.keys().forEach( (x) => {
                this.global[x] = glob.opt(x, null);
            });
        }
        let context = stream.opt('context', null);
        if ( context )
        {
            for ( var _ref in context.keys() )
            {
                let ctx = {};

                let ref = context.opt(_ref, null);
                if ( ref )
                {
                    for ( var _p in ref.keys() )
                    {
                        let p = ref.opt(_p, null);
                        ctx[_p] = p;
                    }
                }

                this.context[_ref] = ctx;
            }
        }

        return true;
    }

    serialize(stream)
    {
        if ( this.global )
        {
            let _global = new JSONObject();
            for ( var ref in this.global )
            {
                if ( this.global[ref] !== undefined ) 
                {
                    _global.put(ref, this.global[ref]);
                }
            }
            stream.put('global', _global);
        }
        if ( this.context )
        {
            let _context = new JSONObject();
            for ( var ref in this.context )
            {
                let _reference = new JSONObject();
                let _ref = this.context[ref];
                for ( var id in _ref )
                {
                    _reference.put(id, _ref[id]);
                }
                _context.put(ref, _reference);
            }
            stream.put('context', _context);
        }
    }
}
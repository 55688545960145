import IExampleEmail from "../../../../sdk/data/manage/event/IExampleEmail";

export class ExampleEmail extends IExampleEmail
{
    constructor()
    {
        super();
        this.external = null;
        this.email = null;
        
        this.template_attendance_email = null;
        this.crew_count = null;
    }

    getExternal()
    {
        return this.external;
    }

    getTemplateAttendanceEmail()
    {
        return this.template_attendance_email;
    }
    setTemplateAttendanceEmail(value)
    {
        this.template_attendance_email = value;
    }

    getCrewCount()
    {
        return this.crew_count;
    }
    setCrewCount(value)
    {
        this.crew_count = value;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getEmail()
    {
        return this.email;
    }
}
import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import './EventScreen.css'
import { DrawBottomRight } from '../LayoutHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch, faFlagCheckered, faCog } from '@fortawesome/free-solid-svg-icons';
import AttendanceWidget from '../../components/widgets/AttendanceWidget';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SosIcon from '@mui/icons-material/Sos';

import Box from '@mui/material/Box';

export const EventScreen = ({session}) =>  {
    const urlParams = useParams();
    const navigate = useNavigate();
    
    const [eventId, setEventId] = useState(undefined);
    const [ leagues, setLeagues ] = useState(null);

    const [event, setEvent] = useState(undefined);
    const [canSeeRegistration, setCanSeeRegistration] = useState(false);
    const [canEditLeague, setCanEditLeague] = useState(false);
    const [canEditResults, setCanEditResults] = useState(false);
    
    useEffect(() => {
        setEventId(urlParams.eventId);
    }, [urlParams])

    
    useEffect( () => { 
        const leagueChangedCallback = (sender, leagues) => {
            setLeagues(leagues) ;
        }
        session.onLeaguesChanged.register(leagueChangedCallback);

        setLeagues(session.getLeagues()) ;
        return () => {
            session.onPersonalizeChanged.unregister(leagueChangedCallback);
        }
    }, [])

    useEffect( () => {
        if ( null == eventId )
        {
            return;
        }
        if ( null == leagues )
        {
            return;
        }
        for ( const l of leagues )
        {
            if ( null != l.getEvents() )
            {
              for ( const e of l.getEvents() )
              {
                  if ( eventId != e.getExternal() )
                  {
                      continue;
                  }
                  let event = {};
                  event.external = e.getExternal();
                  event.shortname = e.getShortname();
                  event.description = e.getDescription();
                  event.location = e.getLocation();
                  event.thumbnail = e.getThumbnail();
                  event.timestamp_start = e.getTimestampStart();
                  
                  event.league_id = l.getExternal();
                  event.league_shortname = l.getShortname();
  
                  event.is_registration_open = e.getIsRegistrationOpen();
                  
                  event.ob = e;
                  setEvent(event);
                  return;
              }
            }
        }
    }, [eventId, leagues]);

    useEffect( () => {
        if ( null == event )
        {
            setCanEditLeague(false);
            return;
        }

        let perm = session.getPermissions().hasPermissionContext('can_edit_league', event.league_id) || session.getPermissions().hasPermissionGlobal('can_edit_league');
        setCanEditLeague(perm);
    }, [event])

    useEffect( () => {
        if ( null == event )
        {
            setCanEditResults(false);
            return;
        }

        let perm = session.getPermissions().hasPermissionContext('can_edit_results', event.league_id) || session.getPermissions().hasPermissionGlobal('can_edit_results');
        setCanEditResults(perm);
    }, [event])

    useEffect(() => {
        if ( null == event )
        {
            setCanSeeRegistration(false);
            return;
        }
        if ( !canEditLeague )
        {
            setCanSeeRegistration(false);
            return;
        }
        let perm = session.getPersonalized() && (event.is_registration_open || canEditLeague);
        setCanSeeRegistration(perm);
    }, [event, canEditLeague])

    const renderSpeedMenu = () => {
        if ( !canEditLeague && !canEditResults )
        {
            return null;
        }
        let actions = [];
        if ( canEditLeague )
        {
            actions.push(<SpeedDialAction
                key='create'
                icon={<AppRegistrationIcon />}
                tooltipTitle={'Edytuj wydarzenie'}
                tooltipOpen
                onClick={() => navigate('/manage/event/' + event.external + '/edit')}
                /> );
        }
        if ( canEditResults )
        {
            actions.push(
                <SpeedDialAction
                key='recover'
                icon={<SosIcon />}
                tooltipTitle={'Edytuj wyniki'}
                tooltipOpen
                onClick={() => navigate('/manage/event/' + event.external + '/results')}
                />
            )
        }

        return (
            <DrawBottomRight>
                <SpeedDial
                    ariaLabel="SpeedDial playground example"
                    hidden={false}
                    icon={<SpeedDialIcon />}
                    direction={'up'}
                    sx={{position: 'absolute', bottom: 0, right: 0}}
                >
                {actions}
                </SpeedDial>
            </DrawBottomRight>    
        )
    }

    return (
        <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
            {renderSpeedMenu()}
            
            {null == event && 
                <Box>Trwa wczytywanie danych</Box>
            }
            {null != event && 
                <>
                    <Box>
                        <h5><b>{event.league_shortname} - {event.shortname}</b></h5>
                        <div className="w3-container">
                            <div className="w3-row-padding w3-margin-bottom">
                                <div className="w3-quarter">
                                    <div className="w3-container w3-teal w3-text-white w3-padding-16">
                                        <div className="w3-right w3-xxxlarge">
                                            <FontAwesomeIcon icon={faFlagCheckered} />
                                        </div>
                                        <div className="w3-clear"></div>
                                        <h4>Wyniki</h4>
                                    </div>
                                </div>
                                <div className="w3-quarter">
                                    <Link to={'/results/' + event.external + "/best"}>
                                    <div className="w3-container w3-red w3-text-white w3-padding-16">
                                        <div className="w3-right w3-xxxlarge">
                                            <FontAwesomeIcon icon={faStopwatch} />
                                        </div>
                                        <div className="w3-clear"></div>
                                        <h4>Przejazdy</h4>
                                    </div>
                                    </Link>
                                </div>
                                {canSeeRegistration && 
                                    <div className="w3-quarter">
                                        <AttendanceWidget session={session} token={event.external} type='EVENT' manage={canEditLeague} />
                                    </div>
                                }
                            </div>
                            <div className="w3-row-padding">
                                <div className="w3-third">
                                    <img src={event.thumbnail}/>
                                </div>
                                <div className="w3-twothird">
                                    {event.timestamp_start && <h5>{event.timestamp_start.format('YYYY-MM-DD')}</h5> }
                                    <span className="w3-medium">{event.description}</span>
                                </div>
                            </div>
                        </div>
                    </Box>
                </>
            }
        </Box>
    )
/*
  renderScreen(child)
  {
      if ( null == this.state.event )
      {
          return super.renderScreen(
              <div>Trwa wczytywanie danych</div>
          );
      }
      let event = this.state.event;

      let canEditLeague = this.props.session.getPermissions().hasPermissionContext('can_edit_league', event.league_id) || this.props.session.getPermissions().hasPermissionGlobal('can_edit_league');
      let canEditResults = ( this.props.session.getPermissions().hasPermissionContext('can_edit_results', event.league_id) || this.props.session.getPermissions().hasPermissionGlobal('can_edit_results') );
      let canShowOptions = canEditLeague || canEditResults;

      let canSeeRegistration = this.props.session.getPersonalized() && (event.is_registration_open || canEditLeague);

      return super.renderScreen(
        <header className="w3-container w3-anchor">
            <h5><b>{event.league_shortname} - {event.shortname}</b></h5>
            <div className="w3-container">
              <div className="w3-row-padding w3-margin-bottom">
                  <div className="w3-quarter">
                      <div className="w3-container w3-teal w3-text-white w3-padding-16">
                          <div className="w3-right w3-xxxlarge">
                              <FontAwesomeIcon icon={faFlagCheckered} />
                          </div>
                          <div className="w3-clear"></div>
                          <h4>Wyniki</h4>
                      </div>
                  </div>
                  <div className="w3-quarter">
                      <Link to={'/results/' + event.external + "/best"}>
                      <div className="w3-container w3-red w3-text-white w3-padding-16">
                          <div className="w3-right w3-xxxlarge">
                              <FontAwesomeIcon icon={faStopwatch} />
                          </div>
                          <div className="w3-clear"></div>
                          <h4>Przejazdy</h4>
                      </div>
                      </Link>
                  </div>
                  {canSeeRegistration && 
                    <div className="w3-quarter">
                        <AttendanceWidget session={this.props.session} token={event.external} type='EVENT' manage={canEditLeague} />
                    </div>
                  }
              </div>
              <div className="w3-row-padding">
                  <div className="w3-third">
                      <img src={event.thumbnail}/>
                  </div>
                  <div className="w3-twothird">
                      {event.timestamp_start && <h5>{event.timestamp_start.format('YYYY-MM-DD')}</h5> }
                      <span className="w3-medium">{event.description}</span>
                  </div>
              </div>
          </div>
            {canShowOptions &&
                <div className="w3-container w3-float-topright">
                    <h5>
                        {canEditLeague && 
                            <Link className="w3-margin-right" to={'/manage/event/' + event.external + '/edit'}><FontAwesomeIcon icon={faCog} /> Edytuj wydarzenie</Link>
                        }
                        {canEditResults && 
                            <Link className="w3-margin-right" to={'/manage/event/' + event.external + '/results'}><FontAwesomeIcon icon={faCog} /> Edytuj wyniki</Link>
                        }
                    </h5>
                </div>
            }
        </header>
      )
  }
  */
}
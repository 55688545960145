import ISessionActivate from 'pete/sdk/session/ISessionActivate'

export default class SessionActivate extends ISessionActivate
{
    constructor()
    {
        super();
        this.uid = null;
        this.token = null;
    }

    setUid(uid)
    {
        this.uid = uid;
    }

    setToken(token)
    {
        this.token = token;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }
}
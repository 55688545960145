import IApplicationsList from "../../../sdk/data/applications/IApplicationsList";

export class ApplicationsList extends IApplicationsList
{
    constructor()
    {
        super();
        this.vehicles = null;
        this.leagues = null;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getVehicleApplications()
    {
        return this.vehicles;
    }

    getLeagues()
    {
        return this.leagues;
    }
}
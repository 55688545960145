import JSONObject from './JSONObject';

export default class JSONArray
{
    constructor(data)
    {
        this.data = [];
        if ( null != data )
        {
            this.deserialize(data);
        }
    }
    put(/*Object*/ value)
    {
        this.data.push(value);
    }

    get(/*String*/ index)
    {
        return this.data[index];
    }

    length()
    {
        return this.data.length;
    }

    toArray()
    {
        return this.data.slice();
    }

    serialize()
    {
        var retval = [];
        for ( var i = 0; i < this.data.length; ++i )
        {
            var ob = this.data[i];
            if ( ob instanceof JSONArray )
            {
                retval.push(ob.serialize());
                continue;
            }
            if ( ob instanceof JSONObject )
            {
                retval.push(ob.serialize());
                continue;
            }
            retval.push(ob);
        }
        return retval;
    }

    deserialize(/*String*/ value)
    {
        if ( !Array.isArray(value) )
        {
            return false;
        }
        var data = [];
        var len = value.length;
        for ( var i = 0; i < len; ++i )
        {
            var ob = value[i];
            if ( ob === Object(ob) )
            {
                var sub = new JSONObject();
                if ( sub.deserialize(ob) )
                {
                    data.push(sub);
                }
            }
            else
            {
                data.push(ob);
            }
        }
        this.data = data;
        return true;
    }
}
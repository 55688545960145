import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import './EventsScreen.css'
import { DrawBottomRight } from '../LayoutHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch, faFlagCheckered, faCog } from '@fortawesome/free-solid-svg-icons';
import AttendanceWidget from '../../components/widgets/AttendanceWidget';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SosIcon from '@mui/icons-material/Sos';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const EventsScreen = ({session}) =>  {
    const navigate = useNavigate();

    const [ leaguesData, setLeaguesData ] = useState(null);
    const [ leagues, setLeagues ] = useState(null);
    
    const [canCreateLeague, setCanCreateLeague] = useState(false);

    useEffect( () => { 
        const leagueChangedCallback = (sender, leagues) => {
            setLeaguesData(leagues) ;
        }
        session.onLeaguesChanged.register(leagueChangedCallback);

        setLeaguesData(session.getLeagues()) ;
        return () => {
            session.onPersonalizeChanged.unregister(leagueChangedCallback);
        }
    }, [])

    useEffect( () => {
        if ( null == leagues )
        {
            setCanCreateLeague(false);
            return;
        }

        let perm = session.getPermissions().hasPermissionGlobal('can_create_league');
        setCanCreateLeague(perm);
    }, [leagues])

    useEffect( () => {
        if (null == leaguesData) {
            setLeagues(null)
            return;
        }

        let leagues = [];
        for (const l of leaguesData) {
            let league = {};
            league.external = l.getExternal();
            league.shortname = l.getShortname();
            league.description = l.getDescription();
            league.thumbnail = l.getThumbnail();
            league.is_finished = l.getIsFinished();

            if (null != l.getEvents()) {
                let events = [];
                for (const e of l.getEvents()) {
                    let event = {};
                    event.external = e.getExternal();
                    event.shortname = e.getShortname();
                    event.description = e.getDescription();
                    event.location = e.getLocation();
                    event.thumbnail = e.getThumbnail();
                    event.timestamp_start = e.getTimestampStart();
                    events.push(event);
                }
                league.events = events;
            }


            leagues.push(league);
        }
        setLeagues(leagues)
    }, [leaguesData] );


    const renderSpeedMenu = () => {
        if ( !canCreateLeague )
        {
            return null;
        }
        let actions = [];
        if ( canCreateLeague )
        {
            actions.push(<SpeedDialAction
                key='create'
                icon={<AppRegistrationIcon />}
                tooltipTitle={'Stwórz nowa lige'}
                tooltipOpen
                onClick={() => navigate('/manage/league/add')}
                /> );
        }

        return (
            <DrawBottomRight>
                <SpeedDial
                    ariaLabel="SpeedDial playground example"
                    hidden={false}
                    icon={<SpeedDialIcon />}
                    direction={'up'}
                    sx={{position: 'absolute', bottom: 0, right: 0}}
                >
                {actions}
                </SpeedDial>
            </DrawBottomRight>    
        )
    }


    return (
            <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
                {renderSpeedMenu()}
                
                {null == leagues && 
                    <Box>Trwa wczytywanie danych</Box>
                }
                {null != leagues && 
                    <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                        {leagues.map((x) => {
                            return (
                                <Card sx={{ maxWidth: 345 }}>
                                    {x.thumbnail && 
                                    <CardMedia
                                        component="img"
                                        alt={x.shortname}
                                        height="140"
                                        image={x.thumbnail}
                                    />}
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {x.shortname}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {x.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" onClick={() => navigate('/league/' + x.external)}>Wiecej</Button>
                                    </CardActions>
                                </Card>)
                        })}
                    </Stack>
                }
            </Box>
        );

    // renderScreen(child) {
    //     if (null == this.state.leagues) {
    //         return super.renderScreen(
    //             <div>Trwa wczytywanie danych</div>
    //         );
    //     }

    //     let data = null;
    //     if (this.state.leagues.length < 1) {
    //         data = (<div>Brak danych</div>);
    //     }
    //     else {

    //         data = this.state.leagues.map((x) => {
    //             return (
    //                 <tr key={x.external} className="w3-padding-16 w3-hoverable w3-tr-with-link">
    //                     <td><span className="w3-xlarge">{x.shortname}</span></td>
    //                     <td>
    //                         {!x.is_finished && <span className="w3-xlarge"> - W TRAKCIE</span>}
    //                         {x.is_finished && <span className="w3-xlarge"> - ZAKOŃCZONY</span>}
    //                     </td>
    //                     <Link to={'/league/' + x.external} className="w3-rowlink" />
    //                 </tr>

    //             );
    //         })
    //     }
    // }
}
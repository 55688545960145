import Session from '../Session'

import IServiceResult from '../connect/IServiceResult';
import Engine, {Error} from '../Engine';

import Log from '../logger/Log';
import EventsList from '../api/globals/EventsList';
import { League } from '../data/League';
import { LeagueEvent } from '../data/LeagueEvent';
import ApiUtils from '../api/ApiUtils';
import { LeagueClass } from '../data/LeagueClass';
import { LeaguePlayer } from '../data/LeaguePlayer';
import { Filter } from '../data/Filter';


export default class FiltersMonitor
{
    constructor(/*Session*/ session)
    {
        this.mSession = session;

    }

    process(data)
    {
        let filters = [];

        let size = data.length();
        for ( var i = 0; i < size; ++i )
        {
            let ob = data.get(i);

            let filter = new Filter();
            filter.external = ob.get('external', null);
            filter.shortname = ob.get('shortname', null);
            filter.url = ob.get('url', null);
            filter.is_global = ob.get('is_global', null);

            filters.push(filter);
        }


        this.mSession.filters = filters; 
        this.mSession.onFiltersChanged.callback(this.mSession, filters);
    }
}
import React, { Component } from "react";
import { Link } from "react-router-dom";
import update from "react-addons-update";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faSync, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import autoBind from "react-autobind";
import "./ResultEditScreen.css";
import BaseScreenHeader from "../../BaseScreenHeader";
import InputField from "../../../components/common/InputField";
import Button from "../../../components/common/Button";
import HelpIcon from "../../../components/common/HelpIcon";
import Utils from "../../../utils/Utils";
import withRouter from "../../../utils/withRouter";

class ResultEditScreen extends BaseScreenHeader {
  static defaultProps = {
    session: null,
  };
  constructor(props) {
    super(props);
    autoBind(this);
  }

  onCleanupParameters() {}
  onInitParameters(props) {
    this.state.resultId = props.params.resultId;

    this.state.player = null;
    this.state.lane = null;

    this.state.measured_reaction_time = null;
    this.state.displayed_reaction_time = null;

    this.state.measured_time_60ft = null;
    this.state.measured_time_330ft = null;
    this.state.measured_time_660ft = null;
    this.state.measured_time_1000ft = null;
    this.state.measured_time_1320ft = null;

    this.state.measured_speed_60ft = null;
    this.state.measured_speed_330ft = null;
    this.state.measured_speed_660ft = null;
    this.state.measured_speed_1000ft = null;
    this.state.measured_speed_1320ft = null;

    this.state.displayed_time_60ft = null;
    this.state.displayed_time_330ft = null;
    this.state.displayed_time_660ft = null;
    this.state.displayed_time_1000ft = null;
    this.state.displayed_time_1320ft = null;

    this.state.displayed_speed_60ft = null;
    this.state.displayed_speed_330ft = null;
    this.state.displayed_speed_660ft = null;
    this.state.displayed_speed_1000ft = null;
    this.state.displayed_speed_1320ft = null;

    this.state.is_invalid = null;
    this.state.is_public = null;

    this.state.timestamp_race = null;

    this.state.action = this.fetchData(this.state.resultId);
    return true;
  }

  bindDelegates() {
    super.bindDelegates();
  }
  unbindDelegates() {
    super.unbindDelegates();
  }

  fetchData(id) {
    var action = this.session.createRawResult();

    action.setToken(id);

    action.OnQueryRequested.register(this.onRawResultQueryRequested);
    action.OnQueryFinished.register(this.onRawResultQueryFinished);
    action.OnQueryError.register(this.onRawResultQueryError);
    action.Query();

    return action;
  }

  onRawResultQueryRequested(sender, params) {
    this.setState((oldState) => {
      return update(oldState, { fetching: { $set: true } });
    });
  }
  onRawResultQueryFinished(sender, params) {
    let action = sender;

    let player = action.getPlayer();
    let lane = action.getLane();

    let measured_reaction_time = action.getMeasuredReactionTime();
    let displayed_reaction_time = action.getDisplayedReactionTime();

    let measured_time_60ft = action.getMeasuredTime60ft();
    let measured_time_330ft = action.getMeasuredTime330ft();
    let measured_time_660ft = action.getMeasuredTime660ft();
    let measured_time_1000ft = action.getMeasuredTime1000ft();
    let measured_time_1320ft = action.getMeasuredTime1320ft();

    let measured_speed_60ft = action.getMeasuredSpeed60ft();
    let measured_speed_330ft = action.getMeasuredSpeed330ft();
    let measured_speed_660ft = action.getMeasuredSpeed660ft();
    let measured_speed_1000ft = action.getMeasuredSpeed1000ft();
    let measured_speed_1320ft = action.getMeasuredSpeed1320ft();

    let displayed_time_60ft = action.getDisplayedTime60ft();
    let displayed_time_330ft = action.getDisplayedTime330ft();
    let displayed_time_660ft = action.getDisplayedTime660ft();
    let displayed_time_1000ft = action.getDisplayedTime1000ft();
    let displayed_time_1320ft = action.getDisplayedTime1320ft();

    let displayed_speed_60ft = action.getDisplayedSpeed60ft();
    let displayed_speed_330ft = action.getDisplayedSpeed330ft();
    let displayed_speed_660ft = action.getDisplayedSpeed660ft();
    let displayed_speed_1000ft = action.getDisplayedSpeed1000ft();
    let displayed_speed_1320ft = action.getDisplayedSpeed1320ft();

    let is_invalid = action.getIsInvalid();
    let is_public = action.getIsPublic();

    let timestamp_race = action.getTimestampRace();

    this.setState((oldState) => {
      return update(oldState, {
        fetching: { $set: false },
        action: { $set: action },
        player: { $set: player },
        lane: { $set: lane },

        measured_reaction_time: { $set: measured_reaction_time },
        displayed_reaction_time: { $set: displayed_reaction_time },

        measured_time_60ft: { $set: measured_time_60ft },
        measured_time_330ft: { $set: measured_time_330ft },
        measured_time_660ft: { $set: measured_time_660ft },
        measured_time_1000ft: { $set: measured_time_1000ft },
        measured_time_1320ft: { $set: measured_time_1320ft },

        measured_speed_60ft: { $set: measured_speed_60ft },
        measured_speed_330ft: { $set: measured_speed_330ft },
        measured_speed_660ft: { $set: measured_speed_660ft },
        measured_speed_1000ft: { $set: measured_speed_1000ft },
        measured_speed_1320ft: { $set: measured_speed_1320ft },

        displayed_time_60ft: { $set: displayed_time_60ft },
        displayed_time_330ft: { $set: displayed_time_330ft },
        displayed_time_660ft: { $set: displayed_time_660ft },
        displayed_time_1000ft: { $set: displayed_time_1000ft },
        displayed_time_1320ft: { $set: displayed_time_1320ft },

        displayed_speed_60ft: { $set: displayed_speed_60ft },
        displayed_speed_330ft: { $set: displayed_speed_330ft },
        displayed_speed_660ft: { $set: displayed_speed_660ft },
        displayed_speed_1000ft: { $set: displayed_speed_1000ft },
        displayed_speed_1320ft: { $set: displayed_speed_1320ft },

        is_invalid: { $set: is_invalid },
        is_public: { $set: is_public },
        timestamp_race: { $set: timestamp_race },
      });
    });

    this.initFormData({
      player: player,
      displayed_reaction_time: displayed_reaction_time,
      displayed_time_60ft: displayed_time_60ft,
      displayed_time_660ft: displayed_time_660ft,
      displayed_time_1320ft: displayed_time_1320ft,
      displayed_speed_660ft: displayed_speed_660ft,
      displayed_speed_1320ft: displayed_speed_1320ft,
    });
  }
  onRawResultQueryError(sender, params) {
    this.setState((oldState) => {
      return update(oldState, { fetching: { $set: false } });
    });
  }

  onUpdateRawResultCommitRequested(sender, params) {
    this.beginAsyncOperation();
  }
  onUpdateRawResultCommitFinished(sender, params) {
    this.endAsyncOperation();
    this.fetchData(this.state.resultId);
  }

  onUpdateRawResultCommitError(sender, error) {
    this.endAsyncOperation();
    this.showError(error);
  }

  changeValidity(validity) {
    let update = this.state.action.createUpdateRawResult();
    update.setIsInvalid(validity);

    update.OnCommitRequested.register(this.onUpdateRawResultCommitRequested);
    update.OnCommitFinished.register(this.onUpdateRawResultCommitFinished);
    update.OnCommitError.register(this.onUpdateRawResultCommitError);

    update.Commit();
  }

  changeVisibility(visibility) {
    let update = this.state.action.createUpdateRawResult();
    update.setIsPublic(visibility);

    update.OnCommitRequested.register(this.onUpdateRawResultCommitRequested);
    update.OnCommitFinished.register(this.onUpdateRawResultCommitFinished);
    update.OnCommitError.register(this.onUpdateRawResultCommitError);

    update.Commit();
  }

  updateData() {
    let errors = [];

    let player = this.getFormData("player");
    if (!player) {
      errors.push("Numer zawodnika nie moze byc pusty");
    }
    let displayed_reaction_time = this.getFormData("displayed_reaction_time");
    let displayed_time_60ft = this.getFormData("displayed_time_60ft");
    let displayed_time_660ft = this.getFormData("displayed_time_660ft");
    let displayed_time_1320ft = this.getFormData("displayed_time_1320ft");
    let displayed_speed_660ft = this.getFormData("displayed_speed_660ft");
    let displayed_speed_1320ft = this.getFormData("displayed_speed_1320ft");

    if (errors.length > 0) {
      this.showMessage(errors);
      return;
    }

    let update = this.state.action.createUpdateRawResult();
    update.setPlayer(player);
    update.setDisplayedReactionTime(displayed_reaction_time ? displayed_reaction_time : null);
    update.setDisplayedTime60ft(displayed_time_60ft ? displayed_time_60ft : null);
    update.setDisplayedTime660ft(displayed_time_660ft ? displayed_time_660ft : null);
    update.setDisplayedTime1320ft(displayed_time_1320ft ? displayed_time_1320ft : null);
    update.setDisplayedSpeed660ft(displayed_speed_660ft ? displayed_speed_660ft : null);
    update.setDisplayedSpeed1320ft(displayed_speed_1320ft ? displayed_speed_1320ft : null);

    update.OnCommitRequested.register(this.onUpdateRawResultCommitRequested);
    update.OnCommitFinished.register(this.onUpdateRawResultCommitFinished);
    update.OnCommitError.register(this.onUpdateRawResultCommitError);

    update.Commit();
  }

  renderScreen(child) {
    let timeslip = {};
    if (null == this.state.lane || this.state.fetching) {
      return super.renderScreen(<div>Trwa wczytywanie danych</div>);
    }

    return super.renderScreen(
      <header className="w3-container">
        <h5>
          Edycja przejazdu <b>{this.state.timestamp_race.format("YYYY-MM-DD HH:mm:ss")}</b> tor <b>{this.state.lane}</b>
        </h5>
        <div className="w3-container">
          <h6 className="w3-inline w3-anchor">
            Pomiar oznaczony jako <b>{this.state.is_invalid ? "NIEPOPRAWNY" : "POPRAWNY"}</b>
            <HelpIcon component={"Pomiar oznaczony jako niepoprawny bedzie widoczny jako przekreslony"} left={false} hover={true} above={false}>
              <FontAwesomeIcon icon={faQuestionCircle} />
            </HelpIcon>
          </h6>
          <h6 className="w3-inline w3-anchor">
            Pomiar oznaczony jako <b>{this.state.is_public ? "WIDOCZNY" : "UKRYTY"}</b>
            <HelpIcon component={"Pomiar ukryty nie bedzie nigdzie widoczny"} left={false} hover={true} above={false}>
              <FontAwesomeIcon icon={faQuestionCircle} />
            </HelpIcon>
          </h6>
          <div className="w3-inline">
            <div className="w3-half">
              {this.state.is_invalid && (
                <Button
                  onClicked={() => {
                    this.changeValidity(false);
                  }}
                  disabled={this.state.asyncOperation}
                  text="USTAW POPRAWNY"
                  iconLeft={this.state.asyncOperation ? faSync : null}
                />
              )}
              {!this.state.is_invalid && (
                <Button
                  onClicked={() => {
                    this.changeValidity(true);
                  }}
                  disabled={this.state.asyncOperation}
                  text="USTAW NIEPOPRAWNY"
                  iconLeft={this.state.asyncOperation ? faSync : null}
                />
              )}
            </div>
            <div className="w3-half">
              {this.state.is_public && (
                <Button
                  onClicked={() => {
                    this.changeVisibility(false);
                  }}
                  disabled={this.state.asyncOperation}
                  text="USTAW UKRYTY"
                  iconLeft={this.state.asyncOperation ? faSync : null}
                />
              )}
              {!this.state.is_public && (
                <Button
                  onClicked={() => {
                    this.changeVisibility(true);
                  }}
                  disabled={this.state.asyncOperation}
                  text="USTAW WIDOCZNY"
                  iconLeft={this.state.asyncOperation ? faSync : null}
                />
              )}
            </div>
          </div>
        </div>
        <div className="w3-container">
          <h6>Numer startowy</h6>
          <InputField id="result-player" text={this.state.player} onCommit={(value) => this.storeFormData("player", value)} />
          <h6>
            Czas reakcji [ zmierzony: <b>{this.state.measured_reaction_time}</b> ]
          </h6>
          <InputField id="result-reaction-time" text={this.state.displayed_reaction_time} onCommit={(value) => this.storeFormData("displayed_reaction_time", value)} validate={Utils.validate_et} />
          <h6>
            Czas 60ft [ zmierzony: <b>{this.state.measured_time_60ft}</b> ]
          </h6>
          <InputField id="result-time-60ft" text={this.state.displayed_time_60ft} onCommit={(value) => this.storeFormData("displayed_time_60ft", value)} validate={Utils.validate_et} />
          <h6>
            Czas 660ft [ zmierzony: <b>{this.state.measured_time_660ft}</b> ]
          </h6>
          <InputField id="result-time-660ft" text={this.state.displayed_time_660ft} onCommit={(value) => this.storeFormData("displayed_time_660ft", value)} validate={Utils.validate_et} />
          <h6>
            Predkosc 660ft [ zmierzona: <b>{this.state.measured_speed_660ft}</b> ]
          </h6>
          <InputField id="result-speed-660ft" text={this.state.displayed_speed_660ft} onCommit={(value) => this.storeFormData("displayed_speed_660ft", value)} validate={Utils.validate_speed} />
          <h6>
            Czas 1320ft [ zmierzony: <b>{this.state.measured_time_1320ft}</b> ]
          </h6>
          <InputField id="result-time-660ft" text={this.state.displayed_time_1320ft} onCommit={(value) => this.storeFormData("displayed_time_1320ft", value)} validate={Utils.validate_et} />
          <h6>
            Predkosc 1320ft [ zmierzona: <b>{this.state.measured_speed_1320ft}</b> ]
          </h6>
          <InputField id="result-speed-1320ft" text={this.state.displayed_speed_1320ft} onCommit={(value) => this.storeFormData("displayed_speed_1320ft", value)} validate={Utils.validate_speed} />
        </div>

        <div className="w3-quarter w3-right w3-margin">
          <Button
            onClicked={() => {
              this.updateData();
            }}
            disabled={this.state.asyncOperation}
            text="Zapisz zmiany"
            iconLeft={this.state.asyncOperation ? faSync : null}
          />
        </div>
      </header>
    );
  }
}
export default withRouter(ResultEditScreen);

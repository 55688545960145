import ISetNextRace from "../../../sdk/data/livescreen/ISetNextRace";

export class SetNextRace extends ISetNextRace
{
    constructor()
    {
        super();
        this.league = null;
        this.event = null;
        this.left = null;
        this.right = null;
    }

    setLeague(league) {
        this.league = league;
    }

    setEvent(event) {
        this.event = event;
    }

    getLeft()
    {
        return this.left;
    }
    setLeft(value) 
    {
        this.left = value;
    }
    getRight()
    {
        return this.right;
    }
    setRight(value) 
    {
        this.right = value
    }
    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }


}
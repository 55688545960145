export default class IRaceDetailsPlayer 
{
    getPlayerNumber()
    {
        throw new TypeError("Method not implemented");
    }

    getReactionTime()
    {
        throw new TypeError("Method not implemented");
    }
    
    getTime60ft()
    {
        throw new TypeError("Method not implemented");
    }
    
    getTime660ft()
    {
        throw new TypeError("Method not implemented");
    }
    getTime1000ft()
    {
        throw new TypeError("Method not implemented");
    }
    getTime1320ft()
    {
        throw new TypeError("Method not implemented");
    }
    getRaceTime()
    {
        throw new TypeError("Method not implemented");
    }
    getSpeed660ft()
    {
        throw new TypeError("Method not implemented");
    }
    getSpeed1000ft()
    {
        throw new TypeError("Method not implemented");
    }
    getSpeed1320ft()
    {
        throw new TypeError("Method not implemented");
    }
    getIsInvalid()
    {
        throw new TypeError("Method not implemented");
    }
     
    getCarPower()
    {
        throw new TypeError("Method not implemented");
    }
    
    getCarMass()
    {
        throw new TypeError("Method not implemented");
    }
    
    getPlayerName()
    {
        throw new TypeError("Method not implemented");
    }

    getPlayerPicture()
    {
        throw new TypeError("Method not implemented");
    }
    
    getPlayerTeam()
    {
        throw new TypeError("Method not implemented");
    }
    
    getPlayerCar()
    {
        throw new TypeError("Method not implemented");
    }
    
    getClassId()
    {
        throw new TypeError("Method not implemented");
    }
    
    getClassName()
    {
        throw new TypeError("Method not implemented");
    }
}
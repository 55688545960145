import React, { Component } from 'react';
import autoBind from 'react-autobind';
import './PhotoBox.css';
import { faVideo, faPlusCircle, faPlus, faUserAstronaut, faEdit, faTruckLoading, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoaderBar from './LoaderBar';
import youtube from '../../images/youtube.jpg';
import update from "react-addons-update";

class PhotoBox extends Component {
  static defaultProps = {
      url: null,
      caption: null,
      allowEdit: false,
      loader: false,
      newImageSelected: (blob) => {}
  }

  constructor(props) {
    super(props)
    autoBind(this);
    this.state = this.processProps(props);
  }

  componentWillReceiveProps(props)
  {
    this.setState(this.processProps(props));
  }

  processProps(props)
  {
    return {};
  }

  setFileSelected(event)
  {
    const me = this;
    event.preventDefault();

    const file = event.target.files[0];

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        me.props.newImageSelected(reader.result);
    };
    reader.onerror = function (error) {
        this.setState((oldState) => { return update(oldState, { error: { $set: error } }); })
    };
  }

  render() 
  {
      if ( null == this.props.url )
      {
          return (
            <div className="w3-container w3-blue w3-text-white w3-margin w3-photobox photo-box-square">
                {this.props.loader && 
                  <div className="photo-loader">
                    <FontAwesomeIcon className="photo-loader-icon" icon={faSyncAlt} />
                  </div>
                }
                <div className="w3-right">
                </div>
                <div className="w3-clear photo-box-container">
                    {this.props.allowEdit && <input type="file" accept=".png,.jpg" id="file" ref="setPhoto" style={{display: "none"}} onChange={(ev) => this.setFileSelected(ev)} />}
                    {this.props.allowEdit && <FontAwesomeIcon icon={faPlusCircle} className="photo-box-avatar photo-box-avatar-clickable" onClick={() => this.refs.setPhoto.click()} /> }
                    {!this.props.allowEdit && <FontAwesomeIcon icon={faUserAstronaut} className="photo-box-avatar" /> }
                </div>
                <h4>{this.props.caption}</h4>
            </div> );
      }
      return (
        <div className="w3-container w3-blue w3-text-white w3-margin w3-photobox photo-box-square">
            {this.props.loader && 
              <div className="photo-loader">
                <FontAwesomeIcon className="photo-loader-icon" icon={faSyncAlt} />
              </div>
            }
            <div className="w3-right">
            </div>
            <div className="w3-clear photo-box-container">
                {this.props.allowEdit && <input type="file" accept=".png,.jpg" id="file" ref="setPhoto" style={{display: "none"}} onChange={(ev) => this.setFileSelected(ev)} />}
                {this.props.allowEdit && <img src={this.props.url} className="photo-box-picture" /> }
                {this.props.allowEdit && <FontAwesomeIcon icon={faEdit} className="photo-box-edit photo-box-avatar-clickable" onClick={() => this.refs.setPhoto.click()} /> }
                {!this.props.allowEdit && <img src={this.props.url} className="photo-box-picture" /> }
            </div>
            <h4>{this.props.caption}</h4>
        </div> );
  }
}

export default PhotoBox;
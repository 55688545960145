import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom'
import update from "react-addons-update";

import autoBind from 'react-autobind';
import './LeagueEditScreen.css'
import BaseScreenHeader from '../../BaseScreenHeader';
import InputField from '../../../components/common/InputField';
import { faSync, faPlusCircle, faTrashAlt, faCog, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import PhotoBox from '../../../components/common/PhotoBox';
import Button from '../../../components/common/Button';
import InputTextArea from '../../../components/common/InputTextArea';
import Utils from '../../../utils/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopupAlert from '../../../components/common/PopupAlert';
import withRouter from '../../../utils/withRouter';

class LeagueEditScreen extends BaseScreenHeader {
    static defaultProps = {
        session: null
    }
    constructor(props) {
        super(props)
        autoBind(this);
    }

    validatePermission()
    {
        return this.props.session.getPermissions().hasPermissionGlobal('can_edit_league') || this.props.session.getPermissions().hasPermissionContext('can_edit_league', this.props.params.leagueId);
    }

    onInitParameters(props)
    {
      this.state.league = this.createLeague(props.params.leagueId);
      return true;
    }
  
    bindDelegates()
    {
        this.session.onLeaguesChanged.register(this.onLeaguesChanged);
        super.bindDelegates();
    }
    unbindDelegates()
    {
        this.session.onLeaguesChanged.unregister(this.onLeaguesChanged);
        super.unbindDelegates();
    }
    onLeaguesChanged(sender, data)
    {
        var league = this.createLeague(this.props.params.leagueId);
  
        this.setState((oldState) => { return update(oldState, { league: { $set: league }})});
    }
  
    createLeague(id)
    {
        let data = this.session.getLeagues();
        if ( null == data )
        {
            return null;
        }
        for ( const l of data )
        {
            if ( l.getExternal() != id )
            {
                continue;
            }
            let league = {};
            league.external = l.getExternal();
            league.shortname = l.getShortname();
            league.description = l.getDescription();
            league.thumbnail = l.getThumbnail();
            league.is_finished = l.getIsFinished();
  
            if ( null != l.getEvents() )
            {
              let events = [];
              for ( const e of l.getEvents() )
              {
                  let event = {};
                  event.ob = e;
                  event.external = e.getExternal();
                  event.shortname = e.getShortname();
                  event.description = e.getDescription();
                  event.location = e.getLocation();
                  event.thumbnail = e.getThumbnail();
                  event.timestamp_start = e.getTimestampStart();
                  event.timestamp_end = e.getTimestampEnd();
                  events.push(event);
              }
              league.events = events;
            }
            if ( null != l.getClasses() )
            {
                let classes = [];
                for ( const cls of l.getClasses() )
                {
                    let data = {}
                    data.ob = cls;
                    data.external = cls.getExternal();
                    data.shortname = cls.getShortname();
                    data.player_start = cls.getPlayerStart();
                    data.priority = cls.getPriority();
  
                    classes.push(data);
                }
                league.classes = classes;
            }
            return league;
        }
        return null;
    }

    setLeagueDescription(value)
    {
        this.setState((oldState) => { return update(oldState, { league_description: { $set: value }})});
    }

    doUpdate()
    {
        let data = this.session.getLeagues();
        if ( null == data )
        {
            return;
        }

        let ob = data.find((x) => { return x.getExternal() == this.props.params.leagueId});

        if ( null == ob )
        {
            return;
        }

        let action = ob.createUpdateLeague();
        if ( undefined != this.state.league_description )
        {
            action.setDescription(this.state.league_description);
        }

        action.OnCommitRequested.register(this.onUpdateLeagueCommitRequested);
        action.OnCommitFinished.register(this.onUpdateLeagueCommitFinished);
        action.OnCommitError.register(this.onUpdateLeagueCommitError);

        action.Commit();

    }
  
    
    onUpdateLeagueCommitRequested(sender, params) {
        this.beginAsyncOperation();
    }

    onUpdateLeagueCommitFinished(sender, params) {
        this.endAsyncOperation();

        this.setState((oldState) => { return update(oldState, { league_description: { $set: null }})});
        this.showMessage('Zmiany zostały zapisane');
    }

    onUpdateLeagueCommitError(sender, err) {
        this.endAsyncOperation();
        this.showError(err);
    }

    removeClass(ob)
    {
        this.showDialog(() => 
        { 
          return (<PopupAlert text={'Czy na pewno chcesz sie usunąc klase ' + ob.getShortname() + '?'} 
                    accept='TAK'
                    reject='NIE' 
                    onRejected={((e) => { this.clearDialog(); })} 
                    onAccepted={(e) => { this.clearDialog(); this.doRemoveClass(ob) }} 
                  />);
        } );
        return;
    }

    doRemoveClass(ob)
    {
        let action = ob.createRemoveClass();

        action.OnCommitRequested.register(this.onRemoveClassCommitRequested);
        action.OnCommitFinished.register(this.onRemoveClassCommitFinished);
        action.OnCommitError.register(this.onRemoveClassCommitError);

        action.Commit();
    }

    onRemoveClassCommitRequested(sender, params) {
        this.beginAsyncOperation();
    }

    onRemoveClassCommitFinished(sender, params) {
        this.endAsyncOperation();
        this.showMessage('Zmiany zostały zapisane');
    }

    onRemoveClassCommitError(sender, err) {
        this.endAsyncOperation();
        this.showError(err);
    }

    
    removeEvent(ob)
    {
        this.showDialog(() => 
        { 
          return (<PopupAlert text={'Czy na pewno chcesz sie usunąc wydarzenie ' + ob.getShortname() + '?'} 
                    accept='TAK'
                    reject='NIE' 
                    onRejected={((e) => { this.clearDialog(); })} 
                    onAccepted={(e) => { this.clearDialog(); this.doRemoveEvent(ob) }} 
                  />);
        } );
        return;
    }

    doRemoveEvent(ob)
    {
        let action = ob.createRemoveEvent();

        action.OnCommitRequested.register(this.onRemoveEventCommitRequested);
        action.OnCommitFinished.register(this.onRemoveEventCommitFinished);
        action.OnCommitError.register(this.onRemoveEventCommitError);

        action.Commit();
    }

    onRemoveEventCommitRequested(sender, params) {
        this.beginAsyncOperation();
    }

    onRemoveEventCommitFinished(sender, params) {
        this.endAsyncOperation();
        this.showMessage('Zmiany zostały zapisane');
    }

    onRemoveEventCommitError(sender, err) {
        this.endAsyncOperation();
        this.showError(err);
    }

    renderScreen(child) {
        var league = this.state.league;

        if ( null == league )
        {
            return super.renderScreen(
                <div>Trwa wczytywanie danych</div>
            );
        }

        let events = null;
        if ( league.events && league.events.length > 0)
        {
            events = league.events.map((x, idx) => {
                return (
                    <tr key={x.external}>
                        <td>{idx + 1}</td>
                        <td>{x.shortname}</td>
                        <td>{Utils.timestamps_to_string(x.timestamp_start, x.timestamp_end, 'YYYY-MM-DD HH:mm')}</td>
                        <td className="w3-inline">
                            <Link to={'/manage/event/' + x.external + '/edit'} ><FontAwesomeIcon icon={faCog} /></Link>
                            
                            <FontAwesomeIcon className="w3-clickable" icon={faTrashAlt} onClick={(e) => {this.removeEvent(x.ob)}} />
                        </td>
                    </tr>
                )
            })
        }
        else
        {
            events = ( <tr><td colSpan={4}>Brak wydarzeń</td> </tr>);
        }

        let classes = null;
        if ( league.classes && league.classes.length > 0)
        {
            classes = league.classes.map((x, idx) => {
                return (
                    <tr key={x.external}>
                        <td>{idx + 1}</td>
                        <td>{x.shortname}</td>
                        <td>{x.player_start}</td>
                        <td>{x.priority}</td>
                        <td className="w3-inline">
                            <Link to={'/manage/class/' + x.external + '/edit'} ><FontAwesomeIcon icon={faCog} /></Link>
                            
                            <FontAwesomeIcon className="w3-clickable" icon={faTrashAlt} onClick={(e) => {this.removeClass(x.ob)}} />
                        </td>
                    </tr>
                )
            })
        }
        else
        {
            classes = ( <tr><td colSpan={4}>Brak klas</td> </tr>);
        }

        return super.renderScreen(
            <header className="w3-container w3-anchor">
                <h5>Edycja ligi </h5>
                <div className="w3-container w3-float-topright">
                    <h5><Link to={'/league/' + league.external}><FontAwesomeIcon icon={faAngleDoubleLeft} /> Wróc</Link></h5>
                </div>
                <div className="w3-container">
                    <h6>Nazwa cyklu</h6>
                    <div>{league.shortname}</div>
                    <h6>Opis cyklu</h6>
                    <InputTextArea value={league.description} onCommit={(value) =>  { this.setLeagueDescription(value)}} />
                    <div className="w3-quarter w3-right w3-margin">
                        <Button onClicked={() => { this.doUpdate()}} disabled={this.state.asyncOperation || (null == this.state.league_description)} text="Zapisz zmiany" iconLeft={this.state.asyncOperation ? faSync : null} />
                    </div>       
                </div>


                <div className="w3-container w3-anchor">
                    <h6>Wydarzenia</h6>
                    <table className="w3-table-all">
                        <thead>
                            <tr>
                                <th>LP</th>
                                <th>Nazwa</th>
                                <th>Termin</th>
                                <th>Akcje</th>
                            </tr>
                        </thead>
                        <tbody>
                            {events}
                        </tbody>
                    </table>
                    <div className="w3-container w3-float-topright">
                        <h5><Link to={'/manage/event/' + league.external + '/add'}><FontAwesomeIcon icon={faPlusCircle} /> Stwórz nowe wydarzenie</Link></h5>
                    </div>
                </div>

                <div className="w3-container w3-anchor">
                    <h6>Klasy</h6>
                    <table className="w3-table-all">
                        <thead>
                            <tr>
                                <th>LP</th>
                                <th>Nazwa</th>
                                <th>Numer startowy</th>
                                <th>Priorytet</th>
                                <th>Akcje</th>
                            </tr>
                        </thead>
                        <tbody>
                            {classes}
                        </tbody>
                    </table>
                    <div className="w3-container w3-float-topright">
                        <h5><Link to={'/manage/class/' + league.external + '/add'}><FontAwesomeIcon icon={faPlusCircle} /> Stwórz nowa klase</Link></h5>
                    </div>
                </div>
            </header>);

    }
}
export default withRouter(LeagueEditScreen);
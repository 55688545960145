import IServiceRequest from "../../../connect/IServiceRequest";

export default class LeagueCreateCommit extends IServiceRequest
{
    constructor()
    {
        super('www/league/add');
    }

    setShortname(shortname)
    {
        this.appendRequest("shortname", shortname);
    }

    setDescription(description)
    {
        this.appendRequest("description", description);
    }    
}
import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import './ClassesScreen.css'
import { DrawBottomRight } from '../LayoutHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch, faFlagCheckered, faCog, faCar, faSmile, faScroll, faUser } from '@fortawesome/free-solid-svg-icons';
import AttendanceWidget from '../../components/widgets/AttendanceWidget';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SosIcon from '@mui/icons-material/Sos';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const ClassScreen = ({session}) =>  {
    const urlParams = useParams();
    const navigate = useNavigate();
    
    const [ classId, setClassId ] = useState(undefined);
    const [ leagues, setLeagues ] = useState(null);

    const [ league, setLeague ] = useState(null);
    const [ clazz, setClazz ] = useState(null);
    const [ players, setPlayers ] = useState(null);

    useEffect(() => {
        setClassId(urlParams.classId);
    }, [urlParams])


    useEffect( () => { 
        const leagueChangedCallback = (sender, leagues) => {
            setLeagues(leagues) ;
        }
        session.onLeaguesChanged.register(leagueChangedCallback);

        setLeagues(session.getLeagues()) ;
        return () => {
            session.onPersonalizeChanged.unregister(leagueChangedCallback);
        }
    }, [])

    
    useEffect( () => {
        if ( null == classId )
        {
            return;
        }
        if ( null == leagues )
        {
            return;
        }
        for ( const l of leagues )
        {
            for ( const cls of l.getClasses() )
            {
                if ( classId != cls.getExternal() )
                {
                    continue;
                }

                {
                    let league = {};
                    league.external = l.getExternal();
                    league.shortname = l.getShortname();
                    league.description = l.getDescription();
                    league.thumbnail = l.getThumbnail();
                    league.is_finished = l.getIsFinished();

                    setLeague(league)
                }
                {
                    let clzz = {}
                    clzz.external = cls.getExternal();
                    clzz.shortname = cls.getShortname();
                    clzz.description = cls.getDescription();
                    clzz.priority = cls.getPriority();
                    clzz.starting_number = cls.getPlayerStart();

                    setClazz(clzz);
                }
                {
                    let players = [];
                    for ( const cls of l.getPlayers() )
                    {
                        if ( classId != cls.getClass() )
                        {
                            continue;
                        }
                        let item = {}
                        item.external = cls.getExternal();
                        item.player = cls.getPlayer();
                        item.class = cls.getClass();
                        item.vehicle = cls.getVehicle();
                        item.player_name = cls.getPlayerName();
                        item.player_car = cls.getPlayerCar();
                        item.car_power = cls.getCarPower();
                        item.car_mass = cls.getCarMass();
                        item.picture_car = cls.getPictureCar();
                        item.picture_driver = cls.getPictureDriver();

                        players.push(item);
                    }
                    setPlayers(players);
                }
                break;
            }
        }

    }, [classId, leagues]);

    const renderPlayers = () => {
        if ( null == players || players.length < 1)
        {
            return <Box>Brak zawodników</Box>
        }
        return players.map((x, index) => {
            return (<TableRow key={x.external} className="w3-padding-16 w3-hoverable w3-tr-with-link">
                        <TableCell key={x.external + "-index"} sx={{padding: '2px'}}>{index+1}</TableCell>
                        <TableCell sx={{padding: '2px'}}><span className="w3-xlarge">{x.player}</span></TableCell>
                        <TableCell sx={{padding: '2px'}}>{x.player_name}</TableCell>
                        <TableCell sx={{padding: '2px'}}>{x.player_car}</TableCell>
                        <TableCell sx={{padding: '2px'}}>{x.car_power}</TableCell>
                        <TableCell sx={{padding: '2px'}}>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                <Link to={'/results/' + league.external + '/player/' + x.player}>
                                    <FontAwesomeIcon icon={faScroll}/>
                                </Link>
                                <Link to={'/player/' + x.external}>
                                    <FontAwesomeIcon icon={faUser}/>
                                </Link>
                            </Stack>
                        </TableCell>
                    </TableRow> )
        })
    };

    return (
        <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
            {(null == leagues || null == clazz) && 
                <Box>Trwa wczytywanie danych</Box>
            }
            {null != leagues && null != clazz && 
                <Box>
                    <h5>Klasa {clazz.shortname} w {league.shortname}:</h5>
                    <h5>{clazz.description}</h5>

                    <Table stickyHeader aria-label="sticky table" className="w3-table w3-striped w3-white w3-bordered">
                        <TableHead>
                            <TableRow>
                                <TableCell key={'lp'} align={'left'} style={{ minWidth: '30px' }} >LP</TableCell>
                                <TableCell key={'shortname'} align={'left'} style={{ minWidth: '30px' }} >Nr</TableCell>
                                <TableCell key={'description'} align={'left'} style={{ minWidth: '30px' }} >Zawodnik</TableCell>
                                <TableCell key={'car'} align={'left'} style={{ minWidth: '30px' }} >Auto</TableCell>
                                <TableCell key={'power'} align={'left'} style={{ minWidth: '30px' }} >Moc</TableCell>
                                <TableCell key={'action'} align={'left'} style={{ minWidth: '30px' }} ></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderPlayers()}
                        </TableBody>
                    </Table>
                </Box>
            }
        </Box>
    );
}
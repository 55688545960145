import IChat from "../../../sdk/data/chat/IChat";
import Event from "../../../sdk/property/Event";
import { CreateMessage } from "./CreateMessage";
import { ChatMessage } from "./ChatMessage";

export class Chat extends IChat
{
    onCreateMessageCreated = new Event();

    constructor()
    {
        super();
        this.token = null;
        this.topic = null;
        this.is_locked = null;
        this.first_message_timestamp = null;
        this.last_message_timestamp = null;
        this.messages = null;
    }

    getToken()
    {
        return this.token;
    }

    Query()
    {
        this.OnQueryRequested.callback(this, null);
    }

    getTopic()
    {
        return this.topic;
    }

    getIsLocked()
    {
        return this.is_locked;
    }

    getFirstMessageTimestamp()
    {
        return this.first_message_timestamp;
    }

    getLastMessageTimestamp()
    {
        return this.last_message_timestamp;
    }

    getMessages()
    {
        return this.messages;
    }

    CreateMessage()
    {
        let retval = new CreateMessage();
        retval.chat = this.external;

        retval.OnCommitFinished.register(this.onCreateMssageCommitFinished.bind(this));

        this.onCreateMessageCreated.callback(this, retval);

        return retval;
    }

    onCreateMssageCommitFinished(sender, params)
    {
        let message = new ChatMessage();

        message.external = sender.getExternal();
        message.message = sender.getMessage();
        message.author_name = sender.getAuthorName();
        message.timestamp = sender.getTimestamp();

        if ( null == this.messages )
        {
            this.messages = [];
        }
        this.messages.push(message);
        this.OnMessagesChanged.callback(this, this.messages);
    }
}
import React, { Component } from 'react';
import update from "react-addons-update";
import { Link } from 'react-router-dom'

import autoBind from 'react-autobind';
import './BaseScreenHeader.css'
import BaseScreen from './BaseScreen';
import Header from 'components/Header';
import PopupAlert from 'components/common/PopupAlert';
import Database from 'Database';
import Footer from '../components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import scs_logo from '../images/logo/scslogo.png';
import avatar6 from '../images/avatar6.png';
import avatar2 from '../images/avatar2.png';
import { faCog, faUser, faEnvelope, faSignInAlt, faSignOutAlt, faUsers, faCar, faBookDead, faBookReader, faFilter } from '@fortawesome/free-solid-svg-icons'
import Loader from '../components/common/Loader';

class BaseScreenHeader extends BaseScreen {
  constructor(props)
  {
    super(props)
    autoBind(this);

    this.menu = React.createRef();
    this.menuOverlay = React.createRef();

    this.delegatePersonalizeChanged = this.onSessionPersonalizedChanged.bind(this);
    this.delegateFiltersChanged = this.onSessionFiltersChanged.bind(this);
  }

  logout()
  {
      this.showDialog(() => 
        { 
          return (<PopupAlert text='Czy na pewno chcesz sie wylogować?' accept='TAK' reject='NIE' 
                    onRejected={((e) => { this.clearDialog(); })} 
                    onAccepted={(e) => { Database.instance().Reset(); this.clearDialog(); }} 
                  />);
        } 
      );
  }

  toggleSideMenu()
  {
    if ( null == this.menu )
    {
      return;
    }

    if (this.menu.current.style.display === 'block') {
      this.menu.current.style.display = 'none';
      this.menuOverlay.current.style.display = "none";
    } else {
      this.menu.current.style.display = 'block';
      this.menuOverlay.current.style.display = "block";
    }
  }

  closeSideMenu()
  {
    if ( null == this.menu )
    {
      return;
    }

    this.menu.current.style.display = 'none';
    this.menuOverlay.current.style.display = "none";
  }

  bindDelegates()
  {
    super.bindDelegates();

    this.props.session.onPersonalizeChanged.register(this.delegatePersonalizeChanged);
    this.props.session.onFiltersChanged.register(this.delegateFiltersChanged);
    
  }

  unbindDelegates()
  {
    this.props.session.onPersonalizeChanged.unregister(this.delegatePersonalizeChanged);
    this.props.session.onFiltersChanged.unregister(this.delegateFiltersChanged);
    super.unbindDelegates();
  }

  onSessionPersonalizedChanged(sender, value)
  {
    this.setState((oldState) => { return update(oldState, { isPersonalized: { $set: value } })});
  }

  onSessionFiltersChanged(sender, value)
  {
    this.setState((oldState) => { return update(oldState, { menu_filters: { $set: value } })});
  }

  renderUserData()
  {
    let isLoggedIn = this.props.session.getPersonalized();
    let user = this.props.session.getUser();
    if ( !isLoggedIn || null == user )
    {
      var redirectUrl = "/account/login";
      var url = window.location.pathname;
      if ( url.length > 1 )
      {
        redirectUrl = redirectUrl + "?redirect=" + encodeURIComponent(url);
      }
      return (                  
        <div className="w3-container w3-row w3-anchor">
          <div className="w3-col s4">
            <img src={avatar6} className="w3-circle w3-margin-right" style={{width: 46 + 'px'}}/>
          </div>
          <div className="w3-col s8 w3-bar">
            <span>Witaj, <strong>nieznajomy</strong></span><br/>
            <Link data-tooltip="Zaloguj się do serwisu" to={redirectUrl}  className="w3-bar-item w3-button"><FontAwesomeIcon icon={faSignInAlt} /></Link>
          </div>
        </div>);
    }

    

    return (
      <div className="w3-container w3-row">
        <div className="w3-col s4">
          <img src={avatar2} className="w3-circle w3-margin-right" style={{width: 46 + 'px'}}/>
        </div>
        <div className="w3-col s8 w3-bar">
          <span>Witaj, <strong>{user.getNick()}</strong></span><br/>
          <Link data-tooltip="Przejdź do wiadomości" to="/" className="w3-bar-item w3-button"><FontAwesomeIcon icon={faEnvelope} /></Link>
          <Link data-tooltip="Przejdź do ustawien profilu" to="/account/profile" className="w3-bar-item w3-button"><FontAwesomeIcon icon={faUser} /></Link>
          <Link data-tooltip="Wyloguj się" to="#" onClick={(e) => this.logout() } className="w3-bar-item w3-button"><FontAwesomeIcon icon={faSignOutAlt} /></Link>
        </div>
      </div>      
    );
  }

  renderUserFilters()
  {
     let filters = this.props.session.getFilters();
     if ( null == filters )
     {
       return null;
     }
     if ( filters.length < 1 )
     {
       return null;
     }

     let data = filters.map((x) => {
       return <Link key={x.url} to={x.url} className="w3-bar-item w3-button w3-padding"><FontAwesomeIcon icon={faFilter} /> {x.shortname}</Link>
     });
     return data;
  }

  renderScreen(child)
  {
      let isLoggedIn = this.props.session.getPersonalized();
      let canSeeApplications = this.props.session.getPermissions().hasPermissionGlobal('menu_applications_vehicle');

      if ( !this.validatePermission() )
      {
        child = ( <div className="w3-container">
                    <h2>Brak uprawnień do oglądania tej treści</h2>
                  </div> );
      }

      return (
          <div className="BaseScreenHeader">

            {/* <!-- Top container --> */}
            <div className="w3-bar w3-top w3-grey w3-large" style={{zIndex:4}}>
              <button className="w3-bar-item w3-button w3-hide-large w3-hover-none w3-hover-text-light-grey" onClick={() => {this.toggleSideMenu()}}><i className="fa fa-bars"></i>  Menu</button>
              <span className="w3-bar-item w3-right">
                <a href="/"><img src={scs_logo} className="scs-logo" /></a>
              </span>
              {this.state.asyncOperation &&
                <div className="screen-menu-loader">
                    <Loader />
                </div>
              }
            </div>
            {/* <!-- Sidebar/menu --> */}
            <nav className="w3-sidebar w3-collapse w3-white w3-animate-left w3-anchor" style={{zIndex:3, width:300 + 'px'}} ref={this.menu} id="mySidebar" >
              <br/>
              {this.renderUserData()}
              <hr/>
              <div className="w3-bar-block">
                <a href="#" className="w3-bar-item w3-button w3-padding-16 w3-hide-large w3-dark-grey w3-hover-black" onClick={() => this.closeSideMenu()} title="close menu"><i className="fa fa-remove fa-fw"></i>  Close Menu</a>
                <Link to="/events" className="w3-bar-item w3-button w3-padding"><FontAwesomeIcon icon={faUsers} />  Wydarzenia</Link>
                {isLoggedIn && <Link to="/garage" className="w3-bar-item w3-button w3-padding"><FontAwesomeIcon icon={faCar} />  Twój garaż</Link> }
                {isLoggedIn && <Link to="/applications/mine" className="w3-bar-item w3-button w3-padding"><FontAwesomeIcon icon={faBookDead} />  Twoje zgłoszenia</Link> }
                {canSeeApplications && <Link to="/applications/view" className="w3-bar-item w3-button w3-padding"><FontAwesomeIcon icon={faBookReader} />  Zgłoszenia zawodników</Link> }
                {this.renderUserFilters()}
              </div>
            </nav>


            {/* <!-- Overlay effect when opening sidebar on small screens --> */}
            <div className="w3-overlay w3-hide-large w3-animate-opacity" onClick={() => this.closeSideMenu()} style={{cursor:'pointer'}} title="close side menu" ref={this.menuOverlay} id="myOverlay"></div>

            {/* <!-- !PAGE CONTENT! --> */}
            <div className="w3-main" style={{marginLeft:300 + 'px', marginTop:43 + 'px'}}>
              {child}
            </div>
          </div>
        )
    }
};

export default BaseScreenHeader;
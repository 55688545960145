import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import './TimeslipScreen.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faFlagCheckered, faStopwatch, faStop, faObjectGroup, faCar, faUserAstronaut, faUser, faGlobeEurope, faCalendar } from '@fortawesome/free-solid-svg-icons'
import Utils from '../../utils/Utils';

import Box from '@mui/material/Box';

export const TimeslipScreen = ({session}) => {
    const urlParams = useParams();
    const navigate = useNavigate();
    
    const [ timeslipId, setTimeslipId ] = useState(undefined);
    const [ isAsyncOperation, setAsyncOperation ] = useState(false);

    const [ leagueName, setLeagueName ] = useState(null);
    const [ eventName, setEventName ] = useState(null);
    const [ playerName, setPlayerName ] = useState(null);
    const [ playerNumber, setPlayerNumber ] = useState(null);
    const [ playerTeam, setPlayerTeam ] = useState(null);
    const [ playerCar, setPlayerCar ] = useState(null);
    const [ playerClass, setPlayerClass ] = useState(null);
    const [ reactionTime, setReactionTime ] = useState(null);
    const [ time60ft, setTime60ft ] = useState(null);
    const [ time660ft, setTime660ft ] = useState(null);
    const [ time1320ft, setTime1320ft ] = useState(null);
    const [ speed660ft, setSpeed660ft ] = useState(null);
    const [ speed1320ft, setSpeed1320ft ] = useState(null);
    const [ pictureDriver, setPictureDriver ] = useState(null);
    const [ pictureCar, setPictureCar ] = useState(null);
    const [ timestampRace, setTimestampRace ] = useState(null);
    const [ isInvalid, setIsInvalid ] = useState(null);
    const [ isPublic, setIsPublic ] = useState(null);

    useEffect(() => {
        setTimeslipId(urlParams.timeslipId);
    }, [urlParams])

    useEffect( () => {
        const commitRequested = (sender, params) => {
            setAsyncOperation(true);
        }
        const commitFinished = (sender, params) => {
            setAsyncOperation(false);

            let action = sender;

            setLeagueName(action.getLeagueName());
            setEventName(action.getEventName());
            setPlayerName(action.getPlayerName());
            setPlayerNumber(action.getPlayerNumber());
            setPlayerTeam(action.getPlayerTeam());
            setPlayerCar(action.getPlayerCar());
            setPlayerClass(action.getPlayerClass());
            setReactionTime(action.getReactionTime());
            setTime60ft(action.getTime60ft());
            setTime660ft(action.getTime660ft());
            setTime1320ft(action.getTime1320ft());
            setSpeed660ft(action.getSpeed660ft());
            setSpeed1320ft(action.getSpeed1320ft());
            setPictureDriver(action.getPictureDriver());
            setPictureCar(action.getPictureCar());
            setTimestampRace(action.getTimestampRace());
            setIsInvalid(action.getIsInvalid());
            setIsPublic(action.getIsPublic());
        }
        const commitError = (sender, params) => {
            setAsyncOperation(false);
        }
        if ( null == timeslipId )
        {
            return;
        }
        var action = session.createTimeslip(timeslipId)

        action.OnCommitRequested.register(commitRequested);
        action.OnCommitFinished.register(commitFinished);
        action.OnCommitError.register(commitError);
        action.Commit();
    }, [timeslipId])

    const renderPlayer = () => {
        let player = playerNumber;
        if ( playerName )
        {
            if ( player )
            {
                player = player + " - " + playerName;
            }
            else
            {
                player = playerName;
            }
        }
        return player;
    }
    return (
        <Box>
            {null != timestampRace && 
                <>
                        <div className="w3-container">
                        <h4 className="w3-h4-fixed">Timeslip 1/4</h4>
                        <div className="w3-container">
                            <div className="w3-row-padding w3-margin-bottom w3-stretch-children-height">
                                <table className="w3-table w3-striped w3-bordered w3-border w3-white w3-twothird">
                                    <thead>
                                        <tr className="row-captions">
                                            <th className="column-caption"></th>
                                            <th className="column-data"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {leagueName && 
                                        <tr style={{height: '46px'}}>
                                            <td className="w3-center">Liga</td>
                                            <td className="w3-center">{leagueName}</td>
                                        </tr>
                                    }
                                    {eventName && 
                                        <tr style={{height: '46px'}}>
                                            <td className="w3-center">Wydarzenie</td>
                                            <td className="w3-center">{eventName}</td>
                                        </tr>
                                    }
                                    {playerNumber && 
                                        <tr style={{height: '46px'}}>
                                            <td className="w3-center">Zawodnik</td>
                                            <td className="w3-center">{renderPlayer()}</td>
                                        </tr>
                                    }
                                    {playerTeam && 
                                        <tr style={{height: '46px'}}>
                                            <td className="w3-center">Drużyna</td>
                                            <td className="w3-center">{playerTeam}</td>
                                        </tr>
                                    }
                                    {playerCar && 
                                        <tr style={{height: '46px'}}>
                                            <td className="w3-center">Auto</td>
                                            <td className="w3-center">{playerCar}</td>
                                        </tr>
                                    }
                                    {playerClass && 
                                        <tr style={{height: '46px'}}>
                                            <td className="w3-center">Klasa</td>
                                            <td className="w3-center">{playerClass}</td>
                                        </tr>
                                    }       
                                    {timestampRace && 
                                        <tr style={{height: '46px'}}>
                                            <td className="w3-center">Data przejazdu</td>
                                            <td className="w3-center">{timestampRace.format('YYYY-MM-DD HH:mm:ss')}</td>
                                        </tr>
                                    }        
                                    </tbody>
                                </table>
                                <div className="w3-third w3-stretch-height">
                                    <div className="w3-container w3-orange w3-text-white w3-padding-16 w3-stretch-fill">
                                        {pictureDriver && <img src={pictureDriver} className="w3-img-fill" /> }
                                        {!pictureDriver && <FontAwesomeIcon icon={faUser} /> }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w3-container">
                            <div className="w3-row-padding w3-margin-bottom w3-stretch-children-height">
                                <table className="w3-table w3-striped w3-bordered w3-border w3-white w3-twothird">
                                    <thead>
                                        <tr className="row-captions">
                                            <th className="column-caption"></th>
                                            <th className="column-data"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {time1320ft && 
                                        <tr style={{height: '46px'}}>
                                            <td className="w3-center">ET 1/4</td>
                                            <td className="w3-center">{time1320ft}</td>
                                        </tr>
                                    } 
                                    {speed1320ft && 
                                        <tr style={{height: '46px'}}>
                                            <td className="w3-center">SPEED 1/4</td>
                                            <td className="w3-center">{speed1320ft}</td>
                                        </tr>
                                    } 
                                    {time660ft && 
                                        <tr style={{height: '46px'}}>
                                            <td className="w3-center">ET 1/8</td>
                                            <td className="w3-center">{time660ft}</td>
                                        </tr>
                                    } 
                                    {speed660ft && 
                                        <tr style={{height: '46px'}}>
                                            <td className="w3-center">SPEED 1/8</td>
                                            <td className="w3-center">{speed660ft}</td>
                                        </tr>
                                    } 
                                    {time60ft && 
                                        <tr style={{height: '46px'}}>
                                            <td className="w3-center">ET 60ft</td>
                                            <td className="w3-center">{time60ft}</td>
                                        </tr>
                                    } 
                                    {reactionTime && 
                                        <tr style={{height: '46px'}}>
                                            <td className="w3-center">RT</td>
                                            <td className="w3-center">{reactionTime}</td>
                                        </tr>
                                    } 
                                    </tbody>
                                </table>
                                <div className="w3-third w3-stretch-height">
                                    <div className="w3-container w3-green w3-text-white w3-padding-16 w3-stretch-fill">
                                        {pictureCar && <img src={pictureCar} className="w3-img-fill" /> }
                                        {!pictureCar && <FontAwesomeIcon icon={faCar} /> }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </Box>
    )
}
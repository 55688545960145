import IRemoveEvent from "../../../../sdk/data/manage/event/IRemoveEvent";

export class RemoveEvent extends IRemoveEvent
{
    constructor()
    {
        super();
        this.external = null;
    }

    getExternal()
    {
        return this.external;
    }
    
    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

}
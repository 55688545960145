import React, { Component } from 'react';

import update from "react-addons-update";
import autoBind from 'react-autobind';
import './Tooltip.css';


class Tooltip extends Component {
    constructor(props)
    {
      super(props)
      autoBind(this);
    }


    render()
    {
        return (<ul id="Tooltip">
                    <li><div className="current">{this.props.text}</div></li>
                </ul>);
    }
}


export default Tooltip;
import Event from "../../../sdk/property/Event";
import IVehicleApplication from "../../../sdk/data/vehicle/IVehicleApplication";

export class VehicleApplication extends IVehicleApplication
{
   constructor()
    {
        super();
        this.league_id = null;
        this.league_class_id = null;
        this.league_event_id = null;
        this.crew_count = null;
        this.assigned_number = null;
        this.car_shortname = null;
        this.car_power = null;
        this.driver_name = null;
        this.comment = null;
    }


    setLeague(data)
    {
        this.league_id = data;
    }

    setLeagueClass(data)
    {
        this.league_class_id = data;
    }
    
    setLeagueEvent(data)
    {
        this.league_event_id = data;
    }

    setCrewCount(data)
    {
        this.crew_count = data;
    }

    setAssignedNumber(data)
    {
        this.assigned_number = data;
    }

    setCarShortname(data)
    {
        this.car_shortname = data;
    }    

    setCarPower(data)
    {
        this.car_power = data;
    }    

    setDriverName(data)
    {
        this.driver_name = data;
    } 
    
    setComment(data)
    {
        this.comment = data;
    }
    
    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }
}
import IPlayerStats from "../../sdk/data/IPlayerStats";

export class PlayerStats extends IPlayerStats
{
    constructor()
    {
        super();
        this.player = null;
        this.driver_name = null;
        this.team_name = null;
        this.player_car = null;
        this.car_power = null;
        this.car_mass = null;
        this.player_class = null;
        this.season_best_time_1320ft = null;
        this.season_best_speed_1320ft = null;
        this.event_best_time_1320ft = null;
        this.event_best_speed_1320ft = null;
        this.picture_url = null;
    }
    getPlayer() 
    {
        return this.player;
    }

    getPlayerClass()
    {
        return this.player_class;
    }

    getDriverName() {
        return this.driver_name;
    }

    getTeamName() {
        return this.team_name;
    }

    getPlayerCar() {
        return this.player_car;
    }

    getCarPower()
    {
        return this.car_power;
    }

    getCarMass()
    {
        return this.car_mass;
    }

    getSeasonBestTime1320ft()
    {
        return this.season_best_time_1320ft;
    }
    getSeasonBestSpeed1320ft()
    {
        return this.season_best_speed_1320ft;
    }

    getEventBestTime1320ft()
    {
        return this.event_best_time_1320ft;
    }
    getEventBestSpeed1320ft()
    {
        return this.event_best_speed_1320ft;
    }
    getPictureUrl()
    {
        return this.picture_url;
    } 
}

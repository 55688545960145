import React, { Component, createRef } from 'react';
import { Link, Navigate } from 'react-router-dom'
import update from "react-addons-update";

import autoBind from 'react-autobind';
import './EventResultsScreen.css'
import BaseScreenHeader from '../../BaseScreenHeader';
import InputField from '../../../components/common/InputField';
import { faSync, faSmile, faQuestionCircle, faAngleDoubleLeft, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import PhotoBox from '../../../components/common/PhotoBox';
import Button from '../../../components/common/Button';
import Utils from '../../../utils/Utils';
import HelpIcon from '../../../components/common/HelpIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopupAlert from '../../../components/common/PopupAlert';
import withRouter from '../../../utils/withRouter';

class EventResultsScreen extends BaseScreenHeader {
    static defaultProps = {
        session: null
    }
    constructor(props) {
        super(props)
        autoBind(this);

        this.uploadFileInput = createRef();
    }

    validatePermission() {
        return true;
    }

    onInitParameters(bundle) {
        var data = this.createEvent(this.props.params.eventId);
        if (data) {
            this.state.league = data.league;
            this.state.event = data.event;

            let event = data.event;

            this.initFormData({ shortname: event.shortname, description: event.description, location: event.location, gps: event.gps, timestamp_start: event.timestamp_start, timestamp_end: event.timestamp_end });
        }
        return true;
    }

    bindDelegates() {
        this.session.onLeaguesChanged.register(this.onLeaguesChanged);
        super.bindDelegates();
    }
    unbindDelegates() {
        this.session.onLeaguesChanged.unregister(this.onLeaguesChanged);
        super.unbindDelegates();
    }
    onLeaguesChanged(sender, data) {
        var data = this.createEvent(this.props.params.eventId);
        let league = null;
        let event = null;
        if (data) {
            league = data.league;
            event = data.event;
        }

        this.initFormData({ shortname: event.shortname, description: event.description, location: event.location, gps: event.gps, timestamp_start: event.timestamp_start, timestamp_end: event.timestamp_end });

        this.setState((oldState) => { return update(oldState, { league: { $set: league }, event: { $set: event } }) });
    }

    createEvent(evid) {
        let data = this.session.getLeagues();
        if (null == data) {
            return null;
        }
        for (const l of data) {
            let found = false;

            let league = {};
            league.external = l.getExternal();
            league.shortname = l.getShortname();

            let event = null;

            if (null != l.getEvents()) {
                let events = [];
                for (const ev of l.getEvents()) {
                    let evitem = {}
                    evitem.external = ev.getExternal();
                    evitem.shortname = ev.getShortname();
                    evitem.description = ev.getDescription();
                    evitem.location = ev.getLocation();
                    evitem.gps = ev.getGps();
                    {
                        let date = ev.getTimestampStart();
                        if (date) {
                            evitem.timestamp_start = date.format('YYYY-MM-DD HH:mm');
                        }
                    }
                    {
                        let date = ev.getTimestampEnd();
                        if (date) {
                            evitem.timestamp_end = date.format('YYYY-MM-DD HH:mm');
                        }
                    }
                    evitem.ob = ev;

                    events.push(evitem);

                    if (ev.getExternal() == evid) {
                        found = true;
                        event = evitem;
                    }
                }
                league.events = events;
            }
            if (!found) {
                continue;
            }
            let retval = { league: league, event: event };
            return retval;
        }
        return null;
    }

    onFileDropped(event) {
        const me = this;
        event.preventDefault();
        let file = Utils.getDataTransferFiles(event).shift();

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let action = me.state.event.ob.createUploadResults();
            action.setData(reader.result);

            action.OnCommitRequested.register(me.onUploadResultsCommitRequested.bind(me));
            action.OnCommitFinished.register(me.onUploadResultsCommitFinished.bind(me));
            action.OnCommitError.register(me.onUploadResultsCommitError.bind(me));
            action.Commit();

            me.uploadFileInput.current.value = null;
        };
        reader.onerror = function (error) {
            this.setState((oldState) => { return update(oldState, { error: { $set: error } }); })
        };
    }


    onUploadResultsCommitRequested(sender, params) {

        this.uploadFileInput.current.clear();

        this.beginAsyncOperation();
    }

    onUploadResultsCommitFinished(sender, params) {
        this.endAsyncOperation();
        this.showMessage('Plik został przesłany i wyniki załadowane');
    }
    onUploadResultsCommitError(sender, error) {
        this.endAsyncOperation();
        this.showError(error);
    }


    askWipeResults(event) {
        this.showDialog(() => {
            return (<PopupAlert text={'Czy na pewno chcesz sie wyczyscic wszystkie wyniki dla wydarzenia ' + event.shortname + '?'}
                accept='TAK'
                reject='NIE'
                onRejected={((e) => { this.clearDialog(); })}
                onAccepted={(e) => { this.clearDialog(); this.doWipeResults(event.ob) }}
            />);
        });
    }

    doWipeResults(event) {
        let action = event.createRemoveResults();

        action.OnCommitRequested.register(this.onRemoveResultCommitRequested);
        action.OnCommitFinished.register(this.onRemoveResultCommitFinished);
        action.OnCommitError.register(this.onRemoveResultCommitError);

        action.Commit();
    }

    onRemoveResultCommitRequested(sender, params) {
        this.beginAsyncOperation();
    }

    onRemoveResultCommitFinished(sender, params) {
        this.endAsyncOperation();
        this.showMessage('Wszystkie przejazdy zostaly usuniete');
    }
    onRemoveResultCommitError(sender, error) {
        this.endAsyncOperation();
        this.showError(error);
    }


    onSubmit() {
    }

    renderScreen(child) {
        let league = this.state.league;

        if (null == league) {
            return super.renderScreen(
                <div>Trwa wczytywanie danych</div>
            );
        }

        let event = this.state.event;

        if (null == league) {
            return super.renderScreen(
                <div>Nieznane wydarzenie</div>
            );
        }

        return super.renderScreen(
            <header className="w3-container w3-anchor">
                <h5>Edycja wyników dla wydarzenia <b>{event.shortname}</b> dla ligi <b>{league.shortname}</b></h5>
                <div className="w3-container w3-float-topright">
                    <h5><Link to={'/event/' + event.external}><FontAwesomeIcon icon={faAngleDoubleLeft} /> Wróc</Link></h5>
                </div>

                <div className="w3-row-padding w3-margin-bottom">
                    <div className="w3-quarter">
                        <div className="w3-container w3-orange w3-text-white w3-padding-16 w3-clickable"
                            onDragOver={(event) => { event.preventDefault(); }}
                            onDragLeave={(event) => { event.preventDefault(); }}
                            onDrop={this.onFileDropped.bind(this)}
                            onClick={() => this.uploadFileInput.current.click()}>
                            <div className="w3-right w3-xxxlarge">
                                <FontAwesomeIcon icon={faUpload} />
                            </div>
                            <div className="w3-clear"></div>
                            <h4>Wczytaj manual.dat</h4>
                            <input type='file' id='file' ref={this.uploadFileInput} style={{ display: 'none' }}
                                onChange={this.onFileDropped.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="w3-quarter">
                        <div className="w3-container w3-teal w3-text-white w3-padding-16 w3-clickable" onClick={(ev) => this.askWipeResults(this.state.event)}>
                            <div className="w3-right w3-xxxlarge">
                                <FontAwesomeIcon icon={faTrash} />
                            </div>
                            <div className="w3-clear"></div>
                            <h4>Wyczyść wyniki</h4>
                        </div>
                    </div>
                </div>
            </header>);

    }
}
export default withRouter(EventResultsScreen);
import React, { Component, useEffect, useRef, useState } from 'react';
import update from "react-addons-update";
import { Link } from 'react-router-dom'
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import './BaseScreenHeader.css'
import Database from 'Database';
import { faCog, faUser, faEnvelope, faSignInAlt, faSignOutAlt, faUsers, faCar, faBookDead, faBookReader, faFilter } from '@fortawesome/free-solid-svg-icons'
import Loader from '../components/common/Loader';
import { SimplePopup } from '../components/common/SimplePopup';
import { Portal } from '@mui/base/Portal';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GarageIcon from '@mui/icons-material/Garage';
import CreateIcon from '@mui/icons-material/Create';
import CelebrationIcon from '@mui/icons-material/Celebration';
import HomeIcon from '@mui/icons-material/Home';
import AlarmIcon from '@mui/icons-material/Alarm';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Container from '@mui/material/Container';

export const DrawBottomRight = ({children}) => {
    return <Portal container={document.getElementById('w3-bottom-right')}>{children}</Portal>
}

export const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  
function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 12 : 0,
    
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export const LayoutMaterial = (props) =>
{    
    const {session, children} = props;
    const navigate = useNavigate();

    const [isLoggedIn, setLoggedIn] = useState(false);
    const [showAdminMenu, setShowAdminMenu] = useState(false);
    const [canSeeApplications, setCanSeeApplications] = useState(false);
    const [canEditLiveScreen, setCanEditLiveScreen] = useState(false);

    const [menuDisplay, setMenuDisplay] = useState(false);
    const [showLiveScreenMenu, setShowLiveScreenMenu] = useState(false);

    const [logoutDialog, setLogoutDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loginUrl, setLoginUrl] = useState("/account/login");
    
    const [menuAdminExpanded, setMenuAdminExpanded] = useState(false);
    const [menuYoursExpanded, setMenuYoursExpanded] = useState(false);
    const [menuFilterUserExpanded, setMenuFilterUserExpanded] = useState(false);
    const [menuFilterGlobalExpanded, setMenuFilterGlobalExpanded] = useState(false);

    const [menuLiveScreenExpanded, setMenuLiveScreenExpanded] = useState(false);

    const [globalFilters, setGlobalFilters] = useState(null);
    const [userFilters, setUserFilters] = useState(null);

    useEffect(() => { 
        const personalizedCallback = (sender, value) => {
            setLoggedIn(value) ;
        }
        const permissionsCallback = (sender, permissions) => {
          setCanSeeApplications(permissions.hasPermissionGlobal('menu_applications_vehicle'));
          setCanEditLiveScreen(permissions.hasPermissionGlobal('can_edit_livescreen'));
        }
        session.onPersonalizeChanged.register(personalizedCallback);
        session.onPermissionsChanged.register(permissionsCallback);

        
        personalizedCallback(session, session.getPersonalized()) ;
        permissionsCallback(session, session.getPermissions());
        return () => {
            session.onPersonalizeChanged.unregister(personalizedCallback);
            session.onPermissionsChanged.unregister(permissionsCallback);
        }
    }, []);

    useEffect( () => {
        if ( canSeeApplications )
        {
          setShowAdminMenu(true);
          return;
        }

        setShowAdminMenu(false);
    }, [canSeeApplications])

    useEffect( () => {
      if ( canEditLiveScreen )
      {
        setShowLiveScreenMenu(true);
        return;
      }

      setShowLiveScreenMenu(false);
  }, [canEditLiveScreen])

    useEffect( () => {
      const filtersCallback = (sender, filters) => {
        if ( null == filters )
        {
          setGlobalFilters(null);
          setUserFilters(null);
          return;
        }
        if ( filters.length < 1 )
        {
          setGlobalFilters(null);
          setUserFilters(null);
          return;
        }

        let userItems = [];
        let globalItems = [];
   
        filters.forEach((x) => {
          let item = { url: x.getUrl(), caption: x.getShortname() };
          if ( x.getIsGlobal() )
          {
            globalItems.push(item);
          }
          else
          {
            userItems.push(item);
          }
        });

        if ( userItems.length > 0 )
        {
          setUserFilters(userItems);
        }
        else
        {
          setUserFilters(null);
        }

        if ( globalItems.length > 0 )
        {
          setGlobalFilters(globalItems);
        }
        else
        {
          setGlobalFilters(null);
        }
        
      }

      session.onFiltersChanged.register(filtersCallback);
      filtersCallback(session, session.getFilters());

      return () => {
        session.onFiltersChanged.unregister(filtersCallback);
      }
    }, [isLoggedIn] );

    useEffect(() => {
        var redirectUrl = "/account/login";
        var url = window.location.pathname;
        if ( url.length > 1 )
        {
          redirectUrl = redirectUrl + "?redirect=" + encodeURIComponent(url);
        }
        setLoginUrl(redirectUrl);
    }, [isLoggedIn])

    const doLogout = () => {
      Database.instance().Reset();
      setLogoutDialog(false);
    }

    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
          <React.Fragment key={"left"}>
            <CssBaseline/>
            <Drawer anchor={"left"} open={menuDisplay} onClose={() => { setMenuDisplay(false)}}>
            <Box sx={{ width: 250 }} role="presentation" >
                  <List>
                    <ListItem key={"main"} disablePadding>
                      <ListItemButton onClick={() => { setMenuDisplay(false); navigate("/")}}>
                        <ListItemIcon>
                          <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Głowny"} />
                      </ListItemButton>
                    </ListItem>
                    <ListItem key={"events"} disablePadding>
                      <ListItemButton onClick={() => { setMenuDisplay(false); navigate("/events")}}>
                        <ListItemIcon>
                          <CelebrationIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Wydarzenia"} />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem key={"yours"} disablePadding>
                      <ListItemButton onClick={(ev) => {ev.preventDefault(); setMenuYoursExpanded(!menuYoursExpanded)}}>
                        <ListItemIcon>
                          <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Twoje sprawy"} />
                        {menuYoursExpanded ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    </ListItem>
                    <Collapse in={menuYoursExpanded} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => { setMenuDisplay(false); navigate("/garage")}}>
                          <ListItemIcon>
                            <GarageIcon />
                          </ListItemIcon>
                          <ListItemText primary="Twój garaż" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => { setMenuDisplay(false); navigate("/applications/mine")}}>
                          <ListItemIcon>
                            <CreateIcon />
                          </ListItemIcon>
                          <ListItemText primary="Twoje zgloszenia" />
                        </ListItemButton>
                      </List>
                    </Collapse>
                    {showAdminMenu &&
                      <>
                      <ListItem key={"admin"} disablePadding>
                        <ListItemButton onClick={(ev) => {ev.preventDefault(); setMenuAdminExpanded(!menuAdminExpanded)}}>
                          <ListItemIcon>
                            <AlarmIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Administracja"} />
                          {menuAdminExpanded ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </ListItem>
                      <Collapse in={menuAdminExpanded} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItemButton sx={{ pl: 4 }} onClick={() => { setMenuDisplay(false); navigate("/applications/view")}}>
                            <ListItemIcon>
                              <CreateIcon />
                            </ListItemIcon>
                            <ListItemText primary="Zgloszenia zawodników" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                      </>
                    }
                    {showLiveScreenMenu &&
                      <>
                      <ListItem key={"livescreen"} disablePadding>
                        <ListItemButton onClick={(ev) => {ev.preventDefault(); setMenuLiveScreenExpanded(!menuLiveScreenExpanded)}}>
                          <ListItemIcon>
                            <AlarmIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Live Stream"} />
                          {menuLiveScreenExpanded ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </ListItem>
                      <Collapse in={menuLiveScreenExpanded} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItemButton sx={{ pl: 4 }} onClick={() => { setMenuDisplay(false); navigate("/livescreen/pilot")}}>
                            <ListItemIcon>
                              <CreateIcon />
                            </ListItemIcon>
                            <ListItemText primary="Pilot" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                      </>
                    }
                  </List>
                  <Divider />
                  <List>
                    {globalFilters && <ListItem key={"current-event"} disablePadding>
                        <ListItemButton onClick={(ev) => {ev.preventDefault(); setMenuFilterGlobalExpanded(!menuFilterGlobalExpanded)}}>
                          <ListItemIcon>
                            <InboxIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Aktualne wyniki"} />
                          {menuFilterGlobalExpanded ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </ListItem>
                    }
                    {globalFilters && 
                      <Collapse in={menuFilterGlobalExpanded} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        {globalFilters.map((x) => {
                          return (<ListItemButton sx={{ pl: 4 }} onClick={() => { setMenuDisplay(false); navigate(x.url)}}>
                            <ListItemIcon>
                              <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary={x.caption} />
                          </ListItemButton>)
                        })}
                        </List>
                      </Collapse>}
                    {userFilters && <ListItem key={"your-score"} disablePadding>
                        <ListItemButton onClick={(ev) => {ev.preventDefault(); setMenuFilterUserExpanded(!menuFilterUserExpanded)}}>
                          <ListItemIcon>
                            <EmojiEventsIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Twoje wyniki"} />
                          {menuFilterUserExpanded ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </ListItem>
                    }
                    {userFilters && 
                      <Collapse in={menuFilterUserExpanded} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        {userFilters.map((x) => {
                          return (<ListItemButton sx={{ pl: 4 }} onClick={() => { setMenuDisplay(false); navigate(x.url)}}>
                            <ListItemIcon>
                              <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary={x.caption} />
                          </ListItemButton>)
                        })}
                        </List>
                      </Collapse>}
                  </List>
                </Box>
            </Drawer>
          </React.Fragment>

          <SimplePopup open={logoutDialog} fullScreen={false} title='Czy na pewno chcesz sie wylogować?'  accept='TAK' reject='NIE' onAccepted={doLogout} onRejected={() => { setLogoutDialog(false)}} />
          <ElevationScroll {...props}>
            <AppBar>
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  sx={{ mr: 2 }}
                  onClick={() => setMenuDisplay(true)}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ display: { xs: 'none', sm: 'block' } }}
                >
                  Portal SCS Klub
                </Typography>
                {/* <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </Search> */}
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                  {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="error">
                      <MailIcon />
                    </Badge>
                  </IconButton>
                  <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                  >
                    <Badge badgeContent={17} color="error">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton> */}
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={"pizda"}
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {!isLoggedIn && <>
                      <MenuItem onClick={() => { setAnchorEl(null); navigate(loginUrl);}}>Zaloguj</MenuItem>
                      <Divider />
                      <MenuItem onClick={() => { setAnchorEl(null); navigate('/account/register');}}>Załóż konto</MenuItem>
                      </>
                    }
                    {isLoggedIn && <>
                      <MenuItem onClick={() => { setAnchorEl(null); navigate('/account/profile');}}>Ustawienia</MenuItem>
                      <Divider />
                      <MenuItem onClick={(e) => { setLogoutDialog(true); setAnchorEl(null);} }>Wyloguj</MenuItem>
                      </>}
                  </Menu>

                </Box>
              </Toolbar>
            </AppBar>
          </ElevationScroll>
          <Toolbar />
          <Container maxWidth={false} sx={{position: 'relative'}}>
            <Box sx={{ my: 2 }}>
              {children}
            </Box>
          </Container>
        </Box> );
};

import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom'
import update from "react-addons-update";

import autoBind from 'react-autobind';
import './ClassEditScreen.css'
import BaseScreenHeader from '../../BaseScreenHeader';
import InputField from '../../../components/common/InputField';
import { faSync, faSmile, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import PhotoBox from '../../../components/common/PhotoBox';
import Button from '../../../components/common/Button';
import Utils from '../../../utils/Utils';
import HelpIcon from '../../../components/common/HelpIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withRouter from '../../../utils/withRouter';

class ClassEditScreen extends BaseScreenHeader {
    static defaultProps = {
        session: null
    }
    constructor(props) {
        super(props)
        autoBind(this);
    }

    validatePermission()
    {
        return this.props.session.getPermissions().hasPermissionGlobal('can_edit_league') || this.props.session.getPermissions().hasPermissionContext('can_edit_league', this.props.params.leagueId);
    }

    onInitParameters(bundle)
    {
        var data = this.createClass(this.props.params.classId);
        if ( data )
        {
            this.state.league = data.league;
            this.state.clazz = data.clazz;

            let clazz = data.clazz;

            this.initFormData({ shortname: clazz.shortname, description: clazz.description, priority: clazz.priority, player_start: clazz.player_start, player_mask: clazz.player_mask});
        }
        return true;
    }
  
    bindDelegates()
    {
        this.session.onLeaguesChanged.register(this.onLeaguesChanged);
        super.bindDelegates();
    }
    unbindDelegates()
    {
        this.session.onLeaguesChanged.unregister(this.onLeaguesChanged);
        super.unbindDelegates();
    }
    onLeaguesChanged(sender, data)
    {
        var data = this.createClass(this.props.params.classId);
        let league = null;
        let clazz = null;
        if ( data )
        {
            league = data.league;
            clazz = data.clazz;
        }

        this.initFormData({ shortname: clazz.shortname, description: clazz.description, priority: clazz.priority, player_start: clazz.player_start, player_mask: clazz.player_mask});
  
        this.setState((oldState) => { return update(oldState, { league: { $set: league }, clazz: { $set: clazz }})});
    }

    createClass(clazz)
    {
        let data = this.session.getLeagues();
        if ( null == data )
        {
            return null;
        }
        for ( const l of data )
        {
            let found = false;

            let league = {};
            league.external = l.getExternal();
            league.shortname = l.getShortname();

            let clas = null;
  
            if ( null != l.getClasses() )
            {
                let classes = [];
                for ( const cls of l.getClasses() )
                {
                    let data = {}
                    data.external = cls.getExternal();
                    data.shortname = cls.getShortname();
                    data.description = cls.getDescription();
                    data.player_start = cls.getPlayerStart();
                    data.player_mask = cls.getPlayerMask();
                    data.priority = cls.getPriority();
                    data.ob = cls;
  
                    classes.push(data);

                    if ( cls.getExternal() == clazz )
                    {
                        found = true;
                        clas = data;
                    }
                }
                league.classes = classes;
            }
            if ( !found )
            {
                continue;
            }
            let retval = { league: league, clazz: clas };
            return retval;
        }   
        return null;
    }

    onSubmit()
    {
        let errors = [];
  
        let shortname = this.getFormData('shortname');
        if ( !shortname )
        {
            errors.push('Nazwa klasy nie moze byc pusta');
        }
        let description = this.getFormData('description');
        if ( !description )
        {
            errors.push('Opis nie może byc pusty');
        }      
        let priority = this.getFormData('priority');
        if ( !priority )
        {
            errors.push('Priorytet nie moze byc pusty');
        }
        let player_start = this.getFormData('player_start');
        let player_mask = this.getFormData('player_mask');
        
        if ( errors.length > 0 )
        {
            this.showMessage(errors);
            return;
        }
  
        var action = this.state.clazz.ob.createUpdateClass();

        action.setShortname(shortname);
        action.setDescription(description);
        action.setPriority(priority);
        action.setPlayerStart(player_start);
        action.setPlayerMask(player_mask);
  
        action.OnCommitRequested.register(this.onUpdateClassCommitRequested);
        action.OnCommitFinished.register(this.onUpdateClassCommitFinished);
        action.OnCommitError.register(this.onUpdateClassCommitError);
  
        action.Commit();
  
    }

    onUpdateClassCommitRequested(sender, params) {
        this.beginAsyncOperation();
    }

    onUpdateClassCommitFinished(sender, params) {
        this.endAsyncOperation();
        this.showAndRedirect('Klasa zostala zaktualizowana', '/manage/league/' + this.state.league.external);
    }

    onUpdateClassCommitError(sender, err) {
        this.endAsyncOperation();

        this.showError(err);
    }

    createPriorityHint()
    {
        let classes = this.state.league.classes.sort((l, r) => { return parseInt(l.priority) > parseInt(r.priority)});

        let data = classes.map((x) => {
            return <tr key={x.external}><td>{x.priority}</td><td>{x.shortname}</td></tr>
        });

        return (
            <table className="w3-popup"> 
                <thead>
                    <tr><th>Priorytet</th><th>Klasa</th></tr>
                </thead>
                <tbody>
                    {data}
                </tbody>
            </table>
        );
    }

    validateClassName(value)
    {
        let org_name = this.state.clazz.shortname;
        if ( !value )
        {
            return;
        }

        if (this.state.league.classes.some((x) => { return x.shortname === value && org_name !== value ;}) )
        {
            return 'Klasa o podanej nazwie juz istnieje';
        }

        return null;
    }

    renderScreen(child) {
        let league = this.state.league;

        if ( null == league )
        {
            return super.renderScreen(
                <div>Trwa wczytywanie danych</div>
            );
        }

        let clazz = this.state.clazz;
        
        if ( null == league )
        {
            return super.renderScreen(
                <div>Nieznana klasa</div>
            );
        }

        return super.renderScreen(
            <header className="w3-container">
                <h5>Edycja klasy <b>{clazz.shortname}</b> dla ligi <b>{league.shortname}</b></h5>
                <div className="w3-container">
                    <h6>Nazwa klasy</h6>
                    <InputField id="class-shortname" text={clazz.shortname} onCommit={(value) => this.storeFormData('shortname', value) } validate={this.validateClassName} />
                    <h6>Opis klasy</h6>
                    <InputField id="class-description" text={clazz.description} onCommit={(value) => this.storeFormData('description', value) }/>
                    <h6 className="w3-inline">
                        Priorytet
                        <HelpIcon className="w3-half" component={this.createPriorityHint()} left={false} hover={true} above={false}>
                            <FontAwesomeIcon icon={faQuestionCircle} />
                        </HelpIcon>
                    </h6>
                    <InputField id="priority" text={clazz.priority} onCommit={(value) => this.storeFormData('priority', value) } characters="1234567890"/>
                    <h6 className="w3-inline">Numer startowy
                        <HelpIcon className="w3-half" component={"Poczatkowy numer startowy pierwszego zawodnika"} left={false} hover={true} above={false}>
                            <FontAwesomeIcon icon={faQuestionCircle} />
                        </HelpIcon>
                    </h6>
                    <InputField id="player-start" text={clazz.player_start} onCommit={(value) => this.storeFormData('player_start', value) } characters="1234567890"/>
                    <h6 className="w3-inline">Maska numeru
                        <HelpIcon className="w3-half" component={"Maska do walidacji numeru startowego (regex): przyklad dla 4XX: ^4[0-9]{2}$"} left={false} hover={true} above={false}>
                            <FontAwesomeIcon icon={faQuestionCircle} />
                        </HelpIcon>
                    </h6>
                    <InputField id="player-mask" text={clazz.player_mask} onCommit={(value) => this.storeFormData('player_mask', value) } validate={Utils.validate_regex} />
                </div>

                <div className="w3-quarter w3-right w3-margin">
                    <Button onClicked={() => { this.onSubmit()}} disabled={this.state.asyncOperation || null == this.getFormData('shortname') || null == this.getFormData('priority')} text="Zapisz zmiany" iconLeft={this.state.asyncOperation ? faSync : null} />
                </div>                
            </header>);

    }
}
export default withRouter(ClassEditScreen);
import JSONObject from '../../sdk/JSONObject';
import RequestData from './RequestData';
import ResponseData from './ResponseData';
import ApiUtils from '../api/ApiUtils';
import JobException from './JobException';
import HttpConnection from './HttpConnection';
import Log from '../logger/Log';
import { sprintf } from 'sprintf-js';


export default class JobApi
{

	constructor(/*ISession*/ session, /*IServiceRequest*/ request, /*IServiceResult*/ result)
	{
		this.session = session;
		this.request = request;
		this.result = result;
	}
    
	serialize()
	{
		var data = new JSONObject();
		// synchronized(session)
		{
			this.session.serializeEnvelope(data);
			/*
			*/
			{
				data.put("request", this.request.getRequest());
			}
		}
		return data;
	}
	
	deserialize(/*JSONObject*/ outdata)
	{
		this.session.deserializeEnvelope(outdata);

		if (outdata.has("reply"))
		{
			var reply = outdata.get("reply");
			return reply;
		}
		else if (outdata.has("error"))
		{
			var error = outdata.get("error");
			throw new JobException(error);
		}
		return new JSONObject();
	}

    createInput()
    {
        var input = new RequestData();
		
		try
		{
			var /*JSONObject*/ data = this.serialize();
			if ( data.has("device") )
			{
				var /*JSONObject*/ _device = data.get("device");
			}

			var /*String*/ uuid = ApiUtils.getUuid();
			data.put("request_id", uuid);
			input.requestId = uuid;

			input.data = JSON.stringify(data.serialize());
			input.method = sprintf("%s", this.request.getService());
			input.sessionId = this.session.getSessionId();
			input.deviceId = this.session.getInstallationId();
		}
		catch ( /*Exception*/ ex )
		{
			try
			{
				var /*JSONObject*/ error = new JSONObject();
				error.put("err_code", "PARSE-ERROR");
				// error.put("error_text", Engine.getContext().getString(R.string.sdk_errors__default_error));
				this.result.ProcessError(error);
			}
			catch ( /*JSONException*/ ee )
			{
			}
			return null;
        } 
        
        return input;
	}
	
	processOutput(/*Object*/ output)
	{
		// rpc
		try
		{
			switch ( output.status )
			{
				case 'ERROR':
				{
					var /*JSONObject*/ error = new JSONObject();
					error.put("err_code", "HTTP-ERROR");
					// error.put("error_text", Engine.getContext().getString(R.string.sdk_errors__http_error));
					this.result.ProcessError(error);
					return false;
				}
				case 'SUCCESS':
					break;
				default:
					return false;
			}
		}
		catch ( /*JSONException*/ ex )
		{
			Log.e("SDK-JOB", "Post request error!");
			ex.printStackTrace();
			try
			{
				var /*JSONObject*/ error = new JSONObject();
				error.put("err_code", "PARSE-ERROR");
				// error.put("error_text", Engine.getContext().getString(R.string.sdk_errors__default_error));
				this.result.ProcessError(error);
			}
			catch ( /*JSONException*/ ee )
			{
			}
			return false;
		}

		// deserialize
		try
		{
			var /*JSONObject*/ response = new JSONObject(JSON.parse(output.data));
			var /*JSONObject*/ reply = this.deserialize(response);
			this.result.ProcessReply(reply);
			return true;
		}
		catch ( /*JobException*/ ex )
		{
			Log.i('JOBAPI', ex);
			if ( ex.error )
			{
				this.result.ProcessError(ex.error);
			}
			else
			{
				var /*JSONObject*/ error = new JSONObject();
				error.put("err_code", "PARSE-ERROR");
				this.result.ProcessError(error);
			}
			return true;
		}
	}

	process(/*IHttpConnection*/ connection)
	{
		var input = new RequestData();
		var output = new ResponseData();
		
		try
		{
			var /*JSONObject*/ data = this.serialize();
			if ( data.has("device") )
			{
				var /*JSONObject*/ _device = data.get("device");
				var /*String*/ uuid = ApiUtils.getUuid();
				_device.put("request_id", uuid);
				input.requestId = uuid;
			}

			input.data = data.toString();
			input.method = sprintf("%s", this.request.getService());
			input.sessionId = this.session.getSessionId();
			input.deviceId = this.session.getInstallationId();
		}
		catch ( /*Exception*/ ex )
		{
			try
			{
				var /*JSONObject*/ error = new JSONObject();
				error.put("err_code", "PARSE-ERROR");
				// error.put("error_text", Engine.getContext().getString(R.string.sdk_errors__default_error));
				this.result.ProcessError(error);
			}
			catch ( /*JSONException*/ ee )
			{
			}
			return false;
		}

		
	}

    abort()
	{
		try
		{
			var /*JSONObject*/ error = new JSONObject();
			error.put("err_code", "INTERRUPTED");
			// error.put("error_text", Engine.getContext().getString(R.string.sdk_errors__default_error));
			this.result.ProcessError(error);
		}
		catch ( /*JSONException*/ e )
		{
		}
	}
}
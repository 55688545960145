import Session from '../Session'

import IServiceResult from '../connect/IServiceResult';
import Engine, { Error } from '../Engine';
import { Vehicle } from '../data/vehicle/Vehicle';
import { ApplicationVehicle } from '../data/applications/ApplicationVehicle';
import ApiUtils from '../api/ApiUtils';
import VehicleApplicationCancel from '../api/vehicle/VehicleApplicationCancel';
import ApplicationsMineCommit from '../api/applications/ApplicationsMineCommit';
import ApplicationsListCommit from '../api/applications/ApplicationsListCommit';
import VehicleApplicationAccept from '../api/vehicle/VehicleApplicationAccept';
import VehicleApplicationReject from '../api/vehicle/VehicleApplicationReject';

export default class ApplicationsMonitor {
    constructor(/*Session*/ session) {
        this.mSession = session;
        session.onApplicationsMineCreated.register(this.onApplicationsMineCreated.bind(this));
        session.onApplicationsListCreated.register(this.onApplicationsListCreated.bind(this));
    }
    onApplicationsMineCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
        action.OnCommitRequested.register(this.onApplicationsMineCommitRequested.bind(this));
    }

    onApplicationsMineCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new ApplicationsMineCommit();
        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                if ( reply.get('vehicles') )
                {
                    let vehicles = [];
                    let _vehicles = reply.get('vehicles');

                    let size = _vehicles.length();
                    for (var i = 0; i < size; ++i) {
                        let ob = _vehicles.get(i);

                        let application = new ApplicationVehicle();

                        application.external = ob.opt('external', null);
                        application.league_id = ob.opt('league', null);
                        application.class_id = ob.opt('class', null);

                        application.status = ob.opt('status', null);

                        application.timestamp_creation = ApiUtils.deserializeDateTime(ob.opt('timestamp_creation', null));
                        application.timestamp_review = ApiUtils.deserializeDateTime(ob.opt('timestamp_review', null));

                        application.assigned_number = ob.opt('assigned_number', null);
                        application.car_shortname = ob.opt('car_shortname', null);
                        application.car_power = ob.opt('car_power', null);
                        application.driver_name = ob.opt('driver_name', null);

                        application.comment = ob.opt('comment', null);

                        application.OnCancelRequested.register(monitor.onApplicationVehicleCancelRequested.bind(monitor));

                        vehicles.push(application);
                    }

                    action.vehicles = vehicles;
                }
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("APPLICATIONS", result, request);
    }

    
    onApplicationVehicleCancelRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new VehicleApplicationCancel();
        request.setExternal(action.external);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.status = reply.get('status', null);
                action.timestamp_review = ApiUtils.deserializeDateTime(reply.opt('timestamp_review', null));
                action.OnCancelFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCancelError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("APPLICATIONS", result, request);
    }

    onApplicationsListCreated(/*Object*/ sender, /*{ApplicationsList}*/ action) {
        action.OnCommitRequested.register(this.onApplicationsListCommitRequested.bind(this));
    }

    onApplicationsListCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new ApplicationsListCommit();
        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                if ( reply.get('vehicles') )
                {
                    let vehicles = [];
                    let _vehicles = reply.get('vehicles');

                    let size = _vehicles.length();
                    for (var i = 0; i < size; ++i) {
                        let ob = _vehicles.get(i);

                        let application = new ApplicationVehicle();

                        application.external = ob.opt('external', null);

                        application.user_name = ob.opt('user_name', null);
                        application.user_nick = ob.opt('user_nick', null);
                        application.user_external = ob.opt('user_external', null);

                        application.league_id = ob.opt('league', null);
                        application.class_id = ob.opt('class', null);

                        application.status = ob.opt('status', null);

                        application.timestamp_creation = ApiUtils.deserializeDateTime(ob.opt('timestamp_creation', null));
                        application.timestamp_review = ApiUtils.deserializeDateTime(ob.opt('timestamp_review', null));

                        application.assigned_number = ob.opt('assigned_number', null);
                        application.car_shortname = ob.opt('car_shortname', null);
                        application.car_power = ob.opt('car_power', null);
                        application.driver_name = ob.opt('driver_name', null);

                        application.comment = ob.opt('comment', null);

                        application.OnAcceptRequested.register(monitor.onApplicationVehicleAcceptRequested.bind(monitor));
                        application.OnRejectRequested.register(monitor.onApplicationVehicleRejectRequested.bind(monitor));

                        vehicles.push(application);
                    }

                    action.vehicles = vehicles;
                }

                if ( reply.get('leagues') )
                {
                    let _leagues = reply.get('leagues');
                    let size = _leagues.length();
                    let data = [];
                    for (var i = 0; i < size; ++i) {
                        let ob = _leagues.get(i);
                        data.push(ob);
                    }
                    action.leagues = data;
                }
                action.OnCommitFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnCommitError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("APPLICATIONS", result, request);
    }

    
    onApplicationVehicleAcceptRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new VehicleApplicationAccept();
        request.setExternal(action.external);
        request.setAssignedNumber(action.assigned_number);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.status = reply.get('status', null);
                action.assigned_number = reply.get('assigned_number', action.assigned_number);
                action.timestamp_review = ApiUtils.deserializeDateTime(reply.opt('timestamp_review', action.timestamp_review));
                action.OnAcceptFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnAcceptError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("APPLICATIONS", result, request);
    }

    
    onApplicationVehicleRejectRequested(/*VehicleList*/ sender, /*Void*/ params) {
        var action = sender;
        var request = new VehicleApplicationReject();
        request.setExternal(action.external);

        var monitor = this;

        var result = new IServiceResult(
            function (/*Object*/ reply) {
                action.status = reply.get('status', null);
                action.timestamp_review = ApiUtils.deserializeDateTime(reply.opt('timestamp_review', action.timestamp_review));
                action.OnRejectFinished.callback(sender, null);
            },
            function (/*Object*/ error) {
                action.OnRejectError.callback(sender, Engine.createError(error));
            }
        );

        this.mSession.SessionRequest("APPLICATIONS", result, request);
    }

}
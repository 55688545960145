import ISessionChangePassword from '../../sdk/session/ISessionChangePassword';

export default class SessionChangePassword extends ISessionChangePassword
{
    constructor()
    {
        super();
        this.old_password = null;
        this.new_password = null;
    }

    setOldPassword(value)
    {
        this.old_password = value;
    }

    setNewPassword(value)
    {
        this.new_password = value;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }
}
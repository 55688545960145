import IUpdateEvent from "../../../../sdk/data/manage/event/IUpdateEvent";

export class UpdateEvent extends IUpdateEvent
{
    constructor()
    {
        super();
        this.external = null;
        
        this.shortname = null;
        this.description = null;

        this.location = null;
        this.gps = null;

        this.timestamp_start = null;
        this.timestamp_end = null;

        this.registration_fee_onsite = null;
        this.registration_fee_transfer = null;
        this.registration_fee_online = null;
        this.registration_fee_crew = null;

        this.timestamp_attendance_start = null;
        this.timestamp_attendance_transfer_end = null;
        this.timestamp_attendance_end = null;

        this.template_attendance_email = null;
        this.race_formula = null;
    }

    getExternal()
    {
        return this.external;
    }
    
    setShortname(value)
    {
        this.shortname = value;
    }
    getShortname()
    {
        return this.shortname;
    }

    setDescription(value)
    {
        this.description = value;
    }

    getDescription()
    {
        return this.description;
    }

    getLocation()
    {
        return this.location;
    }
    setLocation(value)
    {
        this.location = value;
    }

    getGps()
    {
        return this.gps;
    }
    setGps(value)
    {
        this.gps = value;
    }

    getTimestampStart()
    {
        return this.timestamp_start;
    }
    setTimestampStart(value)
    {
        this.timestamp_start = value;
    }

    getTimestampEnd()
    {
        return this.timestamp_end;
    }
    setTimestampEnd(value)
    {
        this.timestamp_end = value;
    }


    getRegistrationFeeOnsite()
    {
        return this.registration_fee_onsite;
    }
    setRegistrationFeeOnsite(value)
    {
        this.registration_fee_onsite = value;
    }

    getRegistrationFeeTransfer()
    {
        return this.registration_fee_transfer;
    }
    setRegistrationFeeTransfer(value)
    {
        this.registration_fee_transfer = value;
    }

    getRegistrationFeeOnline()
    {
        return this.registration_fee_online;
    }
    setRegistrationFeeOnline(value)
    {
        this.registration_fee_online = value;
    }

    getRegistrationFeeCrew()
    {
        return this.registration_fee_crew;
    }
    setRegistrationFeeCrew(value)
    {
        this.registration_fee_crew = value;
    }

    getTimestampAttendanceStart()
    {
        return this.timestamp_attendance_start;
    }
    setTimestampAttendanceStart(value)
    {
        this.timestamp_attendance_start = value;
    }

    getTimestampAttendanceTransferEnd()
    {
        return this.timestamp_attendance_transfer_end;
    }
    setTimestampAttendanceTransferEnd(value)
    {
        this.timestamp_attendance_transfer_end = value;
    }

    getTimestampAttendanceEnd()
    {
        return this.timestamp_attendance_end;
    }
    setTimestampAttendanceEnd(value)
    {
        this.timestamp_attendance_end = value;
    }

    getTemplateAttendanceEmail()
    {
        return this.template_attendance_email;
    }
    setTemplateAttendanceEmail(value)
    {
        this.template_attendance_email = value;
    }

    getRaceFormula()
    {
        return this.race_formula;
    }
    setRaceFormula(value)
    {
        this.race_formula = value;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

}
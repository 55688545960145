import IAttendanceDetails from "../../../sdk/data/attendance/IAttendanceDetails";
import { AttendanceRegister } from "./AttendanceRegister";

export class AttendanceDetails extends IAttendanceDetails
{
    constructor()
    {
        super();
        this.token = null; 
        this.registered = null;
        this.vehicles = null;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }

    getRegistered()
    {
        return this.registered;
    }

    getVehicles()
    {
        return this.vehicles;
    }
}
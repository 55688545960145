import Event from '../property/Event';

export default class ILeaguePlayer
{
    getExternal()
    {
        throw new TypeError("Method not implemented");
    }
    getPlayer()
    {
        throw new TypeError("Method not implemented");
    }
    getClass()
    {
        throw new TypeError("Method not implemented");
    }
    getVehicle()
    {
        throw new TypeError("Method not implemented");
    }
    getPlayerName()
    {
        throw new TypeError("Method not implemented");
    }
    getPlayerCar()
    {
        throw new TypeError("Method not implemented");
    }

    getCarPower()
    {
        throw new TypeError("Method not implemented");
    }

    getCarMass()
    {
        throw new TypeError("Method not implemented");
    }

    getPictureCar()
    {
        throw new TypeError("Method not implemented");
    }
    
    getPictureDriver()
    {
        throw new TypeError("Method not implemented");
    }

    getOwnerExternal()
    {
        throw new TypeError("Method not implemented");
    }

    getOwnerName()
    {
        throw new TypeError("Method not implemented");
    }

    getTeamName()
    {
        throw new TypeError("Method not implemented");
    }

    createUpdatePlayer()
    {
        throw new TypeError("Method not implemented");
    }
    createRemovePlayer()
    {
        throw new TypeError("Method not implemented");
    }
}
import ISessionRecover from '../../sdk/session/ISessionRecover';

export default class SessionRecover extends ISessionRecover
{
    constructor()
    {
        super();
        this.email = null;
        this.security = null;
    }

    setEmail(value)
    {
        this.email = value;
    }

    getEmail()
    {
        return this.email;
    }

    setSecurity(security)
    {
        this.security = security;
    }

    getSecurity()
    {
        return this.security;
    }

    Commit()
    {
        this.OnCommitRequested.callback(this, null);
    }
}
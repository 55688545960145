import IServiceRequest from "../../../connect/IServiceRequest";

export default class EventRemoveCommit extends IServiceRequest
{
    constructor()
    {
        super('www/event/remove');
    }
    
    setExternal(value)
    {
        this.appendRequest("external", value);
    }
    
}

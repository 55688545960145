import React, { Component } from 'react';
import autoBind from 'react-autobind';
import './LoaderBar.css';

class LoaderBar extends Component {
  constructor(props)
  {
    super(props)
    autoBind(this);
  }

  render() {
    return (
        <div class="loader-bar">
            <div class="loader-bar-inner"></div>
        </div>
    );
  }
}

export default LoaderBar;
import IRawResult from "../../../../sdk/data/manage/result/IRawResult";
import UpdateRawResult from "./UpdateRawResult";
import Event from "../../../../sdk/property/Event";

export default class RawResult extends IRawResult
{
    constructor()
    {
        super();
        this.token = null;

        this.player = null;
        this.lane = null;

        this.measured_reaction_time = null;
        this.displayed_reaction_time = null;
        
        this.measured_time_60ft = null;
        this.measured_time_330ft = null;
        this.measured_time_660ft = null;
        this.measured_time_1000ft = null;
        this.measured_time_1320ft = null;
        
        this.measured_speed_60ft = null;
        this.measured_speed_330ft = null;
        this.measured_speed_660ft = null;
        this.measured_speed_1000ft = null;
        this.measured_speed_1320ft = null;
        
        this.displayed_time_60ft = null;
        this.displayed_time_330ft = null;
        this.displayed_time_660ft = null;
        this.displayed_time_1000ft = null;
        this.displayed_time_1320ft = null;
        
        this.displayed_speed_60ft = null;
        this.displayed_speed_330ft = null;
        this.displayed_speed_660ft = null;
        this.displayed_speed_1000ft = null;
        this.displayed_speed_1320ft = null;
        
        this.is_invalid = null;
        this.is_public = null;

        this.timestamp_race = null;

        this.onUpdateRawResultCreated = new Event();
    }

    getToken() 
    {
        return this.token;
    }

    setToken(token) 
    {
        this.token = token;
    }

    Query()
    {
        this.OnQueryRequested.callback(this, null);
        return true;
    }

    
    getPlayer() 
    {
        return this.player;
    }

    getLane() {
        return this.lane;
    }

    getMeasuredReactionTime() {
        return this.measured_reaction_time;
    }
    getDisplayedReactionTime() {
        return this.displayed_reaction_time;
    }

    getMeasuredTime60ft() {
        return this.measured_time_60ft;
    }
    getMeasuredTime330ft() {
        return this.measured_time_330ft;
    }
    getMeasuredTime660ft() {
        return this.measured_time_660ft;
    }
    getMeasuredTime1000ft() {
        return this.measured_time_1000ft;
    }
    getMeasuredTime1320ft() {
        return this.measured_time_1320ft;
    }

    getMeasuredSpeed60ft() {
        return this.measured_speed_60ft;
    }
    getMeasuredSpeed330ft() {
        return this.measured_speed_330ft;
    }
    getMeasuredSpeed660ft() {
        return this.measured_speed_660ft;
    }
    getMeasuredSpeed1000ft() {
        return this.measured_speed_1000ft;
    }
    getMeasuredSpeed1320ft() {
        return this.measured_speed_1320ft;
    }

    getDisplayedTime60ft() {
        return this.displayed_time_60ft;
    }
    getDisplayedTime330ft() {
        return this.displayed_time_330ft;
    }
    getDisplayedTime660ft() {
        return this.displayed_time_660ft;
    }
    getDisplayedTime1000ft() {
        return this.displayed_time_1000ft;
    }
    getDisplayedTime1320ft() {
        return this.displayed_time_1320ft;
    }

    getDisplayedSpeed60ft() {
        return this.displayed_speed_60ft;
    }
    getDisplayedSpeed330ft() {
        return this.displayed_speed_330ft;
    }
    getDisplayedSpeed660ft() {
        return this.displayed_speed_660ft;
    }
    getDisplayedSpeed1000ft() {
        return this.displayed_speed_1000ft;
    }
    getDisplayedSpeed1320ft() {
        return this.displayed_speed_1320ft;
    }

    getIsInvalid() {
        return this.is_invalid;
    }
    getIsPublic() {
        return this.is_public;
    }

    getTimestampRace() {
        return this.timestamp_race;
    }

    createUpdateRawResult() {
        let retval = new UpdateRawResult();

        retval.token = this.token;
        
        retval.player = this.player;
        
        retval.displayed_reaction_time = this.displayed_reaction_time;

        retval.displayed_time_60ft = this.displayed_time_60ft;
        retval.displayed_time_330ft = this.displayed_time_330ft;
        retval.displayed_time_660ft = this.displayed_time_660ft;
        retval.displayed_time_1000ft = this.displayed_time_1000ft;
        retval.displayed_time_1320ft = this.displayed_time_1320ft;
        
        retval.displayed_speed_60ft = this.displayed_speed_60ft;
        retval.displayed_speed_330ft = this.displayed_speed_330ft;
        retval.displayed_speed_660ft = this.displayed_speed_660ft;
        retval.displayed_speed_1000ft = this.displayed_speed_1000ft;
        retval.displayed_speed_1320ft = this.displayed_speed_1320ft;
        
        retval.is_invalid = this.is_invalid;
        retval.is_public = this.is_public;

        this.onUpdateRawResultCreated.callback(this, retval);
        return retval;
    }
}
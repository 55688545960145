import Session from "../Session";

import IServiceResult from "../connect/IServiceResult";
import Engine, { Error } from "../Engine";
import ApiUtils from "../api/ApiUtils";
import AttendanceQuery from "../api/attendance/AttendanceQuery";
import AttendanceRegisterCommit from "../api/attendance/AttendanceRegisterCommit";
import AttendanceRemove from "../api/attendance/AttendanceRemove";

import AttendanceListCommit from "../api/attendance/AttendanceListCommit";
import AttendanceDetailsCommit from "../api/attendance/AttendanceDetailsCommit";

import { AttendingUser } from "../data/attendance/AttendingUser";
import AttendanceDownloadCommit from "../api/attendance/AttendanceDownloadCommit";
import { Vehicle } from "../data/vehicle/Vehicle";
import AttendanceAcceptCommit from "../api/attendance/AttendanceAcceptCommit";
import AttendanceRejectCommit from "../api/attendance/AttendanceRejectCommit";
import AttendanceEmailCommit from "../api/attendance/AttendanceEmailCommit";

export default class AttendanceMonitor {
  constructor(/*Session*/ session) {
    this.mSession = session;
    session.onAttendanceCreated.register(this.onAttendanceCreated.bind(this));

    session.onAttendanceListCreated.register(this.onAttendanceListCreated.bind(this));
    session.onAttendanceDetailsCreated.register(this.onAttendanceDetailsCreated.bind(this));

    session.onAttendanceRegisterCreated.register(this.onAttendanceRegisterCreated.bind(this));
    session.onAttendanceAcceptCreated.register(this.onAttendanceAcceptCreated.bind(this));
    session.onAttendanceRejectCreated.register(this.onAttendanceRejectCreated.bind(this));
    session.onAttendanceEmailCreated.register(this.onAttendanceEmailCreated.bind(this));
  }

  onAttendanceCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
    action.OnQueryRequested.register(this.onAttendanceQueryRequested.bind(this));
  }

  onAttendanceQueryRequested(/*VehicleList*/ sender, /*Void*/ params) {
    var action = sender;
    var request = new AttendanceQuery();

    request.setToken(action.token);
    request.setType(action.type);

    var monitor = this;

    var result = new IServiceResult(
      function (/*Object*/ reply) {
        action.external = reply.opt("external", null);
        action.registered = reply.opt("registered", null);
        action.count = reply.opt("count", null);
        action.is_registration_open = reply.opt("is_registration_open", null);

        action.OnQueryFinished.callback(sender, null);
      },
      function (/*Object*/ error) {
        action.OnQueryError.callback(sender, Engine.createError(error));
      }
    );

    this.mSession.SessionRequest("ATTENDANCE", result, request);
  }

  onAttendanceListCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
    action.OnCommitRequested.register(this.onAttendanceListCommitRequested.bind(this));
    action.OnDownloadRequested.register(this.onAttendanceListDownloadRequested.bind(this));
  }

  onAttendanceListCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
    var action = sender;
    var request = new AttendanceListCommit();

    request.setToken(action.token);

    var monitor = this;

    var result = new IServiceResult(
      function (/*Object*/ reply) {
        if (reply.get("registered")) {
          let registered = [];
          let _registered = reply.get("registered");

          let size = _registered.length();
          for (var i = 0; i < size; ++i) {
            let ob = _registered.get(i);

            let user = new AttendingUser();

            user.external = ob.get("external", null);
            user.description = ob.get("description", null);
            user.assigned_number = ob.get("assigned_number", null);
            user.event = ob.get("event", null);
            user.name = ob.get("name", null);
            user.email = ob.get("email", null);
            user.status = ob.get("status", null);

            user.crew_count = ob.get("crew_count", null);

            user.accepted_by = ob.get("accepted_by", null);
            user.payment_type = ob.get("payment_type", null);

            user.timestamp_creation = ApiUtils.deserializeDateTime(ob.opt("timestamp_creation", null));
            user.timestamp_acceptance = ApiUtils.deserializeDateTime(ob.opt("timestamp_acceptance", null));

            registered.push(user);
          }

          action.registered = registered;
        }
        action.OnCommitFinished.callback(sender, null);
      },
      function (/*Object*/ error) {
        action.OnCommitError.callback(sender, Engine.createError(error));
      }
    );

    this.mSession.SessionRequest("ATTENDANCE", result, request);
  }

  onAttendanceListDownloadRequested(/*VehicleList*/ sender, /*Void*/ params) {
    var action = sender;
    var request = new AttendanceDownloadCommit();

    request.setToken(action.token);

    var monitor = this;

    var result = new IServiceResult(
      function (/*Object*/ reply) {
        action.data = reply.get("data");
        action.OnDownloadFinished.callback(sender, null);
      },
      function (/*Object*/ error) {
        action.OnDownloadError.callback(sender, Engine.createError(error));
      }
    );

    this.mSession.SessionRequest("ATTENDANCE", result, request);
  }

  onAttendanceDetailsCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
    action.OnCommitRequested.register(this.onAttendanceDetailsCommitRequested.bind(this));
  }

  onAttendanceDetailsCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
    var action = sender;
    var request = new AttendanceDetailsCommit();

    request.setToken(action.token);

    var monitor = this;

    var result = new IServiceResult(
      function (/*Object*/ reply) {
        if (reply.get("registered")) {
          {
            let registered = [];
            let _registered = reply.get("registered");

            let size = _registered.length();
            for (var i = 0; i < size; ++i) {
              let ob = _registered.get(i);

              let user = new AttendingUser();

              user.external = ob.get("external", null);
              user.description = ob.get("description", null);
              user.assigned_number = ob.get("assigned_number", null);
              user.event = ob.get("event", null);
              user.name = ob.get("name", null);
              user.email = ob.get("email", null);
              user.status = ob.get("status", null);

              user.accepted_by = ob.get("accepted_by", null);
              user.payment_type = ob.get("payment_type", null);

              user.timestamp_creation = ApiUtils.deserializeDateTime(ob.opt("timestamp_creation", null));
              user.timestamp_acceptance = ApiUtils.deserializeDateTime(ob.opt("timestamp_acceptance", null));

              registered.push(user);
            }
            action.registered = registered;
          }
          {
            let vehicles = [];
            let _vehicles = reply.get("vehicles");
            let vsize = _vehicles.length();
            for (var i = 0; i < vsize; ++i) {
              let ob = _vehicles.get(i);

              let vehicle = new Vehicle();
              vehicle.external = ob.opt("external", null);
              vehicle.assigned_number = ob.opt("assigned_number", null);

              vehicle.league_class_shortname = ob.opt("league_class_shortname", null);

              vehicle.car_external = ob.opt("car_external", null);
              vehicle.car_shortname = ob.opt("car_shortname", null);

              vehicles.push(vehicle);
            }
            action.vehicles = vehicles;
          }
        }
        action.OnCommitFinished.callback(sender, null);
      },
      function (/*Object*/ error) {
        action.OnCommitError.callback(sender, Engine.createError(error));
      }
    );

    this.mSession.SessionRequest("ATTENDANCE", result, request);
  }

  onAttendanceRegisterCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
    action.OnCommitRequested.register(this.onAttendanceRegisterCommitRequested.bind(this));
  }

  onAttendanceRegisterCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
    var action = sender;
    var request = new AttendanceRegisterCommit();

    request.setType(action.type);
    request.setToken(action.token);

    request.setVehicle(action.vehicle);
    request.setCrewCount(action.crew_count);

    var monitor = this;

    var result = new IServiceResult(
      function (/*Object*/ reply) {
        action.custom_message = reply.opt("custom_message", null);
        action.email = reply.opt("email", null);
        let opts = reply.get("payment_options", null);
        if (null != opts) {
          let options = [];

          for (let i = 0; i < opts.length(); ++i) {
            let option = opts.get(i);

            let opt = {};
            opt.type = option.get("type", null);
            opt.amount = option.get("amount", null);

            options.push(opt);
          }

          action.payment_options = options;
        }
        action.OnCommitFinished.callback(sender, null);
      },
      function (/*Object*/ error) {
        action.OnCommitError.callback(sender, Engine.createError(error));
      }
    );

    this.mSession.SessionRequest("ATTENDANCE", result, request);
  }

  onAttendanceAcceptCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
    action.OnCommitRequested.register(this.onAttendanceAcceptCommitRequested.bind(this));
  }

  onAttendanceAcceptCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
    var action = sender;
    var request = new AttendanceAcceptCommit();

    request.setToken(action.token);
    request.setPaymentMethod(action.payment_method);
    request.setPaymentAmount(action.payment_amount);
    request.setPaymentDate(action.payment_date);

    var monitor = this;

    var result = new IServiceResult(
      function (/*Object*/ reply) {
        action.OnCommitFinished.callback(sender, null);
      },
      function (/*Object*/ error) {
        action.OnCommitError.callback(sender, Engine.createError(error));
      }
    );

    this.mSession.SessionRequest("ATTENDANCE", result, request);
  }

  onAttendanceRejectCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
    action.OnCommitRequested.register(this.onAttendanceRejectCommitRequested.bind(this));
  }

  onAttendanceRejectCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
    var action = sender;
    var request = new AttendanceRejectCommit();

    request.setToken(action.token);

    var monitor = this;

    var result = new IServiceResult(
      function (/*Object*/ reply) {
        action.OnCommitFinished.callback(sender, null);
      },
      function (/*Object*/ error) {
        action.OnCommitError.callback(sender, Engine.createError(error));
      }
    );

    this.mSession.SessionRequest("ATTENDANCE", result, request);
  }

  onAttendanceEmailCreated(/*Object*/ sender, /*{ApplicationsMine}*/ action) {
    action.OnTestRequested.register(this.onAttendanceEmailTestRequested.bind(this));
    action.OnCommitRequested.register(this.onAttendanceEmailCommitRequested.bind(this));
  }
  onAttendanceEmailTestRequested(/*VehicleList*/ sender, /*Void*/ params) {
    var action = sender;
    var request = new AttendanceEmailCommit();

    request.setTestMode(true);
    request.setEmailTitle(action.email_title);
    request.setEmailTemplate(action.email_template);
    request.setEmailReplyTo(action.email_reply_to);

    var monitor = this;

    var result = new IServiceResult(
      function (/*Object*/ reply) {
        action.OnTestFinished.callback(sender, null);
      },
      function (/*Object*/ error) {
        action.OnTestError.callback(sender, Engine.createError(error));
      }
    );

    this.mSession.SessionRequest("ATTENDANCE", result, request);
  }

  onAttendanceEmailCommitRequested(/*VehicleList*/ sender, /*Void*/ params) {
    var action = sender;
    var request = new AttendanceEmailCommit();

    request.setTokens(action.tokens);
    request.setEmailTitle(action.email_title);
    request.setEmailTemplate(action.email_template);
    request.setEmailReplyTo(action.email_reply_to);

    var monitor = this;

    var result = new IServiceResult(
      function (/*Object*/ reply) {
        action.OnCommitFinished.callback(sender, null);
      },
      function (/*Object*/ error) {
        action.OnCommitError.callback(sender, Engine.createError(error));
      }
    );

    this.mSession.SessionRequest("ATTENDANCE", result, request);
  }
}

import IServiceRequest from '../../connect/IServiceRequest'

export default class VehicleApplicationCommit extends IServiceRequest
{
    constructor()
    {
        super('www/vehicle/application/add');
    }

    setLeague(value)
    {
        this.appendRequest("league", value);
    }

    setLeagueClass(value)
    {
        this.appendRequest("class", value);
    }

    setLeagueEvent(value)
    {
        this.appendRequest("event", value);
    }

    setCrewCount(value)
    {
        this.appendRequest("crew_count", value);
    }

    setAssignedNumber(value)
    {
        this.appendRequest("assigned_number", value);
    }

    setCarShortname(value)
    {
        this.appendRequest("car_shortname", value);
    }

    setCarPower(value)
    {
        this.appendRequest("car_power", value);
    }    

    setDriverName(value)
    {
        this.appendRequest("driver_name", value);
    }

    setComment(value)
    {
        this.appendRequest("comment", value);
    }
}
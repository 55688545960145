import IConnectionFactory from '../internal/connect/IConnectionFactory';

export class Public /*extends IConnectionFactory*/
{
    constructor()
    {
        // super();
        this.mProtocol = window.lisev.protocol;
        this.mHost = window.lisev.host;
        this.mPath = window.lisev.path;
    }

    getProtocol()
    {
        return this.mProtocol;
    }

    getHost()
    {
        return this.mHost;
    }

    getPath()
    {
        return this.mPath;
    }
}
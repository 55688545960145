import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import update from "react-addons-update";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp, faScroll, faEye, faCog, faEdit } from '@fortawesome/free-solid-svg-icons'
import autoBind from 'react-autobind';
import './ResultsScreen.css'
import BaseScreenHeader from '../BaseScreenHeader';
import withRouter from '../../utils/withRouter';
const queryString = require('query-string');

class ResultsScreen extends BaseScreenHeader {
  static defaultProps = {
      session: null,
      event: null,
      player: null,
      show_best: true
  }
  constructor(props)
  {
    super(props)
    autoBind(this);
  }


  onCleanupParameters()
  {
    if ( null != this.state.timeout )
    {
        clearTimeout(this.state.timeout);
        this.state.timeout = null;
    }
    if ( null != this.state.action )
    {
      let action = this.state.action;
      action.OnCommitRequested.unregister(this.onRaceResultCommitRequested);
      action.OnCommitFinished.unregister(this.onRaceResultCommitFinished);
      action.OnCommitError.unregister(this.onRaceResultCommitError);

      this.state.action = null;
    }
  }
  onInitParameters(props)
  {
    this.state.event_id = props.params.eventId;
    this.state.player = props.params.playerId;
    this.state.class_id = props.params.classId;
    this.state.sort = null; // "race_time";
    this.state.sort_ascending = true;
    this.state.timestamp = null;
    this.state.is_finished = null;
    this.state.event = this.createEvent(props.params.eventId);
    this.state.classes = this.createClasses(props.params.eventId);
    this.state.players = this.createPlayers(props.params.eventId);
    this.state.league_id = this.getLeagueId(props.params.eventId);
    this.state.fetching = false;
    this.state.results = null;
    this.state.rawResults = null;
    this.state.raw_query = null;
    this.state.allowed_players = null;
    this.state.bestValues = {};

    var url = "/results/" + props.params.eventId;
    if ( props.params.playerId )
    {
        url = url + "/player/" + props.params.playerId;
    }
    else if ( props.params.classId )
    {
        url = url + "/class/" + props.params.classId;
    }
    else
    {
        const search = props.search;
        if ( search.get('players') )
        {
            this.state.allowed_players = search.get('players').split(',');
            this.state.raw_query = "players=" + this.state.allowed_players.join(",");
        }
    }
    this.state.raw_url = url;
      
    if ( null != this.state.event )
    {
        this.state.action = this.fetchResults();
    } 

    return true;
  }

  bindDelegates()
  {
      this.state.timeout = null;
      this.session.onLeaguesChanged.register(this.onLeaguesChanged);
      super.bindDelegates();

  }
  unbindDelegates()
  {
      if ( null != this.state.timeout )
      {
          clearTimeout(this.state.timeout);
      }
      this.session.onLeaguesChanged.unregister(this.onLeaguesChanged);
      super.unbindDelegates();
  }
  onLeaguesChanged(sender, data)
  {
      let event = this.createEvent(this.props.params.eventId);
      let classes = this.createClasses(this.props.params.eventId);
      let players = this.createPlayers(this.props.params.eventId);
      let league_id = this.getLeagueId(this.props.params.eventId);

      let action = this.fetchResults();
      this.setState((oldState) => { return update(oldState, { event: { $set: event }, classes: { $set: classes }, players: { $set: players }, action: { $set: action }, league_id: { $set: league_id }})});
  }


  createEvent(eventId)
  {
      let retval = {};

      let data = this.session.getLeagues();
      if ( null == data )
      {
          return null;
      }
      for ( const l of data )
      {
          if ( null != l.getEvents() )
          {
            for ( const e of l.getEvents() )
            {
                if ( eventId !== e.getExternal() && eventId !== l.getExternal() )
                {
                    continue;
                }
                let event = {};
                event.external = e.getExternal();
                event.shortname = e.getShortname();
                event.description = e.getDescription();
                event.location = e.getLocation();
                event.thumbnail = e.getThumbnail();
                event.timestamp_start = e.getTimestampStart();
                
                event.league_id = l.getExternal();
                event.league_shortname = l.getShortname();
                
                retval[event.external] = event;
            }
          }
      }
      return retval;
  }

  getLeagueId(eventId)
  {
      let data = this.session.getLeagues();
      if ( null == data )
      {
          return null;
      }
      for ( const l of data )
      {
          if ( null != l.getEvents() )
          {
            if ( eventId === l.getExternal() )
            {
                return eventId;
            }
            for ( const e of l.getEvents() )
            {
                if ( eventId === e.getExternal() )
                {
                    continue;
                }
                return l.getExternal();
            }
          }
      }
      return null;
  }
  createClasses(eventId)
  {
    let retval = {};

    let data = this.session.getLeagues();
    if ( null == data )
    {
        return null;
    }
    for ( const l of data )
    {
        if ( null != l.getEvents() )
        {
          for ( const e of l.getEvents() )
          {
              if ( eventId !== e.getExternal() && eventId !== l.getExternal() )
              {
                  continue;
              }

              for ( const cls of l.getClasses() )
              {
                  let data = {}
                  data.external = cls.getExternal();
                  data.shortname = cls.getShortname();
                  data.description = cls.getDescription();
                  data.priority = cls.getPriority();

                  retval[data.external] = data;
              }
              break;
              
          }
        }
    }
    return retval;
  }

  
  createPlayers(eventId)
  {
    let retval = {};

    let data = this.session.getLeagues();
    if ( null == data )
    {
        return null;
    }
    for ( const l of data )
    {
        if ( null != l.getEvents() )
        {
          for ( const e of l.getEvents() )
          {
              if ( eventId !== e.getExternal() && eventId !== l.getExternal() )
              {
                  continue;
              }

              for ( const cls of l.getPlayers() )
              {
                  let data = {}
                  data.external = cls.getExternal();
                  data.player = cls.getPlayer();
                  data.class = cls.getClass();
                  data.vehicle = cls.getVehicle();
                  data.player_name = cls.getPlayerName();
                  data.player_car = cls.getPlayerCar();
                  data.car_power = cls.getCarPower();
                  data.car_mass = cls.getCarMass();

                  retval[data.player] = data;
              }
              break;
          }
        }
    }
    return retval;
  }

  fetchResults()
  {
      var action = this.session.createRaceResults();
      action.setEventId(this.state.event_id);
      if ( this.state.player )
      {
          action.setPlayerId(this.state.player);
      }
      if ( this.state.class_id )
      {
          action.setClassId(this.state.class_id);
      }
      if ( this.state.allowed_players )
      {
          action.setAllowedPlayers(this.state.allowed_players);
      }
      
      action.OnCommitRequested.register(this.onRaceResultCommitRequested);
      action.OnCommitFinished.register(this.onRaceResultCommitFinished);
      action.OnCommitError.register(this.onRaceResultCommitError);
      action.Commit();

      return action;
  }

  onRaceResultCommitRequested(sender, params)
  {
    this.beginAsyncOperation();
    this.setState((oldState) => { return update(oldState, { fetching: { $set: true } })});
  }
  onRaceResultCommitFinished(sender, params)
  {
      this.endAsyncOperation();
    let action = sender;
    let bestValues = this.bestValues(action.getResults());
    let rawResults = this.filterResults(this.createResults(action.getResults()));
    let currentSort = this.state.sort;
    if ( null == currentSort )
    {
        currentSort = "time_1320ft";
        if ( null != bestValues.race_time )
        {
            currentSort = "race_time";
        }
    }
    let results = this.sortResults(currentSort, this.state.sort_ascending, rawResults);
    let timestamp = action.getTimestamp();
    let is_finished = action.isFinished();
    if ( !is_finished )
    {
        this.state.timeout = setTimeout(() => action.Commit(), action.getInterval() * 1000 );
    }
    this.setState((oldState) => { return update(oldState, { fetching: { $set: false }, sort: { $set: currentSort }, bestValues: { $set: bestValues }, rawResults: { $set: rawResults }, results: { $set: results }, timestamp: { $set: timestamp }, is_finished: { $set: is_finished } })});
  }
  onRaceResultCommitError(sender, params)
  {
      this.endAsyncOperation();
    this.setState((oldState) => { return update(oldState, { fetching: { $set: false } })});
  }

  toggleSort(column, defaultOrder)
  {
      let nextSort = this.state.sort;
      let nextOrder = this.state.sort_ascending;
      if ( nextSort === column )
      {
          nextOrder = !nextOrder;
      }
      else
      {
          nextSort = column;
          nextOrder = defaultOrder;
      }
      let results = this.sortResults(nextSort, nextOrder, this.state.rawResults);
      this.setState((oldState) => { return update(oldState, { sort: {$set: nextSort}, sort_ascending: { $set: nextOrder}, results: { $set: results }})});
      
  }

  filterResults(data)
  {
      data = data.filter((x) => { return x.is_public; });
      if ( this.props.show_best )
      {
          let retval = [];
          var tmp = new Map();

          for ( const r of data )
          {
              if ( r.is_invalid )
              {
                  continue;
              }
              let id = r.player;
              if ( tmp.has(id) )
              {
                  let rhs = tmp.get(id);
                  if ( null != rhs.race_time )
                  {
                    if ( parseFloat(rhs.race_time) < parseFloat(r.race_time) )
                    {
                        continue;
                    }
                    else if ( parseFloat(rhs.race_time) === parseFloat(r.race_time) )
                    {
                        if ( parseFloat(rhs.speed_1320ft) > parseFloat(r.speed_1320ft) )
                        {
                            continue;
                        }
                    }
                  }
                  else
                  {
                    if ( parseFloat(rhs.time_1320ft) < parseFloat(r.time_1320ft) )
                    {
                        continue;
                    }
                    else if ( parseFloat(rhs.time_1320ft) === parseFloat(r.time_1320ft) )
                    {
                        if ( parseFloat(rhs.speed_1320ft) > parseFloat(r.speed_1320ft) )
                        {
                            continue;
                        }
                    }
                  }
              }
              tmp.set(id, r);              
          }

          for (var value of tmp.values())
          {
              retval.push(value);
          }

          return retval;
      }

      return data;
  }

  sortResults(column, ascending, data)
  {
      if ( "timestamp_race" === column )
      {
        data.sort( (l, r) => 
            {
                let lh = l[column].unix();
                let rh = r[column].unix();
                if ( ascending )
                {
                    return lh - rh;
                }
                return rh - lh;
            }
        );
      }
      else if ( "event_name" === column )
      {
          data.sort( (l, r) =>
          {
              let lh = l[column];
              let rh = r[column];
              if ( ascending )
              {
                return lh.localeCompare(rh);
              }
              return rh.localeCompare(lh);
          });
      }
      else
      {
            data.sort( (l, r) => 
            {
                let lh = parseFloat(l[column]);
                let rh = parseFloat(r[column]);
                if ( ascending )
                {
                if ( isNaN(lh) && !isNaN(rh) )
                {
                    return 1;
                }
                else if ( !isNaN(lh) && isNaN(rh) )
                {
                    return -1;
                }
                    if ( l['is_invalid'] && !r['is_invalid'] )
                    {
                        return 1;
                    }
                    else if ( !l['is_invalid'] && r['is_invalid'] )
                    {
                        return -1;
                    }
                    if ( lh <= 0.0 && rh > 0.0 )
                    {
                    return 1;
                    }
                    else if ( lh > 0.0 && rh <= 0.0 )
                    {
                    return -1;
                    }
                    let retval = lh - rh;
                    if ( lh <= 0.0 && rh <= 0.0 )
                    {
                    retval = rh - lh;
                    }
                    if ( 0 === retval )
                    {
                        let lhh = l["timestamp_race"].unix();
                        let rhh = r["timestamp_race"].unix();
                        return lhh - rhh;
                    }
                    return retval;
                }
                if ( isNaN(lh) && !isNaN(rh) )
                {
                    return 1;
                }
                else if ( !isNaN(lh) && isNaN(rh) )
                {
                    return -1;
                }
                if ( l['is_invalid'] && !r['is_invalid'] )
                {
                    return 1;
                }
                else if ( !l['is_invalid'] && r['is_invalid'] )
                {
                    return -1;
                }
                if ( lh <= 0.0 && rh > 0.0 )
                {
                    return 1;
                }
                else if ( lh > 0.0 && rh <= 0.0 )
                {
                    return -1;
                }
                let retval = rh - lh;
                if ( lh <= 0.0 && rh <= 0.0 )
                {
                    retval = lh - rh;
                }
                if ( 0 === retval )
                {
                    let lhh = l["timestamp_race"].unix();
                    let rhh = r["timestamp_race"].unix();
                    return lhh - rhh;
                }
                return retval;
            }
        );
    }
      return data;
  }

  bestValues(data)
  {
    if ( null === data )
    {
        return null;
    }

    let retval = {
        reaction_time: null,
        time_60ft: null,
        time_660ft: null,
        time_1000ft: null,
        time_1320ft: null,
        speed_660ft: null,
        speed_1000ft: null,
        speed_1320ft: null,
        race_time: null
    };

    for ( const r of data )
    {
        if ( r.getIsInvalid() )
        {
            continue;
        }
        if ( !r.getIsPublic() )
        {
            continue;
        }
        if ( null != r.getReactionTime() )
        {
            let rt = parseFloat(r.getReactionTime());
            if ( !isNaN(rt) && rt > 0.000 )
            {
                if ( null == retval.reaction_time || retval.reaction_time > rt )
                {
                    retval.reaction_time = rt;
                }
            }
        }
        if ( null != r.getTime60ft() )
        {
            let t60ft = parseFloat(r.getTime60ft())
            if ( !isNaN(t60ft) && t60ft > 0.000 )
            {
                if ( null == retval.time_60ft || retval.time_60ft > t60ft )
                {
                    retval.time_60ft = t60ft;
                }
            }
        }
        if ( null != r.getTime660ft() )
        {
            let t660ft = parseFloat(r.getTime660ft())
            if ( !isNaN(t660ft) && t660ft > 0.000 )
            {
                if ( null == retval.time_660ft || retval.time_660ft > t660ft )
                {
                    retval.time_660ft = t660ft;
                }
            }
        }
        if ( null != r.getTime1000ft() )
        {
            let t1000ft = parseFloat(r.getTime1000ft())
            if ( !isNaN(t1000ft) && t1000ft > 0.000 )
            {
                if ( null == retval.time_1000ft || retval.time_1000ft > t1000ft )
                {
                    retval.time_1000ft = t1000ft;
                }
            }
        }
        if ( null != r.getTime1320ft() )
        {
            let t1320ft = parseFloat(r.getTime1320ft())
            if ( !isNaN(t1320ft) && t1320ft > 0.000 )
            {
                if ( null == retval.time_1320ft || retval.time_1320ft > t1320ft )
                {
                    retval.time_1320ft = t1320ft;
                }
            }
        }
        if ( null != r.getRaceTime() )
        {
            let trace = parseFloat(r.getRaceTime())
            if ( !isNaN(trace) && trace > 0.000 )
            {
                if ( null == retval.race_time || retval.race_time > trace )
                {
                    retval.race_time = trace;
                }
            }
        }
        if ( null != r.getSpeed660ft() )
        {
            let s660ft = parseFloat(r.getSpeed660ft())
            if ( !isNaN(s660ft) && s660ft > 0.000 )
            {
                if ( null == retval.speed_660ft || retval.speed_660ft < s660ft )
                {
                    retval.speed_660ft = s660ft;
                }
            }
        }
        if ( null != r.getSpeed1000ft() )
        {
            let s1000ft = parseFloat(r.getSpeed1000ft())
            if ( !isNaN(s1000ft) && s1000ft > 0.000 )
            {
                if ( null == retval.speed_1000ft || retval.speed_1000ft < s1000ft )
                {
                    retval.speed_1000ft = s1000ft;
                }
            }
        }        
        if ( null != r.getSpeed1320ft() )
        {
            let s1320ft = parseFloat(r.getSpeed1320ft())
            if ( !isNaN(s1320ft) && s1320ft > 0.000 )
            {
                if ( null == retval.speed_1320ft || retval.speed_1320ft < s1320ft )
                {
                    retval.speed_1320ft = s1320ft;
                }
            }
        }
    }

    return retval;
  }

  createResults(data)
  {
      if ( null === data )
      {
          return null;
      }

      let retval = [];

      for ( const r of data )
      {
        let result = {};
        
        result.token = r.getToken();
        result.player = r.getPlayer();
        result.timestamp_race = r.getTimestampRace();
        result.is_invalid = r.getIsInvalid();
        result.is_public = r.getIsPublic();

        result.reaction_time = r.getReactionTime();
        result.time_60ft = r.getTime60ft();
        result.time_660ft = r.getTime660ft();
        result.time_1000ft = r.getTime1000ft();
        result.time_1320ft = r.getTime1320ft();
        result.race_time = r.getRaceTime();
        result.speed_660ft = r.getSpeed660ft();
        result.speed_1000ft = r.getSpeed1000ft();
        result.speed_1320ft = r.getSpeed1320ft();

        result.class_id = null;
        result.class_name = null;
        result.vehicle_id = null;
        result.event_name = null;
        if ( null !== r.getEvent() )
        {
            let mapping = this.state.event[r.getEvent()];
            if ( mapping )
            {
                result.event_name = mapping.shortname;
            }
        }

        if ( null !== result.player )
        {
            let mapping = this.state.players[result.player];
            if ( mapping )
            {
                result.class_id = mapping.class;
                result.vehicle_id = mapping.vehicle;
                result.player_name = mapping.player_name;
                result.player_car = mapping.player_car;
            }
        }

        if ( null !== result.class_id )
        {
            let cls = this.state.classes[result.class_id];
            if ( cls )
            {
                result.class_name = cls.shortname;
            }

        }

        retval.push(result);
      }

      return retval;
  }

  filterCaption(caption, field)
  {
      if ( field != this.state.sort )
      {
          return caption;
      }
      if ( this.state.sort_ascending )
      {
          return caption + " \u2191";
      }
      return caption + " \u2193";
  }

  renderScreen(child)
  {
      if ( null === this.state.event )
      {
          return super.renderScreen(null);
          return super.renderScreen(
              <div>Trwa wczytywanie danych</div>
          );
      }
      let canEditResults = ( this.props.session.getPermissions().hasPermissionContext('can_edit_results', this.state.league_id) || this.props.session.getPermissions().hasPermissionGlobal('can_edit_results') );

      var results = null;
      if ( this.state.results )
      {
          let index = 1;
          let show_best = '/best';
          do
          {
            if ( !this.props.show_best )
            {
                break;
            }
            if ( !this.state.class_id )
            {
                break;
            }
            if ( this.state.player )
            {
                break;
            }
            show_best = '';
          } while(false);
              
          results = this.state.results.map( (x) => {
              let player_display = x.player;
              if ( x.player_name )
              {
                  player_display = x.player + " - " + x.player_name;
              }
              let className = "";
              if ( x.is_invalid )
              {
                  className = "w3-invalid";
              }

              return ( <tr key={x.token} className={className}>
                <td data-title="LP" className="">{index++}</td>
                <td data-title={this.filterCaption("Zawodnik", "player")} className="" onClick={(x) => this.toggleSort("player", true)}>
                    <Link to={'/results/' + this.state.event_id + '/player/' + x.player}>{player_display}</Link>
                </td>
                <td data-title={this.filterCaption("Wydarzenie", "event_name")} className="" onClick={(x) => this.toggleSort("event_name", true)}>{x.event_name}</td>
                <td data-title={this.filterCaption("Data", "timestamp_race")} className="" onClick={(x) => this.toggleSort("timestamp_race", true)}>{x.timestamp_race.format('YYYY-MM-DD HH:mm:ss')}</td>
                <td data-title={this.filterCaption("Klasa", "class_id")} className="" onClick={(x) => this.toggleSort("class_id", true)}>
                    {x.class_id !== null &&
                        <Link to={'/results/' + this.state.event_id + '/class/' + x.class_id + show_best}>{x.class_name}</Link>
                    }
                </td>
                <td data-best={this.state.bestValues.reaction_time == x.reaction_time ? "yes" : "no" } data-title={this.filterCaption("RT", "reaction_time")} className="" onClick={(x) => this.toggleSort("reaction_time", true)}>{x.reaction_time}</td>
                <td data-best={null != this.state.bestValues.time_60ft && this.state.bestValues.time_60ft == x.time_60ft ? "yes" : "no" } data-title={this.filterCaption("ET 60ft", "time_60ft")} className="" onClick={(x) => this.toggleSort("time_60ft", true)}>{x.time_60ft}</td>
                <td data-best={null != this.state.bestValues.time_660ft && this.state.bestValues.time_660ft == x.time_660ft ? "yes" : "no" } data-title={this.filterCaption("ET 1/8", "time_660ft")} className="" onClick={(x) => this.toggleSort("time_660ft", true)}>{x.time_660ft}</td>
                <td data-best={null != this.state.bestValues.speed_660ft && this.state.bestValues.speed_660ft == x.speed_660ft ? "yes" : "no" } data-title={this.filterCaption("Speed 1/8", "speed_660ft")} className="" onClick={(x) => this.toggleSort("speed_660ft", true)}>{x.speed_660ft}</td>                
                {null != this.state.bestValues.time_1000ft && 
                    <td data-best={null != this.state.bestValues.time_1000ft && this.state.bestValues.time_1000ft == x.time_1000ft ? "yes" : "no" } data-title={this.filterCaption("ET 1000ft", "time_1000ft")} className="" onClick={(x) => this.toggleSort("time_1000ft", true)}>{x.time_1000ft}</td>
                }
                {null != this.state.bestValues.speed_1000ft && 
                    <td data-best={null != this.state.bestValues.speed_1000ft && this.state.bestValues.speed_1000ft == x.speed_1000ft ? "yes" : "no" } data-title={this.filterCaption("Speed 1/4", "speed_1000ft")} className="" onClick={(x) => this.toggleSort("speed_1000ft", true)}>{x.speed_1000ft}</td>
                }
                {null != this.state.bestValues.time_1320ft && 
                    <td data-best={null != this.state.bestValues.time_1320ft && this.state.bestValues.time_1320ft == x.time_1320ft ? "yes" : "no" } data-title={this.filterCaption("ET 1/4", "time_1320ft")} className="" onClick={(x) => this.toggleSort("time_1320ft", true)}>{x.time_1320ft}</td>
                }
                {null != this.state.bestValues.speed_1320ft && 
                    <td data-best={null != this.state.bestValues.speed_1320ft && this.state.bestValues.speed_1320ft == x.speed_1320ft ? "yes" : "no" } data-title={this.filterCaption("Speed 1/4", "speed_1320ft")} className="" onClick={(x) => this.toggleSort("speed_1320ft", true)}>{x.speed_1320ft}</td>
                }
                {null != this.state.bestValues.race_time && 
                    <td data-best={null != this.state.bestValues.race_time && this.state.bestValues.race_time == x.race_time ? "yes" : "no" } data-title={this.filterCaption("RACE", "race_time")} className="" onClick={(x) => this.toggleSort("race_time", true)}>{x.race_time}</td>
                }
                <td data-title="Akcje" className="w3-inline">
                    <Link className="w3-padding-small" to={'/race/' + x.token}><FontAwesomeIcon icon={faEye}/></Link>
                    <Link className="w3-padding-small" to={'/timeslip/' + x.token}><FontAwesomeIcon icon={faScroll}/></Link>
                    { canEditResults && <Link className="w3-padding-small" to={'/manage/result/' + x.token + '/edit'}><FontAwesomeIcon icon={faEdit}/></Link> }
                </td>
                

              </tr>);
            });
      }

    var filters = [];
    if ( this.state.event )
    {
        let url_filters = "";
        if ( this.props.params.playerId )
        {
            url_filters = "/player/" + this.props.params.playerId;
        }
        else if ( this.props.params.classId )
        {
            url_filters = "/class/" + this.props.params.classId;
        }
        if ( this.props.show_best )
        {
            url_filters = url_filters + "/best";
        }
        if ( this.state.allowed_players )
        {
            url_filters = url_filters + "?" + this.state.raw_query;
        }

        //var concated = "";
        for ( const id in this.state.event )
        { 
            let x = this.state.event[id];
            filters.push(
                <tr key={x.external} className="w3-padding-small">
                    <td className="w3-medium">Wydarzenie</td>
                    <td className="w3-medium"><Link to={'/results/' + x.external + url_filters}>{x.shortname}</Link></td>
                    <td className="w3-medium"><Link to={'/results/' + x.league_id + url_filters}>{x.league_shortname}</Link></td>
                </tr>
            );
            // concated = concated + " " + x.shortname + " @ " + x.league_shortname;
        }
        // events = concated.trim();
    }
    if ( this.state.class_id )
    {
        let cls = this.state.classes[this.state.class_id];
        if ( null !== cls )
        {
            filters.push(
                <tr key={cls.external} className="w3-padding-small">
                    <td className="w3-medium">Klasa</td>
                    <td className="w3-medium"></td>
                    <td className="w3-medium">{cls.shortname}</td>
                </tr>
            );
        }
    }

    if ( this.state.player )
    {
        let plr = this.state.players[this.state.player];
        if ( plr )
        {
            filters.push(
                <tr key={'player-' + this.state.player + '-all'} className="w3-padding-small">
                    <td className="w3-medium">Zawodnik</td>
                    <td className="w3-medium">{this.state.player}</td>
                    <td className="w3-medium"><Link to={'/player/' + plr.vehicle}>{plr.player_name}</Link></td>
                </tr>
            );
        }
        else
        {
            filters.push(
                <tr key={'player-' + this.state.player} className="w3-padding-small">
                    <td className="w3-medium">Zawodnik</td>
                    <td className="w3-medium">{this.state.player}</td>
                    <td className="w3-medium"></td>
                </tr>
            );
        }
    }
    else if ( this.state.allowed_players )
    {
        this.state.allowed_players.map((x) => {
            let plr = this.state.players[x];
            if ( plr )
            {
                filters.push(
                    <tr key={'player-' + x + '-all'} className="w3-padding-small">
                        <td className="w3-medium">Zawodnik</td>
                        <td className="w3-medium">{x}</td>
                        <td className="w3-medium"><Link to={'/player/' + plr.vehicle}>{plr.player_name}</Link></td>
                    </tr>
                );
            }
            else
            {
                filters.push(
                    <tr key={'player-' + x} className="w3-padding-small">
                        <td className="w3-medium">Zawodnik</td>
                        <td className="w3-medium">{x}</td>
                        <td className="w3-medium"></td>
                    </tr>
                );
            }
        })
    }
    if ( !this.state.player )
    {
        filters.push(
            <tr key="best-runs" className="w3-padding-small">
                <td className="w3-medium" colSpan={2}>Tylko najlepsze przejazdy</td>
                <td className="w3-medium">
                    {this.props.show_best && <Link to={this.state.raw_url + (this.state.raw_query ? "?" + this.state.raw_query : "") }>TAK</Link>}
                    {!this.props.show_best && <Link to={this.state.raw_url + '/best' + (this.state.raw_query ? "?" + this.state.raw_query : "")}>NIE</Link>}
                </td>
            </tr>
        );
    }

    //   let event = this.state.event;
    return super.renderScreen(
        <div className="w3-container">
            <h5>
                <span className="w3-half">Aktywne filtry:</span>
                <span className="w3-half">
                    {this.state.timestamp && 
                        <div>Dane z: {this.state.timestamp.format('YYYY-MM-DD HH:mm:ss')}</div>
                    }                
                </span>
            </h5>
            <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
                <thead>
                    <tr className="w3-hide">
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {filters}
                </tbody>
            </table>
            <div className="w3-responsive-table">
                <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
                    <thead className="">
                        <tr>
                            <th className="">LP</th>
                            <th className="" onClick={(x) => this.toggleSort("player", false)}>Zawodnik
                                {this.state.sort === "player" && this.state.sort_ascending && <FontAwesomeIcon icon={faAngleUp} className="results-order"/>}
                                {this.state.sort === "player" && !this.state.sort_ascending && <FontAwesomeIcon icon={faAngleDown} className="results-order"/>}
                            </th>
                            <th className="" onClick={(x) => this.toggleSort("event_name", true)}>Wydarzenie
                                {this.state.sort === "event_name" && this.state.sort_ascending && <FontAwesomeIcon icon={faAngleUp} className="results-order"/>}
                                {this.state.sort === "event_name" && !this.state.sort_ascending && <FontAwesomeIcon icon={faAngleDown} className="results-order"/>}
                            </th>
                            <th className="" onClick={(x) => this.toggleSort("timestamp_race", true)}>Data
                                {this.state.sort === "timestamp_race" && this.state.sort_ascending && <FontAwesomeIcon icon={faAngleUp} className="results-order"/>}
                                {this.state.sort === "timestamp_race" && !this.state.sort_ascending && <FontAwesomeIcon icon={faAngleDown} className="results-order"/>}
                            </th>
                            <th className="" onClick={(x) => this.toggleSort("class_id", true)}>Klasa
                                {this.state.sort === "class_id" && this.state.sort_ascending && <FontAwesomeIcon icon={faAngleUp} className="results-order"/>}
                                {this.state.sort === "class_id" && !this.state.sort_ascending && <FontAwesomeIcon icon={faAngleDown} className="results-order"/>}
                            </th>
                            <th className="" onClick={(x) => this.toggleSort("reaction_time", true)}>RT
                                {this.state.sort === "reaction_time" && this.state.sort_ascending && <FontAwesomeIcon icon={faAngleUp} className="results-order"/>}
                                {this.state.sort === "reaction_time" && !this.state.sort_ascending && <FontAwesomeIcon icon={faAngleDown} className="results-order"/>}
                            </th>
                            <th className="" onClick={(x) => this.toggleSort("time_60ft", true)}>ET 60ft
                                {this.state.sort === "time_60ft" && this.state.sort_ascending && <FontAwesomeIcon icon={faAngleUp} className="results-order"/>}
                                {this.state.sort === "time_60ft" && !this.state.sort_ascending && <FontAwesomeIcon icon={faAngleDown} className="results-order"/>}
                            </th>
                            <th className="" onClick={(x) => this.toggleSort("time_660ft", true)}>ET 660ft
                                {this.state.sort === "time_660ft" && this.state.sort_ascending && <FontAwesomeIcon icon={faAngleUp} className="results-order"/>}
                                {this.state.sort === "time_660ft" && !this.state.sort_ascending && <FontAwesomeIcon icon={faAngleDown} className="results-order"/>}
                            </th>
                            <th className="" onClick={(x) => this.toggleSort("speed_660ft", false)}>SPEED 660ft
                                {this.state.sort === "speed_660ft" && this.state.sort_ascending && <FontAwesomeIcon icon={faAngleUp} className="results-order"/>}
                                {this.state.sort === "speed_660ft" && !this.state.sort_ascending && <FontAwesomeIcon icon={faAngleDown} className="results-order"/>}
                            </th>
                            {null != this.state.bestValues.time_1000ft && 
                                <th className="" onClick={(x) => this.toggleSort("time_1000ft", true)}>ET 1000ft 
                                    {this.state.sort === "time_1000ft" && this.state.sort_ascending && <FontAwesomeIcon icon={faAngleUp} className="results-order"/>}
                                    {this.state.sort === "time_1000ft" && !this.state.sort_ascending && <FontAwesomeIcon icon={faAngleDown} className="results-order"/>}
                                </th>
                            }
                            {null != this.state.bestValues.speed_1000ft && 
                                <th className="" onClick={(x) => this.toggleSort("speed_1000ft", false)}>SPEED 1000ft
                                    {this.state.sort === "speed_1000ft" && this.state.sort_ascending && <FontAwesomeIcon icon={faAngleUp} className="results-order"/>}
                                    {this.state.sort === "speed_1000ft" && !this.state.sort_ascending && <FontAwesomeIcon icon={faAngleDown} className="results-order"/>}
                                </th>
                            }
                            {null != this.state.bestValues.time_1320ft && 
                                <th className="" onClick={(x) => this.toggleSort("time_1320ft", true)}>ET 1320ft 
                                    {this.state.sort === "time_1320ft" && this.state.sort_ascending && <FontAwesomeIcon icon={faAngleUp} className="results-order"/>}
                                    {this.state.sort === "time_1320ft" && !this.state.sort_ascending && <FontAwesomeIcon icon={faAngleDown} className="results-order"/>}
                                </th>
                            }
                            {null != this.state.bestValues.speed_1320ft && 
                                <th className="" onClick={(x) => this.toggleSort("speed_1320ft", false)}>SPEED 1320ft
                                    {this.state.sort === "speed_1320ft" && this.state.sort_ascending && <FontAwesomeIcon icon={faAngleUp} className="results-order"/>}
                                    {this.state.sort === "speed_1320ft" && !this.state.sort_ascending && <FontAwesomeIcon icon={faAngleDown} className="results-order"/>}
                                </th>
                            }
                            {null != this.state.bestValues.race_time && 
                                <th className="" onClick={(x) => this.toggleSort("race_time", true)}>RACE 
                                    {this.state.sort === "race_time" && this.state.sort_ascending && <FontAwesomeIcon icon={faAngleUp} className="results-order"/>}
                                    {this.state.sort === "race_time" && !this.state.sort_ascending && <FontAwesomeIcon icon={faAngleDown} className="results-order"/>}
                                </th>
                            }
                            <th className="">&nbsp;
                            </th>
                        </tr>
                    </thead>
                    {null != results && 
                        <tbody className="">
                            {results}
                        </tbody>
                    }
                </table>
            </div>
        </div>
    )
  }
}
export default withRouter(ResultsScreen);
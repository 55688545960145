import Event from '../property/Event';

export default class ITimeslip
{
    getToken()
    {
        throw new TypeError("Method not implemented");
    }

    OnCommitRequested = new Event();
    OnCommitFinished = new Event();
    OnCommitError = new Event();

    Commit()
    {
        throw new TypeError("Method not implemented");
    }

    getLeagueName()
    {
        throw new TypeError("Method not implemented");
    }

    getEventName()
    {
        throw new TypeError("Method not implemented");
    }

    getPlayerName()
    {
        throw new TypeError("Method not implemented");
    }
    
    getPlayerNumber()
    {
        throw new TypeError("Method not implemented");
    }
    
    getPlayerTeam()
    {
        throw new TypeError("Method not implemented");
    }
    
    getPlayerCar()
    {
        throw new TypeError("Method not implemented");
    }

    getPlayerClass()
    {
        throw new TypeError("Method not implemented");
    }

    getReactionTime()
    {
        throw new TypeError("Method not implemented");
    }

    getTime60ft()
    {
        throw new TypeError("Method not implemented");
    }
    
    getTime660ft()
    {
        throw new TypeError("Method not implemented");
    }
    
    getTime1320ft()
    {
        throw new TypeError("Method not implemented");
    }

    getSpeed660ft()
    {
        throw new TypeError("Method not implemented");
    }

    getSpeed1320ft()
    {
        throw new TypeError("Method not implemented");
    }

    getTimestampRace()
    {
        throw new TypeError("Method not implemented");
    }    

    getIsInvalid()
    {
        throw new TypeError("Method not implemented");
    }   

    getIsPublic()
    {
        throw new TypeError("Method not implemented");
    }   

    getPictureDriver()
    {
        throw new TypeError("Method not implemented");
    }

    getPictureCar()
    {
        throw new TypeError("Method not implemented");
    }
}
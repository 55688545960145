
class SoftaxSecurityProvider
{
    constructor()
    {

    }

    createSecurity(input)
    {
        return btoa(input);
    }
};

export default SoftaxSecurityProvider;
import IServiceRequest from "../../connect/IServiceRequest";

export default class AttendanceRegisterCommit extends IServiceRequest
{
    constructor()
    {
        super('www/attendance/add');
    }
    
    setType(value)
    {
        this.appendRequest("type", value);
    }

    setToken(value)
    {
        this.appendRequest("token", value);
    }

    setVehicle(value)
    {
        this.appendRequest("vehicle", value);
    }   
    
    setCrewCount(value)
    {
        this.appendRequest("crew_count", value);
    }    
}

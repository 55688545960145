import IServiceRequest from '../../connect/IServiceRequest'

export default class SessionRecoverCommit extends IServiceRequest
{
    constructor()
    {
        super('www/session/recover');
    }

    setEmail(value)
    {
        this.appendRequest('email', value);
    }

    setSecurity(value)
    {
        this.appendRequest('security', value);
    }

}
import IServiceRequest from "../../connect/IServiceRequest";
import ApiUtils from "../ApiUtils";

export default class AttendanceAcceptCommit extends IServiceRequest
{
    constructor()
    {
        super('www/attendance/accept');
    }

    setToken(value)
    {
        this.appendRequest("token", value);
    }

    setPaymentMethod(value)
    {
        this.appendRequest("payment_method", value);
    }    

    setPaymentAmount(value)
    {
        this.appendRequest("payment_amount", value);
    }    

    setPaymentDate(value)
    {
        this.appendRequest("payment_date", value);
    }    
}
